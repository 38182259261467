<template>
  <v-dialog
    v-model="showConfirmDeleteLayerDialog"
    max-width="600px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-card-text>
        <div class="text-h5 py-3">Delete Related Layer?</div>

        <p>
          Are you sure you want to delete this related layer? This action cannot
          be undone.
        </p>

        <LayerCannotBeDeletedDialog
          :showLayerCannotBeDeletedDialog="showLayerCannotBeDeletedDialog"
          @cancel="showLayerCannotBeDeletedDialog = false"
        />
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn text @click="$emit('close')" color="primary"> Cancel </v-btn>
        <v-btn color="primary" @click="deleteLayer()"> Delete Layer </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import LayerCannotBeDeletedDialog from "@/components/layers/LayerCannotBeDeletedDialog.vue";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "DeleteLayerDialog",
  components: { LayerCannotBeDeletedDialog },
  data() {
    return {
      showLayerCannotBeDeletedDialog: false,
    };
  },
  props: {
    showConfirmDeleteLayerDialog: Boolean,
    layerForDeletion: Object,
  },
  methods: {
    async deleteLayer() {
      const mapServiceId = this.layerForDeletion.map_service_id;
      try {
        const {
          data: {
            results: { renderer },
          },
        } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
        const { renderer_id: rendererId } = renderer;
        if (rendererId) {
          await axios.delete(`${APIURL}/renderer_symbol/all/${rendererId}`);
        }
        const {
          data: { results: gisDataFields },
        } = await axios.get(`${APIURL}/gis_data_fields`, {
          params: {
            map_service_id: mapServiceId,
          },
        });
        const gisDataFieldOptions = gisDataFields
          .map((f) => f.gis_data_field_options)
          .flat();
        await Promise.all(
          gisDataFieldOptions.map((o) =>
            axios.delete(
              `${APIURL}/gis_data_field_options/${o.gis_data_field_option_id}`
            )
          )
        );
        await Promise.all(
          gisDataFields.map((f) =>
            axios.delete(`${APIURL}/gis_data_fields/${f.gis_data_field_id}`)
          )
        );
        await axios.delete(`${APIURL}/map_services/${mapServiceId}`);
        this.$emit("layer-deleted");
      } catch (error) {
        this.showLayerCannotBeDeletedDialog = true;
      }
    },
  },
};
</script>
