<template>
  <v-card class="m-2" id="editLayerPanel">
    <validation-observer ref="editLayerForm" v-slot="{ invalid }">
      <form @submit.prevent="editLayer">
        <v-toolbar dark color="primary" class="elevation-0" ref="toolbar">
          <v-toolbar-title v-if="layer">
            Edit Layer: {{ layer.service_name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$emit('edit-layer-form-close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text
          class="px-8"
          :style="{
            height: $vuetify.breakpoint.xsOnly ? `${contentHeight}px` : '60vh',
            'overflow-y': 'auto',
            background: '#fafafa',
            'max-height': $vuetify.breakpoint.xsOnly ? undefined : '60vh',
          }"
        >
          <v-row>
            <v-col cols="12">
              <UtiliSyncLayerForm
                v-if="layer.service_type === 'U'"
                :layer="layer"
                @layer-changed="onLayerChanged"
                @symbology-saved="$emit('edit-layer-form-submitted')"
                @open-edit-form-dialog="openEditFormDialog($event, true)"
                @create-new-form="createNewForm()"
                @utilisync-fields-saved="onUtiliSyncFieldsSaved"
                @gis-fields-saved="onGisFieldsSaved"
              />
              <LayerForm811
                v-else-if="layer.service_type === 'L'"
                :layer="layer"
                @layer-changed="layer = { ...layer, ...$event }"
                @symbology-saved="$emit('edit-layer-form-submitted')"
                @open-edit-form-dialog="openEditFormDialog($event, true)"
                @create-new-form="createNewForm()"
              />
              <FeatureServiceForm
                v-else-if="layer.service_type === 'F'"
                :layer="layer"
                @layer-changed="layer = { ...layer, ...$event }"
                @symbology-saved="$emit('edit-layer-form-submitted')"
                @open-edit-form-dialog="openEditFormDialog($event, true)"
                @create-new-form="createNewForm()"
                @utilisync-fields-saved="onUtiliSyncFieldsSaved"
                @gis-fields-saved="onGisFieldsSaved"
              />
              <MapTileServiceForm
                v-else-if="['S', 'T'].includes(layer.service_type)"
                :layer="layer"
                @layer-changed="layer = { ...layer, ...$event }"
                @symbology-saved="$emit('edit-layer-form-submitted')"
              />
              <WmsServiceForm
                v-else-if="['W'].includes(layer.service_type)"
                :layer="layer"
                @layer-changed="layer = { ...layer, ...$event }"
              />
              <AccessForm
                v-if="departments.length > 0"
                :layer="layer"
                @access-updated="newDepartmentsThatHaveAccessToLayer = $event"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions ref="cardAction">
          <v-col cols="12" class="d-flex justify-end">
            <v-btn
              color="primary"
              type="submit"
              :disabled="invalid || !canManageLayers"
              id="saveLayerBtn"
            >
              Save Layer
            </v-btn>
          </v-col>
        </v-card-actions>
      </form>
    </validation-observer>
    <v-dialog
      v-model="showEditFormDialog"
      fullscreen
      content-class="form-builder-edit-form"
      :retain-focus="false"
      persistent
    >
      <FormBuilderEditForm
        v-if="showEditFormDialog"
        @form-builder-edit-form-submitted="selectedFormDefinitionId = $event"
        @form-builder-edit-form-close="
          showEditFormDialog = false;
          resetURL();
        "
        :selectedFormDefinitionId="selectedFormDefinitionId"
        :formIsNewForm="formIsNewForm"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import { cloneDeep } from "lodash";
import UtiliSyncLayerForm from "@/components/layers/edit-layer-form/UtiliSyncLayerForm";
import LayerForm811 from "@/components/layers/edit-layer-form/LayerForm811";
import FeatureServiceForm from "@/components/layers/edit-layer-form/FeatureServiceForm";
import MapTileServiceForm from "@/components/layers/edit-layer-form/MapTileServiceForm";
import contentHeightMixin from "@/mixins/contentHeightMixin";
import FormBuilderEditForm from "@/components/forms/FormBuilderEditForm.vue";
import WmsServiceForm from "@/components/layers/edit-layer-form/WmsServiceForm.vue";
import AccessForm from "@/components/layers/edit-layer-form/AccessForm";
import { omit, isEqual } from "lodash";
import permissionsMixin from "@/mixins/permissionsMixin";

const APIURL = process.env.VUE_APP_API_URL;
const layer = {
  locate_request_provider_account_id: undefined,
  map_service_id: "",
  ref_field: "",
  fields: [],
  renderer: {
    apply_renderer_to_feature_service: "",
    convert_date_to_days: "",
    overrides: [],
    reference_field: "",
    renderer_id: "",
    renderer_symbols: [
      {
        field_option_label: "",
        field_option_value: "",
        fill_color: "",
        gis_data_field_option_id: "",
        label: "",
        max_value: "",
        min_value: "",
        outline_color: "",
        picture_size: "",
        renderer_id: "",
        renderer_symbol_id: "",
        style: "",
        symbol_type: "",
        url: "",
        width: "",
      },
    ],
    renderer_type: "",
    type: "",
  },
  service_name: "",
  service_type: "",
  service_url: "",
  stormwater_enabled: "",
  token_type: "",
  user_group_id: "",
};

export default {
  name: "EditLayerForm",
  components: {
    UtiliSyncLayerForm,
    LayerForm811,
    FeatureServiceForm,
    MapTileServiceForm,
    FormBuilderEditForm,
    WmsServiceForm,
    AccessForm,
  },
  props: {
    selectedLayer: Object,
  },
  mixins: [contentHeightMixin, permissionsMixin],
  data() {
    return {
      layer,
      showEditFormDialog: false,
      selectedFormDefinitionId: undefined,
      formIsNewForm: false,
      newDepartmentsThatHaveAccessToLayer: [],
      departmentsThatHaveAccessToLayer: [],
      departments: [],
    };
  },
  methods: {
    async getDepartments() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/departments`);
      this.departments = results;
    },
    async getDepartmentsThatHaveAccessToLayer(mapServiceId) {
      const {
        data: { results },
      } = await axios.get(
        `${APIURL}/department_map_services/map_service/${mapServiceId}`
      );
      this.departmentsThatHaveAccessToLayer = results;
      this.newDepartmentsThatHaveAccessToLayer = cloneDeep(
        this.departmentsThatHaveAccessToLayer
      );
    },
    onLayerChanged(newLayer) {
      this.layer = {
        ...this.layer,
        ...omit(newLayer, ["display_gis_fields", "display_utilisync_fields"]),
      };
    },
    onUtiliSyncFieldsSaved({ displayUtiliSyncFields }) {
      this.layer = {
        ...this.layer,
        display_utilisync_fields: displayUtiliSyncFields,
      };
    },
    onGisFieldsSaved({ displayGisFields }) {
      this.layer = {
        ...this.layer,
        display_gis_fields: displayGisFields,
      };
    },
    createNewForm() {
      this.showEditFormDialog = true;
      this.formIsNewForm = true;
    },
    async editLayer() {
      const success = await this.$refs.editLayerForm.validate();
      if (!success) {
        return;
      }
      const { layer } = this;
      const { user_group_id: userGroupId } = JSON.parse(
        localStorage.getItem("auth")
      );
      layer.user_group_id = userGroupId;
      await axios.put(`${APIURL}/map_services/${layer.map_service_id}`, layer);
      await this.updateDepartmentsThatHaveAccessToLayer();
      this.$emit("edit-layer-form-submitted");
    },
    openEditFormDialog(item, pushRouter) {
      this.showEditFormDialog = true;
      this.selectedFormDefinitionId = item;
      if (pushRouter) {
        this.$router.push({
          path: "/layers",
          query: {
            layer: this.layer.map_service_id,
            form: this.selectedFormDefinitionId,
            tab: "form-builder",
          },
        });
      }
    },
    resetURL() {
      this.$router.push({
        path: "/layers",
        query: {
          layer: this.layer.map_service_id,
        },
      });
    },
    async updateDepartmentsThatHaveAccessToLayer() {
      const {
        newDepartmentsThatHaveAccessToLayer,
        departmentsThatHaveAccessToLayer,
      } = this;
      const departmentMapServicesToInsert =
        newDepartmentsThatHaveAccessToLayer.filter(
          (newDepartmentMapService) => {
            return !departmentsThatHaveAccessToLayer.find(
              (oldDepartmentMapService) => {
                return isEqual(
                  oldDepartmentMapService,
                  newDepartmentMapService
                );
              }
            );
          }
        );
      await axios.post(`${APIURL}/department_map_services/batch`, {
        department_map_services: departmentMapServicesToInsert,
      });
      const departmentMapServicesToDelete =
        departmentsThatHaveAccessToLayer.filter((oldDepartmentMapService) => {
          return !newDepartmentsThatHaveAccessToLayer.find(
            (newDepartmentMapService) => {
              return isEqual(oldDepartmentMapService, newDepartmentMapService);
            }
          );
        });
      await axios.delete(`${APIURL}/department_map_services/batch`, {
        data: {
          department_map_services: departmentMapServicesToDelete,
        },
      });
      this.$emit("saved");
    },
  },

  async beforeMount() {
    this.layer = cloneDeep(this.selectedLayer);
    await this.getDepartmentsThatHaveAccessToLayer(this.layer.map_service_id);
    this.getDepartments();
  },
  mounted() {
    const formDefId = this.$route.query.form;
    if (formDefId) {
      this.showEditFormDialog = true;
      this.selectedFormDefinitionId = formDefId;
    }
  },
};
</script>
