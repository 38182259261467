var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "elevation-2 pb-2" },
    [
      _c(
        "v-card-text",
        {
          staticClass: "pl-6 pr-6",
          class:
            _vm.formDefinition.form.city_works_webhook_config.on_form_submission
              .close_activity_condition.condition === "Conditional"
              ? "pb-2"
              : "pb-7",
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pb-0 my-0", attrs: { cols: "12" } },
                [
                  _c("p", { staticClass: "caption" }, [
                    _c("b", [_vm._v("When UtiliSync form is started")]),
                  ]),
                  _c("v-switch", {
                    staticClass: "py-0 my-0",
                    attrs: {
                      label: "Create Cityworks Activity if not already created",
                      disabled: !_vm.canManageForms,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$emit("input", _vm.formDefinition)
                      },
                    },
                    model: {
                      value:
                        _vm.formDefinition.form.city_works_webhook_config
                          .on_form_creation.enabled,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formDefinition.form.city_works_webhook_config
                            .on_form_creation,
                          "enabled",
                          $$v
                        )
                      },
                      expression:
                        "\n            formDefinition.form.city_works_webhook_config.on_form_creation\n              .enabled\n          ",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0 my-0", attrs: { cols: "12" } },
                [
                  _c("p", { staticClass: "caption pt-2" }, [
                    _c("b", [_vm._v("When UtiliSync form is submitted")]),
                  ]),
                  _c("v-switch", {
                    staticClass: "py-0 my-0",
                    attrs: {
                      label: "Attach PDF",
                      disabled: !_vm.canManageForms,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$emit("input", _vm.formDefinition)
                      },
                    },
                    model: {
                      value:
                        _vm.formDefinition.form.city_works_webhook_config
                          .on_form_submission.attach_PDF,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formDefinition.form.city_works_webhook_config
                            .on_form_submission,
                          "attach_PDF",
                          $$v
                        )
                      },
                      expression:
                        "\n            formDefinition.form.city_works_webhook_config.on_form_submission\n              .attach_PDF\n          ",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0 my-0", attrs: { cols: "12" } },
                [
                  _c("v-switch", {
                    staticClass: "py-0 my-0",
                    attrs: {
                      label: "Attach Photos",
                      disabled: !_vm.canManageForms,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$emit("input", _vm.formDefinition)
                      },
                    },
                    model: {
                      value:
                        _vm.formDefinition.form.city_works_webhook_config
                          .on_form_submission.attach_photos,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formDefinition.form.city_works_webhook_config
                            .on_form_submission,
                          "attach_photos",
                          $$v
                        )
                      },
                      expression:
                        "\n            formDefinition.form.city_works_webhook_config.on_form_submission\n              .attach_photos\n          ",
                    },
                  }),
                ],
                1
              ),
              _vm.activityType === "Inspection"
                ? _c(
                    "v-col",
                    { staticClass: "pt-0 my-0", attrs: { cols: "6" } },
                    [
                      _c("v-select", {
                        attrs: {
                          autocomplete: "off",
                          label: "Inspection Date",
                          "hide-details": "auto",
                          color: "primary",
                          name: "inspectDate",
                          items: _vm.dateQuestionChoices,
                          "item-text": "label",
                          "item-value": "value",
                          clearable: "",
                          disabled: !_vm.canManageForms,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$emit("input", _vm.formDefinition)
                          },
                        },
                        model: {
                          value:
                            _vm.formDefinition.form.city_works_webhook_config
                              .on_form_submission.inspection_date_field_id,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formDefinition.form.city_works_webhook_config
                                .on_form_submission,
                              "inspection_date_field_id",
                              $$v
                            )
                          },
                          expression:
                            "\n            formDefinition.form.city_works_webhook_config.on_form_submission\n              .inspection_date_field_id\n          ",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                { staticClass: "pt-3 pb-0 my-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0 my-0",
                          attrs: { cols: "12", md: "3" },
                        },
                        [
                          _c("validation-provider", {
                            attrs: { name: "Close Cityworks Activity" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ errors, valid }) {
                                  return [
                                    _c("v-select", {
                                      attrs: {
                                        autocomplete: "off",
                                        label: "Close Cityworks Activity",
                                        "hide-details": "auto",
                                        "error-messages": errors,
                                        success: valid,
                                        color: "primary",
                                        name: "closeCityworksActivity",
                                        items: _vm.activityOptions,
                                        "item-text": "label",
                                        "item-value": "value",
                                        disabled: !_vm.canManageForms,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$emit(
                                            "input",
                                            _vm.formDefinition
                                          )
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.formDefinition.form
                                            .city_works_webhook_config
                                            .on_form_submission
                                            .close_activity_condition.condition,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDefinition.form
                                              .city_works_webhook_config
                                              .on_form_submission
                                              .close_activity_condition,
                                            "condition",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "\n                  formDefinition.form.city_works_webhook_config\n                    .on_form_submission.close_activity_condition.condition\n                ",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm.formDefinition.form.city_works_webhook_config
                        .on_form_submission.close_activity_condition
                        .condition === "Conditional"
                        ? _c(
                            "v-col",
                            {
                              staticClass: "py-0 my-0",
                              attrs: { cols: "12", md: "3" },
                            },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "Form Field",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors, valid }) {
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              autocomplete: "off",
                                              label: "Form Field",
                                              "hide-details": "auto",
                                              "error-messages": errors,
                                              success: valid,
                                              color: "primary",
                                              name: "formField",
                                              items: _vm.questionChoices,
                                              "item-text": "label",
                                              "item-value": "value",
                                              disabled: !_vm.canManageForms,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.$emit(
                                                  "input",
                                                  _vm.formDefinition
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.formDefinition.form
                                                  .city_works_webhook_config
                                                  .on_form_submission
                                                  .close_activity_condition
                                                  .questionID,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formDefinition.form
                                                    .city_works_webhook_config
                                                    .on_form_submission
                                                    .close_activity_condition,
                                                  "questionID",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                  formDefinition.form.city_works_webhook_config\n                    .on_form_submission.close_activity_condition.questionID\n                ",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3801775626
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.formDefinition.form.city_works_webhook_config
                        .on_form_submission.close_activity_condition
                        .condition === "Conditional"
                        ? _c(
                            "v-col",
                            {
                              staticClass: "py-0 my-0",
                              attrs: { cols: "12", md: "3" },
                            },
                            [
                              _c("validation-provider", {
                                attrs: { name: "Condition", rules: "required" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors, valid }) {
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              autocomplete: "off",
                                              label: "Condition",
                                              "hide-details": "auto",
                                              "error-messages": errors,
                                              success: valid,
                                              color: "primary",
                                              name: "questionCondition",
                                              items:
                                                _vm.questionConditionOptions,
                                              "item-text": "label",
                                              "item-value": "value",
                                              disabled: !_vm.canManageForms,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.$emit(
                                                  "input",
                                                  _vm.formDefinition
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.formDefinition.form
                                                  .city_works_webhook_config
                                                  .on_form_submission
                                                  .close_activity_condition
                                                  .question_condition,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formDefinition.form
                                                    .city_works_webhook_config
                                                    .on_form_submission
                                                    .close_activity_condition,
                                                  "question_condition",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                  formDefinition.form.city_works_webhook_config\n                    .on_form_submission.close_activity_condition\n                    .question_condition\n                ",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  456268524
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.formDefinition.form.city_works_webhook_config
                        .on_form_submission.close_activity_condition
                        .condition === "Conditional"
                        ? _c(
                            "v-col",
                            {
                              staticClass: "py-0 my-0",
                              attrs: { cols: "12", md: "3" },
                            },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "Value",
                                  rules: {
                                    required: true,
                                    numeric: _vm.questionType === "NUMBER",
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors, valid }) {
                                        return [
                                          _vm.questionType !== "SINGLE_SELECT"
                                            ? _c("v-text-field", {
                                                attrs: {
                                                  label: "Value",
                                                  "error-messages": errors,
                                                  success: valid,
                                                  color: "primary",
                                                  name: "value",
                                                  type:
                                                    _vm.questionType ===
                                                    "NUMBER"
                                                      ? "number"
                                                      : "",
                                                  "item-text": "label",
                                                  "item-value": "value",
                                                  disabled: !_vm.canManageForms,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.$emit(
                                                      "input",
                                                      _vm.formDefinition
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.formDefinition.form
                                                      .city_works_webhook_config
                                                      .on_form_submission
                                                      .close_activity_condition
                                                      .value,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formDefinition.form
                                                        .city_works_webhook_config
                                                        .on_form_submission
                                                        .close_activity_condition,
                                                      "value",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "\n                  formDefinition.form.city_works_webhook_config\n                    .on_form_submission.close_activity_condition.value\n                ",
                                                },
                                              })
                                            : _c("v-select", {
                                                attrs: {
                                                  autocomplete: "off",
                                                  label: "Value",
                                                  "error-messages": errors,
                                                  success: valid,
                                                  color: "primary",
                                                  name: "value",
                                                  "item-text": "label",
                                                  "item-value": "value",
                                                  items:
                                                    _vm.singleSelectOptions,
                                                  disabled: !_vm.canManageForms,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.$emit(
                                                      "input",
                                                      _vm.formDefinition
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.formDefinition.form
                                                      .city_works_webhook_config
                                                      .on_form_submission
                                                      .close_activity_condition
                                                      .value,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formDefinition.form
                                                        .city_works_webhook_config
                                                        .on_form_submission
                                                        .close_activity_condition,
                                                      "value",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "\n                  formDefinition.form.city_works_webhook_config\n                    .on_form_submission.close_activity_condition.value\n                ",
                                                },
                                              }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2418521148
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }