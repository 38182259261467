<template>
  <div>
    <div class="d-flex justify-center" v-if="!addFieldOptions">
      <v-btn color="primary" text @click="addFieldOptions = true">
        + Add Field Options
      </v-btn>
    </div>

    <validation-observer ref="createLayerForm" v-else>
      <form>
        <v-simple-table>
          <tbody class="white">
            <tr class="white">
              <td class="white value">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Value"
                  rules="required"
                >
                  <v-text-field
                    label="Value"
                    v-model="gisDataFieldOption.value"
                    name="value"
                    :hide-details="false"
                    background-color="white"
                    :error-messages="errors"
                    :success="valid"
                  >
                  </v-text-field>
                </validation-provider>
              </td>
              <td class="white">
                <v-text-field
                  label="Label"
                  v-model="gisDataFieldOption.label"
                  :hide-details="false"
                  name="label"
                  background-color="white"
                >
                </v-text-field>
              </td>
              <td class="white d-flex justify-end align-center">
                <v-btn fab x-small color="primary">
                  <v-icon color="white" @click="addOption">
                    {{ mdiPlus }}
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-simple-table>
          <thead>
            <tr>
              <th class="value">
                <p class="caption">Value</p>
              </th>
              <th>
                <p class="caption">Label (Optional)</p>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(option, index) of gisDataFieldOptions"
              :key="option.gis_data_field_id"
              class="white"
            >
              <td
                class="white value"
                :class="{
                  'd-flex': editing[index],
                  'align-start': editing[index],
                }"
              >
                <div class="d-flex align-start" v-if="editing[index]">
                  <validation-provider
                    v-slot="{ errors, valid }"
                    name="Value"
                    rules="required"
                  >
                    <v-text-field
                      label="Value"
                      v-model="option.value"
                      :hide-details="false"
                      name="value"
                      background-color="white"
                      :error-messages="errors"
                      :success="valid"
                    >
                    </v-text-field>
                  </validation-provider>
                </div>
                <div v-else class="white d-flex pt-3">
                  <p>{{ option.value }}</p>
                </div>
              </td>
              <td class="white value">
                <div v-if="editing[index]" class="white">
                  <div class="d-flex align-start">
                    <v-text-field
                      label="Label"
                      v-model="option.label"
                      :hide-details="false"
                      name="label"
                      background-color="white"
                    >
                    </v-text-field>
                  </div>
                  <div class="white d-flex justify-end mt-n5 mr-n4">
                    <v-btn
                      text
                      color="primary"
                      @click="
                        cancelEditGisDataFieldOptions();
                        $set(editing, index, false);
                      "
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$set(editing, index, false)"
                    >
                      Save
                    </v-btn>
                  </div>
                </div>
                <div v-else class="white d-flex pt-3">
                  <p>{{ option.label }}</p>
                </div>
              </td>
              <td class="white justify-end d-flex">
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon color="primary">{{ mdiDotsVertical }}</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item>
                      <v-list-item-title
                        class="primary--text text--lighten-1 cursor-pointer"
                        @click="
                          saveOriginalGisDataFieldOptions();
                          $set(editing, index, true);
                        "
                      >
                        <v-icon color="primary">{{ mdiPencil }}</v-icon>
                        Edit
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteOption(index)">
                      <v-list-item-title
                        class="primary--text text--lighten-1 cursor-pointer"
                      >
                        <v-icon color="primary">{{ mdiDelete }}</v-icon>
                        Delete
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { mdiPlus, mdiDelete, mdiDotsVertical, mdiPencil } from "@mdi/js";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep } from "lodash";

export default {
  name: "GisDataFieldOptionsForm",
  data() {
    return {
      gisDataFieldOptions: [],
      mdiPencil,
      mdiPlus,
      mdiDelete,
      mdiDotsVertical,
      gisDataFieldOption: {},
      editing: [],
      addFieldOptions: false,
      gisDataFieldOptionsCopy: [],
    };
  },
  methods: {
    async addOption() {
      const success = await this.$refs.createLayerForm.validate();
      if (!success) {
        return;
      }
      this.gisDataFieldOptions.push({
        gis_data_field_id: uuidv4(),
        ...this.gisDataFieldOption,
      });
      this.$emit("gis-field-options-added", this.gisDataFieldOptions);
      this.gisDataFieldOption = {};
      this.resetEditStates();
      await this.$nextTick();
      this.$refs.createLayerForm.reset();
    },
    deleteOption(index) {
      this.gisDataFieldOptions.splice(index, 1);
      this.$emit("gis-field-options-deleted", this.gisDataFieldOptions);
      this.resetEditStates();
    },
    resetEditStates() {
      this.editing = this.gisDataFieldOptions.map(() => false);
    },
    saveOriginalGisDataFieldOptions() {
      this.gisDataFieldOptionsCopy = cloneDeep(this.gisDataFieldOptions);
    },
    cancelEditGisDataFieldOptions() {
      this.gisDataFieldOptions = cloneDeep(this.gisDataFieldOptionsCopy);
    },
  },
  beforeMount() {
    this.resetEditStates();
  },
};
</script>

<style>
.value {
  width: 200px;
}
</style>
