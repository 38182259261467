var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showRepeatingGroupDialog,
        "max-width": "600px",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", color: "primary" } },
            [
              _c("v-toolbar-title", [
                _vm._v("Group " + _vm._s(_vm.item.question.label)),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { icon: "", dark: "" }, on: { click: _vm.closeForm } },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              staticClass: "pt-3",
              staticStyle: { height: "60vh", "overflow-y": "auto" },
            },
            [
              _c(
                "validation-observer",
                { ref: "groupForm" },
                [
                  _vm._l(_vm.groupedItems, function (item, index) {
                    return [
                      _vm.isVisible(item)
                        ? [
                            item.question
                              ? [
                                  ["FILE"].includes(_vm.getType(item))
                                    ? [
                                        !item.question.isImage
                                          ? _c("GroupFileInput", {
                                              key: item.id,
                                              attrs: {
                                                item: item,
                                                readOnly: _vm.readOnly,
                                              },
                                              model: {
                                                value: item.value,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "value", $$v)
                                                },
                                                expression: "item.value",
                                              },
                                            })
                                          : _c("GroupPhotoInput", {
                                              key: item.id,
                                              attrs: {
                                                item: item,
                                                readOnly: _vm.readOnly,
                                              },
                                              model: {
                                                value: item.value,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "value", $$v)
                                                },
                                                expression: "item.value",
                                              },
                                            }),
                                      ]
                                    : ["MULTI_SELECT"].includes(
                                        _vm.getType(item)
                                      )
                                    ? _c("GroupMultiSelectInput", {
                                        key: item.id,
                                        attrs: {
                                          item: item,
                                          readOnly: _vm.readOnly,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.onInput(index, item)
                                          },
                                        },
                                        model: {
                                          value: item.value,
                                          callback: function ($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value",
                                        },
                                      })
                                    : ["SIGNATURE"].includes(_vm.getType(item))
                                    ? _c("GroupSignaturePad", {
                                        key: item.id,
                                        attrs: {
                                          item: item,
                                          readOnly: _vm.readOnly,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.onInput(index, item)
                                          },
                                        },
                                        model: {
                                          value: item.value,
                                          callback: function ($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value",
                                        },
                                      })
                                    : ["SINGLE_SELECT"].includes(
                                        _vm.getType(item)
                                      )
                                    ? _c("GroupSingleSelectInput", {
                                        key: item.id,
                                        attrs: {
                                          item: item,
                                          readOnly: _vm.readOnly,
                                          isRequired: _vm.isRequired(item),
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.onInput(index, item)
                                          },
                                        },
                                        model: {
                                          value: item.value,
                                          callback: function ($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value",
                                        },
                                      })
                                    : ["DATE"].includes(_vm.getType(item))
                                    ? _c("GroupDateInput", {
                                        key: item.id,
                                        attrs: {
                                          item: item,
                                          readOnly: _vm.readOnly,
                                          isRequired: _vm.isRequired(item),
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.onInput(index, item)
                                          },
                                        },
                                        model: {
                                          value: item.value,
                                          callback: function ($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value",
                                        },
                                      })
                                    : ["TIME"].includes(_vm.getType(item))
                                    ? _c("GroupTimeInput", {
                                        key: item.id,
                                        attrs: {
                                          item: item,
                                          readOnly: _vm.readOnly,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.onInput(index, item)
                                          },
                                        },
                                        model: {
                                          value: item.value,
                                          callback: function ($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value",
                                        },
                                      })
                                    : ["TEXT", "EMAIL", "NUMBER"].includes(
                                        _vm.getType(item)
                                      )
                                    ? _c("GroupTextInput", {
                                        key: item.id,
                                        attrs: {
                                          item: item,
                                          readOnly: _vm.readOnly,
                                          formDefinition: _vm.formDefinition,
                                          isRequired: _vm.isRequired(item),
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.onInput(index, item)
                                          },
                                        },
                                        model: {
                                          value: item.value,
                                          callback: function ($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value",
                                        },
                                      })
                                    : ["CALCULATION"].includes(
                                        _vm.getType(item)
                                      )
                                    ? _c("GroupCalculationInput", {
                                        key: item.id,
                                        attrs: {
                                          item: item,
                                          selected: _vm.selected,
                                          groupedItems: _vm.groupedItems,
                                          formDefinition: _vm.formDefinition,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.onInput(index, item)
                                          },
                                        },
                                        model: {
                                          value: item.value,
                                          callback: function ($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value",
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              : item.information
                              ? _c("RepeatingGroupInfo", {
                                  key: item.id,
                                  attrs: { item: item, readOnly: _vm.readOnly },
                                })
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end px-5" },
            [
              _c(
                "v-btn",
                { attrs: { color: "primary" }, on: { click: _vm.save } },
                [_vm._v(" Save ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }