<template>
  <v-dialog
    :value="showAddAdvancedSettingsDialog"
    max-width="600px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-toolbar dark color="primary">
      <v-toolbar-title> Add Advanced Setting </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="$emit('add-advanced-settings-dialog-close')">
        <v-icon>
          {{ mdiClose }}
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-card class="rounded-0">
      <v-card-text>
        <p class="caption">Select an advanced setting type to add</p>

        <v-card
          class="my-3"
          @click="selectType(ADVANCED_SETTINGS_CHOICES.DEFAULT)"
          v-if="!hasDefaultSettings && showDefaultChoice"
        >
          <v-card-text class="d-flex align-center justify-space-between">
            <div class="d-flex gap">
              <v-icon color="primary">
                {{ mdiFormTextboxPassword }}
              </v-icon>

              <div>
                <div class="primary--text"><b>Default</b></div>
                <div class="primary--text">
                  Set a default value and set the conditions for which it occurs
                </div>
              </div>
            </div>

            <div>
              <v-btn icon color="primary">
                <v-icon x-large>
                  {{ mdiMenuRight }}
                </v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>

        <v-card
          class="my-3"
          @click="selectType(ADVANCED_SETTINGS_CHOICES.CREATE)"
          v-if="!hasCreateSettings && showCreateChoice"
        >
          <v-card-text class="d-flex align-center justify-space-between">
            <div class="d-flex gap">
              <v-icon color="primary">
                {{ mdiPlaylistPlus }}
              </v-icon>

              <div>
                <div class="primary--text"><b>Create</b></div>
                <div class="primary--text">
                  Set the conditions for the creation of this field
                </div>
              </div>
            </div>

            <div>
              <v-btn icon color="primary">
                <v-icon x-large>
                  {{ mdiMenuRight }}
                </v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>

        <v-card
          class="my-3"
          @click="selectType(ADVANCED_SETTINGS_CHOICES.REQUIRE)"
          v-if="!hasRequiredSettings && showRequireChoice"
        >
          <v-card-text class="d-flex align-center justify-space-between">
            <div class="d-flex gap">
              <v-icon color="primary">
                {{ mdiAsterisk }}
              </v-icon>

              <div>
                <div class="primary--text"><b>Require</b></div>
                <div class="primary--text">
                  Set the conditions for the requirement of this field
                </div>
              </div>
            </div>

            <div>
              <v-btn icon color="primary">
                <v-icon x-large>
                  {{ mdiMenuRight }}
                </v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>

        <v-card
          class="my-3"
          @click="selectType(ADVANCED_SETTINGS_CHOICES.VISIBILITY)"
          v-if="!hasVisibleSettings && showVisibilityChoice"
        >
          <v-card-text class="d-flex align-center justify-space-between">
            <div class="d-flex gap">
              <v-icon color="primary">
                {{ mdiEye }}
              </v-icon>

              <div>
                <div class="primary--text"><b>Visibility</b></div>
                <div class="primary--text">
                  Set the conditions for the visibility of this field
                </div>
              </div>
            </div>

            <div>
              <v-btn icon color="primary">
                <v-icon x-large>
                  {{ mdiMenuRight }}
                </v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiClose,
  mdiFormTextboxPassword,
  mdiMenuRight,
  mdiPlaylistPlus,
  mdiAsterisk,
  mdiEye,
} from "@mdi/js";
import {
  ADVANCED_SETTINGS_CHOICES,
  CONDITION_CHOICES,
} from "@/constants/advancedSettings";

export default {
  name: "AddAdvancedSettingsDialog",
  props: {
    showAddAdvancedSettingsDialog: Boolean,
    selectedItemOptions: Object,
    oldSelectedItemOptions: Object,
    showVisibilityChoice: { type: Boolean, default: true },
    showDefaultChoice: { type: Boolean, default: true },
    showRequireChoice: { type: Boolean, default: true },
    showCreateChoice: { type: Boolean, default: true },
  },
  data() {
    return {
      mdiClose,
      mdiFormTextboxPassword,
      mdiMenuRight,
      mdiPlaylistPlus,
      mdiAsterisk,
      mdiEye,
      ADVANCED_SETTINGS_CHOICES,
      advancedSettingType: undefined,
    };
  },
  computed: {
    hasDefaultSettings() {
      return Boolean(this.selectedItemOptions?.question?.default?.type);
    },
    hasVisibleSettings() {
      return (
        this.selectedItemOptions.question.visible?.applyVisible !==
        CONDITION_CHOICES.ALWAYS
      );
    },
    hasRequiredSettings() {
      return (
        this.selectedItemOptions.question.required?.applyRequired !==
        CONDITION_CHOICES.NEVER
      );
    },
    hasCreateSettings() {
      return (
        this.selectedItemOptions.question?.create &&
        this.selectedItemOptions.question.create?.applyCreate !==
          CONDITION_CHOICES.ALWAYS
      );
    },
  },
  methods: {
    selectType(advancedSettingType) {
      this.advancedSettingType = advancedSettingType;
      this.selectedAdvancedSetting();
    },
    selectedAdvancedSetting() {
      this.$emit("advanced-settings-selected", this.advancedSettingType);
    },
  },
};
</script>

<style scoped>
.gap {
  gap: 10px;
}
</style>
