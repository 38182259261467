<template>
  <v-card-text class="elevation-0 ma-0 pa-0">
    <validation-observer ref="formPreview" slim>
      <form>
        <v-expansion-panels
          :value="openSections"
          multiple
          id="form-builder-preview"
        >
          <draggable
            :list="formDefinition.form.sections"
            group="sections"
            class="list-group"
            :options="{ disabled: previewMode }"
            handle=".section-handle"
            @end="assignNumbersToSections"
            :disabled="!canManageForms"
          >
            <v-expansion-panel
              v-for="(section, sectionIndex) of formDefinition.form.sections"
              v-show="isSectionVisible(section)"
              :key="section.id"
            >
              <v-expansion-panel-header
                @click="
                  selectItem(section.id, undefined, 'SECTION');
                  selectWholeSection(section);
                "
                :class="{
                  selectedField: selectedWholeSectionId === section.id,
                }"
              >
                <div class="d-flex align-center">
                  <v-icon
                    class="section-handle"
                    v-if="!previewMode && canManageForms"
                  >
                    {{ mdiDragVertical }}
                  </v-icon>
                  <div class="px-1">
                    {{ section.hideHeader ? "" : section ? section.name : "" }}
                  </div>
                </div>

                <v-spacer></v-spacer>

                <div
                  v-if="!previewMode && selectedWholeSectionId === section.id"
                >
                  <v-btn
                    color="primary"
                    text
                    @click="showConfirmDeleteSectionDialog = true"
                    :disabled="!canManageForms"
                  >
                    <v-icon class="mr-2">
                      {{ mdiDelete }}
                    </v-icon>
                    Delete
                  </v-btn>

                  <v-btn
                    color="primary"
                    text
                    @click="duplicateSection(sectionIndex)"
                    :disabled="!canManageForms"
                  >
                    <v-icon class="mr-2">
                      {{ mdiContentCopy }}
                    </v-icon>
                    Duplicate
                  </v-btn>

                  <ConfirmDeleteDialog
                    itemType="section"
                    :showConfirmDialog="showConfirmDeleteSectionDialog"
                    @confirm-delete="
                      deleteSection(sectionIndex);
                      showConfirmDeleteSectionDialog = false;
                    "
                    @cancel-delete="showConfirmDeleteSectionDialog = false"
                  />
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content
                :class="{
                  selectedField: selectedWholeSectionId === section.id,
                }"
              >
                <draggable
                  :list="section.items"
                  group="items"
                  class="list-group"
                  @end="assignNumbersToItems"
                  :options="{ disabled: previewMode }"
                  handle=".handle"
                  :disabled="!canManageForms"
                >
                  <div
                    v-for="item of section.items"
                    :key="`${item.id}-${item.number}`"
                    class="elevation-0 py-0"
                    @click="
                      selectItem(section.id, item.id, getSelectedItemType(item))
                    "
                  >
                    <div
                      :class="{
                        selectedField:
                          selectedItemId === item.id ||
                          selectedWholeSectionId === section.id,
                      }"
                    >
                      <section
                        class="d-flex px-0 pb-0 pt-2 justify-space-between align-center gap"
                      >
                        <ItemPreview
                          class="flex-grow-1 my-4"
                          :sectionId="section.id"
                          :item="item"
                          :formDefinition="formDefinition"
                          @input="onInput"
                          :selected="
                            selectedItemId === item.id ||
                            selectedWholeSectionId === section.id
                          "
                          :previewMode="previewMode"
                          :readOnly="!previewMode"
                        />

                        <section
                          class="d-flex flex-grow-0"
                          v-if="selectedItemId === item.id && !previewMode"
                          @click="
                            selectItem(
                              section.id,
                              item.id,
                              getSelectedItemType(item)
                            )
                          "
                        >
                          <div class="d-flex flex-column flex-align-center">
                            <v-btn
                              v-if="showDuplicateButton(item)"
                              class="mb-2"
                              color="primary"
                              text
                              width="20px"
                              min-width="20px"
                              height="20px"
                              min-height="20px"
                              @click.stop="duplicateItem(section.id, item.id)"
                              :disabled="!canManageForms"
                            >
                              <v-icon class="mr-2">
                                {{ mdiContentCopy }}
                              </v-icon>
                            </v-btn>

                            <v-btn
                              v-if="item.type === 'QUESTION'"
                              class="mb-2"
                              color="primary"
                              text
                              width="20px"
                              min-width="20px"
                              height="20px"
                              min-height="20px"
                              @click="showAdvancedEditDialog = true"
                              :disabled="!canManageForms"
                            >
                              <v-icon class="mr-2">
                                {{ mdiPencil }}
                              </v-icon>
                            </v-btn>

                            <v-btn
                              class="mb-2"
                              color="primary"
                              text
                              width="20px"
                              min-width="20px"
                              height="20px"
                              min-height="20px"
                              @click="showConfirmDeleteDialog = true"
                              :disabled="!canManageForms"
                            >
                              <v-icon class="mr-2">
                                {{ mdiDelete }}
                              </v-icon>
                            </v-btn>
                          </div>
                          <ConfirmDeleteDialog
                            itemType="item"
                            v-if="showConfirmDeleteDialog"
                            :showConfirmDialog="showConfirmDeleteDialog"
                            @confirm-delete="
                              removeItemFromSection(section.id, item.id);
                              showConfirmDeleteDialog = false;
                            "
                            @cancel-delete="showConfirmDeleteDialog = false"
                          />
                        </section>
                      </section>
                    </div>
                    <!-- Inline Add Field -->
                    <v-row v-if="checkFieldIsNotLast(item, section.id)">
                      <v-col class="pa-0">
                        <section
                          class="d-flex mt-4 mb-0 px-5 justify-center"
                          v-if="selectedItemId === item.id && !previewMode"
                        >
                          <v-btn
                            :dark="!addingField && canManageForms"
                            color="primary"
                            tile
                            @click.stop="addField(section.id)"
                            :disabled="addingField || !canManageForms"
                            id="inlineAddFieldBtn"
                          >
                            <v-icon>{{ mdiPlus }}</v-icon>
                            <div class="pl-2">ADD FIELD</div>
                          </v-btn>
                        </section>
                      </v-col>
                    </v-row>
                  </div>
                </draggable>
                <!-- Bottom Add Field -->
                <section
                  class="d-flex my-5 px-5 justify-center"
                  v-if="!previewMode"
                >
                  <v-btn
                    color="primary"
                    tile
                    @click.stop="addField(section.id)"
                    id="bottomAddFieldBtn"
                    :dark="canManageForms"
                    :disabled="!canManageForms"
                  >
                    <v-icon>{{ mdiPlus }}</v-icon>
                    <div class="pl-2">ADD FIELD</div>
                  </v-btn>
                </section>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </draggable>
        </v-expansion-panels>
      </form>
    </validation-observer>
  </v-card-text>
</template>

<script>
import dependantValueMixin from "@/mixins/dependantValueMixin";
import permissionsMixin from "@/mixins/permissionsMixin";
import ItemPreview from "@/components/forms/form-builder-edit-form/ItemPreview.vue";
import ConfirmDeleteDialog from "@/components/forms/form-builder-edit-form/ConfirmDeleteDialog.vue";
import {
  mdiDotsVertical,
  mdiDelete,
  mdiContentCopy,
  mdiChevronDown,
  mdiPlus,
  mdiMinus,
  mdiPencil,
  mdiDragVertical,
  mdiFormTextbox,
  mdiText,
  mdiEmail,
  mdiCalendar,
  mdiClockOutline,
  mdiNumeric,
  mdiFormatListChecks,
  mdiFormatListCheckbox,
  mdiImageMultiple,
  mdiFile,
  mdiSignatureFreehand,
  mdiCalculator,
  mdiCheckboxMarked,
  mdiPlusBoxMultiple,
  mdiFormatTitle,
  mdiLink,
  mdiClipboardText,
  mdiImage,
  mdiMenu,
  mdiCog,
  mdiFormSelect,
  mdiFileDocumentEdit,
  mdiTable,
  mdiMapMarkerRadius,
  mdiCalendarCheck,
  mdiCity,
} from "@mdi/js";
import draggable from "vuedraggable";

export default {
  name: "FormBuilderPreview",
  props: {
    previewMode: Boolean,
    formDefinition: Object,
    selectedItemId: Number,
    selectedWholeSectionId: Number,
    openSections: Array,
    addingField: Boolean,
  },
  mixins: [dependantValueMixin, permissionsMixin],
  components: { ItemPreview, ConfirmDeleteDialog, draggable },
  data() {
    return {
      mdiDotsVertical,
      mdiDelete,
      mdiContentCopy,
      mdiChevronDown,
      mdiPlus,
      mdiMinus,
      mdiPencil,
      mdiDragVertical,
      mdiFormTextbox,
      mdiText,
      mdiEmail,
      mdiCalendar,
      mdiClockOutline,
      mdiNumeric,
      mdiFormatListChecks,
      mdiFormatListCheckbox,
      mdiImageMultiple,
      mdiFile,
      mdiSignatureFreehand,
      mdiCalculator,
      mdiCheckboxMarked,
      mdiPlusBoxMultiple,
      mdiFormatTitle,
      mdiLink,
      mdiClipboardText,
      mdiImage,
      mdiMenu,
      mdiCog,
      mdiFormSelect,
      mdiFileDocumentEdit,
      mdiTable,
      mdiMapMarkerRadius,
      mdiCalendarCheck,
      mdiCity,
      showConfirmDeleteDialog: false,
      showConfirmDeleteSectionDialog: false,
    };
  },
  watch: {
    selectedWholeSectionId(val) {
      console.log(val);
    },
    selectedItemId(val) {
      console.log(val);
    },
  },
  methods: {
    assignNumbersToItems() {
      this.$emit("assign-numbers-to-items");
    },
    assignNumbersToSections() {
      this.$emit("assign-numbers-to-sections");
    },
    addField(sectionId) {
      this.$emit("add-field", sectionId);
    },
    async selectItem(selectedSectionId, selectedItemId, selectedControlType) {
      this.$emit(
        "select-item",
        selectedSectionId,
        selectedItemId,
        selectedControlType
      );
    },
    selectWholeSection(section) {
      this.$emit("select-whole-section", section);
    },
    showDuplicateButton(item) {
      return item?.question?.type !== "POSITIVE_RESPONSE";
    },
    duplicateItem(sectionId, itemId) {
      this.$emit("duplicate-item", sectionId, itemId);
    },
    duplicateSection(sectionIndex) {
      this.$emit("duplicate-section", sectionIndex);
    },
    removeItemFromSection(sectionId, itemId) {
      this.$emit("remove-item-from-section", sectionId, itemId);
    },
    onInput(value, sectionId, itemId) {
      this.$emit("input", value, sectionId, itemId);
    },
    checkFieldIsNotLast(item, sectionId) {
      const section = this.formDefinition.form.sections.find(
        (s) => s.id === sectionId
      );
      return item.id !== section.items.at(-1).id;
    },
    isSectionVisible(section) {
      if (
        !this.previewMode ||
        !section.visible ||
        !section.visible?.condition ||
        section.visible?.condition === "ALWAYS"
      ) {
        return true;
      }
      if (section.visible.condition === "NEVER") {
        return false;
      }
      const { sections } = { ...this.formDefinition.form };
      const flattenedItems = sections.map(({ items }) => items).flat();
      const dependantItem = flattenedItems.find(
        (item) => item.id === section.visible.dependantId
      );
      const dependantItemValue = dependantItem?.value;
      if (Array.isArray(dependantItemValue)) {
        if (dependantItemValue.length > 1) {
          return false;
        } else {
          const [dependantValue] = dependantItemValue;
          return this.checkSectionDependantValue(
            dependantValue,
            section,
            "visible"
          );
        }
      } else {
        return this.checkSectionDependantValue(
          dependantItemValue,
          section,
          "visible"
        );
      }
    },
    getSelectedItemType(item) {
      if (typeof item?.question === "object" && item?.question !== null) {
        return item?.question?.type;
      }
      return item?.information?.type;
    },
  },
};
</script>

<style scoped>
.selectedField {
  background-color: #fff8e1;
  /* border: 2px #286054 solid; */
}

#form-builder-preview >>> .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
