var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex justify-space-between" },
    [
      _c("validation-observer", { ref: "signatureForm" }, [
        _c(
          "form",
          [
            _vm.selectedRepeatingGroupItemOptions &&
            _vm.selectedRepeatingGroupItemOptions.question
              ? _c(
                  "v-list",
                  [
                    _c(
                      "v-list-item",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c("validation-provider", {
                              attrs: { name: "Label", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Label",
                                            "hide-details": "auto",
                                            "error-messages": errors,
                                            success: valid,
                                            color: "primary",
                                            name: "name",
                                            disabled: !_vm.canManageForms,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.onSubmit()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm
                                                .selectedRepeatingGroupItemOptions
                                                .question.label,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm
                                                  .selectedRepeatingGroupItemOptions
                                                  .question,
                                                "label",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "selectedRepeatingGroupItemOptions.question.label",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1296071596
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-switch", {
                              staticClass: "my-0 py-0",
                              attrs: {
                                label: "Allow Multiple",
                                disabled: !_vm.canManageForms,
                                "hide-details": "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.onSubmit()
                                },
                              },
                              model: {
                                value:
                                  _vm.selectedRepeatingGroupItemOptions
                                    .allowMultiple,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedRepeatingGroupItemOptions,
                                    "allowMultiple",
                                    $$v
                                  )
                                },
                                expression:
                                  "selectedRepeatingGroupItemOptions.allowMultiple",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { staticClass: "px-0 mx-0" },
                      [
                        _c(
                          "v-list-item-content",
                          { staticClass: "px-0 mx-0" },
                          [
                            _c("AdvancedSettingsForm", {
                              attrs: {
                                formDefinition: _vm.formDefinition,
                                selectedItem:
                                  _vm.selectedRepeatingGroupItemOptions,
                                selectedRepeatingGroupItemOptions:
                                  _vm.selectedRepeatingGroupItemOptions,
                                layer: _vm.layer,
                                showVisibilityChoice: false,
                                showDefaultChoice: false,
                                showCreateChoice: false,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.onSubmit()
                                },
                              },
                              model: {
                                value: _vm.selectedRepeatingGroupItemOptions,
                                callback: function ($$v) {
                                  _vm.selectedRepeatingGroupItemOptions = $$v
                                },
                                expression: "selectedRepeatingGroupItemOptions",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { staticClass: "px-0 mx-0" },
                      [
                        _c(
                          "v-list-item-content",
                          { staticClass: "px-0 mx-0" },
                          [
                            _vm.hasAdvancedSettings
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex flex-wrap gap" },
                                  [
                                    _vm.hasRequiredSettings
                                      ? _c(
                                          "section",
                                          {
                                            staticClass:
                                              "text-uppercase d-flex primary--text gap align-center",
                                            on: {
                                              click:
                                                _vm.openRequiredSettingsDialog,
                                            },
                                          },
                                          [
                                            _c("div", [
                                              _vm._v(
                                                "Required " +
                                                  _vm._s(_vm.requiredSetting)
                                              ),
                                            ]),
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  icon: "",
                                                  disabled: !_vm.canManageForms,
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: { color: "primary" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.mdiPencil) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-center justify-center",
                                  },
                                  [_vm._v(" No advanced settings ")]
                                ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm.showAdvancedRequiredSettingsDialog
        ? _c("AdvancedRequiredSettingsDialog", {
            attrs: {
              showAdvancedRequiredSettingsDialog:
                _vm.showAdvancedRequiredSettingsDialog,
              formDefinition: _vm.formDefinition,
              layer: _vm.layer,
              selectedItem: _vm.selectedRepeatingGroupItemOptions,
            },
            on: {
              "advanced-settings-dialog-close": function ($event) {
                _vm.showAdvancedRequiredSettingsDialog = false
              },
              input: function ($event) {
                return _vm.onSubmit()
              },
            },
            model: {
              value: _vm.selectedRepeatingGroupItemOptions,
              callback: function ($$v) {
                _vm.selectedRepeatingGroupItemOptions = $$v
              },
              expression: "selectedRepeatingGroupItemOptions",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }