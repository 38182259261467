var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "caption mt-6 mb-3" }, [_vm._v("Access")]),
      _c(
        "v-list",
        [
          _c("DepartmentsAccessForm", {
            attrs: { layer: _vm.layer },
            on: {
              "access-updated": function ($event) {
                return _vm.$emit("access-updated", $event)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }