<template>
  <v-main fluid height="100vh" width="100%" max-width="100%">
    <v-toolbar
      class="white--text elevation-0 flex-grow-0 top-bar mt-n14 mx-0 px-0"
      height="56px"
    >
      <v-toolbar-title class="d-flex align-center">
        <v-icon
          color="white"
          @click="showMenuText = !showMenuText"
          class="mx-0"
        >
          {{ mdiMenu }}
        </v-icon>

        <div class="px-8">
          {{ formDefinition.form.formDescription.title }}
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        dark
        text
        @click="showPlaceholderDrawer = true"
        outlined
        :disabled="!canManageForms"
      >
        <v-icon>
          {{ mdiPlus }}
        </v-icon>
        Form Placeholders
      </v-btn>
      <v-btn
        dark
        type="submit"
        outlined
        @click="saveForm"
        :disabled="isButtonDisabled || !canManageForms"
        id="save-button"
        class="ml-2"
      >
        Save Form
      </v-btn>
      <v-btn icon dark @click="formBuilderClose">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <section class="d-flex">
      <section
        :style="{
          height: `calc(100vh - 56px)`,
          width: `56px`,
          'min-width': '56px',
          'max-width': '56px',
        }"
        class="white overflow-y-hidden"
        @mouseenter="showMenuText = true"
        v-if="!showMenuText"
      >
        <v-list
          class="pa-0"
          dense
          ref="formBuilderMenu"
          @mouseenter="showMenuText = true"
        >
          <v-list-item
            @click="activeTab = tabs.FORM_BUILDER"
            :class="activeTab === tabs.FORM_BUILDER ? 'highlighted' : ''"
            id="formBuilderTab"
          >
            <v-list-item-icon class="px-0 mx-0">
              <v-icon color="primary">{{ mdiFormSelect }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item
            @click="activeTab = tabs.UTILIBOTS.DOC_BUILDER"
            :class="
              activeTab === tabs.UTILIBOTS.DOC_BUILDER ? 'highlighted' : ''
            "
          >
            <v-list-item-icon class="px-0 mx-0">
              <v-icon color="primary">{{ mdiFileDocumentEdit }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider></v-divider>

          <v-list-item
            @click="activeTab = tabs.UTILIBOTS.EMAIL"
            id="emailTab"
            :class="activeTab === tabs.UTILIBOTS.EMAIL ? 'highlighted' : ''"
          >
            <v-list-item-icon class="px-0 mx-0">
              <v-icon color="primary">{{ mdiEmail }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item
            v-if="selectedFormDefLayer.service_type === 'F'"
            @click="activeTab = tabs.UTILIBOTS.ARCGIS_RELATED_TABLE"
            :class="
              activeTab === tabs.UTILIBOTS.ARCGIS_RELATED_TABLE
                ? 'highlighted'
                : ''
            "
          >
            <v-list-item-icon class="px-0 mx-0">
              <v-icon color="primary">{{ mdiTable }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item
            v-if="isLocateRequestLayer"
            @click="activeTab = tabs.UTILIBOTS.SAVE_811_TICKET"
            :class="
              activeTab === tabs.UTILIBOTS.SAVE_811_TICKET ? 'highlighted' : ''
            "
          >
            <v-list-item-icon class="px-0 mx-0">
              <v-icon color="primary">{{ mdiMapMarkerRadius }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item
            @click="activeTab = tabs.UTILIBOTS.SCHEDULE_FORM"
            :class="
              activeTab === tabs.UTILIBOTS.SCHEDULE_FORM ? 'highlighted' : ''
            "
          >
            <v-list-item-icon class="px-0 mx-0">
              <v-icon color="primary">{{ mdiCalendarCheck }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item
            v-if="showCityworksUtiliBot"
            @click="activeTab = tabs.UTILIBOTS.CITYWORKS"
            :class="activeTab === tabs.UTILIBOTS.CITYWORKS ? 'highlighted' : ''"
          >
            <v-list-item-icon class="px-0 mx-0">
              <v-icon color="primary">{{ mdiCity }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            @click="activeTab = tabs.SETTINGS"
            id="settingsTab"
            :class="activeTab === tabs.SETTINGS ? 'highlighted' : ''"
          >
            <v-list-item-icon class="px-0 mx-0">
              <v-icon color="primary">{{ mdiCog }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider></v-divider>
        </v-list>
      </section>
      <section
        :style="{
          height: `calc(100vh - 56px)`,
          width: `56px`,
          'min-width': '56px',
          'max-width': '56px',
        }"
        class="white overflow-y-hidden"
        @mouseleave="showMenuText = false"
        v-if="showMenuText"
      >
        <v-list
          class="pa-0 border-right"
          dense
          :style="{
            position: 'fixed',
            top: '56px',
            'z-index': 1000,
            height: 'calc(100vh - 56px)',
          }"
        >
          <v-list-item
            @click="activeTab = tabs.FORM_BUILDER"
            :class="activeTab === tabs.FORM_BUILDER ? 'highlighted' : ''"
            id="formBuilderTab"
          >
            <v-list-item-icon class="px-0 mx-0">
              <v-icon color="primary">{{ mdiFormSelect }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="nav-list-items pl-3">
              Form Builder
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="activeTab = tabs.UTILIBOTS.DOC_BUILDER"
            :class="
              activeTab === tabs.UTILIBOTS.DOC_BUILDER ? 'highlighted' : ''
            "
          >
            <v-list-item-icon class="px-0 mx-0">
              <v-icon color="primary">{{ mdiFileDocumentEdit }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="nav-list-items pl-3">
              Doc Builder
            </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>

          <v-list-item
            @click="activeTab = tabs.UTILIBOTS.EMAIL"
            id="emailTab"
            :class="activeTab === tabs.UTILIBOTS.EMAIL ? 'highlighted' : ''"
          >
            <v-list-item-icon class="px-0 mx-0">
              <v-icon color="primary">{{ mdiEmail }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="nav-list-items pl-3">
              Email
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="selectedFormDefLayer.service_type === 'F'"
            @click="activeTab = tabs.UTILIBOTS.ARCGIS_RELATED_TABLE"
            :class="
              activeTab === tabs.UTILIBOTS.ARCGIS_RELATED_TABLE
                ? 'highlighted'
                : ''
            "
          >
            <v-list-item-icon class="px-0 mx-0">
              <v-icon color="primary">{{ mdiTable }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="nav-list-items pl-3">
              ArcGIS Related Table
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isLocateRequestLayer"
            @click="activeTab = tabs.UTILIBOTS.SAVE_811_TICKET"
            :class="
              activeTab === tabs.UTILIBOTS.SAVE_811_TICKET ? 'highlighted' : ''
            "
          >
            <v-list-item-icon class="px-0 mx-0">
              <v-icon color="primary">{{ mdiMapMarkerRadius }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="nav-list-items pl-3">
              Save 811 Tickets to Feature Service
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="activeTab = tabs.UTILIBOTS.SCHEDULE_FORM"
            :class="
              activeTab === tabs.UTILIBOTS.SCHEDULE_FORM ? 'highlighted' : ''
            "
          >
            <v-list-item-icon class="px-0 mx-0">
              <v-icon color="primary">{{ mdiCalendarCheck }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="nav-list-items pl-3">
              Schedule Form
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="showCityworksUtiliBot"
            @click="activeTab = tabs.UTILIBOTS.CITYWORKS"
            :class="activeTab === tabs.UTILIBOTS.CITYWORKS ? 'highlighted' : ''"
          >
            <v-list-item-icon class="px-0 mx-0">
              <v-icon color="primary">{{ mdiCity }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="nav-list-items pl-3" v-if="showMenuText">
              Cityworks
            </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            @click="activeTab = tabs.SETTINGS"
            id="settingsTab"
            :class="activeTab === tabs.SETTINGS ? 'highlighted' : ''"
          >
            <v-list-item-icon class="px-0 mx-0">
              <v-icon color="primary">{{ mdiCog }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="nav-list-items pl-3" v-if="showMenuText">
              Settings
            </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
        </v-list>
      </section>

      <form
        class="elevation-0 pa-0 ma-0 flex-grow-1 d-flex"
        :class="{ 'flex-wrap': $vuetify.breakpoint.xsOnly }"
        :style="{
          height: $vuetify.breakpoint.smAndUp ? 'calc(100vh - 56px)' : 'auto',
          'min-width': '0px',
        }"
      >
        <UnsavedChangesDialog
          :showFormBuilderEditFormCloseDialog="
            showFormBuilderEditFormCloseDialog
          "
          @form-builder-edit-form-save-and-close="
            onFormBuilderEditFormSaveAndClose
          "
          @form-builder-edit-form-close="$emit('form-builder-edit-form-close')"
          @reverse-changes="showFormBuilderEditFormCloseDialog = false"
        />

        <section
          :class="{
            'main-page-background': [tabs.SETTINGS, tabs.FORM_BUILDER].includes(
              activeTab
            ),
            'gray-main-page-background': ![
              tabs.SETTINGS,
              tabs.FORM_BUILDER,
            ].includes(activeTab),
          }"
          class="flex-grow-1 overflow-y-auto"
        >
          <template v-if="activeTab === tabs.SETTINGS">
            <validation-observer ref="settingsForm">
              <SettingsForm v-model="formDefinition" />
            </validation-observer>
          </template>

          <template v-if="activeTab === tabs.FORM_BUILDER">
            <div class="mt-6 mb-3 mx-auto" id="preview-form">
              <v-switch
                v-model="previewMode"
                label="Preview Form"
                @change="onPreviewModeToggle"
              ></v-switch>
            </div>

            <AdvancedFormEditDialog
              v-if="showAdvancedFormEditDialog"
              :showAdvancedFormEditDialog="showAdvancedFormEditDialog"
              v-model="formDefinition"
              @close-advanced-form-edit-dialog="
                showAdvancedFormEditDialog = false
              "
              @input="showAdvancedFormEditDialog = false"
            />

            <v-card
              width="600px"
              min-width="600px"
              max-width="600px"
              class="mx-auto elevation-0"
              id="formBuilder"
            >
              <v-toolbar dark color="primary">
                <v-toolbar-title>
                  {{ formDefinition.form.formDescription.title }}
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-menu offset-y v-if="canManageForms">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark v-bind="attrs" v-on="on" icon>
                      <v-icon>
                        {{ mdiDotsVertical }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list class="py-0">
                    <v-list-item @click="showAdvancedFormEditDialog = true">
                      <v-icon class="mr-2" color="primary">
                        {{ mdiPencil }}
                      </v-icon>
                      <div color="primary" class="overflowText">
                        Advanced Edit
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>

              <FormBuilderPreview
                :openSections="openSections"
                :formDefinition="formDefinition"
                :previewMode="previewMode"
                :selectedItemId="selectedItemId"
                :selectedWholeSectionId="selectedWholeSectionId"
                :addingField="addingField"
                @add-field="addField"
                @select-item="selectItem"
                @select-whole-section="selectWholeSection"
                @duplicate-item="duplicateItem"
                @duplicate-section="duplicateSection"
                @remove-item-from-section="removeItemFromSection"
                @assign-numbers-to-sections="assignNumbersToSections"
                @input="onInput"
                @assign-numbers-to-items="assignNumbersToItems"
              />
            </v-card>

            <v-card
              class="pl-5 pr-5 mx-auto"
              v-if="!previewMode"
              width="600px"
              min-width="600px"
              max-width="600px"
            >
              <v-card-text>
                <v-btn
                  color="primary"
                  :dark="canManageForms"
                  @click="addSection"
                  tile
                  id="addSectionBtn"
                  :disabled="!canManageForms"
                >
                  <v-icon>{{ mdiPlus }}</v-icon>
                  <div class="pl-2">ADD SECTION</div>
                </v-btn>
              </v-card-text>
            </v-card>
          </template>

          <template v-if="activeTab === tabs.UTILIBOTS.DOC_BUILDER">
            <validation-observer ref="settingsForm">
              <ReportTemplateForm
                v-model="formDefinition"
                :activeTab="activeTab"
                :selectedFormDefLayer="selectedFormDefLayer"
                :isLocateRequestLayer="isLocateRequestLayer"
                @input="onTemplateChanged()"
              />
            </validation-observer>
          </template>

          <template v-if="activeTab === tabs.UTILIBOTS.EMAIL">
            <validation-observer ref="settingsForm">
              <EmailForm v-model="formDefinition" id="emailForm" />
            </validation-observer>
          </template>

          <template v-if="activeTab === tabs.UTILIBOTS.ARCGIS_RELATED_TABLE">
            <validation-observer ref="settingsForm">
              <ArcGisRelatedTableForm v-model="formDefinition" />
            </validation-observer>
          </template>

          <template v-if="activeTab === tabs.UTILIBOTS.SAVE_811_TICKET">
            <validation-observer ref="settingsForm">
              <Save811TicketForm v-model="formDefinition" />
            </validation-observer>
          </template>

          <template v-if="activeTab === tabs.UTILIBOTS.SCHEDULE_FORM">
            <validation-observer ref="settingsForm">
              <TaskSchedulerForm v-model="formDefinition" />
            </validation-observer>
          </template>

          <template v-if="activeTab === tabs.UTILIBOTS.CITYWORKS">
            <validation-observer ref="settingsForm">
              <CityworksForm v-model="formDefinition" />
            </validation-observer>
          </template>
        </section>

        <v-navigation-drawer
          stateless
          app
          v-model="showFields"
          left
          :permanent="showFields"
          style="z-index: 1002"
          id="fieldSelectionPanel"
        >
          <template>
            <v-toolbar
              color="primary"
              height="56px"
              class="elevation-0"
              ref="toolbar"
            >
              <v-toolbar-title class="white--text text-h6 ml-2">
                Select Field
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon color="white" @click="showFields = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-list
              style="max-height: calc(100vh - 56px)"
              class="overflow-y-auto"
              dense
            >
              <v-subheader>STANDARD QUESTIONS</v-subheader>
              <v-list-item
                v-for="q of standardQuestionTypes"
                :key="q.title"
                @click="
                  showFields = false;
                  selectItemType(q.title);
                  addItemToForm(
                    selectedQuestionType,
                    selectedSectionId,
                    addAtIndex
                  );
                  selectItem(
                    selectedSectionId,
                    selectedItemId,
                    getSelectedItemType(selectedItem)
                  );
                "
              >
                <v-icon color="primary">{{ q.icon }}</v-icon>
                <div class="pl-3" style="color: #093637">{{ q.title }}</div>
              </v-list-item>

              <v-subheader>ADVANCED QUESTIONS</v-subheader>
              <v-list-item
                v-for="aq of computedAdvancedQuestionTypes"
                :key="aq.title"
                @click="
                  showFields = false;
                  addingField = true;
                  selectItemType(aq.title);
                  addItemToForm(
                    selectedQuestionType,
                    selectedSectionId,
                    addAtIndex
                  );
                  addingField = false;

                  selectItem(
                    selectedSectionId,
                    selectedItemId,
                    getSelectedItemType(selectedItem)
                  );
                "
              >
                <v-icon color="primary">{{ aq.icon }}</v-icon>
                <div class="pl-3" style="color: #093637">{{ aq.title }}</div>
              </v-list-item>

              <v-subheader>INFORMATION TYPE</v-subheader>

              <v-list-item
                v-for="it of itemTypes"
                :key="it.title"
                @click="
                  showFields = false;

                  selectItemType(it.title);
                  addItemToForm(
                    selectedQuestionType,
                    selectedSectionId,
                    addAtIndex
                  );
                  selectItem(
                    selectedSectionId,
                    selectedItemId,
                    getSelectedItemType(selectedItem)
                  );
                "
              >
                <v-icon color="primary">{{ it.icon }}</v-icon>
                <div class="pl-3" style="color: #093637">{{ it.title }}</div>
              </v-list-item>
            </v-list>
          </template>
        </v-navigation-drawer>

        <v-navigation-drawer
          stateless
          app
          v-model="showDrawer"
          right
          :permanent="typeof selectedItemId === 'string' && showDrawer"
          style="z-index: 1002"
        >
          <FormControlEditForm
            v-if="showDrawer"
            v-model="formDefinition"
            :selectedSectionId="selectedSectionId"
            :selectedItemId="selectedItemId"
            :selectedControlType="selectedControlType"
            :edit="typeof selectedFormDefinitionId === 'string'"
            @input="assignNumbersToItems()"
            @discard-changes="showDrawer = false"
            @form-control-form-submitted="showDrawer = false"
          />
        </v-navigation-drawer>

        <v-navigation-drawer
          stateless
          app
          v-model="showPlaceholderDrawer"
          right
          :permanent="showPlaceholderDrawer"
          style="z-index: 1002"
          width="300px"
        >
          <v-toolbar color="primary" height="56px">
            <v-toolbar-title class="white--text text-h6 ml-2">
              Form Placeholders
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="white" @click="showPlaceholderDrawer = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <FormPlaceholdersForm
            :formDefinition="formDefinition"
            :type="activeTab"
            @placeholder-copied="showCopiedSnackbar = true"
          />
        </v-navigation-drawer>
      </form>
    </section>

    <v-snackbar
      v-model="showSavedSnackbar"
      style="z-index: 1001"
      :timeout="3000"
    >
      Form Saved
    </v-snackbar>

    <v-snackbar v-model="showCopiedSnackbar" style="z-index: 1001">
      Form Placeholder Copied
    </v-snackbar>

    <v-dialog
      v-model="showAdvancedEditDialog"
      :max-width="$vuetify.breakpoint.xsOnly ? undefined : '600px'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      :style="{ height: $vuetify.breakpoint.xsOnly ? '100vh' : '95vh' }"
    >
      <AdvancedEditForm
        @close-advanced-edit-dialog="showAdvancedEditDialog = false"
        v-if="showAdvancedEditDialog"
        v-model="formDefinition"
        :selectedSectionId="selectedSectionId"
        :selectedItemId="selectedItemId"
        :selectedControlType="selectedControlType"
        @input="
          showAdvancedEditDialog = false;
          assignNumbersToItems();
          reloadDrawer();
        "
      />
    </v-dialog>
  </v-main>
</template>

<script>
import FormControlEditForm from "@/components/forms/form-builder-edit-form/FormControlEditForm.vue";
import FormPlaceholdersForm from "@/components/forms/form-builder-edit-form/FormPlaceholdersForm.vue";
import SettingsForm from "@/components/forms/form-builder-edit-form/SettingsForm.vue";
import ReportTemplateForm from "@/components/forms/form-builder-edit-form/ReportTemplateForm.vue";
import EmailForm from "@/components/forms/form-builder-edit-form/EmailForm.vue";
import ArcGisRelatedTableForm from "@/components/forms/form-builder-edit-form/ArcGisRelatedTableForm.vue";
import Save811TicketForm from "@/components/forms/form-builder-edit-form/Save811TicketForm.vue";
import CityworksForm from "@/components/forms/form-builder-edit-form/CityworksForm.vue";
import TaskSchedulerForm from "@/components/forms/form-builder-edit-form/TaskSchedulerForm.vue";
import AdvancedEditForm from "@/components/forms/form-builder-edit-form/AdvancedEditForm.vue";
import UnsavedChangesDialog from "@/components/forms/form-builder-edit-form/UnsavedChangesDialog.vue";
import FormBuilderPreview from "@/components/forms/form-builder-edit-form/FormBuilderPreview.vue";
import {
  mdiDotsVertical,
  mdiDelete,
  mdiContentCopy,
  mdiChevronDown,
  mdiPlus,
  mdiMinus,
  mdiPencil,
  mdiDragVertical,
  mdiFormTextbox,
  mdiText,
  mdiEmail,
  mdiCalendar,
  mdiClockOutline,
  mdiNumeric,
  mdiFormatListChecks,
  mdiFormatListCheckbox,
  mdiImageMultiple,
  mdiFile,
  mdiSignatureFreehand,
  mdiCalculator,
  mdiCheckboxMarked,
  mdiPlusBoxMultiple,
  mdiFormatTitle,
  mdiLink,
  mdiClipboardText,
  mdiImage,
  mdiMenu,
  mdiCog,
  mdiFormSelect,
  mdiFileDocumentEdit,
  mdiTable,
  mdiMapMarkerRadius,
  mdiCalendarCheck,
  mdiCity,
} from "@mdi/js";
import {
  standardQuestionTypes,
  advancedQuestionTypes,
  formDefinition,
  itemTypes,
  newSection,
  getItemByType,
} from "@/constants/question";
import { cloneDeep, isEqual } from "lodash";
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";
import dependantValueMixin from "@/mixins/dependantValueMixin";
import AdvancedFormEditDialog from "@/components/forms/form-builder-edit-form/AdvancedFormEditDialog";
import permissionsMixin from "@/mixins/permissionsMixin";

const APIURL = process.env.VUE_APP_API_URL;

const tabs = {
  FORM_BUILDER: "form-builder",
  SETTINGS: "settings",
  UTILIBOTS: {
    EMAIL: "email",
    ARCGIS_RELATED_TABLE: "arcgis-related-table",
    SAVE_811_TICKET: "save-811-ticket",
    SCHEDULE_FORM: "schedule-form",
    CITYWORKS: "cityworks",
    DOC_BUILDER: "pdf-report",
  },
};
export default {
  name: "FormBuilderEditForm",
  props: {
    selectedFormDefinitionId: String,
    formIsNewForm: Boolean,
  },
  mixins: [dependantValueMixin, permissionsMixin],
  data() {
    const [{ title: selectedQuestionType }] = standardQuestionTypes;
    const { innerHeight } = this;
    return {
      formDefinition,
      showDrawer: false,
      showFields: false,
      previewMode: false,
      openSections: [],
      standardQuestionTypes,
      advancedQuestionTypes,
      itemTypes,
      selectedQuestionType,
      selectedSectionId: 1,
      selectedItemId: undefined,
      selectedControlType: "",
      mdiDelete,
      mdiContentCopy,
      mdiChevronDown,
      mdiPlus,
      mdiMinus,
      mdiDotsVertical,
      mdiPencil,
      mdiDragVertical,
      mdiFormTextbox,
      mdiText,
      mdiEmail,
      mdiCalendar,
      mdiClockOutline,
      mdiNumeric,
      mdiFormatListChecks,
      mdiFormatListCheckbox,
      mdiImageMultiple,
      mdiFile,
      mdiSignatureFreehand,
      mdiCalculator,
      mdiCheckboxMarked,
      mdiPlusBoxMultiple,
      mdiFormatTitle,
      mdiLink,
      mdiClipboardText,
      mdiImage,
      mdiMenu,
      mdiFormSelect,
      mdiFileDocumentEdit,
      mdiTable,
      mdiMapMarkerRadius,
      mdiCalendarCheck,
      mdiCity,
      mdiCog,
      showFormBuilderEditFormCloseDialog: false,
      tabs,
      activeTab: this.$route.query.tab,
      selectedWholeSectionId: undefined,
      showSavedSnackbar: false,
      savedForm: {},
      selectedFormDefLayer: {},
      leftTabOverflow: false,
      showAdvancedEditDialog: false,
      showConfirmDeleteDialog: false,
      showConfirmDeleteSectionDialog: false,
      innerHeight,
      showPlaceholderDrawer: false,
      showCopiedSnackbar: false,
      templateChanged: false,
      showAdvancedFormEditDialog: false,
      showPdfSampleDialog: false,
      addAtIndex: undefined,
      addingField: false,
      formDefinitionId: undefined,
      showMenuText: false,
      menuObserver: undefined,
      observer: undefined,
    };
  },
  components: {
    FormControlEditForm,
    SettingsForm,
    ReportTemplateForm,
    EmailForm,
    ArcGisRelatedTableForm,
    Save811TicketForm,
    CityworksForm,
    AdvancedEditForm,
    UnsavedChangesDialog,
    FormPlaceholdersForm,
    AdvancedFormEditDialog,
    TaskSchedulerForm,
    FormBuilderPreview,
  },
  computed: {
    showCityworksUtiliBot() {
      const auth = JSON.parse(localStorage.getItem("auth"));
      const hasCityworksAMSIntegration =
        auth.organization.access_cityworks_ams_integration;
      const hasCityworks811Integration =
        auth.organization.access_cityworks_811_integration;
      return (
        (hasCityworksAMSIntegration &&
          this.selectedFormDefLayer.service_type !== "L") ||
        (hasCityworks811Integration &&
          this.selectedFormDefLayer.service_type === "L")
      );
    },
    computedAdvancedQuestionTypes() {
      let questionTypes = advancedQuestionTypes;
      if (this.isLocateRequestLayer) {
        return questionTypes.filter((type) => type.title !== "Action Item");
      } else {
        return questionTypes.filter(
          (type) => type.title !== "Positive Response"
        );
      }
    },
    isButtonDisabled() {
      if (this.activeTab === this.tabs.UTILIBOTS.DOC_BUILDER) {
        return !this.templateChanged;
      }
      return (
        !this.isEsriTokenPresent &&
        this.activeTab === tabs.UTILIBOTS.ARCGIS_RELATED_TABLE
      );
    },
    selectedItem() {
      if (!this.formDefinition.form) {
        return {};
      }
      const section = this.formDefinition.form.sections.find(
        (s) => s.id === this.selectedSectionId
      );
      if (section) {
        const { items } = section;
        if (Array.isArray(items)) {
          return items.find((item) => item.id === this.selectedItemId);
        }
      }
      return {};
    },
    hasChanges() {
      return isEqual(this.formDefinition, this.savedForm);
    },
    isLocateRequestLayer() {
      const { service_type: serviceType } = this.selectedFormDefLayer;
      return serviceType === "L";
    },
    isEsriTokenPresent() {
      return Boolean(localStorage.getItem("esri_token"));
    },
    ...mapGetters(["newFormData"]),
  },
  methods: {
    ...mapMutations(["setNewFormData"]),
    addField(sectionId) {
      this.selectedSectionId = sectionId;
      this.showFields = true;
    },
    showDuplicateButton(item) {
      return item?.question?.type !== "POSITIVE_RESPONSE";
    },
    getLeftTabWidth() {
      if (this.$vuetify.breakpoint.smAndUp) {
        return "250px";
      }
      return "100%";
    },
    onTemplateChanged() {
      this.templateChanged = true;
    },
    async reloadDrawer() {
      this.showDrawer = false;
      await this.$nextTick();
      this.showDrawer = true;
    },
    async onFormBuilderEditFormSaveAndClose() {
      this.showFormBuilderEditFormCloseDialog = false;
      await this.saveForm();
      this.$emit("form-builder-edit-form-close");
    },
    isSectionVisible(section) {
      if (
        !this.previewMode ||
        !section.visible ||
        !section.visible?.condition ||
        section.visible?.condition === "ALWAYS"
      ) {
        return true;
      }
      if (section.visible.condition === "NEVER") {
        return false;
      }
      const { sections } = { ...this.formDefinition.form };
      const flattenedItems = sections.map(({ items }) => items).flat();
      const dependantItem = flattenedItems.find(
        (item) => item.id === section.visible.dependantId
      );
      const dependantItemValue = dependantItem?.value;
      if (Array.isArray(dependantItemValue)) {
        if (dependantItemValue.length > 1) {
          return false;
        } else {
          const [dependantValue] = dependantItemValue;
          return this.checkSectionDependantValue(
            dependantValue,
            section,
            "visible"
          );
        }
      } else {
        return this.checkSectionDependantValue(
          dependantItemValue,
          section,
          "visible"
        );
      }
    },
    formBuilderClose() {
      if (!this.hasChanges && this.canManageForms) {
        this.showFormBuilderEditFormCloseDialog = true;
      } else {
        this.$emit("form-builder-edit-form-close");
      }
    },
    async saveForm() {
      this.templateChanged = false;
      const success = await this.$refs.settingsForm?.validate?.();
      if (this.$refs.settingsForm && !success) {
        return;
      }

      const formDefinition = cloneDeep(this.formDefinition);
      if (
        typeof formDefinition.organization_user_enabled === "undefined" ||
        formDefinition.organization_user_enabled == null
      ) {
        formDefinition.organization_user_enabled = true;
      }

      if (
        typeof formDefinition.site_contact_enabled === "undefined" ||
        formDefinition.site_contact_enabled === null
      ) {
        formDefinition.site_contact_enabled = false;
      }

      if (
        typeof formDefinition.show_utilibots_on_form_submission ===
          "undefined" ||
        formDefinition.show_utilibots_on_form_submission === null
      ) {
        formDefinition.show_utilibots_on_form_submission = false;
      }

      for (const section of formDefinition.form.sections) {
        for (const item of section.items) {
          delete item.value;
          if (
            item.question &&
            ["SINGLE_SELECT", "MULTI_SELECT"].includes(item.question.type)
          ) {
            if (!item.question?.default) {
              item.default = {
                type: null,
              };
            }
          }
        }
      }
      formDefinition.form.emailSettings = formDefinition.form.emailSettings.map(
        // eslint-disable-next-line no-unused-vars
        ({ id, ...rest }) => rest
      );
      if (typeof this.selectedFormDefinitionId === "string") {
        await axios.put(
          `${APIURL}/form_definitions/${this.selectedFormDefinitionId}`,
          formDefinition
        );
        this.savedForm = cloneDeep(this.formDefinition);
      } else {
        const { data: savedForm } = await axios.post(
          `${APIURL}/form_definitions`,
          formDefinition
        );
        this.formDefinitionId = savedForm.form_definition_id;
        this.savedForm = cloneDeep(this.formDefinition);
        this.$emit("form-builder-edit-form-submitted", this.formDefinitionId);

        if (this.formIsNewForm) {
          await axios.put(
            `${APIURL}/form_definitions/${this.formDefinitionId}/is_active`,
            { is_active: true }
          );
        }
      }
      this.showSavedSnackbar = true;
    },
    async onInput(value, sectionId, itemId) {
      const sectionIndex = this.formDefinition.form.sections.findIndex(
        (s) => s.id === sectionId
      );
      const { items } = this.formDefinition.form.sections[sectionIndex];
      const itemIndex = items.findIndex((item) => item.id === itemId);
      this.$set(
        this.formDefinition.form.sections[sectionIndex].items,
        itemIndex,
        {
          ...this.formDefinition.form.sections[sectionIndex].items[itemIndex],
          value,
        }
      );
      this.assignNumbersToItems();
      await this.$nextTick();
      this.formDefinition = cloneDeep(this.formDefinition);
    },
    getSelectedItemType(item) {
      if (typeof item?.question === "object" && item?.question !== null) {
        return item?.question?.type;
      }
      return item?.information?.type;
    },
    checkFieldIsNotLast(item, sectionId) {
      const section = this.formDefinition.form.sections.find(
        (s) => s.id === sectionId
      );
      if (item.id !== section.items.at(-1).id) {
        return true;
      } else {
        return false;
      }
    },
    selectWholeSection(section) {
      if (this.previewMode) {
        this.showDrawer = false;
        this.selectedSectionId = undefined;
        this.selectedItemId = undefined;
        this.selectedControlType = undefined;
        this.selectedWholeSectionId = undefined;
        return;
      }
      this.selectedWholeSectionId = section.id;
    },
    async selectItem(selectedSectionId, selectedItemId, selectedControlType) {
      if (this.previewMode) {
        this.showDrawer = false;
        this.selectedSectionId = undefined;
        this.selectedItemId = undefined;
        this.selectedControlType = undefined;
        this.selectedWholeSectionId = undefined;
        return;
      }
      this.showDrawer = false;
      await this.$nextTick();
      this.showDrawer = true;
      this.selectedSectionId = selectedSectionId;
      this.selectedItemId = selectedItemId;
      this.selectedControlType = selectedControlType;
    },
    async addSection() {
      const ids = this.formDefinition.form.sections.map(({ id }) => id);
      const id = Math.max(...ids) + 1;
      let section = {};
      if (this.formDefinition.form.sections.length === 0) {
        section = { ...newSection, isOpenByDefault: true };
      } else {
        section = { ...newSection, isOpenByDefault: false };
      }
      this.formDefinition.form.sections.push(cloneDeep({ ...section, id }));
      this.assignNumbersToSections();
      this.assignNumbersToItems();
      await this.$nextTick();
      this.onPreviewModeToggle();
    },
    onPreviewModeToggle() {
      if (!this.previewMode) {
        this.openSections = [
          ...this.formDefinition.form.sections.map((_, i) => i),
        ];
      } else {
        this.openSections = [
          ...this.formDefinition.form.sections
            .filter((s) => s.isOpenByDefault)
            .map((_, i) => i),
        ];
      }
    },
    selectItemType(type) {
      this.selectedQuestionType = type;
    },
    addItemToForm(selectedQuestionType, selectedSectionId, addAtIndex) {
      if (addAtIndex !== undefined) {
        this.addItemToSectionAtIndex(
          selectedQuestionType,
          selectedSectionId,
          addAtIndex
        );
      } else {
        this.addItemToSection(selectedQuestionType, selectedSectionId);
      }
      this.addAtIndex = undefined;
    },
    addItemToSection(type, sectionId) {
      const section = this.formDefinition.form.sections.find(
        (s) => s.id === sectionId
      );
      const sectionIndex = this.formDefinition.form.sections.findIndex(
        (s) => s.id === sectionId
      );
      const item = getItemByType(type, this.formDefinition);
      section.items.push(item);
      this.$set(this.formDefinition.form.sections, sectionIndex, {
        ...section,
      });
      this.assignNumbersToItems();
      this.selectedItemId = item.id;
    },
    addItemToSectionAtIndex(type, sectionId, index) {
      const section = this.formDefinition.form.sections.find(
        (s) => s.id === sectionId
      );
      const sectionIndex = this.formDefinition.form.sections.findIndex(
        (s) => s.id === sectionId
      );
      const item = getItemByType(type, this.formDefinition);

      section.items.splice(index + 1, 0, item);
      this.$set(this.formDefinition.form.sections, sectionIndex, {
        ...section,
      });
      this.assignNumbersToItems();
      this.selectedItemId = item.id;
    },

    removeItemFromSection(sectionId, itemId) {
      const section = this.formDefinition.form.sections.find(
        (s) => s.id === sectionId
      );
      const itemIndex = section.items.findIndex((item) => item.id === itemId);
      section.items.splice(itemIndex, 1);
      this.assignNumbersToItems();
      this.selectedItemId = undefined;
      this.selectedControlType = "";
      this.showDrawer = false;
    },
    duplicateItem(sectionId, itemId) {
      const sectionIndex = this.formDefinition.form.sections.findIndex(
        (s) => s.id === sectionId
      );
      const { sections } = this.formDefinition.form;
      const section = this.formDefinition.form.sections.find(
        (s) => s.id === sectionId
      );
      const { items } = this.formDefinition.form.sections[sectionIndex];
      const item = items.find((item) => item.id === itemId);
      const itemIndex = items.findIndex((item) => item.id === itemId);
      const sectionItems = [...sections.map((s) => s.items)];
      const itemIds = sectionItems.flat().map((item) => item.id);
      const id = Math.max(...itemIds) + 1;
      section.items.splice(itemIndex + 1, 0, { ...item, id });
      this.$set(this.formDefinition.form.sections, sectionIndex, {
        ...section,
      });
      this.assignNumbersToItems();

      this.selectedItemId = id;
      this.selectItem(
        sectionId,
        this.selectedItemId,
        this.getSelectedItemType(this.selectedItem)
      );
    },
    async duplicateSection(sectionIndex) {
      const sectionId = this.formDefinition.form.sections[sectionIndex].id;
      const sectionItems = [
        ...this.formDefinition.form.sections.map((s) => s.items),
      ];
      const itemIds = sectionItems.flat().map((item) => item.id);
      let id = Math.max(...itemIds) + 1;
      const section = cloneDeep(
        this.formDefinition.form.sections.find((s) => s.id === sectionId)
      );
      for (const item of section.items) {
        item.id = id;
        id++;
      }
      this.formDefinition.form.sections.splice(sectionIndex + 1, 0, section);
      this.assignNumbersToSections();
      await this.$nextTick();
      this.openSections = [
        ...this.formDefinition.form.sections.map(() => false),
      ];
      this.assignNumbersToItems();
    },
    async deleteSection(sectionIndex) {
      this.formDefinition.form.sections.splice(sectionIndex, 1);
      this.assignNumbersToSections();
      await this.$nextTick();
      this.openSections = [
        ...this.formDefinition.form.sections.map(() => false),
      ];
      this.showDrawer = false;
      this.assignNumbersToItems();
    },
    assignNumbersToItems() {
      for (const [
        sectionIndex,
        section,
      ] of this.formDefinition.form.sections.entries()) {
        let num = 1;
        for (const [index, item] of section.items.entries()) {
          if (item.question) {
            if (section.numbered) {
              this.$set(
                this.formDefinition.form.sections[sectionIndex].items,
                index,
                {
                  ...item,
                  number: `${num}. `,
                }
              );
              num++;
            } else {
              this.$set(
                this.formDefinition.form.sections[sectionIndex].items,
                index,
                {
                  ...item,
                  number: "",
                }
              );
            }
          }
        }
      }
    },
    assignNumbersToSections() {
      for (const [
        index,
        section,
      ] of this.formDefinition.form.sections.entries()) {
        this.formDefinition.form.sections[index] = {
          ...section,
          id: index + 1,
        };
      }
    },
    async getLayer(formDefintion) {
      const { map_service_id: mapServiceId } = formDefintion;
      const {
        data: { results },
      } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
      this.selectedFormDefLayer = results;
    },
    onResize() {
      const [elHtml] = document.getElementsByTagName("html");
      if (elHtml) {
        elHtml.style.overflowY = "hidden";
      }
      this.innerHeight = window.innerHeight;
    },
    addResizeListener() {
      window.addEventListener("resize", this.onResize);
    },
  },
  beforeMount() {
    if (this.formIsNewForm) {
      this.saveForm();
    }
    this.onPreviewModeToggle();
    this.addResizeListener();
    this.onResize();
  },
  beforeDestroy() {
    const [elHtml] = document.getElementsByTagName("html");
    elHtml.style.overflowY = null;
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    selectedFormDefinitionId: {
      immediate: true,
      async handler(val) {
        if (typeof val === "string") {
          this.setNewFormData({});
          const { data } = await axios.get(`${APIURL}/form_definitions/${val}`);
          data.form.sections = data.form.sections.map((s, i) => ({
            ...s,
            id: i + 1,
          }));
          this.formDefinition = { ...data };
          await this.$nextTick();
          this.openSections = [
            ...this.formDefinition.form.sections.map((_, i) => i),
          ];
        } else {
          const {
            title,
            mapServiceId,
            html_merge_definition_version: htmlMergeDefinitionVersion,
            site_contact_enabled: siteContactEnabled,
            organization_user_enabled: organizationUserEnabled,
          } = this.newFormData;
          this.$set(this.formDefinition, "map_service_id", mapServiceId);
          this.$set(
            this.formDefinition,
            "site_contact_enabled",
            siteContactEnabled
          );
          this.$set(
            this.formDefinition,
            "organization_user_enabled",
            organizationUserEnabled
          );
          this.$set(
            this.formDefinition,
            "html_merge_definition_version",
            htmlMergeDefinitionVersion
          );
          this.$set(this.formDefinition.form.formDescription, "title", title);
        }
        this.savedForm = cloneDeep(this.formDefinition);
        await this.getLayer(this.formDefinition);
      },
    },
    selectedItemId(val) {
      if (typeof val === "number") {
        this.selectedWholeSectionId = undefined;
      }
    },
    previewMode(val) {
      if (val) {
        this.showDrawer = false;
        this.selectedSectionId = undefined;
        this.selectedItemId = undefined;
        this.selectedControlType = undefined;
        this.selectedWholeSectionId = undefined;
      } else {
        for (const [
          sectionIndex,
          section,
        ] of this.formDefinition.form.sections.entries()) {
          for (const [itemIndex] of section.items.entries()) {
            delete this.formDefinition.form.sections[sectionIndex].items[
              itemIndex
            ].value;
          }
        }
      }
    },
    activeTab(val) {
      if (val !== this.tabs.FORM_BUILDER) {
        this.showDrawer = false;
      }
      if (this.$route.query.layer) {
        this.$router
          .push({
            path: "/layers",
            query: {
              [`layer`]: this.$route.query.layer,
              [`form`]: this.$route.query.form,
              [`tab`]: val,
            },
          })
          .catch((error) => {
            if (error.name !== "NavigationDuplicated") {
              // capture exception
            }
          });
      } else {
        this.$router
          .push({
            query: {
              [`form`]: this.$route.query.form,
              [`tab`]: val,
            },
          })
          .catch((error) => {
            if (error.name !== "NavigationDuplicated") {
              // capture exception
            }
          });
      }
    },
    formDefinitionId() {
      if (this.$route.query.layer) {
        this.$router
          .push({
            path: "/layers",
            query: {
              [`layer`]: this.$route.query.layer,
              [`form`]: this.formDefinitionId,
              [`tab`]: "form-builder",
            },
          })
          .catch((error) => {
            if (error.name !== "NavigationDuplicated") {
              // capture exception
            }
          });
      } else {
        this.$router
          .push({
            query: {
              [`form`]: this.formDefinitionId,
              [`tab`]: "form-builder",
            },
          })
          .catch((error) => {
            if (error.name !== "NavigationDuplicated") {
              // capture exception
            }
          });
      }
    },

    showDrawer() {
      const [elHtml] = document.getElementsByTagName("html");
      elHtml.style.overflowY = "hidden";
    },
    "$route.query.forms": {
      immediate: true,
      deep: true,
      async handler() {
        if (this.$route.query.tab) {
          this.activeTab = this.$route.query.tab;
        } else {
          this.activeTab = "form-builder";
        }
      },
    },
  },
};
</script>

<style>
.list-group {
  width: 100%;
}
</style>

<style scoped>
.line {
  z-index: 9999;
}

.main-page-background {
  background-color: #f1f2f1;
}

.gray-main-page-background {
  background-color: #f1f2f1;
}

.right-pane {
  height: calc(100vh - 125px);
  overflow-y: auto;
}

.highlighted {
  background-color: #dae1df;
}

.wrap-text {
  -webkit-line-clamp: unset !important;
}

.v-list-item {
  position: unset;
}

.overflowText {
  font-size: 14px;
  font-family: Roboto;
  text-transform: uppercase;
  font-weight: 500;
  color: #286054;
}

#preview-form {
  max-width: 600px;
}

#card-actions {
  position: relative;
  z-index: 1000;
}

.v-list-item:hover {
  background-color: #dddddd;
}

.v-list:hover {
  background-color: none !important;
}

.nav-list-items {
  color: #286054;
  font-weight: 500;
}

.theme--light.v-navigation-drawer .v-divider {
  border-color: rgba(0, 0, 0, 0.25) !important;
}
</style>

<style scoped>
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.v-list-item .v-list-item__title {
  font-size: 16px;
}

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
