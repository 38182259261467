var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.addFieldOptions
        ? _c(
            "div",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.addFieldOptions = true
                    },
                  },
                },
                [_vm._v(" + Add Field Options ")]
              ),
            ],
            1
          )
        : _c("validation-observer", { ref: "createLayerForm" }, [
            _c(
              "form",
              [
                _c("v-simple-table", [
                  _c("tbody", { staticClass: "white" }, [
                    _c("tr", { staticClass: "white" }, [
                      _c(
                        "td",
                        { staticClass: "white value" },
                        [
                          _c("validation-provider", {
                            attrs: { name: "Value", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ errors, valid }) {
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Value",
                                        name: "value",
                                        "hide-details": false,
                                        "background-color": "white",
                                        "error-messages": errors,
                                        success: valid,
                                      },
                                      model: {
                                        value: _vm.gisDataFieldOption.value,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.gisDataFieldOption,
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression: "gisDataFieldOption.value",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { staticClass: "white" },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Label",
                              "hide-details": false,
                              name: "label",
                              "background-color": "white",
                            },
                            model: {
                              value: _vm.gisDataFieldOption.label,
                              callback: function ($$v) {
                                _vm.$set(_vm.gisDataFieldOption, "label", $$v)
                              },
                              expression: "gisDataFieldOption.label",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "white d-flex justify-end align-center",
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                fab: "",
                                "x-small": "",
                                color: "primary",
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: { color: "white" },
                                  on: { click: _vm.addOption },
                                },
                                [_vm._v(" " + _vm._s(_vm.mdiPlus) + " ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c("v-simple-table", [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { staticClass: "value" }, [
                        _c("p", { staticClass: "caption" }, [_vm._v("Value")]),
                      ]),
                      _c("th", [
                        _c("p", { staticClass: "caption" }, [
                          _vm._v("Label (Optional)"),
                        ]),
                      ]),
                      _c("th"),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.gisDataFieldOptions, function (option, index) {
                      return _c(
                        "tr",
                        { key: option.gis_data_field_id, staticClass: "white" },
                        [
                          _c(
                            "td",
                            {
                              staticClass: "white value",
                              class: {
                                "d-flex": _vm.editing[index],
                                "align-start": _vm.editing[index],
                              },
                            },
                            [
                              _vm.editing[index]
                                ? _c(
                                    "div",
                                    { staticClass: "d-flex align-start" },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "Value",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors, valid }) {
                                                return [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Value",
                                                      "hide-details": false,
                                                      name: "value",
                                                      "background-color":
                                                        "white",
                                                      "error-messages": errors,
                                                      success: valid,
                                                    },
                                                    model: {
                                                      value: option.value,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          option,
                                                          "value",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "option.value",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "white d-flex pt-3" },
                                    [_c("p", [_vm._v(_vm._s(option.value))])]
                                  ),
                            ]
                          ),
                          _c("td", { staticClass: "white value" }, [
                            _vm.editing[index]
                              ? _c("div", { staticClass: "white" }, [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-start" },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Label",
                                          "hide-details": false,
                                          name: "label",
                                          "background-color": "white",
                                        },
                                        model: {
                                          value: option.label,
                                          callback: function ($$v) {
                                            _vm.$set(option, "label", $$v)
                                          },
                                          expression: "option.label",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "white d-flex justify-end mt-n5 mr-n4",
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { text: "", color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              _vm.cancelEditGisDataFieldOptions()
                                              _vm.$set(
                                                _vm.editing,
                                                index,
                                                false
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Cancel ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { text: "", color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$set(
                                                _vm.editing,
                                                index,
                                                false
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Save ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _c(
                                  "div",
                                  { staticClass: "white d-flex pt-3" },
                                  [_c("p", [_vm._v(_vm._s(option.label))])]
                                ),
                          ]),
                          _c(
                            "td",
                            { staticClass: "white justify-end d-flex" },
                            [
                              _c(
                                "v-menu",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  { attrs: { icon: "" } },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: { color: "primary" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.mdiDotsVertical
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "primary--text text--lighten-1 cursor-pointer",
                                              on: {
                                                click: function ($event) {
                                                  _vm.saveOriginalGisDataFieldOptions()
                                                  _vm.$set(
                                                    _vm.editing,
                                                    index,
                                                    true
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "primary" } },
                                                [_vm._v(_vm._s(_vm.mdiPencil))]
                                              ),
                                              _vm._v(" Edit "),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteOption(index)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "primary--text text--lighten-1 cursor-pointer",
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "primary" } },
                                                [_vm._v(_vm._s(_vm.mdiDelete))]
                                              ),
                                              _vm._v(" Delete "),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ],
              1
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }