<template>
  <v-dialog
    :value="showFormsDialog"
    max-width="600px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar dark color="primary" class="elevation-0">
        <v-toolbar-title>Forms</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('forms-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        class="py-5"
        :style="{
          height: $vuetify.breakpoint.xsOnly ? `${contentHeight}px` : 'auto',
          'overflow-y': 'auto',
          'max-height': $vuetify.breakpoint.xsOnly ? undefined : '60vh',
        }"
      >
        <v-data-table
          v-if="!showEditDialog"
          :headers="headers"
          :items="forms"
          item-key="form_definition_id"
          class="elevation-0"
          disable-pagination
          hide-default-footer
          :custom-filter="filter"
          :search="search"
          @click:row="openEditFormDialog"
        >
          <template v-slot:top>
            <div class="d-flex justify-space-between">
              <v-text-field
                v-model="search"
                label="Search"
                class="mx-4"
                name="search"
                append-icon="mdi-magnify"
              />
              <div class="mt-4 mr-4">
                <v-btn
                  color="primary"
                  @click="
                    showNewFormDialog = true;
                    selectedFormDefinitionId = undefined;
                  "
                  id="add-form-button"
                  :disabled="!canManageLayers"
                >
                  + Form
                </v-btn>
              </div>
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              class="mr-2 edit-button"
              @click.stop="openEditFormDialog(item, true)"
              text
              :disabled="!canManageLayers"
            >
              {{ mdiPencil }}
            </v-icon>
            <v-icon
              @click.stop="
                showDeleteDialog = true;
                itemToDelete = item;
              "
              text
              class="delete-button"
              :disabled="!canManageLayers"
            >
              {{ mdiDelete }}
            </v-icon>
          </template>
        </v-data-table>

        <v-dialog
          v-model="showNewFormDialog"
          max-width="400px"
          :fullscreen="$vuetify.breakpoint.xsOnly"
        >
          <MapServiceNewFormForm
            @new-form-close="showNewFormDialog = false"
            @create-new-form="
              $emit('create-new-form');
              showNewFormDialog = false;
              setMenuState(false);
            "
            :mapServiceId="mapServiceId"
          />
        </v-dialog>

        <ConfirmDeleteFormDialog
          v-if="showDeleteDialog"
          :itemToDelete="itemToDelete"
          :showDeleteDialog="showDeleteDialog"
          @cancel-confirm-delete-form="showDeleteDialog = false"
          @form-deleted="
            showDeleteDialog = false;
            getForms();
          "
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { mdiPencil, mdiDelete } from "@mdi/js";
import MapServiceNewFormForm from "@/components/layers/edit-layer-form/shared/layer-details/forms-dialog/MapServiceNewFormForm.vue";
import ConfirmDeleteFormDialog from "@/components/layers/edit-layer-form/shared/layer-details/forms-dialog/ConfirmDeleteFormDialog.vue";
import { mapMutations } from "vuex";
import permissionsMixin from "@/mixins/permissionsMixin";

const APIURL = process.env.VUE_APP_API_URL;
const headers = [
  {
    text: "Form",
    align: "start",
    sortable: true,
    groupable: false,
    value: "title",
  },
  { text: "Actions", value: "actions", sortable: false, groupable: false },
];

export default {
  name: "FormsDialog",
  props: {
    showFormsDialog: Boolean,
    mapServiceId: String,
  },
  components: {
    MapServiceNewFormForm,
    ConfirmDeleteFormDialog,
  },
  data() {
    return {
      forms: [],
      showNewFormDialog: false,
      showEditDialog: false,
      selectedFormDefinitionId: undefined,
      search: "",
      headers,
      mdiPencil,
      mdiDelete,
      itemToDelete: undefined,
      showDeleteDialog: false,
    };
  },
  mixins: [permissionsMixin],
  methods: {
    ...mapMutations(["setMenuState"]),
    async getForms() {
      let authResults;
      try {
        authResults = JSON.parse(localStorage.getItem("auth")) || {};
      } catch (error) {
        authResults = {};
      }

      const { user_group_id: userGroupId } = authResults;
      const {
        data: { results },
      } = await axios.get(`${APIURL}/map_services/${this.mapServiceId}`, {
        params: { userGroupId },
      });
      this.forms = results.forms;
    },
    filter(value, search, item) {
      const { title, serviceName } = item;
      return (
        (title || "")
          .toLocaleLowerCase()
          .includes(this.search.toLocaleLowerCase()) ||
        (serviceName || "")
          .toLocaleLowerCase()
          .includes(this.search.toLocaleLowerCase())
      );
    },
    openEditFormDialog({ form_definition_id: formDefinitionId }) {
      this.setMenuState(false);
      this.$emit("open-edit-form-dialog", formDefinitionId);
    },
    async deleteItem({ form_definition_id: formDefinitionId }) {
      try {
        await axios.delete(`${APIURL}/form_definitions/${formDefinitionId}`);
      } finally {
        this.itemToDelete = {};
        this.showDeleteDialog = false;
        await this.downloadMapServices();
      }
    },
  },
  beforeMount() {
    this.getForms();
  },
};
</script>

<style>
.form-builder-edit-form {
  overflow-y: hidden;
}
</style>
