<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Calculation</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="$emit('calculation-list-close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-list>
            <v-list-item>
              <v-list-item-content class="caption">
                Formula
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                {{
                  item.question.calculation | readableQuestion(formDefinition)
                }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="12">
          <v-list>
            <v-list-item>
              <v-list-item-content class="caption">
                Values
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="v of variables" :key="v.id">
              <v-list-item-content>
                <div class="d-flex justify-space-between">
                  <p>{{ v.label }}</p>
                  <p>{{ v.value }}</p>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="12">
          <v-list>
            <v-list-item>
              <v-list-item-content class="caption">
                Calculation
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content
                >{{ calculationExpression }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  name: "GroupCalculationList",
  props: {
    groupedItems: Array,
    item: Object,
  },
  computed: {
    variables() {
      const items = this.groupedItems.filter(({ question }) => {
        return ["NUMBER", "SINGLE_SELECT"].includes(question?.type);
      });
      return this.item.question.watchIds.map((id) => {
        const item = items.find((item) => item.id === id);
        if (!item) {
          return {};
        }
        const { value, question } = item;
        return {
          id: uuidv4(),
          label: question && `<${question.label}>`,
          value,
        };
      });
    },
    calculationExpression() {
      const items = this.groupedItems.filter(({ question }) => {
        return ["NUMBER", "SINGLE_SELECT"].includes(question?.type);
      });
      let calculationExpression = this.item.question.calculation;
      const params = [...this.item.question.calculation.matchAll(/{{(.*?)}}/g)];
      if (!Array.isArray(params) || params.length === 0) {
        return "";
      }
      for (const p of params) {
        const [fullParam, param] = p.toString().split(",");
        const [, questionId] = param.split(".");
        const { value } = items.find((i) => +i.id === +questionId) ?? {};
        calculationExpression = calculationExpression.replace(
          fullParam,
          value ?? 0
        );
      }
      return calculationExpression;
    },
  },
};
</script>
