var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex justify-space-between" },
    [
      _c("validation-observer", { ref: "informationForm" }, [
        _c(
          "form",
          [
            _c(
              "v-list",
              { staticClass: "px-0 py-0 my-0", attrs: { dense: "" } },
              [
                ["HEADER"].includes(
                  _vm.getType(_vm.selectedRepeatingGroupItemOptions)
                )
                  ? _c(
                      "v-list-item",
                      { staticClass: "px-0 pt-0" },
                      [
                        _c(
                          "v-list-item-content",
                          { staticClass: "mt-0 pt-0" },
                          [
                            _c("validation-provider", {
                              attrs: { name: "Label", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Label",
                                            "hide-details": "auto",
                                            "error-messages": errors,
                                            success: valid,
                                            color: "primary",
                                            name: "Label",
                                            disabled: !_vm.canManageForms,
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.onSubmit()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm
                                                .selectedRepeatingGroupItemOptions
                                                .information.label,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm
                                                  .selectedRepeatingGroupItemOptions
                                                  .information,
                                                "label",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "selectedRepeatingGroupItemOptions.information.label",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                882827807
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                ["TEXT"].includes(
                  _vm.getType(_vm.selectedRepeatingGroupItemOptions)
                )
                  ? _c(
                      "v-list-item",
                      { staticClass: "px-0 pt-0 mt-0" },
                      [
                        _c(
                          "v-list-item-content",
                          { staticClass: "mt-0 pt-0" },
                          [
                            _c(
                              "v-card",
                              {
                                staticClass:
                                  "elevation-0 d-flex justify-space-between",
                              },
                              [
                                _c("div", [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "caption",
                                      style: _vm.canManageForms
                                        ? "color: #4caf50"
                                        : "color: #aaaaaa",
                                    },
                                    [_vm._v(" Text ")]
                                  ),
                                  _c("p", {
                                    staticClass: "pt-0",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.selectedRepeatingGroupItemOptions
                                          .information.label
                                      ),
                                    },
                                  }),
                                ]),
                                _vm.canManageForms
                                  ? _c(
                                      "v-icon",
                                      {
                                        staticClass: "mt-n2",
                                        attrs: { color: "primary" },
                                        on: {
                                          click: function ($event) {
                                            _vm.showTextDialog = true
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.mdiPencil) + " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-dialog",
                                  {
                                    attrs: {
                                      "max-width": "600px",
                                      "retain-focus": false,
                                      fullscreen:
                                        _vm.$vuetify.breakpoint.xsOnly,
                                    },
                                    model: {
                                      value: _vm.showTextDialog,
                                      callback: function ($$v) {
                                        _vm.showTextDialog = $$v
                                      },
                                      expression: "showTextDialog",
                                    },
                                  },
                                  [
                                    _c("TextForm", {
                                      on: {
                                        "text-form-close": function ($event) {
                                          _vm.showTextDialog = false
                                        },
                                        input: function ($event) {
                                          _vm.showTextDialog = false
                                          _vm.$emit(
                                            "input",
                                            _vm.selectedRepeatingGroupItemOptions
                                          )
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.selectedRepeatingGroupItemOptions
                                            .information.label,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm
                                              .selectedRepeatingGroupItemOptions
                                              .information,
                                            "label",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "\n                    selectedRepeatingGroupItemOptions.information.label\n                  ",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.getType(_vm.selectedRepeatingGroupItemOptions) === "URL"
                  ? _c(
                      "v-list-item",
                      { staticClass: "px-0 pt-0 mt-0" },
                      [
                        _c(
                          "v-list-item-content",
                          { staticClass: "mt-0 pt-0" },
                          [
                            _c("validation-provider", {
                              attrs: { name: "URL", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "URL",
                                            "hide-details": "auto",
                                            "error-messages": errors,
                                            success: valid,
                                            color: "primary",
                                            name: "name",
                                            hint: "Input a valid URL",
                                            disabled: !_vm.canManageForms,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.onSubmit()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm
                                                .selectedRepeatingGroupItemOptions
                                                .information.url,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm
                                                  .selectedRepeatingGroupItemOptions
                                                  .information,
                                                "url",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "selectedRepeatingGroupItemOptions.information.url",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1950397545
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.getType(_vm.selectedRepeatingGroupItemOptions) === "URL"
                  ? _c(
                      "v-list-item",
                      { staticClass: "px-0 pt-0 mt-0" },
                      [
                        _c(
                          "v-list-item-content",
                          { staticClass: "mt-0 pt-0" },
                          [
                            _c("validation-provider", {
                              attrs: { name: "Label", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Label",
                                            "hide-details": "auto",
                                            "error-messages": errors,
                                            success: valid,
                                            color: "primary",
                                            name: "name",
                                            hint: "Input a valid URL",
                                            disabled: !_vm.canManageForms,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.onSubmit()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm
                                                .selectedRepeatingGroupItemOptions
                                                .information.label,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm
                                                  .selectedRepeatingGroupItemOptions
                                                  .information,
                                                "label",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "selectedRepeatingGroupItemOptions.information.label",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                940128297
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.getType(_vm.selectedRepeatingGroupItemOptions) === "IMAGE"
                  ? _c(
                      "v-list-item",
                      { staticClass: "px-0 pt-0 mt-0" },
                      [
                        _c(
                          "v-list-item-content",
                          { staticClass: "mt-0 pt-0" },
                          [
                            _c(
                              "section",
                              { staticClass: "d-flex align-center" },
                              [
                                _c("v-switch", {
                                  attrs: {
                                    label: "Dynamic",
                                    disabled:
                                      _vm.disableDynamicSwitch ||
                                      !_vm.canManageForms,
                                    "hide-details": "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onSubmit()
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.selectedRepeatingGroupItemOptions
                                        .information.dynamic,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectedRepeatingGroupItemOptions
                                          .information,
                                        "dynamic",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "\n                  selectedRepeatingGroupItemOptions.information.dynamic\n                ",
                                  },
                                }),
                                _vm.disableDynamicSwitch
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        { attrs: { icon: "" } },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.mdiInformation
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3213387561
                                        ),
                                      },
                                      [
                                        !_vm.showImageInputs
                                          ? _c("span", [
                                              _vm._v(
                                                " Remove default advanced setting or change default value setting to Custom Value to enable. "
                                              ),
                                            ])
                                          : _c("span", [
                                              _vm._v(
                                                " Dynamic info images work only with Custom Value repeating groups "
                                              ),
                                            ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm.showImageInputs
                              ? _c("validation-provider", {
                                  attrs: { name: "Label" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "Label",
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                success: valid,
                                                color: "primary",
                                                name: "name",
                                                disabled: !_vm.canManageForms,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.onSubmit()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm
                                                    .selectedRepeatingGroupItemOptions
                                                    .information.label,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm
                                                      .selectedRepeatingGroupItemOptions
                                                      .information,
                                                    "label",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "selectedRepeatingGroupItemOptions.information.label",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2033845550
                                  ),
                                })
                              : _vm._e(),
                            _vm.showImageInputs
                              ? _c("validation-provider", {
                                  attrs: {
                                    name: "Image URL",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "Image URL",
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                success: valid,
                                                color: "primary",
                                                name: "name",
                                                hint: "Input a valid URL",
                                                disabled: !_vm.canManageForms,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.onSubmit()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm
                                                    .selectedRepeatingGroupItemOptions
                                                    .information.url,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm
                                                      .selectedRepeatingGroupItemOptions
                                                      .information,
                                                    "url",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "selectedRepeatingGroupItemOptions.information.url",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    4015610990
                                  ),
                                })
                              : _vm._e(),
                            _vm.showImageInputs
                              ? _c("validation-provider", {
                                  attrs: { name: "Open URL" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "Open URL",
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                success: valid,
                                                color: "primary",
                                                name: "name",
                                                hint: "Input a valid URL",
                                                disabled: !_vm.canManageForms,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.onSubmit()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm
                                                    .selectedRepeatingGroupItemOptions
                                                    .information.openUrl,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm
                                                      .selectedRepeatingGroupItemOptions
                                                      .information,
                                                    "openUrl",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                  selectedRepeatingGroupItemOptions.information.openUrl\n                ",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3547598985
                                  ),
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-list-item",
                  { staticClass: "px-0 pt-0 mt-0" },
                  [
                    _c(
                      "v-list-item-content",
                      { staticClass: "mt-0 pt-0" },
                      [
                        _c("validation-provider", {
                          attrs: { name: "Visible", rules: "required" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors, valid }) {
                                return [
                                  _c("v-select", {
                                    attrs: {
                                      autocomplete: "off",
                                      items: _vm.visibilityOptions,
                                      label: "Visible",
                                      "error-messages": errors,
                                      success: valid,
                                      "item-text": "label",
                                      "item-value": "value",
                                      "hide-details": "",
                                      disabled: !_vm.canManageForms,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.onSubmit()
                                      },
                                    },
                                    model: {
                                      value: _vm.visibleCondition,
                                      callback: function ($$v) {
                                        _vm.visibleCondition = $$v
                                      },
                                      expression: "visibleCondition",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        ["conditional"].includes(_vm.visibleCondition)
                          ? _c("ComparisonConditionForm", {
                              attrs: {
                                formDefinition: _vm.formDefinition,
                                selectedItem:
                                  _vm.selectedRepeatingGroupItemOptions,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.onSubmit()
                                },
                              },
                              model: {
                                value:
                                  _vm.selectedRepeatingGroupItemOptions
                                    .information.visible,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedRepeatingGroupItemOptions
                                      .information,
                                    "visible",
                                    $$v
                                  )
                                },
                                expression:
                                  "selectedRepeatingGroupItemOptions.information.visible",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-list-item",
                  { staticClass: "px-0 mx-0" },
                  [
                    _c(
                      "v-list-item-content",
                      { staticClass: "px-0 mx-0" },
                      [
                        _c("AdvancedInformationSettingsForm", {
                          attrs: {
                            formDefinition: _vm.formDefinition,
                            selectedItem: _vm.selectedRepeatingGroupItemOptions,
                            selectedRepeatingGroupItemOptions:
                              _vm.selectedRepeatingGroupItemOptions,
                            layer: _vm.layer,
                            showDefaultChoice: false,
                            showRequireChoice: false,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.onSubmit()
                            },
                          },
                          model: {
                            value: _vm.selectedRepeatingGroupItemOptions,
                            callback: function ($$v) {
                              _vm.selectedRepeatingGroupItemOptions = $$v
                            },
                            expression: "selectedRepeatingGroupItemOptions",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-list-item",
                  { staticClass: "px-0 mx-0" },
                  [
                    _c("v-list-item-content", { staticClass: "px-0 mx-0" }, [
                      _vm.hasAdvancedSettings
                        ? _c("div", { staticClass: "d-flex flex-wrap gap" }, [
                            _vm.hasVisibleSettings
                              ? _c(
                                  "section",
                                  {
                                    staticClass:
                                      "text-uppercase d-flex primary--text gap align-center",
                                    on: { click: _vm.onVisibleSettingClick },
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        "Visible " + _vm._s(_vm.visibleSetting)
                                      ),
                                    ]),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          icon: "",
                                          disabled: !_vm.canManageForms,
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "primary" } },
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.mdiPencil) + " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.hasCreateSettings
                              ? _c(
                                  "section",
                                  {
                                    staticClass:
                                      "text-uppercase d-flex primary--text gap align-center",
                                    on: { click: _vm.openCreateSettingsDialog },
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        "Create " + _vm._s(_vm.createSetting)
                                      ),
                                    ]),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          icon: "",
                                          disabled: !_vm.canManageForms,
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "primary" } },
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.mdiPencil) + " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ])
                        : _c(
                            "div",
                            {
                              staticClass: "d-flex align-center justify-center",
                            },
                            [_vm._v(" No advanced settings ")]
                          ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm.showAdvancedInformationVisibilitySettingsDialog
        ? _c("AdvancedInformationVisibilitySettingsDialog", {
            attrs: {
              showAdvancedInformationVisibilitySettingsDialog:
                _vm.showAdvancedInformationVisibilitySettingsDialog,
              formDefinition: _vm.formDefinition,
              layer: _vm.layer,
              selectedItem: _vm.selectedRepeatingGroupItemOptions,
            },
            on: {
              "advanced-settings-dialog-close": function ($event) {
                _vm.showAdvancedInformationVisibilitySettingsDialog = false
              },
              input: function ($event) {
                return _vm.onSubmit()
              },
            },
            model: {
              value: _vm.selectedRepeatingGroupItemOptions,
              callback: function ($$v) {
                _vm.selectedRepeatingGroupItemOptions = $$v
              },
              expression: "selectedRepeatingGroupItemOptions",
            },
          })
        : _vm._e(),
      _vm.showAdvancedInformationCreateSettingsDialog
        ? _c("AdvancedInformationCreateSettingsDialog", {
            attrs: {
              showAdvancedInformationCreateSettingsDialog:
                _vm.showAdvancedInformationCreateSettingsDialog,
              formDefinition: _vm.formDefinition,
              layer: _vm.layer,
              selectedItem: _vm.selectedRepeatingGroupItemOptions,
            },
            on: {
              "advanced-settings-dialog-close": function ($event) {
                _vm.showAdvancedInformationCreateSettingsDialog = false
              },
              input: function ($event) {
                return _vm.onSubmit()
              },
            },
            model: {
              value: _vm.selectedRepeatingGroupItemOptions,
              callback: function ($$v) {
                _vm.selectedRepeatingGroupItemOptions = $$v
              },
              expression: "selectedRepeatingGroupItemOptions",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }