<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Edit Formula</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn dark text @click="showPlaceholderDrawer = true">
        Form Placeholders
      </v-btn>
      <v-btn icon dark @click="closeForm">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <validation-observer ref="formulaForm">
        <form @submit.prevent="onSubmit">
          <section class="my-4">
            <validation-provider
              name="Calculation"
              rules="required"
              v-slot="{ errors, valid }"
            >
              <v-textarea
                outlined
                label="Calculation"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="value"
                v-model="formula"
              >
              </v-textarea>
            </validation-provider>
          </section>

          <section>
            <p class="caption">Readable Formula</p>
            <p>
              {{
                formula
                  | groupReadableQuestion(
                    formDefinition,
                    groupItems,
                    selectedRepeatingGroupItemOptions
                  )
              }}
            </p>
          </section>

          <section class="d-flex justify-end">
            <v-btn color="primary" type="submit">Save Calculation</v-btn>
          </section>
        </form>

        <v-navigation-drawer
          fixed
          app
          right
          temporary
          v-model="showPlaceholderDrawer"
          width="300px"
          style="z-index: 2"
        >
          <v-toolbar color="primary">
            <v-list-item two-line>
              <v-list-item-content>
                <div class="d-flex justify-space-between">
                  <v-list-item-title class="white--text text-h6">
                    Form Placeholders
                  </v-list-item-title>
                  <v-btn
                    text
                    color="white"
                    @click="showPlaceholderDrawer = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-toolbar>
          <GroupFormPlaceholdersForm
            :groupItems="groupItems"
            type="formula"
            @placeholder-copied="showCopiedSnackbar = true"
          />
        </v-navigation-drawer>
      </validation-observer>

      <v-snackbar v-model="showCopiedSnackbar" style="z-index: 9999">
        Form Placeholder Copied
      </v-snackbar>
    </v-card-text>
  </v-card>
</template>

<script>
import GroupFormPlaceholdersForm from "@/components/forms/form-builder-edit-form/GroupFormPlaceholdersForm.vue";

export default {
  name: "GroupFormulaForm",
  props: {
    label: String,
    groupItems: Array,
    columnNumber: Number,
    value: Object,
    formDefinition: Object,
    selectedRepeatingGroupItemOptions: Object,
  },
  components: {
    GroupFormPlaceholdersForm,
  },
  computed: {
    questionChoices() {
      return this.groupItems
        .filter((item, index) => {
          const { question } = item;
          return (
            index !== this.columnNumber &&
            ["NUMBER", "SINGLE_SELECT"].includes(question?.type)
          );
        })
        .map((item, index) => {
          const { question } = item;
          return {
            value: `{col.${index}}}`,
            label: `${index}. ${question?.label}`,
          };
        });
    },
  },
  data() {
    return {
      formula: "",
      items: [],
      showPlaceholderDrawer: false,
      showCopiedSnackbar: false,
    };
  },
  methods: {
    closeForm() {
      this.$emit("formula-form-close");
    },
    onOpen() {
      this.items = [...this.questionChoices];
    },
    async onSubmit() {
      const success = await this.$refs.formulaForm.validate();
      if (!success) {
        return;
      }
      this.$emit("formula-form-submitted", this.formula);
    },
  },
  watch: {
    groupItems: {
      deep: true,
      immediate: true,
      handler() {
        const item = this.groupItems[this.columnNumber - 1];
        this.formula = item?.question?.calculation;
      },
    },
  },
};
</script>

<style>
.tooltip.popover {
  z-index: 999999;
  background-color: white;
  font-family: Roboto, "sans-serif";
  border-radius: 3px;
}

.mention-item {
  border-radius: 4px;
}

.mention-selected {
  background: rgb(192, 250, 153);
  margin: 0px;
}

.mentionable textarea {
  width: 100%;
  height: 100px;
  border: 1px solid gray;
}

.dim {
  padding: 5px;
}
</style>
