var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      (_vm.displayFeaturesUsing === "class_break" && _vm.fieldToReference) ||
      _vm.layerObj.service_type === "L"
        ? _c(
            "div",
            { staticClass: "d-flex justify-end" },
            [
              _vm.displayFeaturesUsing !== "simple"
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        color: "primary",
                        disabled:
                          (_vm.layerObj.service_type !== "L" &&
                            !_vm.fieldToReference) ||
                          !_vm.canManageLayers,
                      },
                      on: {
                        click: function ($event) {
                          _vm.showAddValueRangeDialog = true
                        },
                      },
                    },
                    [_vm._v(" + Add Value Range ")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card",
        { staticClass: "mt-5" },
        [
          _c(
            "v-card-text",
            { staticClass: "ma-0 pa-0" },
            [
              _c("AddValueRangeDialog", {
                attrs: { showAddValueRangeDialog: _vm.showAddValueRangeDialog },
                on: {
                  "add-value-range-dialog-close": function ($event) {
                    _vm.showAddValueRangeDialog = false
                  },
                  "range-set": _vm.onRangeSet,
                },
              }),
              _c("EditValueRangeDialog", {
                attrs: {
                  showEditValueRangeDialog: _vm.showEditValueRangeDialog,
                  selectedRendererSymbolIndex: _vm.selectedRendererSymbolIndex,
                  selectedRendererSymbol: _vm.selectedRendererSymbol,
                },
                on: {
                  "edit-value-range-dialog-close": function ($event) {
                    _vm.showEditValueRangeDialog = false
                  },
                  "range-edited": _vm.onRangeEdited,
                },
              }),
              _c(
                "v-expansion-panels",
                { attrs: { multiple: "", accordion: "" } },
                _vm._l(_vm.rendererSymbols, function (rendererSymbol, index) {
                  return _c(
                    "v-expansion-panel",
                    { key: rendererSymbol.renderer_symbol_id },
                    [
                      _c(
                        "v-expansion-panel-header",
                        {
                          staticClass: "px-3 py-2",
                          attrs: { "hide-actions": "" },
                        },
                        [
                          _c(
                            "validation-observer",
                            { ref: "gisDataFieldForm", refInFor: true },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex align-center",
                                  class:
                                    _vm.displayFeaturesUsing === "simple"
                                      ? ""
                                      : "justify-space-between",
                                },
                                [
                                  _c("div", { staticClass: "d-flex" }, [
                                    _c(
                                      "div",
                                      { staticClass: "mr-5" },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "caption ml-3 py-0 my-0",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  index === 0 ? "Symbol" : " "
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "pa-0 d-flex align-center",
                                            attrs: {
                                              text: "",
                                              height: ["basic"].includes(
                                                rendererSymbol.symbol_type
                                              )
                                                ? rendererSymbol.picture_size
                                                : undefined,
                                              disabled: !_vm.canManageLayers,
                                            },
                                          },
                                          [
                                            ["shape"].includes(
                                              rendererSymbol.symbol_type
                                            )
                                              ? [
                                                  rendererSymbol.url
                                                    ? _c("img", {
                                                        style: {
                                                          width: `${rendererSymbol.picture_size}px`,
                                                          height: `${rendererSymbol.picture_size}px`,
                                                        },
                                                        attrs: {
                                                          src: require(`@/assets${rendererSymbol.url}`),
                                                        },
                                                      })
                                                    : _vm.findColorValue(
                                                        rendererSymbol.fill_color
                                                      )
                                                    ? _c("img", {
                                                        style: {
                                                          width: `${rendererSymbol.picture_size}px`,
                                                          height: `${rendererSymbol.picture_size}px`,
                                                        },
                                                        attrs: {
                                                          src: require(`@/assets/img/mapSymbols/${_vm.findColorValue(
                                                            rendererSymbol.fill_color
                                                          )}${
                                                            _vm.symbols[0]
                                                          }.png`),
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ]
                                              : ["basic"].includes(
                                                  rendererSymbol.symbol_type
                                                )
                                              ? _c("div", {
                                                  style: {
                                                    "background-color":
                                                      rendererSymbol.fill_color,
                                                    "border-radius": "50%",
                                                    border: `${rendererSymbol.width}px solid ${rendererSymbol.outline_color}`,
                                                    width: `${
                                                      rendererSymbol.picture_size -
                                                      rendererSymbol.width
                                                    }px`,
                                                    height: `${
                                                      rendererSymbol.picture_size -
                                                      rendererSymbol.width
                                                    }px`,
                                                  },
                                                })
                                              : ["image"].includes(
                                                  rendererSymbol.symbol_type
                                                )
                                              ? [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mt-2" },
                                                    [
                                                      _c(
                                                        "EditSymbolsFormImage",
                                                        {
                                                          attrs: {
                                                            rendererSymbol:
                                                              rendererSymbol,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              { staticClass: "mt-1" },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "my-0 py-0" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.mdiChevronDown
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.displayFeaturesUsing !== "simple"
                                      ? _c("div", [
                                          _c(
                                            "p",
                                            { staticClass: "caption my-0" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    index === 0 ? "Label" : " "
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-center flex-grow-1 py-3",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(rendererSymbol.label) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                  _vm.displayFeaturesUsing === "class_break"
                                    ? _c(
                                        "div",
                                        { staticClass: "d-flex align-center" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "mr-3 value" },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "caption my-0" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        index === 0
                                                          ? "Min. Value"
                                                          : " "
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-center flex-grow-1 py-3",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        rendererSymbol.min_value
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("div", { staticClass: "value" }, [
                                            _c(
                                              "p",
                                              { staticClass: "caption my-0" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      index === 0
                                                        ? "Max. Value"
                                                        : " "
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-center flex-grow-1 py-3",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      rendererSymbol.max_value
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-stretch align-center",
                                            },
                                            [
                                              _c(
                                                "v-menu",
                                                {
                                                  attrs: { "offset-y": "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          on,
                                                          attrs,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "pa-0",
                                                                    attrs: {
                                                                      text: "",
                                                                      disabled:
                                                                        !_vm.canManageLayers,
                                                                    },
                                                                  },
                                                                  "v-btn",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "my-0 py-0",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.mdiDotsVertical
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "v-list",
                                                    [
                                                      _c(
                                                        "v-list-item",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.openEditValueRangeDialog(
                                                                index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            {
                                                              staticClass:
                                                                "d-flex align-center menu-item",
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.mdiPencil
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" Edit "),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-list-item",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteRendererSymbol(
                                                                index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            {
                                                              staticClass:
                                                                "d-flex align-center menu-item",
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.mdiDelete
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                " Delete "
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm.displayFeaturesUsing === "simple"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-stretch align-self-stretch",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-end pb-1",
                                            },
                                            [
                                              _vm._v(
                                                " One symbol is displayed for all features "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm.displayFeaturesUsing ===
                                      "unique_value"
                                    ? _c(
                                        "div",
                                        { staticClass: "d-flex align-center" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "mr-3 value" },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "caption my-0" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        index === 0
                                                          ? "Value"
                                                          : " "
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-center flex-grow-1 py-3",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        rendererSymbol.value
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("v-expansion-panel-content", { staticClass: "px-0" }, [
                        _c(
                          "div",
                          [
                            _c(
                              "validation-observer",
                              { ref: "gisDataFieldForm", refInFor: true },
                              [
                                _c(
                                  "form",
                                  {
                                    staticClass: "d-flex justify-space-between",
                                  },
                                  [
                                    _c("div", { staticClass: "d-flex" }, [
                                      _c(
                                        "div",
                                        { staticClass: "pr-3" },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "Symbol Type",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({
                                                    errors,
                                                    valid,
                                                  }) {
                                                    return [
                                                      _c("v-select", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          autocomplete: "off",
                                                          label: "Symbol Type",
                                                          "hide-details":
                                                            "auto",
                                                          "error-messages":
                                                            errors,
                                                          success: valid,
                                                          color: "primary",
                                                          name: "symbolType",
                                                          items:
                                                            _vm.symbolTypeChoices,
                                                          "item-text": "label",
                                                          "item-value": "value",
                                                          disabled:
                                                            !_vm.canManageLayers,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            _vm.onRenderSymbolChange(
                                                              index,
                                                              rendererSymbol.symbol_type
                                                            )
                                                            _vm.$emit(
                                                              "renderer-symbols-changed",
                                                              _vm.rendererSymbols
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            rendererSymbol.symbol_type,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              rendererSymbol,
                                                              "symbol_type",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "rendererSymbol.symbol_type",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      ["shape"].includes(
                                        rendererSymbol.symbol_type
                                      )
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-center",
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "mr-2 font-weight-regular caption mb-2",
                                                      style: {
                                                        color:
                                                          rendererSymbol.fill_color
                                                            ? "#4caf50"
                                                            : undefined,
                                                      },
                                                    },
                                                    [_vm._v(" Color ")]
                                                  ),
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      attrs: { "offset-y": "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                              attrs,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        staticClass:
                                                                          "pa-0 d-flex",
                                                                        attrs: {
                                                                          text: "",
                                                                          "x-small":
                                                                            "",
                                                                          disabled:
                                                                            !_vm.canManageLayers,
                                                                        },
                                                                      },
                                                                      "v-btn",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        style: {
                                                                          "background-color":
                                                                            rendererSymbol.fill_color,
                                                                          width:
                                                                            "25px",
                                                                          height:
                                                                            "25px",
                                                                          border:
                                                                            "1px solid black",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "   "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "my-0 py-0",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.mdiChevronDown
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _c(
                                                        "v-list",
                                                        _vm._l(
                                                          _vm.colorChoices,
                                                          function (choice) {
                                                            return _c(
                                                              "v-list-item",
                                                              {
                                                                key: choice.value,
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      rendererSymbol.fill_color =
                                                                        choice.actualColor
                                                                      rendererSymbol.url =
                                                                        _vm.updateSymbolUrl(
                                                                          rendererSymbol,
                                                                          index
                                                                        )
                                                                      _vm.$emit(
                                                                        "renderer-symbols-changed",
                                                                        _vm.rendererSymbols
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex align-center",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "mr-2",
                                                                        style: {
                                                                          "background-color":
                                                                            choice.actualColor,
                                                                          width:
                                                                            "25px",
                                                                          height:
                                                                            "25px",
                                                                          border:
                                                                            "1px solid black",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "   "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c("div", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          choice.label
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "ml-2 mr-2 font-weight-regular caption mt-n2",
                                                      style: {
                                                        color:
                                                          rendererSymbol.url
                                                            ? "#4caf50"
                                                            : undefined,
                                                      },
                                                    },
                                                    [_vm._v(" Shape ")]
                                                  ),
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      attrs: { "offset-y": "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                              attrs,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        staticClass:
                                                                          "pa-0 d-flex pt-2",
                                                                        attrs: {
                                                                          text: "",
                                                                          "x-small":
                                                                            "",
                                                                          disabled:
                                                                            !_vm.canManageLayers,
                                                                        },
                                                                      },
                                                                      "v-btn",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    rendererSymbol.url
                                                                      ? _c(
                                                                          "img",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                src: require(`@/assets${rendererSymbol.url}`),
                                                                              },
                                                                          }
                                                                        )
                                                                      : _vm.findColorValue(
                                                                          rendererSymbol.fill_color
                                                                        )
                                                                      ? _c(
                                                                          "img",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                src: require(`@/assets/img/mapSymbols/${_vm.findColorValue(
                                                                                  rendererSymbol.fill_color
                                                                                )}${
                                                                                  _vm
                                                                                    .symbols[0]
                                                                                }.png`),
                                                                              },
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "my-0 py-0",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.mdiChevronDown
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _c(
                                                        "v-list",
                                                        _vm._l(
                                                          _vm.symbols,
                                                          function (symbol) {
                                                            return _c(
                                                              "v-list-item",
                                                              {
                                                                key: symbol,
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.symbolChoices,
                                                                        index,
                                                                        symbol
                                                                      )
                                                                      rendererSymbol.url = `/img/mapSymbols/${_vm.findColorValue(
                                                                        rendererSymbol.fill_color
                                                                      )}${symbol}.png`
                                                                      _vm.$emit(
                                                                        "renderer-symbols-changed",
                                                                        _vm.rendererSymbols
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex align-center",
                                                                  },
                                                                  [
                                                                    _vm.findColorValue(
                                                                      rendererSymbol.fill_color
                                                                    )
                                                                      ? _c(
                                                                          "img",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                src: require(`@/assets/img/mapSymbols/${_vm.findColorValue(
                                                                                  rendererSymbol.fill_color
                                                                                )}${symbol}.png`),
                                                                              },
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : ["basic"].includes(
                                            rendererSymbol.symbol_type
                                          )
                                        ? _c("div", { staticClass: "d-flex" }, [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "mr-2 font-weight-regular caption",
                                                    style: {
                                                      color:
                                                        rendererSymbol.fill_color
                                                          ? "#4caf50"
                                                          : undefined,
                                                    },
                                                  },
                                                  [_vm._v(" Fill Color ")]
                                                ),
                                                _c(
                                                  "v-menu",
                                                  {
                                                    attrs: { "offset-y": "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                            attrs,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "pa-0 d-flex",
                                                                      attrs: {
                                                                        text: "",
                                                                        disabled:
                                                                          !_vm.canManageLayers,
                                                                      },
                                                                    },
                                                                    "v-btn",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "mr-2",
                                                                      style: {
                                                                        "background-color":
                                                                          rendererSymbol.fill_color,
                                                                        width:
                                                                          "25px",
                                                                        height:
                                                                          "25px",
                                                                        border:
                                                                          "1px solid black",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "   "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "my-0 py-0",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.mdiChevronDown
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c(
                                                      "v-list",
                                                      [
                                                        _c(
                                                          "v-list-item",
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              {
                                                                staticClass:
                                                                  "d-flex align-center",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-color-picker",
                                                                  {
                                                                    attrs: {
                                                                      "dot-size":
                                                                        "25",
                                                                      width:
                                                                        "230px",
                                                                      "show-swatches":
                                                                        "",
                                                                    },
                                                                    on: {
                                                                      input:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$emit(
                                                                            "renderer-symbols-changed",
                                                                            _vm.rendererSymbols
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        rendererSymbol.fill_color,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            rendererSymbol,
                                                                            "fill_color",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "rendererSymbol.fill_color",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: { width: "100px" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "mr-2 font-weight-regular caption",
                                                    style: {
                                                      color:
                                                        rendererSymbol.fill_color
                                                          ? "#4caf50"
                                                          : undefined,
                                                    },
                                                  },
                                                  [_vm._v(" Outline Color ")]
                                                ),
                                                _c(
                                                  "v-menu",
                                                  {
                                                    attrs: { "offset-y": "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                            attrs,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "pa-0 d-flex",
                                                                      attrs: {
                                                                        text: "",
                                                                        disabled:
                                                                          !_vm.canManageLayers,
                                                                      },
                                                                    },
                                                                    "v-btn",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "mr-2",
                                                                      style: {
                                                                        "background-color":
                                                                          rendererSymbol.outline_color,
                                                                        width:
                                                                          "25px",
                                                                        height:
                                                                          "25px",
                                                                        border:
                                                                          "1px solid black",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "   "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "my-0 py-0",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.mdiChevronDown
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c(
                                                      "v-list",
                                                      [
                                                        _c(
                                                          "v-list-item",
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              {
                                                                staticClass:
                                                                  "d-flex align-center",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-color-picker",
                                                                  {
                                                                    attrs: {
                                                                      "dot-size":
                                                                        "15",
                                                                      width:
                                                                        "230px",
                                                                      "show-swatches":
                                                                        "",
                                                                    },
                                                                    on: {
                                                                      input:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$emit(
                                                                            "renderer-symbols-changed",
                                                                            _vm.rendererSymbols
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        rendererSymbol.outline_color,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            rendererSymbol,
                                                                            "outline_color",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "rendererSymbol.outline_color",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                    _c("div", { staticClass: "slider" }, [
                                      ["basic", "shape", "image"].includes(
                                        rendererSymbol.symbol_type
                                      )
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-center",
                                            },
                                            [
                                              _c("v-slider", {
                                                attrs: {
                                                  label: "Symbol Size",
                                                  max: 64,
                                                  min: 0,
                                                  "hide-details": "",
                                                  disabled:
                                                    !_vm.canManageLayers,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.$emit(
                                                      "renderer-symbols-changed",
                                                      _vm.rendererSymbols
                                                    )
                                                  },
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "append",
                                                      fn: function () {
                                                        return [
                                                          _c("v-text-field", {
                                                            staticClass:
                                                              "number-input my-0 py-0 mt-n2",
                                                            attrs: {
                                                              readonly: "",
                                                              "hide-details":
                                                                "",
                                                              disabled:
                                                                !_vm.canManageLayers,
                                                            },
                                                            model: {
                                                              value:
                                                                rendererSymbol.picture_size,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    rendererSymbol,
                                                                    "picture_size",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "rendererSymbol.picture_size",
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value:
                                                    rendererSymbol.picture_size,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      rendererSymbol,
                                                      "picture_size",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "rendererSymbol.picture_size",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      ["basic"].includes(
                                        rendererSymbol.symbol_type
                                      )
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-center",
                                            },
                                            [
                                              _c("v-slider", {
                                                attrs: {
                                                  label: "Outline Width",
                                                  max: 10,
                                                  min: 0,
                                                  "hide-details": "",
                                                  disabled:
                                                    !_vm.canManageLayers,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.$emit(
                                                      "renderer-symbols-changed",
                                                      _vm.rendererSymbols
                                                    )
                                                  },
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "append",
                                                      fn: function () {
                                                        return [
                                                          _c("v-text-field", {
                                                            staticClass:
                                                              "number-input my-0 py-0 mt-n2",
                                                            attrs: {
                                                              readonly: "",
                                                              "hide-details":
                                                                "",
                                                              disabled:
                                                                !_vm.canManageLayers,
                                                            },
                                                            model: {
                                                              value:
                                                                rendererSymbol.width,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    rendererSymbol,
                                                                    "width",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "rendererSymbol.width",
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value: rendererSymbol.width,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      rendererSymbol,
                                                      "width",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "rendererSymbol.width",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        ["image"].includes(rendererSymbol.symbol_type)
                          ? _c(
                              "div",
                              { staticClass: "d-flex align-center mt-4" },
                              [
                                _c("validation-provider", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    name: "Image URL",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "Image URL",
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                success: valid,
                                                color: "primary",
                                                name: "imageUrl",
                                                disabled: !_vm.canManageLayers,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.$emit(
                                                    "renderer-symbols-changed",
                                                    _vm.rendererSymbols
                                                  )
                                                },
                                              },
                                              model: {
                                                value: rendererSymbol.url,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    rendererSymbol,
                                                    "url",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "rendererSymbol.url",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }