import { render, staticRenderFns } from "./EditSymbolsForm.vue?vue&type=template&id=0afb03f1&scoped=true&"
import script from "./EditSymbolsForm.vue?vue&type=script&lang=js&"
export * from "./EditSymbolsForm.vue?vue&type=script&lang=js&"
import style0 from "./EditSymbolsForm.vue?vue&type=style&index=0&id=0afb03f1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0afb03f1",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0afb03f1')) {
      api.createRecord('0afb03f1', component.options)
    } else {
      api.reload('0afb03f1', component.options)
    }
    module.hot.accept("./EditSymbolsForm.vue?vue&type=template&id=0afb03f1&scoped=true&", function () {
      api.rerender('0afb03f1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/layers/edit-layer-form/shared/layer-details/edit-custom-layer-symbology-dialog/EditSymbolsForm.vue"
export default component.exports