var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "m-2", attrs: { id: "editLayerPanel" } },
    [
      _c("validation-observer", {
        ref: "editLayerForm",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ invalid }) {
              return [
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.editLayer.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "v-toolbar",
                      {
                        ref: "toolbar",
                        staticClass: "elevation-0",
                        attrs: { dark: "", color: "primary" },
                      },
                      [
                        _vm.layer
                          ? _c("v-toolbar-title", [
                              _vm._v(
                                " Edit Layer: " +
                                  _vm._s(_vm.layer.service_name) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "", dark: "" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("edit-layer-form-close")
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-close")])],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card-text",
                      {
                        staticClass: "px-8",
                        style: {
                          height: _vm.$vuetify.breakpoint.xsOnly
                            ? `${_vm.contentHeight}px`
                            : "60vh",
                          "overflow-y": "auto",
                          background: "#fafafa",
                          "max-height": _vm.$vuetify.breakpoint.xsOnly
                            ? undefined
                            : "60vh",
                        },
                      },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _vm.layer.service_type === "U"
                                  ? _c("UtiliSyncLayerForm", {
                                      attrs: { layer: _vm.layer },
                                      on: {
                                        "layer-changed": _vm.onLayerChanged,
                                        "symbology-saved": function ($event) {
                                          return _vm.$emit(
                                            "edit-layer-form-submitted"
                                          )
                                        },
                                        "open-edit-form-dialog": function (
                                          $event
                                        ) {
                                          return _vm.openEditFormDialog(
                                            $event,
                                            true
                                          )
                                        },
                                        "create-new-form": function ($event) {
                                          return _vm.createNewForm()
                                        },
                                        "utilisync-fields-saved":
                                          _vm.onUtiliSyncFieldsSaved,
                                        "gis-fields-saved":
                                          _vm.onGisFieldsSaved,
                                      },
                                    })
                                  : _vm.layer.service_type === "L"
                                  ? _c("LayerForm811", {
                                      attrs: { layer: _vm.layer },
                                      on: {
                                        "layer-changed": function ($event) {
                                          _vm.layer = {
                                            ..._vm.layer,
                                            ...$event,
                                          }
                                        },
                                        "symbology-saved": function ($event) {
                                          return _vm.$emit(
                                            "edit-layer-form-submitted"
                                          )
                                        },
                                        "open-edit-form-dialog": function (
                                          $event
                                        ) {
                                          return _vm.openEditFormDialog(
                                            $event,
                                            true
                                          )
                                        },
                                        "create-new-form": function ($event) {
                                          return _vm.createNewForm()
                                        },
                                      },
                                    })
                                  : _vm.layer.service_type === "F"
                                  ? _c("FeatureServiceForm", {
                                      attrs: { layer: _vm.layer },
                                      on: {
                                        "layer-changed": function ($event) {
                                          _vm.layer = {
                                            ..._vm.layer,
                                            ...$event,
                                          }
                                        },
                                        "symbology-saved": function ($event) {
                                          return _vm.$emit(
                                            "edit-layer-form-submitted"
                                          )
                                        },
                                        "open-edit-form-dialog": function (
                                          $event
                                        ) {
                                          return _vm.openEditFormDialog(
                                            $event,
                                            true
                                          )
                                        },
                                        "create-new-form": function ($event) {
                                          return _vm.createNewForm()
                                        },
                                        "utilisync-fields-saved":
                                          _vm.onUtiliSyncFieldsSaved,
                                        "gis-fields-saved":
                                          _vm.onGisFieldsSaved,
                                      },
                                    })
                                  : ["S", "T"].includes(_vm.layer.service_type)
                                  ? _c("MapTileServiceForm", {
                                      attrs: { layer: _vm.layer },
                                      on: {
                                        "layer-changed": function ($event) {
                                          _vm.layer = {
                                            ..._vm.layer,
                                            ...$event,
                                          }
                                        },
                                        "symbology-saved": function ($event) {
                                          return _vm.$emit(
                                            "edit-layer-form-submitted"
                                          )
                                        },
                                      },
                                    })
                                  : ["W"].includes(_vm.layer.service_type)
                                  ? _c("WmsServiceForm", {
                                      attrs: { layer: _vm.layer },
                                      on: {
                                        "layer-changed": function ($event) {
                                          _vm.layer = {
                                            ..._vm.layer,
                                            ...$event,
                                          }
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm.departments.length > 0
                                  ? _c("AccessForm", {
                                      attrs: { layer: _vm.layer },
                                      on: {
                                        "access-updated": function ($event) {
                                          _vm.newDepartmentsThatHaveAccessToLayer =
                                            $event
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { ref: "cardAction" },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "d-flex justify-end",
                            attrs: { cols: "12" },
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "primary",
                                  type: "submit",
                                  disabled: invalid || !_vm.canManageLayers,
                                  id: "saveLayerBtn",
                                },
                              },
                              [_vm._v(" Save Layer ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-dialog",
        {
          attrs: {
            fullscreen: "",
            "content-class": "form-builder-edit-form",
            "retain-focus": false,
            persistent: "",
          },
          model: {
            value: _vm.showEditFormDialog,
            callback: function ($$v) {
              _vm.showEditFormDialog = $$v
            },
            expression: "showEditFormDialog",
          },
        },
        [
          _vm.showEditFormDialog
            ? _c("FormBuilderEditForm", {
                attrs: {
                  selectedFormDefinitionId: _vm.selectedFormDefinitionId,
                  formIsNewForm: _vm.formIsNewForm,
                },
                on: {
                  "form-builder-edit-form-submitted": function ($event) {
                    _vm.selectedFormDefinitionId = $event
                  },
                  "form-builder-edit-form-close": function ($event) {
                    _vm.showEditFormDialog = false
                    _vm.resetURL()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }