<template>
  <div class="d-flex justify-space-between">
    <validation-observer ref="fileForm">
      <v-list>
        <v-list-item class="pa-0 ma-0">
          <v-list-item-content>
            <validation-provider
              v-slot="{ errors, valid }"
              name="Label"
              rules="required"
            >
              <v-text-field
                v-model="selectedRepeatingGroupItemOptions.question.label"
                label="Label"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="name"
                @change="onSubmit()"
                :disabled="!canManageForms"
              />
            </validation-provider>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="pa-0 ma-0">
          <v-list-item-content class="mt-0 mb-0 pt-0 pb-0">
            <v-switch
              v-model="
                selectedRepeatingGroupItemOptions.question.showDescription
              "
              label="Show Description"
              @change="onSubmit()"
              :disabled="!canManageForms"
              hide-details
            ></v-switch>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="pa-0 ma-0">
          <v-list-item-content class="mt-0 mb-0 pt-0 pb-0">
            <v-switch
              v-model="selectedRepeatingGroupItemOptions.allowMultiple"
              label="Allow Multiple"
              @change="onSubmit()"
              :disabled="!canManageForms"
              hide-details
            ></v-switch>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="px-0 mx-0">
          <v-list-item-content class="px-0 mx-0">
            <AdvancedSettingsForm
              v-model="selectedRepeatingGroupItemOptions"
              :formDefinition="formDefinition"
              :selectedItem="selectedRepeatingGroupItemOptions"
              :selectedRepeatingGroupItemOptions="
                selectedRepeatingGroupItemOptions
              "
              :layer="layer"
              :showVisibilityChoice="false"
              :showDefaultChoice="false"
              :showCreateChoice="false"
              @input="onSubmit()"
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="px-0 mx-0">
          <v-list-item-content class="px-0 mx-0">
            <div class="d-flex flex-wrap gap" v-if="hasAdvancedSettings">
              <section
                class="text-uppercase d-flex primary--text gap align-center"
                v-if="hasRequiredSettings"
                @click="openRequiredSettingsDialog"
              >
                <div>Required {{ requiredSetting }}</div>

                <v-btn icon :disabled="!canManageForms">
                  <v-icon color="primary">
                    {{ mdiPencil }}
                  </v-icon>
                </v-btn>
              </section>
            </div>
            <div v-else class="d-flex align-center justify-center">
              No advanced settings
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </validation-observer>

    <AdvancedRequiredSettingsDialog
      v-if="showAdvancedRequiredSettingsDialog"
      :showAdvancedRequiredSettingsDialog="showAdvancedRequiredSettingsDialog"
      :formDefinition="formDefinition"
      :layer="layer"
      :selectedItem="selectedRepeatingGroupItemOptions"
      @advanced-settings-dialog-close="
        showAdvancedRequiredSettingsDialog = false
      "
      v-model="selectedRepeatingGroupItemOptions"
      @input="onSubmit()"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import {
  mdiDragVertical,
  mdiDotsVertical,
  mdiDelete,
  mdiPencil,
} from "@mdi/js";
import { groupItemRequiredOptions } from "@/constants/choices";
import permissionsMixin from "@/mixins/permissionsMixin";
import AdvancedSettingsForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/AdvancedSettingsForm.vue";
import AdvancedRequiredSettingsDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/AdvancedRequiredSettingsDialog.vue";
import {
  ADVANCED_SETTINGS_CHOICES,
  CONDITION_CHOICES,
} from "@/constants/advancedSettings";

export default {
  name: "TableFieldFileForm",
  mixins: [permissionsMixin],
  components: {
    AdvancedSettingsForm,
    AdvancedRequiredSettingsDialog,
  },
  computed: {
    requiredSetting() {
      if (
        this.selectedRepeatingGroupItemOptions?.question?.required
          ?.applyRequired === "NEVER"
      ) {
        return "";
      }
      return this.selectedRepeatingGroupItemOptions?.question?.required
        ?.applyRequired;
    },
    hasAdvancedSettings() {
      const { hasRequiredSettings } = this;
      return hasRequiredSettings;
    },
    hasRequiredSettings() {
      return (
        this.selectedRepeatingGroupItemOptions.question.required
          ?.applyRequired !== CONDITION_CHOICES.NEVER
      );
    },
  },
  data() {
    return {
      mdiDragVertical,
      mdiDotsVertical,
      mdiDelete,
      mdiPencil,
      selectedRepeatingGroupItemOptions: {},
      groupItemRequiredOptions,
      showAdvancedRequiredSettingsDialog: false,
    };
  },
  props: {
    value: Object,
    formDefinition: Object,
    layer: Object,
    gisDataFields: Array,
    userDataFields: Array,
    fields: Array,
  },
  beforeMount() {
    this.getselectedRepeatingGroupItemOptions();
    if (!this.selectedRepeatingGroupItemOptions?.question?.required) {
      this.selectedRepeatingGroupItemOptions.question.required = {
        condition: "NEVER",
      };
    }

    if (!this.selectedRepeatingGroupItemOptions?.question?.default) {
      this.selectedRepeatingGroupItemOptions.question.default = {};
    }
  },
  methods: {
    openRequiredSettingsDialog() {
      if (this.canManageForms) {
        this.showAdvancedRequiredSettingsDialog = true;
      }
    },
    async onSubmit() {
      const success = await this.$refs.fileForm.validate();
      if (!success) {
        return;
      }
      this.$emit("input", this.selectedRepeatingGroupItemOptions);
    },
    getselectedRepeatingGroupItemOptions() {
      this.selectedRepeatingGroupItemOptions = cloneDeep(this.value);
      if (!this.selectedRepeatingGroupItemOptions?.question?.required) {
        this.selectedRepeatingGroupItemOptions.question.required = {
          condition: "NEVER",
        };
      }

      if (!this.selectedRepeatingGroupItemOptions?.question?.visible) {
        this.selectedRepeatingGroupItemOptions.question.visible = {
          condition: "ALWAYS",
        };
      }

      if (!this.selectedRepeatingGroupItemOptions?.question?.default) {
        this.selectedRepeatingGroupItemOptions.question.default = {};
      }

      if (!this.selectedRepeatingGroupItemOptions?.question?.default) {
        this.selectedRepeatingGroupItemOptions.question.default = {
          type: null,
        };
      }
    },
    openAdvancedSettingDialog() {
      if (this.advancedSettingType === ADVANCED_SETTINGS_CHOICES.REQUIRE) {
        this.showAdvancedRequiredSettingsDialog = true;
      }
    },
  },
};
</script>
