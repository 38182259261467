var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "600px",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
      model: {
        value: _vm.showConfirmDeleteLayerDialog,
        callback: function ($$v) {
          _vm.showConfirmDeleteLayerDialog = $$v
        },
        expression: "showConfirmDeleteLayerDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c("div", { staticClass: "text-h5 py-3" }, [
                _vm._v("Delete Related Layer?"),
              ]),
              _c("p", [
                _vm._v(
                  " Are you sure you want to delete this related layer? This action cannot be undone. "
                ),
              ]),
              _c("LayerCannotBeDeletedDialog", {
                attrs: {
                  showLayerCannotBeDeletedDialog:
                    _vm.showLayerCannotBeDeletedDialog,
                },
                on: {
                  cancel: function ($event) {
                    _vm.showLayerCannotBeDeletedDialog = false
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteLayer()
                    },
                  },
                },
                [_vm._v(" Delete Layer ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }