<template>
  <div>
    <p class="caption mt-6 mb-3">Service Info</p>

    <v-card class="py-5">
      <validation-observer ref="form" v-slot="{ invalid }">
        <form>
          <v-card-text>
            <validation-provider
              :bails="false"
              name="arcGisServiceUrl"
              v-slot="{ errors, valid }"
              :rules="{
                required: true,
                regex:
                  /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
                ends_with_regex:
                  layer.service_type === 'F'
                    ? '/FeatureServer/[0-9]+'
                    : '/MapServer',
              }"
            >
              <div class="d-flex flex-row">
                <v-textarea
                  v-model="selectedLayer.service_url"
                  :error-messages="errors"
                  :success="valid"
                  label="ArcGIS Service URL *"
                  color="primary"
                  class="ma-0 mr-4 pa-0"
                  @change="$emit('layer-changed', selectedLayer)"
                  auto-grow
                  rows="1"
                  hide-details
                  :disabled="!canManageLayers"
                >
                </v-textarea>
                <v-btn
                  text
                  @click="goToService(selectedLayer.service_url)"
                  width="30px"
                  min-width="30px"
                  height="30px"
                  min-height="30px"
                >
                  <v-icon color="#1976d2">{{ mdiOpenInNew }}</v-icon>
                </v-btn>
              </div>
            </validation-provider>

            <template v-if="submitted">
              <p class="caption my-0 py-0" style="color: red" v-if="error">
                {{ error }}
              </p>
              <p class="caption my-0 py-0" style="color: #093637" v-else>
                Success! Connected to ArcGIS Feature Service.
              </p>
            </template>

            <validation-provider>
              <v-switch
                class="py-0"
                v-model="securedService"
                label="Secured Service"
                :disabled="!canManageLayers"
              >
              </v-switch>
            </validation-provider>
          </v-card-text>
        </form>
        <v-card-actions class="d-flex justify-end py-0">
          <v-btn
            color="primary"
            class="mr-4 mt-n4 mb-2"
            @click="testUrl"
            :disabled="invalid || !canManageLayers"
          >
            Test Connection
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { mdiOpenInNew } from "@mdi/js";
import { EventBus } from "@/main.js";
import permissionsMixin from "@/mixins/permissionsMixin";

export default {
  name: "ServiceInfoForm",
  props: {
    layer: Object,
  },
  data() {
    return {
      selectedLayer: {},
      securedService: false,
      submitted: false,
      mdiOpenInNew,
    };
  },
  mixins: [permissionsMixin],
  watch: {
    securedService(val) {
      if (val) {
        this.selectedLayer.token_type = "AGOL";
      } else {
        this.selectedLayer.token_type = "NONE";
      }
      this.$emit("layer-changed", this.selectedLayer);
    },
    layer: {
      immediate: true,
      deep: true,
      handler(val) {
        this.selectedLayer = cloneDeep(val);
        if (this.selectedLayer.token_type !== "NONE") {
          this.securedService = true;
        }
      },
    },
  },
  methods: {
    async testUrl() {
      const success = await this.$refs.form.validate();
      if (!success) {
        return;
      }
      this.submitted = false;
      this.error = "";
      const { selectedLayer, securedService } = this;
      try {
        const url = new URL(selectedLayer.service_url);
        const searchParams = new URLSearchParams();
        searchParams.append("f", "pjson");
        const token = securedService
          ? localStorage.getItem("esri_token")
          : undefined;
        if (token) {
          searchParams.append("token", token);
        }
        url.search = searchParams;
        const res = await fetch(url.toString(), {
          redirect: "manual",
        });
        const { type, status } = res;
        if (type === "opaqueredirect") {
          this.error =
            "Unable to connect to layer. Your user does not have permission to access that layer";
        } else {
          try {
            const data = await res.json();
            const error = data?.error?.message;
            if (error) {
              this.error = error;
            } else if (status >= 300) {
              this.error = `${status}: Unable to connect to layer, reason unknown.`;
            }
          } catch {
            const data = await res.text();
            const parser = new DOMParser();
            const doc = parser.parseFromString(data, "text/html");
            const [error] = doc.getElementsByClassName("restErrors");
            if (error) {
              this.error = error?.textContent;
            } else if (status >= 300) {
              this.error = `${status}: Unable to connect to layer, reason unknown.`;
            }
          }
        }
      } catch (error) {
        this.error = "Unable to connect to layer, reason unknown.";
      } finally {
        this.submitted = true;
      }

      EventBus.$emit("test-clicked", this.error);
    },
    goToService(serviceURL) {
      const token = localStorage.getItem("esri_token");
      if (this.securedService && token) {
        window.open(`${serviceURL}?token=${token}`, "blank");
      } else {
        window.open(serviceURL, "blank");
      }
    },
  },
};
</script>
