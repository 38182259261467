<template>
  <v-dialog
    :value="showLayerDepartmentAccessDialog"
    max-width="600px"
    ref="dialog"
  >
    <v-card style="background-color: #f1f2f1">
      <v-toolbar dark color="primary" ref="toolbar">
        <v-toolbar-title>Department Access</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('department-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        :style="{
          'background-color': '#f1f2f1',
          'overflow-y': 'auto',
          height: '65vh',
        }"
        class="py-2"
        ref="cardText"
      >
        <p ref="cardInnerText">
          <b>The layer can be accessed by the following departments:</b>
        </p>

        <v-simple-table fixed-header :height="`${tableHeight}px`">
          <thead>
            <tr>
              <th>
                <v-simple-checkbox
                  :value="
                    newDepartmentsThatHaveAccessToLayer.length ===
                    departments.length
                  "
                  @input="toggleAllDepartmentsAccessToLayer()"
                ></v-simple-checkbox>
              </th>
              <th>Departments</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="d of departments" :key="d.department_id">
              <td>
                <v-simple-checkbox
                  :value="departmentHasAccessToLayer(d.department_id)"
                  @input="toggleDepartmentAccessToLayer(d.department_id)"
                ></v-simple-checkbox>
              </td>
              <td>{{ d.name }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <LayerIsInTheFollowingMapDialog
          :showLayerIsInTheFollowingMapDialog="
            showLayerIsInTheFollowingMapDialog
          "
          :showLayerIsInTheFollowingMapDialogData="
            showLayerIsInTheFollowingMapDialogData
          "
          @close="showLayerIsInTheFollowingMapDialog = false"
        />
      </v-card-text>

      <v-card-actions class="d-flex justify-end pa-5" ref="actions">
        <v-btn
          color="primary"
          @click="$emit('access-updated', newDepartmentsThatHaveAccessToLayer)"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import contentHeightMixin from "@/mixins/contentHeightMixin";
import axios from "axios";
import { cloneDeep } from "lodash";
import LayerIsInTheFollowingMapDialog from "@/components/layers/edit-layer-form/shared/department-access-form/layer-department-access-dialog/LayerIsInTheFollowingMapDialog.vue";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "LayerDepartmentAccessDialog",
  mixins: [contentHeightMixin],
  props: {
    showLayerDepartmentAccessDialog: Boolean,
    layer: Object,
    departmentsThatHaveAccessToLayer: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    LayerIsInTheFollowingMapDialog,
  },
  data() {
    return {
      departments: [],
      newDepartmentsThatHaveAccessToLayer: [],
      showLayerIsInTheFollowingMapDialog: false,
      showLayerIsInTheFollowingMapDialogData: {
        departmentMapsWithMapService: [],
        mapService: {},
      },
      departmentMapServices: [],
      tableHeight: 0,
    };
  },
  methods: {
    getTableHeight() {
      this.$options.mutationObserver = new MutationObserver(() => {
        this.$options.resizeObserver = new ResizeObserver(() => {
          this.tableHeight =
            this.$refs.cardText?.clientHeight -
            this.$refs.cardInnerText?.clientHeight -
            40;
        });
        if (this.$refs.cardText instanceof HTMLElement) {
          this.$options.resizeObserver.observe(this.$refs.cardText);
        }
        if (this.$refs.cardInnerText instanceof HTMLElement) {
          this.$options.resizeObserver.observe(this.$refs.cardInnerText);
        }
      });
      const config = { attributes: true, childList: true, subtree: true };
      this.$options.mutationObserver.observe(document.body, config);
    },
    async getLayerWithMapsThatDepartmentHasAccessTo(
      mapServiceIdWithDepartmentAccess,
      departmentIdWithAccess
    ) {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/department_maps`);
      const flattenedMapServices = results.map((r) => r.map_services).flat();
      const departmentMapsWithMapService = results.filter((r) => {
        const { map_services: mapServices, department_id: departmentId } = r;
        return mapServices.find((ms) => {
          const { map_service_id: mapServiceId } = ms;
          return (
            mapServiceIdWithDepartmentAccess === mapServiceId &&
            departmentIdWithAccess === departmentId
          );
        });
      });
      const mapService = flattenedMapServices.find(
        (ms) => ms.map_service_id === mapServiceIdWithDepartmentAccess
      );

      return {
        departmentMapsWithMapService,
        mapService,
        departmentId: departmentIdWithAccess,
      };
    },
    async getDepartments() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/departments`);
      this.departments = results;
    },
    async getDepartmentMaps(mapId) {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/department_maps/${mapId}`);
      this.departmentMapServices = results;
    },
    async getDepartmentMapServices() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/department_map_services`);
      this.departmentMapServices = results;
    },
    departmentHasAccessToLayer(departmentId) {
      return Boolean(
        this.newDepartmentsThatHaveAccessToLayer.find(
          (d) => d.department_id === departmentId
        )
      );
    },
    async toggleDepartmentAccessToLayer(departmentId) {
      const index = this.newDepartmentsThatHaveAccessToLayer.findIndex(
        (d) => d.department_id === departmentId
      );
      if (index >= 0) {
        const mapServicesToDeleteWithDepartmentMapAccess =
          await this.getLayerWithMapsThatDepartmentHasAccessTo(
            this.layer.map_service_id,
            departmentId
          );
        const { departmentMapsWithMapService } =
          mapServicesToDeleteWithDepartmentMapAccess;
        if (departmentMapsWithMapService.length > 0) {
          this.showLayerIsInTheFollowingMapDialogData =
            mapServicesToDeleteWithDepartmentMapAccess;
          this.showLayerIsInTheFollowingMapDialog = true;
        } else {
          this.newDepartmentsThatHaveAccessToLayer.splice(index, 1);
        }
      } else {
        const departmentMapService = {
          map_service_id: this.layer.map_service_id,
          department_id: departmentId,
        };
        this.newDepartmentsThatHaveAccessToLayer.push(departmentMapService);
      }
    },
    async toggleAllDepartmentsAccessToLayer() {
      if (this.newDepartmentsThatHaveAccessToLayer.length === 0) {
        this.newDepartmentsThatHaveAccessToLayer = this.departments.map((d) => {
          const { department_id: departmentId } = d;
          return {
            map_service_id: this.layer.map_service_id,
            department_id: departmentId,
          };
        });
      } else {
        const mapServicesIdsDepartmentHasAccessTo =
          this.newDepartmentsThatHaveAccessToLayer.map(
            ({ map_service_id: mapServiceId }) => {
              return mapServiceId;
            }
          );

        const {
          data: { results: departmentMapServicesWithDepartmentMaps },
        } = await axios.post(`${APIURL}/department_map_service_maps`, {
          map_service_ids: mapServicesIdsDepartmentHasAccessTo,
        });
        if (departmentMapServicesWithDepartmentMaps.length > 0) {
          this.showLayerIsInTheFollowingMapDialogData = {
            departmentMapsWithMapService:
              departmentMapServicesWithDepartmentMaps,
          };
          this.showLayerIsInTheFollowingMapDialog = true;
          return;
        }
        this.newDepartmentsThatHaveAccessToLayer = [];
      }
    },
  },
  beforeMount() {
    this.getDepartments();
    this.getDepartmentMapServices();
    this.getTableHeight();
  },
  watch: {
    "map.map_id": {
      immediate: true,
      handler(mapId) {
        if (mapId) {
          this.getDepartmentMaps(mapId);
        }
      },
    },
    departmentsThatHaveAccessToLayer: {
      deep: true,
      immediate: true,
      handler(val) {
        if (Array.isArray(val)) {
          this.newDepartmentsThatHaveAccessToLayer = cloneDeep(val);
        }
      },
    },
  },
};
</script>
