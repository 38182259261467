<template>
  <div class="d-flex justify-space-between">
    <validation-observer ref="selectForm">
      <form>
        <v-list
          v-if="
            selectedRepeatingGroupItemOptions &&
            selectedRepeatingGroupItemOptions.question
          "
          dense
        >
          <v-list-item class="pa-0 ma-0">
            <v-list-item-content>
              <validation-provider
                v-slot="{ errors, valid }"
                name="Label"
                rules="required"
              >
                <v-text-field
                  v-model="selectedRepeatingGroupItemOptions.question.label"
                  label="Label"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="name"
                  @change="onSubmit()"
                  :disabled="!canManageForms"
                />
              </validation-provider>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pa-0 ma-0">
            <v-list-item-content class="mt-0 mb-0 pt-0">
              <validation-provider v-slot="{ errors, valid }" name="Hint">
                <v-text-field
                  v-model="
                    selectedRepeatingGroupItemOptions.question.placeholder
                  "
                  label="Hint"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="hint"
                  @change="onSubmit()"
                  :disabled="!canManageForms"
                />
              </validation-provider>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="mt-0 pt-0 px-0 mx-0">
            <v-list-item-content
              class="mt-0 pt-0"
              :key="selectedRepeatingGroupItemOptions.question.default.type"
            >
              <validation-provider
                v-slot="{ errors, valid }"
                name="Default Value"
              >
                <v-select
                  autocomplete="off"
                  v-model="
                    selectedRepeatingGroupItemOptions.question.default.type
                  "
                  :items="defaultValueOptions"
                  label="Default Value"
                  :error-messages="errors"
                  :success="valid"
                  item-text="label"
                  item-value="value"
                  @change="onSubmit()"
                  hide-details
                  :disabled="!canManageForms"
                ></v-select>
              </validation-provider>

              <v-list
                v-if="
                  ['LOCATE_DATA'].includes(
                    selectedRepeatingGroupItemOptions.question.default.type
                  )
                "
                class="pa-0 ma-0"
                :key="selectedRepeatingGroupItemOptions.question.default.type"
              >
                <v-list-item class="pr-0 mr-0">
                  <v-list-item-content class="pt-0 mt-0">
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="811 Ticket"
                      rules="required"
                    >
                      <v-select
                        autocomplete="off"
                        v-model="
                          selectedRepeatingGroupItemOptions.question.default
                            .value
                        "
                        :items="locateRequestDataValues"
                        label="811 Ticket"
                        :error-messages="errors"
                        :success="valid"
                        item-text="label"
                        item-value="value"
                        @change="onSubmit()"
                        hide-details
                        :disabled="!canManageForms"
                      ></v-select>
                    </validation-provider>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-list
                v-if="
                  ['USER'].includes(
                    selectedRepeatingGroupItemOptions.question.default.type
                  )
                "
                class="pa-0 ma-0"
                :key="selectedRepeatingGroupItemOptions.question.default.type"
              >
                <v-list-item class="pr-0 mr-0">
                  <v-list-item-content>
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="User Field"
                      rules="required"
                    >
                      <v-select
                        autocomplete="off"
                        v-model="
                          selectedRepeatingGroupItemOptions.question.default
                            .value
                        "
                        :items="userDataChoices"
                        label="User Field"
                        :error-messages="errors"
                        :success="valid"
                        item-text="label"
                        item-value="value"
                        @change="onSubmit()"
                        hide-details
                        :disabled="!canManageForms"
                      ></v-select>
                    </validation-provider>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-list
                v-if="
                  ['GIS_DATA'].includes(
                    selectedRepeatingGroupItemOptions.question.default.type
                  )
                "
                class="pa-0 ma-0"
                :key="selectedRepeatingGroupItemOptions.question.default.type"
              >
                <v-list-item class="pr-0 mr-0">
                  <v-list-item-content>
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="UtiliSync Field"
                      rules="required"
                    >
                      <v-select
                        autocomplete="off"
                        v-model="
                          selectedRepeatingGroupItemOptions.question.default
                            .value
                        "
                        :items="gisDataChoices"
                        label="UtiliSync Field"
                        :error-messages="errors"
                        :success="valid"
                        item-text="label"
                        item-value="value"
                        @change="onSubmit()"
                        hide-details
                        :disabled="!canManageForms"
                      ></v-select>
                    </validation-provider>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-list
                v-if="
                  ['CUSTOM'].includes(
                    selectedRepeatingGroupItemOptions.question.default.type
                  )
                "
                :key="selectedRepeatingGroupItemOptions.question.default.type"
              >
                <v-list-item class="pr-0 mr-0">
                  <v-list-item-content class="pa-0 ma-0">
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="Default Value"
                      rules="required"
                    >
                      <v-text-field
                        v-model="
                          selectedRepeatingGroupItemOptions.question.default
                            .value
                        "
                        label="Default Value"
                        :error-messages="errors"
                        :success="valid"
                        @change="onSubmit()"
                        :disabled="!canManageForms"
                      />
                    </validation-provider>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-list
                v-if="
                  ['ORGANIZATION'].includes(
                    selectedRepeatingGroupItemOptions.question.default.type
                  )
                "
                :key="selectedRepeatingGroupItemOptions.question.default.type"
              >
                <v-list-item class="pr-0 mr-0">
                  <v-list-item-content class="my-0 py-0">
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="Organization Data"
                      rules="required"
                    >
                      <v-select
                        autocomplete="off"
                        v-model="
                          selectedRepeatingGroupItemOptions.question.default
                            .value
                        "
                        :items="orgDataOptions"
                        name="Organization Data"
                        :error-messages="errors"
                        :success="valid"
                        item-text="label"
                        item-value="value"
                        placeholder="Organization Data"
                        @change="onSubmit()"
                        :disabled="!canManageForms"
                      ></v-select>
                    </validation-provider>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-list
                v-if="
                  ['GIS'].includes(
                    selectedRepeatingGroupItemOptions.question.default.type
                  )
                "
                :key="selectedRepeatingGroupItemOptions.question.default.type"
              >
                <v-list-item class="mr-0 pr-0">
                  <v-list-item-content v-if="fieldChoices.length > 0">
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="Default Value"
                      rules="required"
                    >
                      <v-select
                        autocomplete="off"
                        v-model="
                          selectedRepeatingGroupItemOptions.question.default
                            .value
                        "
                        :items="fieldChoices"
                        name="gisField"
                        label="GIS Field"
                        :error-messages="errors"
                        :success="valid"
                        item-text="label"
                        item-value="value"
                        @change="onSubmit()"
                        hide-details
                        :disabled="!canManageForms"
                      ></v-select>
                    </validation-provider>
                  </v-list-item-content>

                  <v-list-item-content class="ma-0 pa-0" v-else>
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="Default Value"
                      rules="required"
                    >
                      <v-text-field
                        class="ma-0 pa-0"
                        v-model="
                          selectedRepeatingGroupItemOptions.question.default
                            .value
                        "
                        label="Default Value"
                        :error-messages="errors"
                        :success="valid"
                        @change="onSubmit()"
                      />
                    </validation-provider>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-list-item-content>
          </v-list-item>

          <div class="d-flex justify-space-between align-center">
            <div class="caption">Choices</div>
            <div>
              <v-btn
                text
                @click="showAddChoiceDialog = true"
                color="primary"
                class="px-2"
                :disabled="!canManageForms"
              >
                + Choice
              </v-btn>
            </div>
          </div>

          <v-list-item class="pa-0 ma-0">
            <v-list-item-content>
              <draggable
                :list="selectedRepeatingGroupItemOptions.question.options"
                @end="onSubmit()"
                class="pa-0 ma-0"
                :disabled="!canManageForms"
                handle=".section-handle"
              >
                <div
                  v-for="(option, index) of selectedRepeatingGroupItemOptions
                    .question.options"
                  :key="option.id"
                  class="pa-0 d-flex align-center justify-space-between mt-n8 mb-n3"
                >
                  <div class="d-flex cursor-pointer">
                    <div class="mt-6">
                      <v-icon v-if="canManageForms">{{
                        mdiDragVertical
                      }}</v-icon>
                    </div>
                    <div class="ml-n2">
                      <v-list dense two-line max-width="110">
                        <v-list-item class="mr-n8">
                          <v-list-item-content>
                            <v-list-item-subtitle>
                              {{ option.value }}
                            </v-list-item-subtitle>
                            <v-list-item-title>
                              {{ option.label }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>
                  </div>

                  <div class="d-flex">
                    <v-btn
                      text
                      @click="
                        showEditChoiceDialog = true;
                        selectedOption = option;
                        selectedOptionIndex = index;
                      "
                      :disabled="!canManageForms"
                      x-small
                      class="pl-0 pr-0 mr-0"
                    >
                      <v-icon color="primary">{{ mdiPencil }}</v-icon>
                    </v-btn>
                    <v-btn
                      text
                      @click="removeChoice(index)"
                      x-small
                      class="pl-0 pr-0 mr-0"
                      :disabled="!canManageForms"
                    >
                      <v-icon color="primary">{{ mdiDelete }}</v-icon>
                    </v-btn>
                  </div>
                </div>
              </draggable>

              <v-dialog
                v-model="showEditChoiceDialog"
                max-width="600px"
                persistent
                :fullscreen="$vuetify.breakpoint.xsOnly"
              >
                <ChoiceForm
                  v-if="showEditChoiceDialog"
                  @choice-submitted="onChoiceSubmitted"
                  :option="selectedOption"
                  :index="selectedOptionIndex"
                  :selectedItemOptions="selectedRepeatingGroupItemOptions"
                  @choice-form-close="showEditChoiceDialog = false"
                />
              </v-dialog>

              <v-dialog
                v-model="showAddChoiceDialog"
                max-width="600px"
                :fullscreen="$vuetify.breakpoint.xsOnly"
              >
                <ChoiceForm
                  v-if="showAddChoiceDialog"
                  @choice-submitted="onChoiceSubmitted"
                  :selectedItemOptions="selectedRepeatingGroupItemOptions"
                  @choice-form-close="showAddChoiceDialog = false"
                />
              </v-dialog>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pa-0 ma-0">
            <v-list-item-content class="my-0 py-0">
              <v-switch
                v-model="selectedRepeatingGroupItemOptions.question.isImage"
                label="Choices are Images"
                @change="onSubmit()"
                class="my-0 py-0"
                :disabled="!canManageForms"
                hide-details
              ></v-switch>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="pa-0 ma-0"
            v-if="!selectedRepeatingGroupItemOptions.question.isImage"
          >
            <v-list-item-content class="my-0 py-0">
              <v-switch
                v-model="selectedRepeatingGroupItemOptions.question.hasOther"
                label="Include 'Other' Option"
                @change="onSubmit()"
                class="my-0 py-0"
                :disabled="!canManageForms"
                hide-details
              ></v-switch>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="pa-0 ma-0"
            v-if="!selectedRepeatingGroupItemOptions.question.isImage"
          >
            <v-list-item-content class="my-0 py-0">
              <v-switch
                v-model="
                  selectedRepeatingGroupItemOptions.question.showAllOptions
                "
                label="Show All Choices at Once"
                @change="onSubmit()"
                class="my-0 py-0"
                :disabled="!canManageForms"
                hide-details
              ></v-switch>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pa-0 ma-0">
            <v-list-item-content class="my-0 py-0">
              <v-switch
                v-model="
                  selectedRepeatingGroupItemOptions.question.default
                    .applyDefaultValue
                "
                label="Apply Default Value"
                @change="onSubmit()"
                class="my-0 py-0"
                :disabled="!canManageForms"
                hide-details
              >
              </v-switch>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="px-0 mx-0">
            <v-list-item-content class="px-0 mx-0">
              <AdvancedSettingsForm
                v-model="selectedRepeatingGroupItemOptions"
                :formDefinition="formDefinition"
                :selectedItem="selectedRepeatingGroupItemOptions"
                :selectedRepeatingGroupItemOptions="
                  selectedRepeatingGroupItemOptions
                "
                :layer="layer"
                @input="onSubmit()"
              />
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="px-0 mx-0">
            <v-list-item-content class="px-0 mx-0">
              <div class="d-flex flex-wrap gap" v-if="hasAdvancedSettings">
                <section
                  class="text-uppercase d-flex primary--text gap align-center"
                  v-if="hasDefaultSettings"
                  @click="onDefaultSettingClick"
                >
                  <div>Default {{ defaultSetting }}</div>

                  <v-btn icon :disabled="!canManageForms">
                    <v-icon color="primary">
                      {{ mdiPencil }}
                    </v-icon>
                  </v-btn>
                </section>

                <section
                  class="text-uppercase d-flex primary--text gap align-center"
                  v-if="hasVisibleSettings"
                  @click="onVisibleSettingClick"
                >
                  <div>Visible {{ visibleSetting }}</div>

                  <v-btn icon :disabled="!canManageForms">
                    <v-icon color="primary">
                      {{ mdiPencil }}
                    </v-icon>
                  </v-btn>
                </section>

                <section
                  class="text-uppercase d-flex primary--text gap align-center"
                  v-if="hasRequiredSettings"
                  @click="openRequiredSettingsDialog"
                >
                  <div>Required {{ requiredSetting }}</div>

                  <v-btn icon :disabled="!canManageForms">
                    <v-icon color="primary">
                      {{ mdiPencil }}
                    </v-icon>
                  </v-btn>
                </section>

                <section
                  class="text-uppercase d-flex primary--text gap align-center"
                  v-if="hasCreateSettings"
                  @click="openCreateSettingsDialog"
                >
                  <div>Create {{ createSetting }}</div>

                  <v-btn icon :disabled="!canManageForms">
                    <v-icon color="primary">
                      {{ mdiPencil }}
                    </v-icon>
                  </v-btn>
                </section>
              </div>
              <div v-else class="d-flex align-center justify-center">
                No advanced settings
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </form>
    </validation-observer>

    <AdvancedDefaultSettingsDialog
      v-if="showAdvancedDefaultSettingsDialog"
      :showAdvancedDefaultSettingsDialog="showAdvancedDefaultSettingsDialog"
      :formDefinition="formDefinition"
      :layer="layer"
      @advanced-settings-dialog-close="
        showAdvancedDefaultSettingsDialog = false
      "
      v-model="selectedRepeatingGroupItemOptions"
      @input="onSubmit()"
    />

    <AdvancedRequiredSettingsDialog
      v-if="showAdvancedRequiredSettingsDialog"
      :showAdvancedRequiredSettingsDialog="showAdvancedRequiredSettingsDialog"
      :formDefinition="formDefinition"
      :layer="layer"
      :selectedItem="selectedRepeatingGroupItemOptions"
      @advanced-settings-dialog-close="
        showAdvancedRequiredSettingsDialog = false
      "
      v-model="selectedRepeatingGroupItemOptions"
      @input="onSubmit()"
    />

    <AdvancedVisibilitySettingsDialog
      v-if="showAdvancedVisibilitySettingsDialog"
      :showAdvancedVisibilitySettingsDialog="
        showAdvancedVisibilitySettingsDialog
      "
      :formDefinition="formDefinition"
      :layer="layer"
      :selectedItem="selectedRepeatingGroupItemOptions"
      @advanced-settings-dialog-close="
        showAdvancedVisibilitySettingsDialog = false
      "
      v-model="selectedRepeatingGroupItemOptions"
      @input="onSubmit()"
    />

    <AdvancedCreateSettingsDialog
      v-if="showAdvancedCreateSettingsDialog"
      :showAdvancedCreateSettingsDialog="showAdvancedCreateSettingsDialog"
      :formDefinition="formDefinition"
      :layer="layer"
      :selectedItem="selectedRepeatingGroupItemOptions"
      @advanced-settings-dialog-close="showAdvancedCreateSettingsDialog = false"
      v-model="selectedRepeatingGroupItemOptions"
      @input="onSubmit()"
    />
  </div>
</template>

<script>
import {
  mdiDragVertical,
  mdiDotsVertical,
  mdiDelete,
  mdiPencil,
} from "@mdi/js";
import { cloneDeep } from "lodash";
import {
  comparisonTypes,
  defaultValueTypes,
  groupItemRequiredOptions,
  groupItemVisibleOptions,
  visibilityOptions,
  orgDataOptions,
} from "@/constants/choices";
import ChoiceForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/select-form/ChoiceForm.vue";
import draggable from "vuedraggable";
import permissionsMixin from "@/mixins/permissionsMixin";
import AdvancedSettingsForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/AdvancedSettingsForm.vue";
import AdvancedDefaultSettingsDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/AdvancedDefaultSettingsDialog.vue";
import AdvancedVisibilitySettingsDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/AdvancedVisibilitySettingsDialog.vue";
import AdvancedCreateSettingsDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/AdvancedCreateSettingsDialog.vue";
import AdvancedRequiredSettingsDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/AdvancedRequiredSettingsDialog.vue";
import {
  ADVANCED_SETTINGS_CHOICES,
  CONDITION_CHOICES,
} from "@/constants/advancedSettings";

export default {
  name: "TableFieldSelectForm",
  mixins: [permissionsMixin],
  data() {
    return {
      mdiDragVertical,
      mdiDotsVertical,
      mdiDelete,
      mdiPencil,
      selectedRepeatingGroupItemOptions: {},
      comparisonTypes,
      defaultValueTypes,
      groupItemRequiredOptions,
      groupItemVisibleOptions,
      visibilityOptions,
      orgDataOptions,
      selectedOption: {},
      showAddChoiceDialog: false,
      showEditChoiceDialog: false,
      showAdvancedDefaultSettingsDialog: false,
      showAdvancedVisibilitySettingsDialog: false,
      showAdvancedRequiredSettingsDialog: false,
      showAdvancedCreateSettingsDialog: false,
    };
  },
  props: {
    value: Object,
    formDefinition: Object,
    layer: Object,
    gisDataFields: Array,
    userDataFields: Array,
    fields: Array,
  },
  computed: {
    defaultSetting() {
      if (
        this.selectedRepeatingGroupItemOptions?.question?.default
          ?.applyDefault === "ALWAYS"
      ) {
        return "";
      }
      return this.selectedRepeatingGroupItemOptions?.question?.default
        ?.applyDefault;
    },
    visibleSetting() {
      if (
        this.selectedRepeatingGroupItemOptions?.question?.visible
          ?.applyVisible === "ALWAYS"
      ) {
        return "";
      }
      return this.selectedRepeatingGroupItemOptions?.question?.visible
        ?.applyVisible;
    },
    requiredSetting() {
      if (
        this.selectedRepeatingGroupItemOptions?.question?.required
          ?.applyRequired === "NEVER"
      ) {
        return "";
      }
      return this.selectedRepeatingGroupItemOptions?.question?.required
        ?.applyRequired;
    },
    createSetting() {
      if (
        this.selectedRepeatingGroupItemOptions?.question?.create
          ?.applyCreate === "ALWAYS"
      ) {
        return "";
      }
      return this.selectedRepeatingGroupItemOptions?.question?.create
        ?.applyCreate;
    },
    hasAdvancedSettings() {
      const {
        hasDefaultSettings,
        hasVisibleSettings,
        hasRequiredSettings,
        hasCreateSettings,
      } = this;
      return (
        hasDefaultSettings ||
        hasVisibleSettings ||
        hasRequiredSettings ||
        hasCreateSettings
      );
    },
    hasDefaultSettings() {
      return (
        this.selectedRepeatingGroupItemOptions.question.default.type !== null
      );
    },
    hasVisibleSettings() {
      return (
        this.selectedRepeatingGroupItemOptions.question.visible
          ?.applyVisible !== CONDITION_CHOICES.ALWAYS
      );
    },
    hasRequiredSettings() {
      return (
        this.selectedRepeatingGroupItemOptions.question.required
          ?.applyRequired !== CONDITION_CHOICES.NEVER
      );
    },
    hasCreateSettings() {
      return (
        this.selectedRepeatingGroupItemOptions.question?.create &&
        this.selectedRepeatingGroupItemOptions.question.create?.applyCreate !==
          CONDITION_CHOICES.ALWAYS
      );
    },
    gisDataChoices() {
      return this.gisDataFields.map(
        ({ gis_data_field_id: value, name, alias }) => ({
          value,
          label: alias || name,
        })
      );
    },
    fieldChoices() {
      const fieldChoices = this.fields.map(({ name, alias }) => ({
        value: name,
        label: alias || name,
      }));
      return fieldChoices;
    },
    userDataChoices() {
      const apiChoices = this.userDataFields.map(
        ({ user_data_field_id: value, name: label }) => ({
          value,
          label,
        })
      );
      return [
        { value: "f_name", label: "First Name" },
        { value: "l_name", label: "Last Name" },
        { value: "full_name", label: "Full Name" },
        { value: "email", label: "Email" },
        ...apiChoices,
      ];
    },
    defaultValueOptions() {
      if (
        this.selectedRepeatingGroupItemOptions.question.type === "SIGNATURE"
      ) {
        return [
          {
            value: null,
            label: "None",
          },
          {
            value: "USER",
            label: "User Data",
          },
          {
            value: "GIS_DATA",
            label: "UtiliSync Field",
          },
        ];
      }

      if (
        this.selectedRepeatingGroupItemOptions.question.isLong ||
        this.selectedRepeatingGroupItemOptions.question.type === "EMAIL"
      ) {
        const defaultValueTypes = this.defaultValueTypes.filter(
          ({ label }) => label !== "Organization Data"
        );
        if (this.layer.service_type === "L") {
          defaultValueTypes.push({
            value: "LOCATE_DATA",
            label: "811 Ticket",
          });
        }
        return defaultValueTypes;
      }

      const defaultValueTypes = [...this.defaultValueTypes];
      if (this.layer.service_type === "L") {
        defaultValueTypes.push({
          value: "LOCATE_DATA",
          label: "811 Ticket",
        });
      }
      return defaultValueTypes;
    },
  },
  components: {
    ChoiceForm,
    draggable,
    AdvancedSettingsForm,
    AdvancedDefaultSettingsDialog,
    AdvancedVisibilitySettingsDialog,
    AdvancedCreateSettingsDialog,
    AdvancedRequiredSettingsDialog,
  },
  beforeMount() {
    this.getSelectedItemOptions();
  },
  methods: {
    openVisibilitySettingsDialog() {
      if (this.canManageForms) {
        this.showAdvancedVisibilitySettingsDialog = true;
      }
    },
    openRequiredSettingsDialog() {
      if (this.canManageForms) {
        this.showAdvancedRequiredSettingsDialog = true;
      }
    },
    openCreateSettingsDialog() {
      if (this.canManageForms) {
        this.showAdvancedCreateSettingsDialog = true;
      }
    },
    onDefaultSettingClick() {
      if (!this.canManageForms) {
        return;
      }
      this.advancedSettingType = ADVANCED_SETTINGS_CHOICES.DEFAULT;
      this.showAdvancedDefaultSettingsDialog = true;
    },
    onVisibleSettingClick() {
      if (!this.canManageForms) {
        return;
      }
      this.advancedSettingType = ADVANCED_SETTINGS_CHOICES.VISIBILITY;

      this.showAdvancedVisibilitySettingsDialog = true;
    },
    openAdvancedSettingDialog() {
      if (this.advancedSettingType === ADVANCED_SETTINGS_CHOICES.DEFAULT) {
        this.showAdvancedDefaultSettingsDialog = true;
      } else if (
        this.advancedSettingType === ADVANCED_SETTINGS_CHOICES.VISIBILITY
      ) {
        this.showAdvancedVisibilitySettingsDialog = true;
      } else if (
        this.advancedSettingType === ADVANCED_SETTINGS_CHOICES.REQUIRE
      ) {
        this.showAdvancedRequiredSettingsDialog = true;
      } else if (
        this.advancedSettingType === ADVANCED_SETTINGS_CHOICES.CREATE
      ) {
        this.showAdvancedCreateSettingsDialog = true;
      }
    },
    getSelectedItemOptions() {
      this.selectedRepeatingGroupItemOptions = cloneDeep(this.value);
      if (!this.selectedRepeatingGroupItemOptions?.question?.required) {
        this.selectedRepeatingGroupItemOptions.question.required = {
          condition: "NEVER",
        };
      }

      if (!this.selectedRepeatingGroupItemOptions?.question?.visible) {
        this.selectedRepeatingGroupItemOptions.question.visible = {
          condition: "ALWAYS",
        };
      }

      if (!this.selectedRepeatingGroupItemOptions?.question?.default) {
        this.selectedRepeatingGroupItemOptions.question.default = {};
      }

      if (!this.selectedRepeatingGroupItemOptions?.question?.default) {
        this.selectedRepeatingGroupItemOptions.question.default = {
          type: null,
        };
      }
    },
    async onSubmit() {
      const success = await this.$refs.selectForm.validate();
      if (!success) {
        return;
      }
      const options = cloneDeep(this.selectedRepeatingGroupItemOptions);
      options.question.options = options.question.options.map(
        ({ value, label }) => ({
          value,
          label,
        })
      );
      this.$emit("input", options);
    },
    onChoiceSubmitted(choice, index) {
      if (typeof index === "number") {
        this.$set(
          this.selectedRepeatingGroupItemOptions.question.options,
          index,
          choice
        );
        this.showEditChoiceDialog = false;
      } else {
        this.selectedRepeatingGroupItemOptions.question.options.push(choice);
        this.showAddChoiceDialog = false;
      }
      this.onSubmit();
    },
    async removeChoice(index) {
      this.selectedRepeatingGroupItemOptions.question.options.splice(index, 1);
      await this.onSubmit();
    },
  },
};
</script>
