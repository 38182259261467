<template>
  <v-card class="elevation-0">
    <v-toolbar dark color="primary">
      <v-toolbar-title>New Form</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="$emit('new-form-close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text class="elevation-0">
      <validation-observer ref="form">
        <form @submit.prevent="submit">
          <v-row>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Title"
                rules="required"
              >
                <v-text-field
                  v-model="form.title"
                  label="Title"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="title"
                  hint="Title of form. This can be changed later."
                  persistent-hint
                />
              </validation-provider>
            </v-col>

            <v-col cols="12" class="d-flex justify-end">
              <v-btn type="submit" color="primary">Create Form</v-btn>
            </v-col>
          </v-row>
        </form>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "MapServiceNewFormForm",
  props: {
    mapServiceId: String,
  },
  data() {
    return {
      form: {
        title: "",
        mapServiceId: "",
        site_contact_enabled: false,
        organization_user_enabled: true,
        form: {
          locate_request_settings: {
            is_secure_service: false,
            archive_to_service: false,
          },
        },
        html_merge_definition_version: 2,
      },
    };
  },
  methods: {
    async submit() {
      const success = await this.$refs.form.validate();
      if (!success) {
        return;
      }
      this.setNewFormData(this.form);
      this.$emit("create-new-form");
    },
    ...mapMutations(["setNewFormData"]),
  },
  beforeMount() {
    this.setNewFormData({});
  },
  watch: {
    mapServiceId: {
      immediate: true,
      handler(val) {
        this.form.mapServiceId = val;
      },
    },
  },
};
</script>
