var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card-text",
    { staticClass: "elevation-0 ma-0 pa-0" },
    [
      _c("validation-observer", { ref: "formPreview", attrs: { slim: "" } }, [
        _c(
          "form",
          [
            _c(
              "v-expansion-panels",
              {
                attrs: {
                  value: _vm.openSections,
                  multiple: "",
                  id: "form-builder-preview",
                },
              },
              [
                _c(
                  "draggable",
                  {
                    staticClass: "list-group",
                    attrs: {
                      list: _vm.formDefinition.form.sections,
                      group: "sections",
                      options: { disabled: _vm.previewMode },
                      handle: ".section-handle",
                      disabled: !_vm.canManageForms,
                    },
                    on: { end: _vm.assignNumbersToSections },
                  },
                  _vm._l(
                    _vm.formDefinition.form.sections,
                    function (section, sectionIndex) {
                      return _c(
                        "v-expansion-panel",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isSectionVisible(section),
                              expression: "isSectionVisible(section)",
                            },
                          ],
                          key: section.id,
                        },
                        [
                          _c(
                            "v-expansion-panel-header",
                            {
                              class: {
                                selectedField:
                                  _vm.selectedWholeSectionId === section.id,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.selectItem(
                                    section.id,
                                    undefined,
                                    "SECTION"
                                  )
                                  _vm.selectWholeSection(section)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  !_vm.previewMode && _vm.canManageForms
                                    ? _c(
                                        "v-icon",
                                        { staticClass: "section-handle" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.mdiDragVertical) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("div", { staticClass: "px-1" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          section.hideHeader
                                            ? ""
                                            : section
                                            ? section.name
                                            : ""
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c("v-spacer"),
                              !_vm.previewMode &&
                              _vm.selectedWholeSectionId === section.id
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            text: "",
                                            disabled: !_vm.canManageForms,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.showConfirmDeleteSectionDialog = true
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-2" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.mdiDelete) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" Delete "),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            text: "",
                                            disabled: !_vm.canManageForms,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.duplicateSection(
                                                sectionIndex
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-2" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.mdiContentCopy) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" Duplicate "),
                                        ],
                                        1
                                      ),
                                      _c("ConfirmDeleteDialog", {
                                        attrs: {
                                          itemType: "section",
                                          showConfirmDialog:
                                            _vm.showConfirmDeleteSectionDialog,
                                        },
                                        on: {
                                          "confirm-delete": function ($event) {
                                            _vm.deleteSection(sectionIndex)
                                            _vm.showConfirmDeleteSectionDialog = false
                                          },
                                          "cancel-delete": function ($event) {
                                            _vm.showConfirmDeleteSectionDialog = false
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-expansion-panel-content",
                            {
                              class: {
                                selectedField:
                                  _vm.selectedWholeSectionId === section.id,
                              },
                            },
                            [
                              _c(
                                "draggable",
                                {
                                  staticClass: "list-group",
                                  attrs: {
                                    list: section.items,
                                    group: "items",
                                    options: { disabled: _vm.previewMode },
                                    handle: ".handle",
                                    disabled: !_vm.canManageForms,
                                  },
                                  on: { end: _vm.assignNumbersToItems },
                                },
                                _vm._l(section.items, function (item) {
                                  return _c(
                                    "div",
                                    {
                                      key: `${item.id}-${item.number}`,
                                      staticClass: "elevation-0 py-0",
                                      on: {
                                        click: function ($event) {
                                          _vm.selectItem(
                                            section.id,
                                            item.id,
                                            _vm.getSelectedItemType(item)
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          class: {
                                            selectedField:
                                              _vm.selectedItemId === item.id ||
                                              _vm.selectedWholeSectionId ===
                                                section.id,
                                          },
                                        },
                                        [
                                          _c(
                                            "section",
                                            {
                                              staticClass:
                                                "d-flex px-0 pb-0 pt-2 justify-space-between align-center gap",
                                            },
                                            [
                                              _c("ItemPreview", {
                                                staticClass: "flex-grow-1 my-4",
                                                attrs: {
                                                  sectionId: section.id,
                                                  item: item,
                                                  formDefinition:
                                                    _vm.formDefinition,
                                                  selected:
                                                    _vm.selectedItemId ===
                                                      item.id ||
                                                    _vm.selectedWholeSectionId ===
                                                      section.id,
                                                  previewMode: _vm.previewMode,
                                                  readOnly: !_vm.previewMode,
                                                },
                                                on: { input: _vm.onInput },
                                              }),
                                              _vm.selectedItemId === item.id &&
                                              !_vm.previewMode
                                                ? _c(
                                                    "section",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-grow-0",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.selectItem(
                                                            section.id,
                                                            item.id,
                                                            _vm.getSelectedItemType(
                                                              item
                                                            )
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column flex-align-center",
                                                        },
                                                        [
                                                          _vm.showDuplicateButton(
                                                            item
                                                          )
                                                            ? _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "mb-2",
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                    text: "",
                                                                    width:
                                                                      "20px",
                                                                    "min-width":
                                                                      "20px",
                                                                    height:
                                                                      "20px",
                                                                    "min-height":
                                                                      "20px",
                                                                    disabled:
                                                                      !_vm.canManageForms,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        return _vm.duplicateItem(
                                                                          section.id,
                                                                          item.id
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "mr-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.mdiContentCopy
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          item.type ===
                                                          "QUESTION"
                                                            ? _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "mb-2",
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                    text: "",
                                                                    width:
                                                                      "20px",
                                                                    "min-width":
                                                                      "20px",
                                                                    height:
                                                                      "20px",
                                                                    "min-height":
                                                                      "20px",
                                                                    disabled:
                                                                      !_vm.canManageForms,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.showAdvancedEditDialog = true
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "mr-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.mdiPencil
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mb-2",
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                text: "",
                                                                width: "20px",
                                                                "min-width":
                                                                  "20px",
                                                                height: "20px",
                                                                "min-height":
                                                                  "20px",
                                                                disabled:
                                                                  !_vm.canManageForms,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.showConfirmDeleteDialog = true
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.mdiDelete
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm.showConfirmDeleteDialog
                                                        ? _c(
                                                            "ConfirmDeleteDialog",
                                                            {
                                                              attrs: {
                                                                itemType:
                                                                  "item",
                                                                showConfirmDialog:
                                                                  _vm.showConfirmDeleteDialog,
                                                              },
                                                              on: {
                                                                "confirm-delete":
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.removeItemFromSection(
                                                                      section.id,
                                                                      item.id
                                                                    )
                                                                    _vm.showConfirmDeleteDialog = false
                                                                  },
                                                                "cancel-delete":
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.showConfirmDeleteDialog = false
                                                                  },
                                                              },
                                                            }
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm.checkFieldIsNotLast(item, section.id)
                                        ? _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { staticClass: "pa-0" },
                                                [
                                                  _vm.selectedItemId ===
                                                    item.id && !_vm.previewMode
                                                    ? _c(
                                                        "section",
                                                        {
                                                          staticClass:
                                                            "d-flex mt-4 mb-0 px-5 justify-center",
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                dark:
                                                                  !_vm.addingField &&
                                                                  _vm.canManageForms,
                                                                color:
                                                                  "primary",
                                                                tile: "",
                                                                disabled:
                                                                  _vm.addingField ||
                                                                  !_vm.canManageForms,
                                                                id: "inlineAddFieldBtn",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.addField(
                                                                      section.id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.mdiPlus
                                                                  )
                                                                ),
                                                              ]),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pl-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "ADD FIELD"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                              !_vm.previewMode
                                ? _c(
                                    "section",
                                    {
                                      staticClass:
                                        "d-flex my-5 px-5 justify-center",
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            tile: "",
                                            id: "bottomAddFieldBtn",
                                            dark: _vm.canManageForms,
                                            disabled: !_vm.canManageForms,
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.addField(section.id)
                                            },
                                          },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(_vm._s(_vm.mdiPlus)),
                                          ]),
                                          _c("div", { staticClass: "pl-2" }, [
                                            _vm._v("ADD FIELD"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }
                  ),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }