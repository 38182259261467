<template>
  <v-dialog
    :value="showGisFieldsDialog"
    max-width="650px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar
        dark
        color="primary"
        class="elevation-0 align-center"
        ref="toolbar"
      >
        <v-toolbar-title>GIS Fields</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn outlined @click="syncGisFields" :disabled="!canManageLayers">
          <v-icon class="mr-2">mdi-sync-circle</v-icon>Sync GIS Fields
        </v-btn>
        <v-btn icon dark @click="$emit('edit-gis-fields-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        class="py-5"
        :style="{
          'background-color': '#f1f2f1',
          height: $vuetify.breakpoint.xsOnly ? `${contentHeight}px` : 'auto',
          'overflow-y': 'auto',
          'max-height': $vuetify.breakpoint.xsOnly ? undefined : '60vh',
        }"
      >
        <p>GIS fields are used to store information about a feature or site.</p>

        <div
          class="d-flex gap py-1"
          :class="{
            'justify-end': someSelected,
            'justify-space-between': !someSelected,
          }"
        >
          <v-chip
            class="d-flex gap align-center"
            @click="toggleVisible"
            v-if="!someSelected"
          >
            {{ showVisible ? "Visible" : "Hidden" }}
            <v-icon size="18" class="ml-1">{{ mdiSyncCircle }}</v-icon>
          </v-chip>

          <v-btn
            v-if="someSelected"
            text
            color="primary"
            class="px-0 mx-0 d-flex align-center"
            @click="toggleFieldsVisibility()"
            :disabled="!canManageLayers"
          >
            <v-icon class="mr-1">{{ showVisible ? mdiEyeOff : mdiEye }}</v-icon>
            Make Fields {{ showVisible ? "Hidden" : "Visible" }}
          </v-btn>
          <v-btn
            v-else-if="showVisible"
            text
            color="primary"
            class="px-0 mx-0"
            @click="addSectionLabel()"
            :disabled="!canManageLayers"
          >
            + Add Section Label
          </v-btn>
        </div>

        <v-card>
          <v-card-text class="pa-0">
            <v-simple-table height="40vh" fixed-header>
              <thead>
                <tr>
                  <th v-if="showVisible && canManageLayers"></th>
                  <th class="pl-2 pr-0">
                    <div class="d-flex align-center">
                      <v-checkbox
                        @change="selectAll"
                        :value="allSelected"
                        :indeterminate="someSelected"
                        color="primary"
                        class="mx-0 mt-2 mb-n2"
                        :disabled="!canManageLayers"
                      ></v-checkbox>
                    </div>
                  </th>
                  <th class="pl-2 pr-0">
                    <div class="d-flex align-center">UtiliSync Label</div>
                  </th>
                  <th class="pl-2 pr-0">
                    <div class="d-flex align-center">ArcGIS Field</div>
                  </th>
                  <th class="pl-2 pr-0">
                    <div class="d-flex align-center">ArcGIS Alias</div>
                  </th>
                  <th class="pl-2 pr-0"></th>
                </tr>
              </thead>

              <tr v-if="notAuthenticatedToEsri">
                <td colspan="6">
                  <div class="d-flex align-center justify-center pa-5">
                    Sign in with ArcGIS to view fields.
                  </div>
                </td>
              </tr>
              <template v-else>
                <draggable
                  :list="mapServiceFields"
                  tag="tbody"
                  v-if="mapServiceFields.length > 0"
                  @end="onDragEnd(mapServiceFields)"
                  :disabled="!showVisible || !canManageLayers"
                >
                  <tr
                    v-for="field of getMapServiceFieldsByVisibility(
                      mapServiceFields,
                      showVisible
                    )"
                    :key="field.id"
                  >
                    <td
                      class="white pl-1 pr-0 mx-0 cursor-grab"
                      v-if="showVisible && canManageLayers"
                    >
                      <v-icon>{{ mdiDragVertical }}</v-icon>
                    </td>
                    <td class="white pl-2 pr-0">
                      <v-checkbox
                        v-if="
                          [GIS_FIELD_TYPES.FIELD, GIS_FIELD_TYPES.ID].includes(
                            getFieldType(field)
                          )
                        "
                        v-model="visibilityCheckBoxesSelected[field.id]"
                        class="mx-0 mt-2 mb-n2"
                        :disabled="!canManageLayers"
                      >
                      </v-checkbox>
                    </td>
                    <template
                      v-if="getFieldType(field) === GIS_FIELD_TYPES.FIELD"
                    >
                      <td class="white pl-2 pr-0">
                        <section
                          class="white cursor-pointer"
                          v-if="!editingUtiliSyncLabel[field.id]"
                          @click="$set(editingUtiliSyncLabel, field.id, true)"
                        >
                          {{
                            getGisField(field.fieldId) &&
                            getGisField(field.fieldId).labels.join(", ")
                          }}
                        </section>
                        <section v-else class="white">
                          <validation-provider
                            v-slot="{ errors, valid }"
                            name="UtiliSync Label"
                            rules="required"
                          >
                            <v-text-field
                              label="UtiliSync Label"
                              v-model="field.labels[0]"
                              hide-details
                              :error-messages="errors"
                              :success="valid"
                              name="utiliSyncLabel"
                              :disabled="!canManageLayers"
                            >
                            </v-text-field>

                            <div
                              class="my-2 d-flex align-center justify-end gap"
                            >
                              <v-btn
                                class="mx-1 px-0"
                                text
                                @click="
                                  $set(editingUtiliSyncLabel, field.id, false)
                                "
                                :disabled="!canManageLayers"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                color="primary"
                                @click="
                                  $set(editingUtiliSyncLabel, field.id, false);
                                  onGisLayerSaved();
                                "
                                :disabled="!canManageLayers"
                              >
                                Save
                              </v-btn>
                            </div>
                          </validation-provider>
                        </section>
                      </td>
                      <td class="white pl-2 pr-0">
                        {{
                          getGisField(field.fieldId) &&
                          getGisField(field.fieldId).name
                        }}
                      </td>
                      <td class="white pl-2 pr-0">
                        {{
                          getGisField(field.fieldId) &&
                          getGisField(field.fieldId).alias
                        }}
                      </td>
                    </template>
                    <template
                      v-else-if="
                        getFieldType(field) === GIS_FIELD_TYPES.SECTION_LABEL
                      "
                    >
                      <td class="white px-2">
                        <section
                          class="white d-flex align-center cursor-pointer"
                          v-if="!editingSectionLabel[field.id]"
                          @click="editSectionLabel(field)"
                        >
                          {{ field.sectionLabel }}
                        </section>
                        <section v-else class="white">
                          <validation-provider
                            v-slot="{ errors, valid }"
                            name="Section Label"
                            rules="required"
                          >
                            <v-text-field
                              label="Section Label"
                              v-model="field.sectionLabel"
                              hide-details
                              :error-messages="errors"
                              :success="valid"
                              name="sectionLabel"
                              :disabled="!canManageLayers"
                            >
                            </v-text-field>

                            <div
                              class="my-2 d-flex align-center justify-end gap"
                            >
                              <v-btn
                                class="mx-1 px-0"
                                text
                                @click="cancelEditSectionLabel(field)"
                                :disabled="!canManageLayers"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                color="primary"
                                @click="
                                  $set(editingSectionLabel, field.id, false);
                                  onGisLayerSaved();
                                "
                                :disabled="!canManageLayers"
                              >
                                Save
                              </v-btn>
                            </div>
                          </validation-provider>
                        </section>
                      </td>
                      <td class="white" colspan="2"></td>
                    </template>
                    <td class="white pl-2 pr-0">
                      <div class="d-flex justify-end align-center px-2">
                        <v-menu
                          class="white"
                          v-if="getFieldType(field) === GIS_FIELD_TYPES.FIELD"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              :disabled="!canManageLayers"
                            >
                              <v-icon color="primary">
                                {{ mdiDotsVertical }}
                              </v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item
                              class="cursor-pointer"
                              v-if="field.fieldId"
                              @click="
                                $set(editingUtiliSyncLabel, field.id, true)
                              "
                              :disabled="!canManageLayers"
                            >
                              <v-list-item-title
                                class="primary--text text--lighten-1"
                              >
                                <v-icon color="primary">{{ mdiPencil }}</v-icon>
                                Edit UtiliSync Label
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              class="cursor-pointer"
                              @click="
                                field.visible = !field.visible;
                                onGisLayerSaved();
                              "
                              :disabled="!canManageLayers"
                            >
                              <v-list-item-title
                                class="primary--text text--lighten-1"
                              >
                                <v-icon color="primary">
                                  {{ field.visible ? mdiEyeOff : mdiEye }}
                                </v-icon>
                                {{ field.visible ? "Hide" : "Show" }} GIS Field
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>

                        <v-menu
                          class="white"
                          v-else-if="
                            getFieldType(field) ===
                            GIS_FIELD_TYPES.SECTION_LABEL
                          "
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              :disabled="!canManageLayers"
                            >
                              <v-icon color="primary">
                                {{ mdiDotsVertical }}
                              </v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item @click="editSectionLabel(field)">
                              <v-list-item-title
                                class="primary--text text--lighten-1"
                              >
                                <v-icon color="primary">{{ mdiPencil }}</v-icon>
                                Edit Section Label
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="deleteSectionLabel(field.id)">
                              <v-list-item-title
                                class="primary--text text--lighten-1"
                              >
                                <v-icon color="primary">{{ mdiDelete }}</v-icon>
                                Delete Section Label
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                    </td>
                  </tr>
                </draggable>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-card-actions class="d-flex justify-end pa-2" ref="cardAction">
        <v-btn dark color="primary" @click="returnToUtilibot">
          Return to Utilibot
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import draggable from "vuedraggable";
import { v4 as uuidv4 } from "uuid";
import {
  mdiDragVertical,
  mdiEyeOff,
  mdiDotsVertical,
  mdiPencil,
  mdiDelete,
  mdiSyncCircle,
  mdiEye,
} from "@mdi/js";
import axios from "axios";
import { cloneDeep } from "lodash";
import permissionsMixin from "@/mixins/permissionsMixin";

const APIURL = process.env.VUE_APP_API_URL;
const GIS_FIELD_TYPES = {
  ID: "id",
  FIELD: "field",
  SECTION_LABEL: "section-label",
};

export default {
  name: "EditGisFieldsDialog",
  props: {
    showGisFieldsDialog: Boolean,
    mapServiceId: String,
  },
  components: {
    draggable,
  },
  mixins: [permissionsMixin],
  data() {
    return {
      mapServiceFields: [],
      GIS_FIELD_TYPES,
      mdiDragVertical,
      mdiEyeOff,
      mdiDotsVertical,
      mdiPencil,
      mdiDelete,
      mdiSyncCircle,
      mdiEye,
      gisFields: [],
      editingSectionLabel: {},
      editingUtiliSyncLabel: {},
      utiliSyncLabels: {},
      visibilityCheckBoxesSelected: {},
      showVisible: true,
      newSectionLabels: [],
      originalMapServiceFields: [],
      notAuthenticatedToEsri: false,
    };
  },
  computed: {
    allSelected() {
      const values = Object.values(this.visibilityCheckBoxesSelected);
      return (
        values.length > 0 &&
        values.length ===
          this.mapServiceFields.filter(
            (f) =>
              f.visible === this.showVisible &&
              [GIS_FIELD_TYPES.FIELD, GIS_FIELD_TYPES.ID].includes(
                this.getFieldType(f)
              )
          ).length &&
        values.every((checked) => checked)
      );
    },
    someSelected() {
      const values = Object.values(this.visibilityCheckBoxesSelected);
      return values.length > 0 && values.some((checked) => checked);
    },
  },
  methods: {
    toggleFieldsVisibility() {
      const idsToChange = Object.keys(this.visibilityCheckBoxesSelected);
      this.mapServiceFields = [
        ...this.mapServiceFields.map((f) => {
          if (idsToChange.includes(f.id)) {
            return { ...f, visible: !f.visible };
          } else {
            return { ...f };
          }
        }),
      ];
      this.visibilityCheckBoxesSelected = {};
    },
    getMapServiceFieldsByVisibility(mapServiceFields, visible) {
      return mapServiceFields.filter((f) => f.visible === visible);
    },
    toggleVisible() {
      this.showVisible = !this.showVisible;
    },
    selectAll(checked) {
      const fieldsToCheck = this.mapServiceFields.filter(
        (f) =>
          f.visible === this.showVisible &&
          [GIS_FIELD_TYPES.FIELD, GIS_FIELD_TYPES.ID].includes(
            this.getFieldType(f)
          )
      );
      for (const field of fieldsToCheck) {
        this.$set(this.visibilityCheckBoxesSelected, field.id, checked);
      }
    },
    deleteSectionLabel(id) {
      const index = this.mapServiceFields.findIndex((f) => f.id === id);
      this.mapServiceFields.splice(index, 1);
      this.onGisLayerSaved();
    },
    getFieldType(field) {
      if (field?.fieldId) {
        if (["objectId", "globalId"].includes(field?.fieldId)) {
          return GIS_FIELD_TYPES.ID;
        } else {
          return GIS_FIELD_TYPES.FIELD;
        }
      } else if (typeof field?.sectionLabel === "string") {
        return GIS_FIELD_TYPES.SECTION_LABEL;
      }
    },
    addSectionLabel() {
      const newSectionLabel = {
        id: uuidv4(),
        sectionLabel: "Untitled Section Label",
        order: this.mapServiceFields.length + 1,
        visible: true,
        labels: [],
      };
      this.newSectionLabels.push(newSectionLabel);
      this.mapServiceFields = [...this.mapServiceFields, newSectionLabel];
      this.$set(this.editingSectionLabel, newSectionLabel.id, true);
      this.onGisLayerSaved();
    },
    editSectionLabel(field) {
      this.originalMapServiceFields = cloneDeep(this.mapServiceFields);
      this.$set(this.editingSectionLabel, field.id, true);
    },
    cancelEditSectionLabel(field) {
      this.$set(this.editingSectionLabel, field.id, false);
      const isNewSectionLabel = this.newSectionLabels
        .map((l) => l.id)
        .includes(field.id);
      if (isNewSectionLabel) {
        const sectionLabelIndex = this.mapServiceFields.findIndex(
          (f) => f.id === field.id
        );
        this.mapServiceFields.splice(sectionLabelIndex, 1);
        this.mapServiceFields = [
          ...this.mapServiceFields.map((f, i) => {
            {
              return { ...f, order: i + 1 };
            }
          }),
        ];
        const newSectionLabelIndex = this.newSectionLabels.findIndex(
          (f) => f.id === field.id
        );
        this.newSectionLabels.splice(newSectionLabelIndex, 1);
      } else {
        this.mapServiceFields = cloneDeep(this.originalMapServiceFields);
      }
    },
    onDragEnd(reorderedMapServiceFields) {
      const visibleFields = reorderedMapServiceFields.filter((f) => f.visible);
      const hiddenFields = reorderedMapServiceFields.filter((f) => !f.visible);
      this.mapServiceFields = [
        ...visibleFields.map((f, i) => {
          return { ...f, order: i + 1 };
        }),
        // eslint-disable-next-line no-unused-vars
        ...hiddenFields.map(({ order, ...rest }) => rest),
      ];
      this.onGisLayerSaved();
    },
    async syncGisFields() {
      await this.getGisFields();

      const gisDataMap = new Map(
        this.gisFields.map((item) => [item.name, item])
      );

      this.mapServiceFields = this.mapServiceFields.filter(
        (item) => gisDataMap.has(item.fieldId) || item?.sectionLabel
      );

      const customizedFieldIds = new Set(
        this.mapServiceFields.map((item) => item.fieldId)
      );

      this.gisFields.forEach((item) => {
        if (!customizedFieldIds.has(item.name)) {
          this.mapServiceFields.push({
            id: uuidv4(),
            fieldId: item.name,
            order: this.mapServiceFields.length + 1,
            visible: true,
            labels: [item.alias || item.name],
          });
        }
      });
    },
    async getGisFields() {
      const { mapServiceId } = this;
      const {
        data: { results: mapService },
      } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
      const mapServiceUrl = mapService?.service_url;

      const token =
        mapService.token_type === "AGOL"
          ? localStorage.getItem("esri_token")
          : undefined;
      const params = {
        where: "1=1",
        outFields: "*",
        f: "pjson",
        token,
      };

      const { data: queryResult } = await axios.get(`${mapServiceUrl}/query`, {
        params: { ...params },
        transformRequest: (data, headers) => {
          delete headers["X-AUTH"];
          return data;
        },
      });
      if (queryResult?.error?.message === "Token Required") {
        this.notAuthenticatedToEsri = true;
      } else {
        this.gisFields = queryResult.fields ?? [];
      }
    },
    async getMapService() {
      const { mapServiceId } = this;
      if (!mapServiceId) {
        return;
      }
      const {
        data: { results },
      } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
      this.selectedMapService = { ...results };

      if (!Array.isArray(results.display_gis_fields)) {
        const fields = this.gisFields?.map((f, i) => {
          const { name: fieldId, alias } = f;
          return {
            id: uuidv4(),
            fieldId,
            order: i + 1,
            visible: true,
            labels: [alias],
          };
        });
        this.mapServiceFields = [...fields];
      } else {
        const { display_gis_fields: displayGisGFields } = results;
        this.mapServiceFields = displayGisGFields.sort(
          (a, b) => a.order - b.order
        );
      }
    },
    getGisField(gisFieldId) {
      const displayGisGFields = this.mapServiceFields?.find(
        (f) => f.fieldId === gisFieldId
      );
      const gisField = this.gisFields?.find((f) => f.name === gisFieldId);
      return { ...displayGisGFields, ...gisField };
    },
    async getData() {
      await this.getGisFields();
      await this.getMapService();
    },
    returnToUtilibot() {
      this.onGisLayerSaved();
      this.$emit("edit-gis-fields-dialog-close");
    },
    onGisLayerSaved() {
      const { selectedMapService, mapServiceFields } = this;
      const layer = {
        ...selectedMapService,
        displayGisFields: mapServiceFields,
      };
      this.$emit("gis-fields-saved", layer);
    },
  },
  beforeMount() {
    this.getData();
  },
};
</script>

<style scoped>
.cursor-grab {
  cursor: grab;
}
</style>
