var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showCustomLayerSymbologyDialog,
        "max-width": "600px",
        persistent: "",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              ref: "toolbar",
              staticClass: "elevation-0",
              attrs: { dark: "", color: "primary" },
            },
            [
              _c("v-toolbar-title", [_vm._v("Customize Layer Symbology")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("custom-symbology-dialog-close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              staticClass: "py-5",
              style: {
                "background-color": "#f1f2f1",
                height: _vm.$vuetify.breakpoint.xsOnly
                  ? `${_vm.contentHeight}px`
                  : "auto",
                "overflow-y": "auto",
                "max-height": _vm.$vuetify.breakpoint.xsOnly
                  ? undefined
                  : "60vh",
              },
            },
            [
              _vm.layer.service_type === "F"
                ? _c("v-card", { staticClass: "mb-5" }, [
                    _c(
                      "div",
                      { staticClass: "px-3 py-0 d-flex align-center" },
                      [
                        _c("v-switch", {
                          attrs: {
                            label: "Apply Custom Map Symbols",
                            disabled: !_vm.canManageLayers,
                          },
                          model: {
                            value:
                              _vm.renderer.apply_renderer_to_feature_service,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.renderer,
                                "apply_renderer_to_feature_service",
                                $$v
                              )
                            },
                            expression:
                              "renderer.apply_renderer_to_feature_service",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              (_vm.layer.service_type === "F" &&
                _vm.renderer.apply_renderer_to_feature_service) ||
              !["L", "F"].includes(_vm.layer.service_type)
                ? _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "validation-observer",
                            { ref: "gisDataFieldForm" },
                            [
                              _c(
                                "form",
                                { staticStyle: { width: "33%" } },
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "Display features using",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors, valid }) {
                                            return [
                                              _c("v-select", {
                                                attrs: {
                                                  label:
                                                    "Display features using",
                                                  "hide-details": "auto",
                                                  "error-messages": errors,
                                                  success: valid,
                                                  color: "primary",
                                                  name: "displayFeaturesUsing",
                                                  items:
                                                    _vm.filteredDisplayFeaturesChoices,
                                                  "item-text": "label",
                                                  "item-value": "value",
                                                  disabled:
                                                    !_vm.canManageLayers,
                                                },
                                                on: {
                                                  change:
                                                    _vm.onDisplayFeatureUsingChanged,
                                                },
                                                model: {
                                                  value:
                                                    _vm.renderer.renderer_type,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.renderer,
                                                      "renderer_type",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "renderer.renderer_type",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3567091248
                                    ),
                                  }),
                                  _vm.renderer.renderer_type === "unique_value"
                                    ? _c("validation-provider", {
                                        attrs: {
                                          name: "Choose a field to display",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors, valid }) {
                                                return [
                                                  _c("v-select", {
                                                    attrs: {
                                                      label:
                                                        "Choose a field to display",
                                                      "hide-details": "auto",
                                                      "error-messages": errors,
                                                      success: valid,
                                                      color: "primary",
                                                      name: "fieldToDisplay",
                                                      items:
                                                        _vm.fieldToReferenceChoices,
                                                      "item-text": "label",
                                                      "item-value": "value",
                                                      disabled:
                                                        !_vm.canManageLayers,
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.onFieldToReferenceChange,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.fieldToReference,
                                                      callback: function ($$v) {
                                                        _vm.fieldToReference =
                                                          $$v
                                                      },
                                                      expression:
                                                        "fieldToReference",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1633835795
                                        ),
                                      })
                                    : _vm.renderer.renderer_type ===
                                        "class_break" &&
                                      _vm.layer.service_type !== "L"
                                    ? _c("validation-provider", {
                                        attrs: {
                                          name: "Choose a field to reference",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors, valid }) {
                                                return [
                                                  _c("v-select", {
                                                    attrs: {
                                                      label:
                                                        "Choose a field to reference",
                                                      "hide-details": "auto",
                                                      "error-messages": errors,
                                                      success: valid,
                                                      color: "primary",
                                                      name: "fieldToReference",
                                                      items:
                                                        _vm.fieldToReferenceChoices,
                                                      "item-text": "label",
                                                      "item-value": "value",
                                                      disabled:
                                                        !_vm.canManageLayers,
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.onFieldToReferenceChange,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.fieldToReference,
                                                      callback: function ($$v) {
                                                        _vm.fieldToReference =
                                                          $$v
                                                      },
                                                      expression:
                                                        "fieldToReference",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          166282963
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm.renderer.renderer_type === "class_break" &&
                              _vm.layer.service_type !== "L"
                                ? _c("v-switch", {
                                    attrs: {
                                      label:
                                        "Convert Date to Days From Current",
                                      disabled: !_vm.canManageLayers,
                                    },
                                    model: {
                                      value: _vm.renderer.convert_date_to_days,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.renderer,
                                          "convert_date_to_days",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "renderer.convert_date_to_days",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              (_vm.layer.service_type === "F" &&
                _vm.renderer.apply_renderer_to_feature_service) ||
              _vm.layer.service_type !== "F"
                ? _c("EditSymbolsForm", {
                    attrs: {
                      layerObj: _vm.layer,
                      mapServiceId: _vm.mapServiceId,
                      rendererId: _vm.rendererId,
                      displayFeaturesUsing: _vm.renderer.renderer_type,
                      fieldToReference: _vm.fieldToReference,
                      gisDataFields: _vm.gisDataFields,
                      fieldToReferenceChanged: _vm.fieldToReferenceChanged,
                      displayFeatureUsingChanged:
                        _vm.displayFeatureUsingChanged,
                    },
                    on: {
                      "renderer-symbols-changed": _vm.onRendererSymbolsChanged,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { ref: "cardAction", staticClass: "d-flex justify-end pa-5" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    dark: _vm.canManageLayers,
                    disabled: !_vm.canManageLayers,
                  },
                  on: { click: _vm.saveSymbology },
                },
                [_vm._v(" Save Symbology ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }