<template>
  <section class="d-flex align-center px-6 py-4" v-if="isVisible">
    <v-icon class="mr-2 cursor-pointer handle" v-if="canManageForms">
      {{ !previewMode ? mdiDragVertical : "" }}
    </v-icon>

    <template v-if="item && item.question">
      <TextInput
        v-if="['TEXT', 'EMAIL', 'NUMBER'].includes(item.question.type)"
        :item="item"
        color="primary"
        :formDefinition="formDefinition"
        v-model="item.value"
        @input="$emit('input', item.value, sectionId, item.id)"
        :readOnly="readOnly"
      />

      <validation-provider
        v-if="['SIGNATURE'].includes(item.question.type)"
        slim
        v-slot="{ errors, valid }"
        :name="item.question.label"
        :rules="{
          required: isRequired,
        }"
      >
        <SignaturePad
          :label="`${item.number} ${item.question.label}`"
          :selected="selected"
          :formDefinition="formDefinition"
          v-model="item.value"
          :errors="errors"
          :valid="valid"
          :readOnly="readOnly"
        />
      </validation-provider>

      <validation-provider
        v-if="['FILE'].includes(item.question.type)"
        v-slot="{ errors, valid }"
        :name="item.question.label"
        :rules="{
          required: isRequired,
        }"
        slim
        class="list-item"
      >
        <template>
          <PhotoInput
            v-if="item.question.isImage"
            :label="`${item.number} ${item.question.label}`"
            :allowMultiple="item.allowMultiple"
            :formResultId="formDefinition.form_result_id"
            :maxWidthHeight="item.question.maxWidthHeight"
            :showDescription="item.question.showDescription"
            v-model="item.value"
            :readOnly="readOnly"
          />

          <FileInput
            v-else
            :label="`${item.number} ${item.question.label}`"
            :allowMultiple="item.allowMultiple"
            :formResultId="formDefinition.form_result_id"
            :showDescription="item.question.showDescription"
            v-model="item.value"
            :readOnly="readOnly"
          />
        </template>
        <p class="caption" v-if="!valid">{{ errors[0] }}</p>
      </validation-provider>

      <CalculationInput
        v-if="['CALCULATION'].includes(item.question.type)"
        :item="item"
        :formDefinition="formDefinition"
        :selected="selected"
        v-model="item.value"
      />

      <PositiveResponseInput
        v-if="['POSITIVE_RESPONSE'].includes(item.question.type)"
        :item="item"
        :formDefinition="formDefinition"
        :selected="selected"
        v-model="item.value"
        :readOnly="readOnly"
        @input="$emit('input', item.value, sectionId, item.id)"
      />

      <SingleSelectInput
        v-if="['SINGLE_SELECT'].includes(item.question.type)"
        :item="item"
        :formDefinition="formDefinition"
        :selected="selected"
        v-model="item.value"
        :readOnly="readOnly"
        @input="$emit('input', item.value, sectionId, item.id)"
      />

      <MultiSelectInput
        v-if="['MULTI_SELECT'].includes(item.question.type)"
        :item="item"
        :formDefinition="formDefinition"
        :selected="selected"
        v-model="item.value"
        :readOnly="readOnly"
        @input="$emit('input', item.value, sectionId, item.id)"
      />

      <validation-provider
        v-if="['DATE'].includes(item.question.type)"
        slim
        v-slot="{ errors, valid }"
        :name="item.question.label"
        :rules="{
          required: isRequired,
        }"
      >
        <div v-if="readOnly" class="mb-n2" style="width: 100%">
          <div
            style="
              color: rgba(0, 0, 0, 0.38);
              font-size: 16px;
              border-bottom: 1px rgba(0, 0, 0, 0.38) dashed;
              padding-bottom: 4px;
              width: 100%;
            "
          >
            {{ item.number }} {{ item.question.label }}
          </div>
          <div
            style="
              color: rgba(0, 0, 0, 0.38);
              font-size: 12px;
              padding-top: 4px;
            "
          >
            {{ item.question.placeholder }}
          </div>
        </div>
        <v-menu v-else>
          <template v-slot:activator="{ on }">
            <v-text-field
              v-on="on"
              :label="`${item.number} ${item.question.label}`"
              color="primary"
              :hint="item.question.placeholder"
              persistent-hint
              v-model="item.value"
              :error-messages="errors"
              :success="valid"
              :disabled="readOnly"
            ></v-text-field>
          </template>
          <v-date-picker v-model="item.value"></v-date-picker>
        </v-menu>
      </validation-provider>

      <validation-provider
        v-if="['TIME'].includes(item.question.type)"
        slim
        v-slot="{ errors, valid }"
        :name="item.question.label"
        :rules="{
          required: isRequired,
        }"
      >
        <div v-if="readOnly" class="mb-n2" style="width: 100%">
          <div
            style="
              color: rgba(0, 0, 0, 0.38);
              font-size: 16px;
              border-bottom: 1px rgba(0, 0, 0, 0.38) dashed;
              padding-bottom: 4px;
              width: 100%;
            "
          >
            {{ item.number }} {{ item.question.label }}
          </div>
          <div
            style="
              color: rgba(0, 0, 0, 0.38);
              font-size: 12px;
              padding-top: 4px;
            "
          >
            {{ item.question.placeholder }}
          </div>
        </div>
        <v-menu v-else>
          <template v-slot:activator="{ on }">
            <v-text-field
              class="time-field"
              v-model="item.value"
              v-on="on"
              :label="`${item.number} ${item.question.label}`"
              :hint="item.question.placeholder"
              persistent-hint
              color="primary"
              :error-messages="errors"
              :success="valid"
            ></v-text-field>
          </template>
          <v-time-picker v-model="item.value"></v-time-picker>
        </v-menu>
      </validation-provider>

      <RepeatingGroup
        v-if="['GROUP'].includes(item.question.type)"
        :item="item"
        :formDefinition="formDefinition"
        :selected="selected"
        :readOnly="readOnly"
        v-model="item.value"
        :previewMode="previewMode"
        @input="$emit('input', item.value, sectionId, item.id)"
      />

      <ActionItem
        v-if="['ACTION_ITEM'].includes(item.question.type)"
        :item="item"
        :formDefinition="formDefinition"
        :selected="selected"
        :readOnly="readOnly"
        v-model="item.value"
      />
    </template>

    <template v-if="item.information">
      <v-card
        v-if="['URL'].includes(item.information.type)"
        class="elevation-0"
        :style="selected ? 'background-color: #fff8e1' : ''"
      >
        <p v-if="readOnly" class="mb-n1">{{ item.information.label }}</p>
        <a :href="item.information.url" v-else target="_blank">
          {{ item.information.label }}
        </a>
      </v-card>

      <v-card
        v-if="['HEADER'].includes(item.information.type)"
        class="elevation-0"
        :style="selected ? 'background-color: #fff8e1' : ''"
      >
        <h2>
          {{ item.information.label }}
        </h2>
      </v-card>

      <v-card
        v-if="['TEXT'].includes(item.information.type)"
        class="elevation-0 pt-4"
        :style="selected ? 'background-color: #fff8e1' : ''"
      >
        <v-card-text class="pa-0 ma-0">
          <div v-html="item.information.label"></div>
        </v-card-text>
      </v-card>

      <v-card
        v-if="['IMAGE'].includes(item.information.type)"
        class="elevation-0"
        :style="selected ? 'background-color: #fff8e1' : ''"
      >
        <div v-if="readOnly">
          <p>{{ item.information.label }}</p>
          <img
            :src="item.information.url"
            :style="{ height: 'auto', 'max-width': '500px' }"
          />
        </div>
        <div v-else>
          <p>{{ item.information.label }}</p>
          <a :href="item.information.openUrl" target="_blank">
            <img
              :src="item.information.url"
              :style="{ height: 'auto', 'max-width': '500px' }"
            />
          </a>
        </div>
      </v-card>
    </template>
  </section>
</template>

<script>
import TextInput from "@/components/forms/form-builder-edit-form/item-preview/TextInput.vue";
import SignaturePad from "@/components/forms/form-builder-edit-form/item-preview/SignaturePad.vue";
import PhotoInput from "@/components/forms/form-builder-edit-form/item-preview/PhotoInput.vue";
import CalculationInput from "@/components/forms/form-builder-edit-form/item-preview/CalculationInput.vue";
import FileInput from "@/components/forms/form-builder-edit-form/item-preview/FileInput.vue";
import MultiSelectInput from "@/components/forms/form-builder-edit-form/item-preview/MultiSelectInput.vue";
import SingleSelectInput from "@/components/forms/form-builder-edit-form/item-preview/SingleSelectInput.vue";
import RepeatingGroup from "@/components/forms/form-builder-edit-form/item-preview/RepeatingGroup.vue";
import ActionItem from "@/components/forms/form-builder-edit-form/item-preview/ActionItem.vue";
import PositiveResponseInput from "@/components/forms/form-builder-edit-form/item-preview/PositiveResponseInput.vue";
import dependantValueMixin from "@/mixins/dependantValueMixin";
import { mdiDragVertical } from "@mdi/js";
import permissionsMixin from "@/mixins/permissionsMixin";

export default {
  name: "ItemPreview",
  props: {
    item: Object,
    formDefinition: Object,
    sectionId: Number,
    selected: Boolean,
    previewMode: Boolean,
    readOnly: Boolean,
  },
  components: {
    TextInput,
    SignaturePad,
    PhotoInput,
    CalculationInput,
    FileInput,
    SingleSelectInput,
    MultiSelectInput,
    RepeatingGroup,
    ActionItem,
    PositiveResponseInput,
  },
  data() {
    return {
      mdiDragVertical,
    };
  },
  mixins: [dependantValueMixin, permissionsMixin],
  computed: {
    isRequired() {
      if (this.item.question.required.condition === "NEVER") {
        return false;
      }

      if (this.item.question.required.condition === "ALWAYS") {
        return true;
      }
      const { sections } = { ...this.formDefinition.form };
      for (const section of sections) {
        for (const dependantItem of section.items) {
          if (+dependantItem.id === +this.item.question.required.dependantId) {
            if (dependantItem?.question?.type === "GROUP") {
              for (const groupDependantItem of dependantItem?.question
                ?.groupedItems ?? []) {
                if (
                  +groupDependantItem.id ===
                  +this.item.question.required.dependantGroupItemId
                ) {
                  const groupDependantItemValue = groupDependantItem.value;
                  if (Array.isArray(groupDependantItemValue)) {
                    if (groupDependantItemValue.length > 1) {
                      return false;
                    } else {
                      const [dependantItemValue] = groupDependantItemValue;
                      return this.checkDependantValue(dependantItemValue);
                    }
                  } else {
                    return this.checkDependantValue(groupDependantItemValue);
                  }
                }
              }
            } else {
              const dependantItemValue = dependantItem.value;
              if (Array.isArray(dependantItemValue)) {
                if (dependantItemValue.length > 1) {
                  return false;
                } else {
                  const [dependantValue] = dependantItemValue;
                  return this.checkDependantValue(dependantValue);
                }
              } else {
                return this.checkDependantValue(dependantItemValue);
              }
            }
          }
        }
      }
      return this.item.question.required.condition === "ALWAYS";
    },
    isVisible() {
      if (!this.previewMode) {
        return true;
      }
      if (this.item.question) {
        return this.checkVisibility("question");
      } else {
        return this.checkVisibility("information");
      }
    },
  },
  methods: {
    checkVisibility(type = "question") {
      if (
        !this.item?.[type]?.visible?.condition ||
        this.item?.[type]?.visible?.condition === "ALWAYS"
      ) {
        return true;
      }

      if (this.item?.[type]?.visible?.condition === "NEVER") {
        return false;
      }
      const { sections } = { ...this.formDefinition.form };
      for (const section of sections) {
        for (const dependantItem of section.items) {
          if (+dependantItem.id === +this.item[type].visible.dependantId) {
            if (dependantItem?.question?.type === "GROUP") {
              for (const groupDependantItem of dependantItem?.question
                ?.groupedItems ?? []) {
                if (
                  +groupDependantItem.id ===
                  +this.item.question.required.dependantGroupItemId
                ) {
                  const groupDependantItemValue = groupDependantItem.value;
                  if (Array.isArray(groupDependantItemValue)) {
                    if (groupDependantItemValue.length > 1) {
                      return false;
                    } else {
                      const [dependantItemValue] = groupDependantItemValue;
                      return this.checkDependantValue(dependantItemValue);
                    }
                  } else {
                    return this.checkDependantValue(groupDependantItemValue);
                  }
                }
              }
            } else {
              const dependantItemValue = dependantItem.value;
              if (Array.isArray(dependantItemValue)) {
                if (dependantItemValue.length > 1) {
                  return false;
                } else {
                  const [dependantValue] = dependantItemValue;
                  return this.checkDependantValue(
                    dependantValue,
                    type,
                    "visible"
                  );
                }
              } else {
                return this.checkDependantValue(
                  dependantItemValue,
                  type,
                  "visible"
                );
              }
            }
          }
        }
      }
      return this.item[type].visible.condition === "ALWAYS";
    },
  },
};
</script>

<style scoped>
.list-item {
  width: 100%;
}

.v-menu__content {
  min-width: unset !important;
}
</style>
