<template>
  <v-dialog
    :value="showUtiliSyncFieldsDialog"
    max-width="650px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar
        dark
        color="primary"
        class="elevation-0 align-center"
        ref="toolbar"
      >
        <v-toolbar-title>UtiliSync Fields</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn icon dark @click="$emit('edit-utilisync-fields-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        class="py-5"
        :style="{
          'background-color': '#f1f2f1',
          height: $vuetify.breakpoint.xsOnly ? `${contentHeight}px` : 'auto',
          'overflow-y': 'auto',
          'max-height': $vuetify.breakpoint.xsOnly ? undefined : '60vh',
        }"
      >
        <p>
          UtiliSync fields are used to store information about a feature or
          site.
        </p>
        <div
          class="d-flex gap py-1"
          :class="{
            'justify-end': someSelected,
            'justify-space-between': !someSelected,
          }"
        >
          <section class="flex-grow-0">
            <v-chip
              class="d-flex gap align-center"
              @click="toggleVisible"
              v-if="!someSelected"
            >
              {{ showVisible ? "Visible" : "Hidden" }}
              <v-icon size="18" class="ml-1">{{ mdiSyncCircle }}</v-icon>
            </v-chip>
          </section>

          <section class="d-flex align-center flex-grow-0 flex-wrap">
            <v-btn
              v-if="someSelected"
              text
              color="primary"
              class="px-0 mx-0 d-flex align-center"
              @click="toggleFieldsVisibility()"
            >
              <v-icon class="mr-1">{{
                showVisible ? mdiEyeOff : mdiEye
              }}</v-icon>
              Make Fields
              {{ showVisible ? "Hidden" : "Visible" }}
            </v-btn>

            <template v-else-if="showVisible">
              <v-btn
                text
                color="primary"
                class="px-2 mx-0"
                @click="addSectionLabel()"
                :disabled="!canManageLayers"
              >
                + Add Section Label
              </v-btn>

              <v-btn
                text
                color="primary"
                class="px-0 mx-0"
                @click="showAddUtiliSyncFieldDialog = true"
                :disabled="!canManageLayers"
              >
                + Add UtiliSync Field
              </v-btn>
            </template>
          </section>
        </div>

        <v-card>
          <v-card-text class="pa-0">
            <v-simple-table height="40vh" fixed-header>
              <thead>
                <tr>
                  <th v-if="showVisible && canManageLayers"></th>
                  <th class="px-2">
                    <v-checkbox
                      @change="selectAll"
                      :value="allSelected"
                      :indeterminate="someSelected"
                      color="primary"
                      class="mx-0 mt-2 mb-n2"
                      :disabled="!canManageLayers"
                    ></v-checkbox>
                  </th>
                  <th class="pl-2 pr-0">
                    <div class="d-flex align-center">Field Name</div>
                  </th>
                  <th class="pl-2 pr-0">
                    <div class="d-flex align-center">ArcGIS Field</div>
                  </th>
                  <th class="pl-2 pr-0">
                    <div class="d-flex align-center">Field Options</div>
                  </th>
                  <th class="pl-2 pr-0"></th>
                </tr>
              </thead>

              <draggable
                :list="mapServiceFields"
                :disabled="!canManageLayers"
                tag="tbody"
                v-if="mapServiceFields.length > 0"
                @end="onDragEnd(mapServiceFields)"
              >
                <tr
                  v-for="field of getMapServiceFieldsByVisibility(
                    mapServiceFields,
                    showVisible
                  )"
                  :key="field.id"
                >
                  <td
                    class="white pl-1 pr-0 mx-0 cursor-grab"
                    v-if="showVisible && canManageLayers"
                  >
                    <v-icon>{{ mdiDragVertical }}</v-icon>
                  </td>
                  <td class="white pl-2 pr-0">
                    <v-checkbox
                      v-if="
                        [
                          UTILISYNC_FIELD_TYPES.FIELD,
                          UTILISYNC_FIELD_TYPES.ID,
                        ].includes(getFieldType(field))
                      "
                      v-model="visibilityCheckBoxesSelected[field.id]"
                      class="mx-0 mt-2 mb-n2"
                      :disabled="!canManageLayers"
                    >
                    </v-checkbox>
                  </td>
                  <template
                    v-if="getFieldType(field) === UTILISYNC_FIELD_TYPES.FIELD"
                  >
                    <td class="white pl-2 pr-0">
                      {{
                        getGisDataField(field.fieldId) &&
                        getGisDataField(field.fieldId).name
                      }}
                    </td>
                    <td class="white pl-2 pr-0">
                      {{
                        getGisDataField(field.fieldId) &&
                        getGisDataField(field.fieldId).type
                      }}
                    </td>
                    <td class="white pl-2 pr-0">
                      {{
                        getGisDataField(field.fieldId) &&
                        getGisDataField(field.fieldId).gis_data_field_options
                          .length > 0
                          ? "Yes"
                          : "No"
                      }}
                    </td>
                  </template>
                  <template
                    v-else-if="getFieldType(field) === UTILISYNC_FIELD_TYPES.ID"
                  >
                    <td class="white pl-2 pr-0">
                      {{ getIdFieldName(field.fieldId) }}
                    </td>
                    <td class="white pl-2 pr-0">string</td>
                    <td class="white pl-2 pr-0">No</td>
                  </template>
                  <template
                    v-else-if="
                      getFieldType(field) ===
                      UTILISYNC_FIELD_TYPES.SECTION_LABEL
                    "
                  >
                    <td class="white pl-2 pr-0">
                      <section
                        class="white d-flex align-center cursor-pointer"
                        v-if="!editingSectionLabel[field.id]"
                        @click="editSectionLabel(field)"
                      >
                        {{ field.sectionLabel }}
                      </section>
                      <section v-else class="white pl-2 pr-0">
                        <validation-provider
                          v-slot="{ errors, valid }"
                          name="Section Label"
                          rules="required"
                        >
                          <v-text-field
                            label="Section Label"
                            v-model="field.sectionLabel"
                            hide-details
                            :error-messages="errors"
                            :success="valid"
                            name="sectionLabel"
                          >
                          </v-text-field>

                          <div class="my-2 d-flex align-center justify-end gap">
                            <v-btn
                              class="mx-1 px-0"
                              text
                              @click="cancelEditSectionLabel(field)"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              color="primary"
                              @click="
                                $set(editingSectionLabel, field.id, false);
                                onSubmit();
                              "
                            >
                              Save
                            </v-btn>
                          </div>
                        </validation-provider>
                      </section>
                    </td>
                    <td class="white pl-2 pr-0" colspan="2"></td>
                  </template>
                  <td class="white pl-2 pr-0">
                    <div class="d-flex justify-end align-center">
                      <v-menu
                        class="white pl-2 pr-0"
                        v-if="
                          [
                            UTILISYNC_FIELD_TYPES.FIELD,
                            UTILISYNC_FIELD_TYPES.ID,
                          ].includes(getFieldType(field))
                        "
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            :disabled="!canManageLayers"
                          >
                            <v-icon color="primary">
                              {{ mdiDotsVertical }}
                            </v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item
                            class="cursor-pointer"
                            @click="
                              field.visible = !field.visible;
                              onUtiliSyncLayerSaved();
                            "
                            :disabled="!canManageLayers"
                          >
                            <v-list-item-title
                              class="primary--text text--lighten-1"
                            >
                              <v-icon color="primary">
                                {{ field.visible ? mdiEyeOff : mdiEye }}
                              </v-icon>
                              {{ field.visible ? "Hide" : "Show" }} UtiliSync
                              Field
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            v-if="
                              [UTILISYNC_FIELD_TYPES.FIELD].includes(
                                getFieldType(field)
                              )
                            "
                            @click="
                              showEditUtiliSyncFieldDialog = true;
                              selectedUtiliSyncField = getGisDataField(
                                field.fieldId
                              );
                            "
                          >
                            <v-list-item-title
                              class="primary--text text--lighten-1"
                            >
                              <v-icon color="primary">{{ mdiPencil }}</v-icon>
                              Edit
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            @click="deleteField(field)"
                            v-if="
                              [UTILISYNC_FIELD_TYPES.FIELD].includes(
                                getFieldType(field)
                              )
                            "
                          >
                            <v-list-item-title
                              class="primary--text text--lighten-1"
                            >
                              <v-icon color="primary">{{ mdiDelete }}</v-icon>
                              Delete
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>

                      <v-menu
                        class="white pl-2 pr-0"
                        v-else-if="
                          getFieldType(field) ===
                          UTILISYNC_FIELD_TYPES.SECTION_LABEL
                        "
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            :disabled="!canManageLayers"
                          >
                            <v-icon color="primary">
                              {{ mdiDotsVertical }}
                            </v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item @click="editSectionLabel(field)">
                            <v-list-item-title
                              class="primary--text text--lighten-1"
                            >
                              <v-icon color="primary">{{ mdiPencil }}</v-icon>
                              Edit Section Label
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="deleteSectionLabel(field.id)">
                            <v-list-item-title
                              class="primary--text text--lighten-1"
                            >
                              <v-icon color="primary">{{ mdiDelete }}</v-icon>
                              Delete Section Label
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </td>
                </tr>
              </draggable>
              <tbody v-else>
                <tr>
                  <td :colspan="showVisible ? 6 : 5" class="caption">
                    <div class="d-flex justify-center">
                      No UtiliSync Fields added yet.
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>

        <AddUtiliSyncFieldDialog
          v-if="showAddUtiliSyncFieldDialog"
          :showAddUtiliSyncFieldDialog="showAddUtiliSyncFieldDialog"
          :mapServiceId="mapServiceId"
          @add-utilisync-field-dialog-close="
            showAddUtiliSyncFieldDialog = false;
            $emit('edit-utilisync-field-dialog-close');
          "
          @add-utilisync-fields-dialog-added="onSubmit()"
          @field-name-set="checkFieldName"
        />

        <EditUtiliSyncFieldDialog
          v-if="showEditUtiliSyncFieldDialog"
          :showEditUtiliSyncFieldDialog="showEditUtiliSyncFieldDialog"
          :mapServiceId="mapServiceId"
          @edit-utilisync-field-dialog-close="
            showEditUtiliSyncFieldDialog = false
          "
          @edit-utilisync-fields-dialog-submitted="
            showEditUtiliSyncFieldDialog = false;
            onSubmit();
          "
          :selectedUtiliSyncField="selectedUtiliSyncField"
        />

        <DuplicateFieldNameDialog
          :showDuplicateFieldNameDialog="showDuplicateFieldNameDialog"
          @duplicate-field-mame-dialog-close="
            showDuplicateFieldNameDialog = false
          "
        />
      </v-card-text>
      <v-card-actions class="d-flex justify-end pa-5" ref="cardAction">
        <v-btn dark color="primary" @click="returnToUtilibot">
          Return to Utilibot
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AddUtiliSyncFieldDialog from "@/components/layers/edit-layer-form/shared/layer-details/edit-utilisync-fields-dialog/AddUtiliSyncFieldDialog";
import EditUtiliSyncFieldDialog from "@/components/layers/edit-layer-form/shared/layer-details/edit-utilisync-fields-dialog/EditUtiliSyncFieldDialog";
import DuplicateFieldNameDialog from "@/components/layers/edit-layer-form/shared/layer-details/edit-utilisync-fields-dialog/DuplicateFieldNameDialog";
import axios from "axios";
import {
  mdiDelete,
  mdiDotsVertical,
  mdiPencil,
  mdiDragVertical,
  mdiEyeOff,
  mdiEye,
  mdiSyncCircle,
} from "@mdi/js";
import contentHeightMixin from "@/mixins/contentHeightMixin";
import draggable from "vuedraggable";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep } from "lodash";
import permissionsMixin from "@/mixins/permissionsMixin";

const APIURL = process.env.VUE_APP_API_URL;
const UTILISYNC_FIELD_TYPES = {
  ID: "id",
  FIELD: "field",
  SECTION_LABEL: "section-label",
};

export default {
  name: "EditUtiliSyncFieldsDialog",
  props: {
    showUtiliSyncFieldsDialog: Boolean,
    mapServiceId: String,
  },
  components: {
    AddUtiliSyncFieldDialog,
    EditUtiliSyncFieldDialog,
    DuplicateFieldNameDialog,
    draggable,
  },
  mixins: [contentHeightMixin, permissionsMixin],
  data() {
    return {
      showAddUtiliSyncFieldDialog: false,
      showEditUtiliSyncFieldDialog: false,
      gisDataFields: [],
      mdiDelete,
      mdiDotsVertical,
      mdiPencil,
      mdiDragVertical,
      mdiEyeOff,
      mdiEye,
      mdiSyncCircle,
      showDuplicateFieldNameDialog: false,
      selectedUtiliSyncField: {},
      mapServiceFields: [],
      selectedMapService: {},
      editingSectionLabel: {},
      UTILISYNC_FIELD_TYPES,
      newSectionLabels: [],
      originalMapServiceFields: [],
      showVisible: true,
      visibilityCheckBoxesSelected: {},
    };
  },
  computed: {
    allSelected() {
      const values = Object.values(this.visibilityCheckBoxesSelected);
      return (
        values.length > 0 &&
        values.length ===
          this.mapServiceFields.filter(
            (f) =>
              f.visible === this.showVisible &&
              [UTILISYNC_FIELD_TYPES.FIELD, UTILISYNC_FIELD_TYPES.ID].includes(
                this.getFieldType(f)
              )
          ).length &&
        values.every((checked) => checked)
      );
    },
    someSelected() {
      const values = Object.values(this.visibilityCheckBoxesSelected);
      return values.length > 0 && values.some((checked) => checked);
    },
  },
  methods: {
    getIdFieldName(field) {
      if (field === "objectId") {
        return "Feature ID";
      } else if (field === "globalId") {
        return "Global ID";
      }
    },
    selectAll(checked) {
      const fieldsToCheck = this.mapServiceFields.filter(
        (f) =>
          f.visible === this.showVisible &&
          [UTILISYNC_FIELD_TYPES.FIELD, UTILISYNC_FIELD_TYPES.ID].includes(
            this.getFieldType(f)
          )
      );
      for (const field of fieldsToCheck) {
        this.$set(this.visibilityCheckBoxesSelected, field.id, checked);
      }
    },
    getMapServiceFieldsByVisibility(mapServiceFields, visible) {
      return mapServiceFields.filter((f) => f.visible === visible);
    },
    toggleFieldsVisibility() {
      const idsToChange = Object.keys(this.visibilityCheckBoxesSelected);
      this.mapServiceFields = [
        ...this.mapServiceFields.map((f) => {
          if (idsToChange.includes(f.id)) {
            return { ...f, visible: !f.visible };
          } else {
            return { ...f };
          }
        }),
      ];
      this.visibilityCheckBoxesSelected = {};
    },
    toggleVisible() {
      this.showVisible = !this.showVisible;
    },
    deleteSectionLabel(id) {
      const index = this.mapServiceFields.findIndex((f) => f.id === id);
      this.mapServiceFields.splice(index, 1);
      this.onUtiliSyncLayerSaved();
    },
    getFieldType(field) {
      if (field?.fieldId) {
        if (["objectId", "globalId"].includes(field?.fieldId)) {
          return UTILISYNC_FIELD_TYPES.ID;
        } else {
          return UTILISYNC_FIELD_TYPES.FIELD;
        }
      } else if (typeof field?.sectionLabel === "string") {
        return UTILISYNC_FIELD_TYPES.SECTION_LABEL;
      }
    },
    onUtiliSyncLayerSaved() {
      const { selectedMapService, mapServiceFields } = this;
      const layer = {
        ...selectedMapService,
        displayUtiliSyncFields: mapServiceFields,
      };
      this.$emit("utilisync-fields-saved", layer);
    },
    async addSectionLabel() {
      const newSectionLabel = {
        id: uuidv4(),
        sectionLabel: "Untitled Section Label",
        order: this.mapServiceFields.length + 1,
        visible: true,
        labels: [],
      };
      this.newSectionLabels.push(newSectionLabel);
      this.mapServiceFields = [...this.mapServiceFields, newSectionLabel];
      this.$set(this.editingSectionLabel, newSectionLabel.id, true);
      this.onUtiliSyncLayerSaved();
    },
    editSectionLabel(field) {
      this.originalMapServiceFields = cloneDeep(this.mapServiceFields);
      this.$set(this.editingSectionLabel, field.id, true);
    },
    async cancelEditSectionLabel(field) {
      this.$set(this.editingSectionLabel, field.id, false);
      const isNewSectionLabel = this.newSectionLabels
        .map((l) => l.id)
        .includes(field.id);
      if (isNewSectionLabel) {
        const sectionLabelIndex = this.mapServiceFields.findIndex(
          (f) => f.id === field.id
        );
        this.mapServiceFields.splice(sectionLabelIndex, 1);
        this.mapServiceFields = [
          ...this.mapServiceFields.map((f, i) => {
            {
              return { ...f, order: i + 1 };
            }
          }),
        ];
        const newSectionLabelIndex = this.newSectionLabels.findIndex(
          (f) => f.id === field.id
        );
        this.newSectionLabels.splice(newSectionLabelIndex, 1);
      } else {
        this.mapServiceFields = cloneDeep(this.originalMapServiceFields);
      }
      this.onUtiliSyncLayerSaved();
    },
    async returnToUtilibot() {
      this.onUtiliSyncLayerSaved();
      this.$emit("edit-utilisync-fields-dialog-close");
    },
    onDragEnd(reorderedMapServiceFields) {
      if (!this.canManageLayers) {
        return;
      }
      const visibleFields = reorderedMapServiceFields.filter((f) => f.visible);
      const hiddenFields = reorderedMapServiceFields.filter((f) => !f.visible);
      this.mapServiceFields = [
        ...visibleFields.map((f, i) => {
          return { ...f, order: i + 1 };
        }),
        // eslint-disable-next-line no-unused-vars
        ...hiddenFields.map(({ order, ...rest }) => rest),
      ];
      this.onUtiliSyncLayerSaved();
    },
    checkFieldName(fieldName) {
      const fieldNameAlreadyExists = this.gisDataFields.find(
        (f) => f.name === fieldName
      );
      if (fieldNameAlreadyExists) {
        this.showDuplicateFieldNameDialog = true;
      }
    },
    async getGisDataFields() {
      const { mapServiceId } = this;
      if (!mapServiceId) {
        return;
      }
      const {
        data: { results },
      } = await axios.get(`${APIURL}/gis_data_fields`, {
        params: {
          map_service_id: mapServiceId,
        },
      });
      this.gisDataFields = results;
      this.$emit("get-gis-data-fields", this.gisDataFields.length);
    },
    async onSubmit() {
      await this.saveMapService();
      await this.getData();
      this.onUtiliSyncLayerSaved();
      this.showAddUtiliSyncFieldDialog = false;
      this.showEditUtiliSyncFieldDialog = false;
    },
    async getMapService() {
      const { mapServiceId } = this;
      if (!mapServiceId) {
        return;
      }
      const {
        data: { results },
      } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
      this.selectedMapService = { ...results };
      const idFields = [
        {
          id: uuidv4(),
          fieldId: "objectId",
          order: 0,
          visible: true,
          labels: [],
        },
        {
          id: uuidv4(),
          fieldId: "globalId",
          order: 1,
          visible: true,
          labels: [],
        },
      ];
      if (!Array.isArray(results.display_utilisync_fields)) {
        const fields = this.gisDataFields.map((f, i) => {
          const { gis_data_field_id: fieldId } = f;
          return {
            id: uuidv4(),
            fieldId,
            order: idFields.length + i + 1,
            visible: true,
            labels: [],
          };
        });
        this.mapServiceFields = [...idFields, ...fields];
      } else {
        const { display_utilisync_fields: displayUtiliSyncFields } = results;
        const displayUtiliSyncFieldIds = displayUtiliSyncFields.map(
          (f) => f.fieldId
        );
        const fieldsNotAdded = this.gisDataFields
          .filter(
            (f) => !displayUtiliSyncFieldIds.includes(f.gis_data_field_id)
          )
          .map((f) => {
            const { gis_data_field_id: fieldId } = f;
            return {
              id: uuidv4(),
              fieldId,
              visible: true,
              labels: [],
            };
          });
        this.mapServiceFields = displayUtiliSyncFields.sort(
          (a, b) => a.order - b.order
        );
        this.mapServiceFields = [
          ...this.mapServiceFields,
          ...fieldsNotAdded,
        ].map((f, i) => {
          return { ...f, order: i + 1 };
        });
      }
    },
    getGisDataField(gisDataFieldId) {
      return this.gisDataFields.find(
        (f) => f.gis_data_field_id === gisDataFieldId
      );
    },
    async deleteField(field) {
      const index = this.mapServiceFields.findIndex(
        (f) => f.fieldId === field.fieldId
      );
      this.mapServiceFields.splice(index, 1);
      if (field?.fieldId) {
        await axios.delete(`${APIURL}/gis_data_fields/${field?.fieldId}`);
      }
      const visibleFields = this.mapServiceFields.filter((f) => f.visible);
      const hiddenFields = this.mapServiceFields.filter((f) => !f.visible);
      this.mapServiceFields = [
        ...visibleFields.map((f, i) => {
          return { ...f, order: i + 1 };
        }),
        // eslint-disable-next-line no-unused-vars
        ...hiddenFields.map(({ order, ...rest }) => rest),
      ];
      await this.saveMapService();
      await this.getData();
      this.onUtiliSyncLayerSaved();
    },
    async saveMapService() {
      const { mapServiceId, selectedMapService, mapServiceFields } = this;
      const layer = {
        ...selectedMapService,
        display_utilisync_fields: mapServiceFields,
      };
      await axios.put(`${APIURL}/map_services/${mapServiceId}`, layer);
      this.onUtiliSyncLayerSaved();
    },
    async getData() {
      await this.getGisDataFields();
      await this.getMapService();
    },
  },
  beforeMount() {
    this.getData();
  },
};
</script>
