<template>
  <validation-observer ref="headerForm">
    <v-toolbar color="primary" height="56px" class="elevation-0" ref="toolbar">
      <v-list-item two-line class="px-0">
        <v-list-item-content>
          <div class="d-flex justify-space-between">
            <v-list-item-title class="white--text text-h6">
              {{ selectedControlType | itemTitle(selectedItem) }}
            </v-list-item-title>
            <v-btn
              icon
              color="white"
              @click="$emit('form-control-form-submitted')"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-toolbar>
    <form
      @submit.prevent="
        onSubmit();
        $emit('form-control-form-submitted');
      "
    >
      <v-list
        dense
        style="max-height: calc(100vh - 56px)"
        class="overflow-y-auto"
      >
        <v-list-item
          v-if="['HEADER'].includes(selectedItemOptions.information.type)"
        >
          <v-list-item-content>
            <validation-provider
              v-slot="{ errors, valid }"
              name="Label"
              rules="required"
            >
              <v-text-field
                v-model="selectedItemOptions.information.label"
                label="Label"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="name"
                @change="onSubmit()"
                ref="labelInput"
                :disabled="!canManageForms"
              />
            </validation-provider>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="['TEXT'].includes(selectedItemOptions.information.type)"
        >
          <v-list-item-content class="mt-3 pt-0">
            <v-card class="elevation-0 d-flex justify-space-between">
              <div>
                <div class="d-flex">
                  <p
                    class="caption my-0"
                    :style="
                      canManageForms ? 'color: #4caf50' : 'color: #aaaaaa'
                    "
                  >
                    Text
                  </p>
                  <v-icon
                    :disabled="!canManageForms"
                    size="16"
                    class="ml-1"
                    color="primary"
                    @click="showTextDialog = true"
                  >
                    {{ mdiPencil }}
                  </v-icon>
                </div>

                <p
                  v-html="selectedItemOptions.information.label"
                  class="my-0 cursor-pointer"
                  @click="showTextDialog = true"
                ></p>
              </div>

              <v-dialog
                v-model="showTextDialog"
                max-width="600px"
                :retain-focus="false"
                :fullscreen="$vuetify.breakpoint.xsOnly"
              >
                <TextForm
                  @text-form-close="showTextDialog = false"
                  @input="
                    showTextDialog = false;
                    $emit('input', selectedItemOptions);
                  "
                  v-model="selectedItemOptions.information.label"
                />
              </v-dialog>
            </v-card>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="selectedItemOptions.information.type === 'URL'">
          <v-list-item-content>
            <validation-provider
              v-slot="{ errors, valid }"
              name="URL"
              rules="required"
            >
              <v-text-field
                v-model="selectedItemOptions.information.url"
                label="URL"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="name"
                hint="Input a valid URL"
                @change="onSubmit()"
                :disabled="!canManageForms"
              />
            </validation-provider>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="selectedItemOptions.information.type === 'URL'">
          <v-list-item-content>
            <validation-provider
              v-slot="{ errors, valid }"
              name="Label"
              rules="required"
            >
              <v-text-field
                v-model="selectedItemOptions.information.label"
                label="Label"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="name"
                hint="Input a valid URL"
                @change="onSubmit()"
                :disabled="!canManageForms"
              />
            </validation-provider>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="selectedItemOptions.information.type === 'IMAGE'">
          <v-list-item-content>
            <validation-provider v-slot="{ errors, valid }" name="Label">
              <v-text-field
                v-model="selectedItemOptions.information.label"
                label="Label"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="name"
                @change="onSubmit()"
                :disabled="!canManageForms"
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Image URL"
              rules="required"
            >
              <v-text-field
                v-model="selectedItemOptions.information.url"
                label="Image URL"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="name"
                hint="Input a valid URL"
                @change="onSubmit()"
                :disabled="!canManageForms"
              />
            </validation-provider>

            <validation-provider v-slot="{ errors, valid }" name="Image URL">
              <v-text-field
                v-model="selectedItemOptions.information.openUrl"
                label="Open URL"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="name"
                hint="Input a valid URL"
                @change="onSubmit()"
                :disabled="!canManageForms"
              />
            </validation-provider>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <AdvancedInformationSettingsForm
              v-model="selectedItemOptions"
              :formDefinition="formDefinition"
              :selectedItem="selectedItem"
              :showDefaultChoice="false"
              :showRequireChoice="false"
              :showCreateChoice="false"
              :layer="layer"
              @input="onSubmit()"
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <div class="d-flex flex-wrap gap" v-if="hasAdvancedSettings">
              <section
                class="text-uppercase d-flex primary--text gap align-center"
                v-if="hasVisibleSettings"
                @click="
                  if (canManageForms) {
                    showAdvancedInfoVisibilitySettingsDialog = true;
                  }
                "
              >
                <div>Visible {{ visibleSetting }}</div>

                <v-btn icon :disabled="!canManageForms">
                  <v-icon color="primary">
                    {{ mdiPencil }}
                  </v-icon>
                </v-btn>
              </section>
            </div>
            <div v-else class="d-flex align-center justify-center">
              No advanced settings
            </div>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="mt-0 mb-0 pt-0 pb-0">
            <v-btn
              color="primary"
              type="submit"
              width="100%"
              :disabled="!canManageForms"
            >
              Update Item
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </form>
    <AdvancedInfoVisibilitySettingsDialog
      v-if="showAdvancedInfoVisibilitySettingsDialog"
      :showAdvancedInfoVisibilitySettingsDialog="
        showAdvancedInfoVisibilitySettingsDialog
      "
      :formDefinition="formDefinition"
      :layer="layer"
      :selectedItem="selectedItem"
      @advanced-settings-dialog-close="
        showAdvancedInfoVisibilitySettingsDialog = false
      "
      v-model="selectedItemOptions"
      @input="onSubmit()"
    />
  </validation-observer>
</template>

<script>
import {
  visibilityOptions,
  comparisonTypes,
  createOptions,
} from "@/constants/choices";
import { cloneDeep } from "lodash";
import TextForm from "./information-form/TextForm";
import { mdiPencil } from "@mdi/js";
import sleep from "@/mixins/sleep";
import AdvancedInfoVisibilitySettingsDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/AdvancedInfoVisibilitySettingsDialog.vue";
import AdvancedInformationSettingsForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/AdvancedInformationSettingsForm.vue";
import {
  APPLY_IF,
  DEPENDANT_OPTIONS,
  CONDITION_CHOICES,
} from "@/constants/advancedSettings";
import { v4 as uuidv4 } from "uuid";
import MATCH_CHOICE_VALUES from "@/constants/matchChoiceValues";
import axios from "axios";
import permissionsMixin from "@/mixins/permissionsMixin";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "InformationForm",
  components: {
    TextForm,
    AdvancedInfoVisibilitySettingsDialog,
    AdvancedInformationSettingsForm,
  },
  props: {
    selectedItem: Object,
    formDefinition: Object,
    selectedControlType: String,
  },
  computed: {
    selectedVisibleDependantQuestion() {
      const { dependantId } = this.selectedItemOptions.information.visible;
      const formDefinition = { ...this.formDefinition };
      const sectionItems = formDefinition.form.sections
        .map((s) => s.items)
        .flat();
      return sectionItems.find(({ id }) => id === dependantId);
    },
    dependantOptions() {
      const formDefinition = { ...this.formDefinition };
      const sectionItems = formDefinition.form.sections.map((s) => s.items);
      return sectionItems
        .flat()
        .filter(({ id, type: itemType }) => {
          return id !== this.selectedItem.id && itemType !== "INFORMATION";
        })
        .map(({ id, number, question: { label } = {} }) => ({
          value: id,
          label: `${number} ${label}`,
        }));
    },
    visibleComparisonOptions() {
      const formDefinition = { ...this.formDefinition };
      const items = formDefinition.form.sections.map((s) => s.items).flat();
      const dependantItem = items.find(
        ({ id }) =>
          id === this.selectedItemOptions.information.visible.dependantId
      );
      if (
        dependantItem &&
        dependantItem.information &&
        dependantItem.information.type === "NUMBER"
      ) {
        return comparisonTypes;
      }
      return [
        {
          value: "NOT_EQUAL",
          label: "Not Equal",
        },
        {
          value: "EQUAL",
          label: "Equal",
        },
      ];
    },
    hasAdvancedSettings() {
      const { hasVisibleSettings } = this;
      return hasVisibleSettings;
    },
    hasVisibleSettings() {
      return (
        this.selectedItemOptions.information.visible?.applyVisible !==
        CONDITION_CHOICES.ALWAYS
      );
    },
    visibleSetting() {
      if (
        this.selectedItemOptions?.information?.visible?.applyVisible ===
        "ALWAYS"
      ) {
        return "";
      }
      return this.selectedItemOptions?.information?.visible?.applyVisible;
    },
  },
  mixins: [permissionsMixin],
  data() {
    return {
      selectedItemOptions: {
        information: {},
      },
      visibilityOptions,
      showTextDialog: false,
      mdiPencil,
      createOptions,
      showAdvancedInfoVisibilitySettingsDialog: false,
      layer: {},
    };
  },
  methods: {
    async getLayer() {
      const { map_service_id: mapServiceId } = this.formDefinition;
      const {
        data: { results },
      } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
      this.layer = results;
    },
    async onSubmit() {
      const success = await this.$refs.headerForm?.validate();
      if (!success) {
        return;
      }
      this.$emit("input", this.selectedItemOptions);
    },
    getSelectedItemOptions() {
      this.selectedItemOptions = cloneDeep(this.selectedItem);

      if (!this.selectedItemOptions?.information?.visible) {
        this.selectedItemOptions.information.visible = {
          applyVisible: null,
          applyVisibleConditions: { conditions: [], applyIf: APPLY_IF.ANY },
        };
      }
      const { condition, dependantId, value } =
        this.selectedItemOptions?.information?.visible ?? {};

      if (
        !this.selectedItemOptions.information?.visible?.applyVisibleConditions
      ) {
        this.selectedItemOptions.information.visible.applyVisibleConditions = {
          applyIf: APPLY_IF.ANY,
          conditions: [
            {
              id: uuidv4(),
              type: null,
              field: null,
              value: null,
              condition: MATCH_CHOICE_VALUES.EQUAL,
            },
          ],
        };
      }

      if (condition) {
        this.selectedItemOptions.information.visible.applyVisibleConditions.conditions =
          [
            {
              type: DEPENDANT_OPTIONS.FORM_ITEM,
              field: dependantId,
              condition,
              value,
            },
          ];
      }

      if (
        !this.selectedItemOptions.information?.visible?.applyVisibleConditions
          .applyIf
      ) {
        this.selectedItemOptions.information.visible.applyVisibleConditions.applyIf =
          APPLY_IF.ANY;
      }

      this.selectedItemOptions.information.visible.applyVisibleConditions.conditions =
        this.selectedItemOptions.information.visible.applyVisibleConditions.conditions.map(
          (c) => {
            const { type, field, value, condition } = c;
            return {
              id: uuidv4(),
              type,
              field,
              value,
              condition,
            };
          }
        );

      if (
        this.selectedItemOptions?.information?.create &&
        !["ALWAYS"].includes(
          this.selectedItemOptions?.information?.create?.condition
        )
      ) {
        this.createCondition = "conditional";
      } else {
        this.createCondition =
          this.selectedItemOptions.information?.visible?.condition;
      }

      if (!this.selectedItemOptions.information.default) {
        this.selectedItemOptions.information.default = {};
      }
    },
  },
  watch: {
    createCondition(val) {
      if (["ALWAYS"].includes(val)) {
        this.selectedItemOptions.information.create.condition = val;
        this.selectedItemOptions.information.create.dependent = undefined;
        this.selectedItemOptions.information.create.dependentFieldId =
          undefined;
        this.selectedItemOptions.information.create.value = undefined;
      }
    },
  },
  beforeMount() {
    this.getSelectedItemOptions();
    this.getLayer();
  },
  async mounted() {
    if (!this.selectedItemOptions.information.label) {
      await this.$nextTick();
      await sleep(500);
      if (["HEADER"].includes(this.selectedItemOptions.information?.type)) {
        this.$refs.labelInput.$refs.input.focus();
      }
    }
  },
};
</script>
