var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "caption" }, [_vm._v("Layer Info")]),
      _c(
        "v-card",
        { attrs: { id: "layerInfo" } },
        [
          _c(
            "v-card-text",
            [
              _c("p", { staticClass: "caption mb-0 pb-0" }, [
                _vm._v("Layer Type"),
              ]),
              _c("p", [
                _vm._v(
                  _vm._s(_vm._f("layerType")(_vm.selectedLayer.service_type))
                ),
              ]),
              _c("validation-observer", { ref: "layerForm" }, [
                _c(
                  "form",
                  [
                    _c("validation-provider", {
                      attrs: { name: "Layer Name", rules: "required" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ errors, valid }) {
                            return [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Layer Name *",
                                  "hide-details": "auto",
                                  "error-messages": errors,
                                  success: valid,
                                  color: "primary",
                                  name: "serviceName",
                                  disabled: !_vm.canManageLayers,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$emit(
                                      "layer-changed",
                                      _vm.selectedLayer
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.selectedLayer.service_name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.selectedLayer,
                                      "service_name",
                                      $$v
                                    )
                                  },
                                  expression: "selectedLayer.service_name",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c(
                      "div",
                      { staticStyle: { width: "40%" } },
                      [
                        ["U", "F"].includes(_vm.selectedLayer.service_type)
                          ? _c("validation-provider", {
                              attrs: { name: "Reference Field" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            clearable: "",
                                            label: "Reference Field",
                                            "hide-details": "auto",
                                            "error-messages": errors,
                                            success: valid,
                                            color: "primary",
                                            name: "refField",
                                            "item-text": "label",
                                            "item-value": "value",
                                            items: _vm.gisDataFieldChoices,
                                            disabled: !_vm.canManageLayers,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$emit(
                                                "layer-changed",
                                                _vm.selectedLayer
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.selectedLayer.ref_field,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.selectedLayer,
                                                "ref_field",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "selectedLayer.ref_field",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4166221547
                              ),
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "d-flex flex-wrap gap" }, [
                      _c(
                        "section",
                        [
                          ["U", "F"].includes(_vm.selectedLayer.service_type)
                            ? _c("v-switch", {
                                attrs: {
                                  label: "Stormwater Enabled",
                                  disabled: !_vm.canManageLayers,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$emit(
                                      "layer-changed",
                                      _vm.selectedLayer
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.selectedLayer.stormwater_enabled,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.selectedLayer,
                                      "stormwater_enabled",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "selectedLayer.stormwater_enabled",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "section",
                        [
                          ["U", "F"].includes(_vm.selectedLayer.service_type)
                            ? _c("v-switch", {
                                attrs: {
                                  label: "Site Enabled",
                                  disabled: !_vm.canManageLayers,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$emit(
                                      "layer-changed",
                                      _vm.selectedLayer
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.selectedLayer.site_enabled,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.selectedLayer,
                                      "site_enabled",
                                      $$v
                                    )
                                  },
                                  expression: "selectedLayer.site_enabled",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    ["L"].includes(_vm.selectedLayer.service_type)
                      ? _c("validation-provider", {
                          attrs: { name: "811 Code" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ errors, valid }) {
                                  return [
                                    _c("v-select", {
                                      attrs: {
                                        label: "811 Code",
                                        "hide-details": "auto",
                                        "error-messages": errors,
                                        success: valid,
                                        color: "primary",
                                        name: "account811",
                                        items: _vm.account811Choices,
                                        "item-text": "label",
                                        "item-value": "value",
                                        disabled: !_vm.canManageLayers,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$emit(
                                            "layer-changed",
                                            _vm.selectedLayer
                                          )
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.selectedLayer
                                            .locate_request_provider_account_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.selectedLayer,
                                            "locate_request_provider_account_id",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "selectedLayer.locate_request_provider_account_id",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3594865486
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }