<template>
  <v-dialog
    v-model="showDeleteDialog"
    max-width="600px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card class="pt-5">
      <v-card-text>
        Are you sure you want to delete this form? This cannot be undone.
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="$emit('cancel-confirm-delete-form')"
        >
          Cancel
        </v-btn>
        <v-btn color="primary" text @click="deleteItem(itemToDelete)">
          Delete Form
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "ConfirmDeleteFormDialog",
  methods: {
    async deleteItem({ form_definition_id: formDefintionId }) {
      await axios.delete(`${APIURL}/form_definitions/${formDefintionId}`);
      this.$emit("form-deleted");
    },
  },
  props: {
    showDeleteDialog: Boolean,
    itemToDelete: Object,
  },
};
</script>
