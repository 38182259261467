<template>
  <validation-observer ref="form">
    <form>
      <v-list style="max-height: calc(100vh - 56px)" class="overflow-y-auto">
        <v-list-item class="py-1">
          <validation-provider v-slot="{ errors, valid }" name="Filter">
            <v-text-field
              v-model="filterText"
              label="Form Placeholder"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="filterText"
            />
          </validation-provider>
        </v-list-item>

        <v-list-item
          v-for="item of questionChoices"
          :key="item.id"
          class="question d-block"
          @click="copyToClipboard(item.id, `{{col.${item.id}}}`)"
        >
          <transition name="slide-fade">
            <p v-if="showCopyAnimation[item.id]" class="absolute">
              <span class="param mr-1" v-html="`{{col.${item.id}}}`"> </span>
              {{ item.question.label }}
            </p>
          </transition>
          <p>
            <span class="param mr-1" v-html="`{{col.${item.id}}}`"> </span>
            {{ item.question.label }}
          </p>
        </v-list-item>
      </v-list>
    </form>
  </validation-observer>
</template>

<script>
import questionsMixin from "@/mixins/questionsMixin";
import { cloneDeep } from "lodash";

export default {
  name: "GroupFormPlaceholdersForm",
  mixins: [questionsMixin],
  props: {
    groupItems: Array,
    type: String,
  },
  data() {
    return {
      filterText: "",
      showCopyAnimation: {},
    };
  },
  computed: {
    groupItemsWithOnlyQuestions() {
      const groupItems = cloneDeep(this.groupItems);
      return groupItems.filter(
        ({ question }) => typeof question === "object" && question !== null
      );
    },
    questionChoices() {
      const { groupItemsWithOnlyQuestions, filterQuestionsByType } = this;
      return filterQuestionsByType(groupItemsWithOnlyQuestions, [
        "NUMBER",
        "SINGLE_SELECT",
        "CALCULATION",
      ]);
    },
  },
  methods: {
    filterQuestionsByType(groupItems, questionTypes) {
      return groupItems.filter(({ question }) => {
        return questionTypes.includes(question?.type);
      });
    },
    async copyToClipboard(id, text) {
      await navigator.clipboard.writeText(text);
      this.$emit("placeholder-copied");
      this.$set(this.showCopyAnimation, id, true);
      setTimeout(() => {
        this.$set(this.showCopyAnimation, id, false);
      }, 1000);
    },
  },
};
</script>

<style scoped>
.param {
  color: rgb(33, 150, 243);
}

.question {
  cursor: pointer;
}

.absolute {
  position: absolute;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  animation: slide-fade 0.5s;
}

.slide-fade-enter,
.slide-fade-leave-to {
  animation: slide-fade 0.5s;
  opacity: 0;
}

@keyframes slide-fade {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-3px);
  }
  50% {
    transform: translateY(-6px);
  }
  75% {
    transform: translateY(-9px);
  }
  100% {
    transform: translateY(-10px);
  }
}
</style>
