<template>
  <validation-observer ref="positiveResponseForm" v-slot="{ invalid }">
    <v-toolbar color="primary" height="56px" class="elevation-0" ref="toolbar">
      <v-list-item two-line class="px-0">
        <v-list-item-content>
          <div class="d-flex justify-space-between">
            <v-list-item-title class="white--text text-h6">
              {{ selectedControlType | itemTitle(selectedItem) }}
            </v-list-item-title>
            <v-btn
              icon
              color="white"
              @click="$emit('form-control-form-submitted')"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-toolbar>
    <form @submit.prevent="updateItem()">
      <v-list
        dense
        style="max-height: calc(100vh - 56px)"
        class="overflow-y-auto"
      >
        <v-list-item>
          <v-list-item-content>
            <validation-provider
              v-slot="{ errors, valid }"
              name="Label"
              rules="required"
            >
              <v-text-field
                v-model="selectedItemOptions.question.label"
                label="Label"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="name"
                ref="labelInput"
                @input="onSubmit()"
                :disabled="!canManageForms"
              />
            </validation-provider>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="caption">
            <b>Positive Response Configuration</b>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <validation-provider
              v-slot="{ errors, valid }"
              name="Call Center"
              rules="required"
            >
              <v-autocomplete
                autocomplete="off"
                label="Select One Call Center"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="callCenter"
                :items="locateRequestProvidersChoices"
                item-text="label"
                item-value="value"
                v-model="selectedItemOptions.question.locateRequestProviderId"
                @change="onSubmit()"
                :disabled="!canManageForms"
              >
              </v-autocomplete>
            </validation-provider>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="
            [
              VIRGINIA_LOCATE_REQUEST_PROVIDER_ID,
              OHIO_LOCATE_REQUEST_PROVIDER_ID,
            ].includes(selectedItemOptions.question.locateRequestProviderId)
          "
          class="my-0 py-0"
        >
          <v-list-item-content class="my-0 py-1">
            <v-switch
              v-model="selectedItemOptions.question.addShowCodes"
              label="Add Show Codes"
              @change="onSubmit()"
              hide-details
              class="my-0 py-0"
              :disabled="!canManageForms"
            >
            </v-switch>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="
            [
              VIRGINIA_LOCATE_REQUEST_PROVIDER_ID,
              OHIO_LOCATE_REQUEST_PROVIDER_ID,
            ].includes(selectedItemOptions.question.locateRequestProviderId) &&
            selectedItemOptions.question.addShowCodes
          "
        >
          <v-list-item-content class="py-1 my-0">
            <section
              :key="showCode.id"
              v-for="(showCode, index) of showCodes"
              class="d-flex align-center gap"
            >
              <validation-provider
                v-slot="{ errors, valid }"
                name="Show Codes"
                rules="required"
              >
                <v-text-field
                  v-model="showCode.showCode"
                  label="Show Code"
                  hide-details
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="showCode"
                  @input="onSubmit()"
                  :disabled="!canManageForms"
                >
                </v-text-field>
              </validation-provider>

              <validation-provider v-slot="{ errors, valid }" name="Label">
                <v-text-field
                  v-model="showCode.label"
                  label="Label"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="showCodeLabel"
                  @input="onSubmit()"
                  :disabled="!canManageForms"
                >
                </v-text-field>
              </validation-provider>

              <v-icon
                color="#093637"
                @click="addShowCode"
                :disabled="!canManageForms"
                v-if="allShowCodesPresent && index === showCodes.length - 1"
              >
                mdi-plus
              </v-icon>
              <v-icon
                color="red"
                @click="removeShowCode(index)"
                v-if="showCodes.length > 1"
                :disabled="!canManageForms"
              >
                mdi-minus
              </v-icon>
            </section>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="my-0 py-0">
          <v-list-item-content class="my-0 py-1">
            <v-switch
              v-model="selectedItemOptions.question.showAllOptions"
              label="Show All Choices at Once"
              @change="onSubmit()"
              hide-details
              class="my-0 py-0"
              :disabled="!canManageForms"
            >
            </v-switch>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="my-0 pa-0">
            <v-btn
              text
              @click="showEditPositiveResponseChoicesDialog = true"
              color="primary"
              class="px-0"
              :disabled="!canManageForms"
            >
              Edit Responses
            </v-btn>

            <EditPositiveResponseChoicesDialog
              v-if="showEditPositiveResponseChoicesDialog"
              @close-dialog="showEditPositiveResponseChoicesDialog = false"
              :showEditPositiveResponseChoicesDialog="
                showEditPositiveResponseChoicesDialog
              "
              :formDefinition="formDefinition"
              :locateRequestProviderId="
                selectedItemOptions.question.locateRequestProviderId
              "
              v-model="selectedItemOptions"
              @input="onSubmit()"
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="caption">
            <b>Comments Configuration</b>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-switch
              v-model="customComments"
              label="Custom Comments"
              @change="onSubmit()"
              hide-details
              class="my-0 py-0"
              :disabled="!canManageForms"
            >
            </v-switch>
          </v-list-item-content>
        </v-list-item>

        <template v-if="customComments">
          <v-list-item>
            <v-list-item-content>
              <v-switch
                v-model="selectedItemOptions.question.showAllComments"
                label="Show All Comments at Once"
                @change="onSubmit()"
                hide-details
                class="my-0 py-0"
                :disabled="!canManageForms"
              >
              </v-switch>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <validation-provider
                v-slot="{ errors, valid }"
                name="Default Comment"
              >
                <v-autocomplete
                  autocomplete="off"
                  label="Default Comment"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="defaultComment"
                  :items="commentChoices"
                  item-text="label"
                  item-value="value"
                  v-model="selectedItemOptions.question.defaultComment"
                  @change="onSubmit()"
                  :disabled="!canManageForms"
                >
                </v-autocomplete>
              </validation-provider>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content class="my-0 pa-0">
              <v-btn
                text
                @click="showEditPositiveResponseCommentChoicesDialog = true"
                color="primary"
                class="px-0"
                :disabled="!canManageForms"
              >
                Edit Comments
              </v-btn>

              <EditPositiveResponseCommentChoicesDialog
                v-if="showEditPositiveResponseCommentChoicesDialog"
                @close-dialog="
                  showEditPositiveResponseCommentChoicesDialog = false
                "
                :showEditPositiveResponseCommentChoicesDialog="
                  showEditPositiveResponseCommentChoicesDialog
                "
                :formDefinition="formDefinition"
                v-model="selectedItemOptions"
                @input="
                  updateDefaultComment();
                  onSubmit();
                "
              />
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item class="my-0 py-0">
          <v-list-item-content class="my-0 py-1">
            <v-btn
              color="primary"
              type="submit"
              width="100%"
              :disabled="invalid || !canManageForms"
            >
              Update Item
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </form>
  </validation-observer>
</template>

<script>
import { comparisonTypes } from "@/constants/choices";
import { mdiDragVertical, mdiDelete, mdiPencil } from "@mdi/js";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep } from "lodash";
import axios from "axios";
import sleep from "@/mixins/sleep";
import EditPositiveResponseChoicesDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/positive-response-form/EditPositiveResponseChoicesDialog.vue";
import EditPositiveResponseCommentChoicesDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/positive-response-form/EditPositiveResponseCommentChoicesDialog.vue";
import permissionsMixin from "@/mixins/permissionsMixin";

const APIURL = process.env.VUE_APP_API_URL;
const VIRGINIA_LOCATE_REQUEST_PROVIDER_ID =
  "c0e6ad40-0e30-4056-a3bc-ae02861e78ae";
const COLORADO_LOCATE_REQUEST_PROVIDER_ID =
  "6eafad96-9001-4986-a750-801e1b317e9d";
const OHIO_LOCATE_REQUEST_PROVIDER_ID = "f96a5f32-a6b0-485b-8553-b6328e8e5427";

export default {
  name: "PositiveResponseForm",
  props: {
    selectedItem: Object,
    formDefinition: Object,
    edit: Boolean,
    selectedControlType: String,
    multiSelect: Boolean,
  },
  components: {
    EditPositiveResponseChoicesDialog,
    EditPositiveResponseCommentChoicesDialog,
  },
  mixins: [permissionsMixin],
  data() {
    return {
      comparisonTypes,
      mdiDelete,
      mdiPencil,
      mdiDragVertical,
      showEditPositiveResponseChoicesDialog: false,
      selectedOption: {},
      selectedOptionIndex: undefined,
      selectedItemOptions: {
        question: {},
      },
      oldSelectedItemOptions: {
        question: {
          default: {},
        },
      },
      layer: {},
      fields: [],
      locateRequestProviders: [],
      VIRGINIA_LOCATE_REQUEST_PROVIDER_ID,
      COLORADO_LOCATE_REQUEST_PROVIDER_ID,
      OHIO_LOCATE_REQUEST_PROVIDER_ID,
      positiveResponses: [],
      accounts811: [],
      showCodes: [{ id: uuidv4(), showCode: "", label: "" }],
      customComments: false,
      showEditPositiveResponseCommentChoicesDialog: false,
    };
  },
  computed: {
    commentChoices() {
      return this.selectedItemOptions.question.comments.map((c) => {
        return { label: c, value: c };
      });
    },
    allShowCodesPresent() {
      return this.showCodes.every((a) => a.showCode);
    },
    locateRequestProvidersChoices() {
      return this.locateRequestProviders
        .map((l) => {
          const { locate_request_provider_id: value, name: label } = l;
          return { value, label };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
    },
    layerType() {
      return this.layer.service_type;
    },
    selectedVisibleDependantQuestion() {
      const { dependantId } = this.selectedItemOptions.question.visible;
      const formDefinition = { ...this.formDefinition };
      const sectionItems = formDefinition.form.sections
        ?.map((s) => s.items)
        ?.flat();
      return sectionItems.find(({ id }) => id === dependantId);
    },
    selectedRequiredDependantQuestion() {
      const { dependantId } = this.selectedItemOptions.question.required;
      const formDefinition = { ...this.formDefinition };
      const sectionItems = formDefinition.form.sections
        ?.map((s) => s.items)
        ?.flat();
      return sectionItems.find(({ id }) => id === dependantId);
    },
    fieldChoices() {
      const fieldChoices = this.fields?.map(({ name, alias }) => ({
        value: name,
        label: alias || name,
      }));
      return fieldChoices;
    },
    dependantOptions() {
      const formDefinition = { ...this.formDefinition };
      const sectionItems = formDefinition.form.sections.map((s) => s.items);
      return sectionItems
        .flat()
        .filter(({ id, type: itemType }) => {
          return id !== this.selectedItem.id && itemType !== "INFORMATION";
        })
        .map(({ id, number, question: { label } = {} }) => ({
          value: id,
          label: `${number} ${label}`,
        }));
    },
    visibleComparisonOptions() {
      const formDefinition = { ...this.formDefinition };
      const items = formDefinition.form.sections.map((s) => s.items).flat();
      const dependantItem = items.find(
        ({ id }) => id === this.selectedItemOptions.question.visible.dependantId
      );
      if (dependantItem?.question?.type === "NUMBER") {
        return comparisonTypes;
      }
      return [
        {
          value: "NOT_EQUAL",
          label: "Not Equal",
        },
        {
          value: "EQUAL",
          label: "Equal",
        },
      ];
    },
  },
  methods: {
    addShowCode() {
      const showCode = {
        id: uuidv4(),
        value: "",
      };
      this.showCodes.push(showCode);
    },
    removeShowCode(index) {
      this.showCodes.splice(index, 1);
      this.onSubmit();
    },
    async getPositiveResponses(locateRequestProviderId) {
      const {
        data: { results },
      } = await axios.get(
        `${APIURL}/positive_response_codes/${locateRequestProviderId}`
      );
      const existingOptions = cloneDeep(
        this.selectedItemOptions.question.options
      );
      this.selectedItemOptions.question.options = [...results].map((r) => {
        const { description, positive_response_code: value } = r;
        const existingOption = existingOptions.find((o) => o.value === value);
        const visible = existingOption?.visible ?? { condition: "ALWAYS" };
        return { label: `${value} ${description}`, value, visible };
      });
      this.onSubmit();
    },
    async updateItem() {
      await this.onSubmit();
      this.$emit("form-control-form-submitted");
    },
    async onSubmit() {
      const success = await this.$refs.positiveResponseForm.validate();
      if (!success) {
        return;
      }
      const options = cloneDeep(this.selectedItemOptions);
      options.question.options = options.question.options.map(
        ({ value, label, visible }) => ({
          value,
          label,
          visible,
        })
      );
      if (
        [
          VIRGINIA_LOCATE_REQUEST_PROVIDER_ID,
          OHIO_LOCATE_REQUEST_PROVIDER_ID,
        ].includes(options.question.locateRequestProviderId)
      ) {
        options.question.positiveResponses = [
          ...this.showCodes.map((f) => {
            const { showCode, label } = f;
            return { label, showCode, visible: true };
          }),
        ];
      } else {
        options.question.positiveResponses = [];
      }
      this.$emit("input", options);
    },
    onChoiceSubmitted(choice, index) {
      if (typeof index === "number") {
        this.$set(this.selectedItemOptions.question.options, index, choice);
        this.showEditChoicesDialog = false;
      } else {
        this.selectedItemOptions.question.options.push(choice);
        this.showAddChoiceDialog = false;
      }
      this.onSubmit();
    },
    removeChoice(index) {
      this.selectedItemOptions.question.options.splice(index, 1);
      this.onSubmit();
    },
    async getLayer() {
      const { map_service_id: mapServiceId } = this.formDefinition;
      const {
        data: { results },
      } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
      this.layer = results;
    },
    getSelectedItemOptions() {
      const selectedItemOptions = cloneDeep(this.selectedItem);
      this.oldSelectedItemOptions = cloneDeep(this.selectedItem);
      if (!selectedItemOptions) {
        return;
      }
      selectedItemOptions.question.options =
        selectedItemOptions?.question?.options?.map((o) => ({
          ...o,
          id: uuidv4(),
        }));

      if (!selectedItemOptions.question.default) {
        selectedItemOptions.question.default = {};
      }

      this.selectedItemOptions = { ...selectedItemOptions };
      if (!Array.isArray(this.selectedItemOptions.question.positiveResponses)) {
        this.selectedItemOptions.question.positiveResponses = [];
      }
      this.positiveResponses = [
        ...this.selectedItemOptions.question.positiveResponses
          .filter((r) => r.visible)
          .map((r) => r.label),
      ];
      if (
        [
          VIRGINIA_LOCATE_REQUEST_PROVIDER_ID,
          OHIO_LOCATE_REQUEST_PROVIDER_ID,
        ].includes(selectedItemOptions.question.locateRequestProviderId)
      ) {
        if (
          Array.isArray(this.selectedItemOptions.question.positiveResponses) &&
          this.selectedItemOptions.question.positiveResponses.length > 0
        ) {
          this.showCodes =
            this.selectedItemOptions.question.positiveResponses.map((p) => {
              const { label, showCode } = p;
              return { id: uuidv4(), label, showCode };
            });
        } else {
          this.showCodes = [{ id: uuidv4(), label: "", showCode: "" }];
        }
      }
      this.customComments =
        Array.isArray(this.selectedItemOptions.question.comments) &&
        this.selectedItemOptions.question.comments.length > 0;
    },
    async getLocateRequestProviders() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/locate_request_providers`);
      this.locateRequestProviders = results;
    },
    async get811Accounts() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/locate_request_provider_accounts/all`);
      this.accounts811 = results;
    },
    updateDefaultComment() {
      const [lastComment] =
        this.selectedItemOptions.question.comments.slice(-1);
      this.selectedItemOptions.question.defaultComment = lastComment;
    },
  },
  beforeMount() {
    this.getSelectedItemOptions();
    this.getLocateRequestProviders();
    this.get811Accounts();
  },
  watch: {
    "selectedItemOptions.question.showAllOptions": {
      immediate: true,
      handler(val) {
        if (!this.selectedItemOptions.question?.default) {
          this.selectedItemOptions.question.default = {};
        }

        if (!this.multiSelect) {
          return;
        }

        if (val) {
          if (!Array.isArray(this.selectedItemOptions.question.default.value)) {
            this.selectedItemOptions.question.default.value = [];
          }
        } else {
          if (!this.selectedItemOptions.question.default.value) {
            this.selectedItemOptions.question.default.value = "";
          }
        }
      },
    },
    "selectedItemOptions.question.locateRequestProviderId": {
      immediate: true,
      handler(val) {
        if (!val) {
          return;
        }
        this.getPositiveResponses(val);
        if (
          [
            VIRGINIA_LOCATE_REQUEST_PROVIDER_ID,
            OHIO_LOCATE_REQUEST_PROVIDER_ID,
          ].includes(val)
        ) {
          this.selectedItemOptions.allowMultiple = true;
        }
      },
    },
    formDefinition: {
      deep: true,
      immediate: true,
      handler() {
        this.getLayer();
      },
    },
    "positiveResponses.length": {
      immediate: true,
      async handler(val) {
        if (val > 1) {
          this.selectedItemOptions.question.showAllOptions = false;
          await this.onSubmit();
        }
      },
    },
  },
  async mounted() {
    if (!this.selectedItemOptions.question.label) {
      await this.$nextTick();
      await sleep(500);
      this.$refs.labelInput.$refs?.input?.focus();
    }
  },
};
</script>
