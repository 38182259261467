<template>
  <div>
    <p class="caption mt-6 mb-3">Related Layers</p>

    <v-card id="relatedLayers">
      <v-card-text>
        <p class="caption" v-if="parentLayerTestError" style="color: red">
          Unable to verify if related layers exist. Parent layer connection
          failed.
        </p>
        <div style="overflow-x: hidden">
          <v-data-table
            :headers="computedHeaders"
            :items="filteredChildLayers"
            hide-default-footer
            disable-pagination
            item-key="map_service_id"
            style="overflow-x: hidden !important"
          >
            <template v-slot:top>
              <v-row>
                <v-col cols="12">
                  <div class="d-flex justify-space-between align-center">
                    <div>
                      <v-chip
                        class="d-flex"
                        @click="
                          showActiveLayers = !showActiveLayers;
                          selectedLayer = {};
                        "
                      >
                        <div
                          class="chip overflow-hidden text-truncate my-0 py-0 cursor-pointer"
                        >
                          {{ showActiveLayers ? "Active" : "Archived" }}
                          <v-icon small>
                            {{ mdiSyncCircle }}
                          </v-icon>
                        </div>
                      </v-chip>
                    </div>
                    <div>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            text
                            v-bind="attrs"
                            v-on="on"
                            :disabled="
                              disableAddButton ||
                              relatedTables?.length === 0 ||
                              !canManageLayers
                            "
                          >
                            + Add Layer
                          </v-btn>
                        </template>
                        <v-list class="pa-0 ma-0">
                          <v-list-item
                            v-for="rt of relatedTables"
                            :key="rt.relatedTableId"
                            @click="addRelatedLayer(rt)"
                          >
                            {{ rt.name ? rt.name : "Unnamed" }}
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.service_name`]="{ item }">
              <div v-if="selectedLayer.map_service_id === item.map_service_id">
                <v-text-field
                  v-model="selectedLayer.service_name"
                  class="pb-2"
                  label="Name"
                  hide-details="auto"
                  name="name"
                  color="primary"
                ></v-text-field>
              </div>
              <div v-else>
                <div>{{ item.service_name }}</div>

                <div
                  v-if="item.successfulTest === true"
                  style="color: #286054"
                  class="pl-2"
                >
                  Related table exists.
                </div>
                <div
                  v-else-if="item.successfulTest === false"
                  style="color: red"
                  class="pl-2"
                >
                  Related table does not exist.
                </div>
              </div>
            </template>
            <template v-slot:[`item.ref_field`]="{ item }">
              <div v-if="selectedLayer.map_service_id === item.map_service_id">
                <v-select
                  autocomplete="off"
                  v-model="selectedLayer.ref_field"
                  class="pb-2"
                  label="Reference Field"
                  hide-details="auto"
                  color="primary"
                  name="refField"
                  item-text="label"
                  item-value="value"
                  :items="gisDataFieldChoices"
                >
                </v-select>
              </div>
              <div v-else>
                {{ item.ref_field }}
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div v-if="selectedLayer.map_service_id === item.map_service_id">
                <v-btn
                  color="primary"
                  text
                  @click="saveChildLayer()"
                  :disabled="!canManageLayers"
                >
                  Save
                </v-btn>
              </div>
              <div v-else>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      :disabled="!canManageLayers"
                    >
                      <v-icon>
                        {{ mdiDotsVertical }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list class="py-0" v-if="item.is_active">
                    <v-list-item class="py-1" @click="editRelatedLayer(item)">
                      <v-icon color="primary" class="mr-2">
                        {{ mdiPencil }}
                      </v-icon>
                      <div class="overflowText">Edit</div>
                    </v-list-item>
                    <v-list-item @click="updateActiveSetting(item, false)">
                      <v-icon color="primary" class="mr-2">
                        {{ mdiArchiveArrowDown }}
                      </v-icon>
                      <div class="overflowText">Archive</div>
                    </v-list-item>
                    <v-list-item
                      @click="
                        showConfirmDeleteLayerDialog = true;
                        layerForDeletion = item;
                      "
                    >
                      <v-icon color="primary" class="mr-2">
                        {{ mdiDelete }}
                      </v-icon>
                      <div class="overflowText">Delete</div>
                    </v-list-item>
                  </v-list>
                  <v-list v-else>
                    <v-list-item @click="updateActiveSetting(item, true)">
                      <v-icon color="primary" class="mr-2">
                        {{ mdiArchiveArrowUp }}
                      </v-icon>
                      <div class="overflowText">Make Active</div>
                    </v-list-item>
                    <v-list-item
                      @click="
                        showConfirmDeleteLayerDialog = true;
                        layerForDeletion = item;
                      "
                    >
                      <v-icon color="primary" class="mr-2">
                        {{ mdiDelete }}
                      </v-icon>
                      <div class="overflowText">Delete</div>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
          </v-data-table>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="showConfirmDeleteLayerDialog"
      max-width="600px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-list-item>
          <v-list-item-content class="pl-3">
            <div class="text-h5 py-3">Delete Related Layer?</div>
            Are you sure you want to delete this related layer? This action
            cannot be undone.
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <div class="d-flex justify-end">
              <v-btn
                text
                @click="
                  showConfirmDeleteLayerDialog = false;
                  layerForDeletion = undefined;
                "
                color="primary"
              >
                Cancel
              </v-btn>
              <v-btn color="primary" @click="deleteLayer()">
                Delete Layer
              </v-btn>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-dialog>
    <LayerCannotBeDeletedDialog
      :showLayerCannotBeDeletedDialog="showLayerCannotBeDeletedDialog"
      @cancel="showLayerCannotBeDeletedDialog = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import LayerCannotBeDeletedDialog from "@/components/layers/LayerCannotBeDeletedDialog.vue";
import { EventBus } from "@/main.js";
import permissionsMixin from "@/mixins/permissionsMixin";

const APIURL = process.env.VUE_APP_API_URL;
import {
  mdiDotsVertical,
  mdiPencil,
  mdiSyncCircle,
  mdiArchiveArrowDown,
  mdiArchiveArrowUp,
  mdiDelete,
} from "@mdi/js";

const headers = [
  {
    text: "Layer Name",
    align: "start",
    sortable: true,
    value: "service_name",
  },
  {
    text: "Reference Field",
    align: "start",
    sortable: false,
    value: "ref_field",
  },
  {
    text: "Actions",
    align: "end",
    sortable: false,
    value: "actions",
  },
];

export default {
  name: "RelatedLayers",
  components: {
    LayerCannotBeDeletedDialog,
  },
  props: {
    layer: Object,
  },
  mixins: [permissionsMixin],
  data() {
    return {
      mdiDotsVertical,
      mdiPencil,
      mdiSyncCircle,
      mdiArchiveArrowDown,
      mdiArchiveArrowUp,
      mdiDelete,
      headers,
      featureServiceInfo: {},
      relatedLayers: [],
      showActiveLayers: true,
      selectedLayer: {},
      showConfirmDeleteLayerDialog: false,
      showLayerCannotBeDeletedDialog: false,
      layerForDeletion: undefined,
      parentLayerTestError: false,
      featureServiceGisFields: [],
    };
  },
  computed: {
    relatedTables() {
      return this.featureServiceInfo?.relationships?.filter((layer) => {
        const hasMatchingLayer = this.relatedLayers.some((addedLayer) => {
          const addedLayerId = addedLayer.service_url.substring(
            addedLayer.service_url.lastIndexOf("/") + 1
          );
          return String(layer.relatedTableId) === addedLayerId;
        });
        return !hasMatchingLayer;
      });
    },
    filteredChildLayers() {
      if (this.showActiveLayers) {
        return this.relatedLayers.filter((l) => l.is_active);
      } else {
        return this.relatedLayers.filter((l) => !l.is_active);
      }
    },
    computedHeaders() {
      return headers;
    },
    disableAddButton() {
      return (
        this.layer.token_type !== "NONE" &&
        Boolean(!localStorage.getItem("esri_token"))
      );
    },
    gisDataFieldChoices() {
      return this.featureServiceGisFields.map((f) => {
        const { name, alias } = f;
        return {
          label: alias || name,
          value: name,
        };
      });
    },
  },
  async beforeMount() {
    await this.getFeatureServiceInfo();
    await this.getRelatedLayers();
  },
  mounted() {
    EventBus.$on("test-clicked", (data) => {
      this.testRelatedLayers(data);
    });
  },
  methods: {
    async getFeatureServiceInfo() {
      const { data } = await axios.get(this.layer.service_url, {
        params: {
          f: "pjson",
          token:
            this.layer.token_type !== "NONE"
              ? localStorage.getItem("esri_token")
              : undefined,
        },
      });
      this.featureServiceInfo = data;
    },
    async getRelatedLayers() {
      const {
        data: { results },
      } = await axios.get(
        `${APIURL}/map_services/${this.layer.map_service_id}/children`
      );
      this.relatedLayers = results;
      this.selectedLayer = {};
    },
    async addRelatedLayer(relatedLayer) {
      const parentServiceUrl = this.layer.service_url;
      const childServiceUrl =
        parentServiceUrl.substring(0, parentServiceUrl.lastIndexOf("/") + 1) +
        relatedLayer.relatedTableId;
      await this.getFeatureServiceFields(childServiceUrl);
      const reference_field = this.featureServiceGisFields.find(
        (field) => field.type === "esriFieldTypeOID"
      ).name;
      const layer = {
        service_url: childServiceUrl,
        service_name: relatedLayer.name ? relatedLayer.name : "Unnamed",
        service_type: "R",
        ref_field: reference_field,
        token_type: this.layer.token_type,
        parent_map_service_id: this.layer.map_service_id,
      };
      await axios.post(`${APIURL}/map_services`, layer);
      this.getRelatedLayers();
    },
    async updateActiveSetting(layer, isActive = true) {
      await axios.put(`${APIURL}/map_services/${layer.map_service_id}`, {
        ...layer,
        is_active: isActive,
      });
      await this.getRelatedLayers();
    },
    async saveChildLayer() {
      const layer = { ...this.selectedLayer };
      await axios.put(`${APIURL}/map_services/${layer.map_service_id}`, {
        ...layer,
      });
      await this.getRelatedLayers();
    },
    async editRelatedLayer(layer) {
      await this.getFeatureServiceFields(layer.service_url);
      this.selectedLayer = layer;
    },
    async getFeatureServiceFields(serviceUrl) {
      const params = {
        f: "pjson",
        token: localStorage.getItem("esri_token"),
      };
      const {
        data: { fields = [] },
      } = await axios.get(serviceUrl, { params });
      this.featureServiceGisFields = fields;
    },
    async deleteLayer() {
      const mapServiceId = this.layerForDeletion.map_service_id;
      try {
        const {
          data: {
            results: { renderer },
          },
        } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
        const { renderer_id: rendererId } = renderer;
        if (rendererId) {
          await axios.delete(`${APIURL}/renderer_symbol/all/${rendererId}`);
        }
        const {
          data: { results: gisDataFields },
        } = await axios.get(`${APIURL}/gis_data_fields`, {
          params: {
            map_service_id: mapServiceId,
          },
        });
        const gisDataFieldOptions = gisDataFields
          .map((f) => f.gis_data_field_options)
          .flat();
        await Promise.all(
          gisDataFieldOptions.map((o) =>
            axios.delete(
              `${APIURL}/gis_data_field_options/${o.gis_data_field_option_id}`
            )
          )
        );
        await Promise.all(
          gisDataFields.map((f) =>
            axios.delete(`${APIURL}/gis_data_fields/${f.gis_data_field_id}`)
          )
        );
        this.showConfirmDeleteLayerDialog = false;
        await axios.delete(`${APIURL}/map_services/${mapServiceId}`);
      } catch (error) {
        this.showLayerCannotBeDeletedDialog = true;
      } finally {
        await this.getRelatedLayers();
      }
    },
    async testRelatedLayers(data) {
      if (data) {
        this.parentLayerTestError = true;
        return;
      } else {
        await this.getFeatureServiceInfo();

        this.relatedLayers = this.relatedLayers.map((layer) => {
          const serviceUrl = layer.service_url;
          const id = serviceUrl.substring(serviceUrl.lastIndexOf("/") + 1);

          layer.successfulTest = Boolean(
            this.featureServiceInfo.relationships.find((relationship) => {
              return String(relationship.relatedTableId) === String(id);
            })
          );
          this.parentLayerTestError = false;
          return layer;
        });
      }
    },
  },
};
</script>

<style scoped>
.overflowText {
  font-size: 14px;
  font-family: Roboto;
  text-transform: uppercase;
  font-weight: 500;
  color: #286054;
}
</style>
