var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "caption mt-6 mb-3" }, [_vm._v("Service Info")]),
      _c(
        "v-card",
        { staticClass: "py-5" },
        [
          _c("validation-observer", {
            ref: "form",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ invalid }) {
                  return [
                    _c(
                      "form",
                      [
                        _c(
                          "v-card-text",
                          [
                            _c("validation-provider", {
                              attrs: {
                                bails: false,
                                name: "arcGisServiceUrl",
                                rules: {
                                  required: true,
                                  regex:
                                    /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
                                  ends_with_regex:
                                    _vm.layer.service_type === "F"
                                      ? "/FeatureServer/[0-9]+"
                                      : "/MapServer",
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "d-flex flex-row" },
                                          [
                                            _c("v-textarea", {
                                              staticClass: "ma-0 mr-4 pa-0",
                                              attrs: {
                                                "error-messages": errors,
                                                success: valid,
                                                label: "ArcGIS Service URL *",
                                                color: "primary",
                                                "auto-grow": "",
                                                rows: "1",
                                                "hide-details": "",
                                                disabled: !_vm.canManageLayers,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.$emit(
                                                    "layer-changed",
                                                    _vm.selectedLayer
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.selectedLayer.service_url,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.selectedLayer,
                                                    "service_url",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "selectedLayer.service_url",
                                              },
                                            }),
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  text: "",
                                                  width: "30px",
                                                  "min-width": "30px",
                                                  height: "30px",
                                                  "min-height": "30px",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.goToService(
                                                      _vm.selectedLayer
                                                        .service_url
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: { color: "#1976d2" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.mdiOpenInNew)
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm.submitted
                              ? [
                                  _vm.error
                                    ? _c(
                                        "p",
                                        {
                                          staticClass: "caption my-0 py-0",
                                          staticStyle: { color: "red" },
                                        },
                                        [_vm._v(" " + _vm._s(_vm.error) + " ")]
                                      )
                                    : _c(
                                        "p",
                                        {
                                          staticClass: "caption my-0 py-0",
                                          staticStyle: { color: "#093637" },
                                        },
                                        [
                                          _vm._v(
                                            " Success! Connected to ArcGIS Feature Service. "
                                          ),
                                        ]
                                      ),
                                ]
                              : _vm._e(),
                            _c(
                              "validation-provider",
                              [
                                _c("v-switch", {
                                  staticClass: "py-0",
                                  attrs: {
                                    label: "Secured Service",
                                    disabled: !_vm.canManageLayers,
                                  },
                                  model: {
                                    value: _vm.securedService,
                                    callback: function ($$v) {
                                      _vm.securedService = $$v
                                    },
                                    expression: "securedService",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "d-flex justify-end py-0" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-4 mt-n4 mb-2",
                            attrs: {
                              color: "primary",
                              disabled: invalid || !_vm.canManageLayers,
                            },
                            on: { click: _vm.testUrl },
                          },
                          [_vm._v(" Test Connection ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }