<template>
  <v-dialog
    :value="showAddUtiliSyncFieldDialog"
    max-width="600px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar dark color="primary" ref="toolbar" class="elevation-0">
        <v-toolbar-title>Add UtiliSync Fields</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('add-utilisync-field-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        class="py-5"
        :style="{
          'background-color': '#f1f2f1',
          height: $vuetify.breakpoint.xsOnly ? `${contentHeight}px` : 'auto',
          'overflow-y': 'auto',
          'max-height': $vuetify.breakpoint.xsOnly ? undefined : '60vh',
        }"
      >
        <section>
          <p class="caption mb-0 pb-0">Field Settings</p>
          <v-card>
            <v-card-text>
              <validation-observer ref="gisDataFieldForm">
                <form>
                  <validation-provider
                    v-slot="{ errors, valid }"
                    name="Field Name"
                    rules="required"
                  >
                    <v-text-field
                      label="Field Name"
                      v-model="gisDataField.name"
                      hide-details="auto"
                      name="name"
                      :error-messages="errors"
                      :success="valid"
                      @blur="$emit('field-name-set', gisDataField.name)"
                    >
                    </v-text-field>
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors, valid }"
                    name="Type"
                    rules="required"
                  >
                    <v-select
                      autocomplete="off"
                      label="Type"
                      hide-details="auto"
                      :error-messages="errors"
                      :success="valid"
                      color="primary"
                      name="type"
                      :items="typeChoices"
                      item-text="label"
                      item-value="value"
                      v-model="gisDataField.type"
                    >
                    </v-select>
                  </validation-provider>

                  <v-switch
                    v-model="gisDataField.editable"
                    label="User Editable. The field can be manually updated by the user."
                    class="mb-0 pb-0"
                  >
                  </v-switch>
                </form>
              </validation-observer>
            </v-card-text>
          </v-card>
        </section>

        <section v-if="gisDataField.type === 'string'">
          <p class="caption mt-3 mb-0 pb-0">Field Options</p>
          <v-card>
            <v-card-text>
              <GisDataFieldOptionsForm
                :gisDataFieldId="gisDataFieldId"
                @gis-field-options-added="gisDataFieldOptions = $event"
                @gis-field-options-deleted="gisDataFieldOptions = $event"
              />
            </v-card-text>
          </v-card>
        </section>
      </v-card-text>

      <v-card-actions class="d-flex justify-end pa-5" ref="cardAction">
        <v-btn color="primary" dark @click="saveField">Save Field</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import GisDataFieldOptionsForm from "@/components/layers/edit-layer-form/shared/layer-details/edit-utilisync-fields-dialog/add-utilisync-field-dialog/GisDataFieldOptionsForm";
import axios from "axios";
import contentHeightMixin from "@/mixins/contentHeightMixin";

const APIURL = process.env.VUE_APP_API_URL;
const typeChoices = [
  { label: "Text", value: "string" },
  { label: "Date", value: "date" },
  { label: "Number", value: "number" },
];

export default {
  name: "AddUtiliSyncFieldDialog",
  props: {
    showAddUtiliSyncFieldDialog: Boolean,
    mapServiceId: String,
  },
  mixins: [contentHeightMixin],
  data() {
    return {
      gisDataField: {
        type: "string",
        editable: true,
        visible: true,
      },
      typeChoices,
      gisDataFieldId: "",
      gisDataFieldOptions: [],
    };
  },
  components: {
    GisDataFieldOptionsForm,
  },
  methods: {
    async saveField() {
      const { mapServiceId, gisDataField } = this;
      const data = {
        ...gisDataField,
        map_service_id: mapServiceId,
      };
      const {
        data: {
          results: { gis_data_field_id: fieldId },
        },
      } = await axios.post(`${APIURL}/gis_data_fields`, data);
      this.gisDataFieldId = fieldId;
      await Promise.all(
        this.gisDataFieldOptions.map((fo) =>
          axios.post(`${APIURL}/gis_data_field_options`, {
            ...fo,
            gis_data_field_id: fieldId,
          })
        )
      );
      this.gisDataField = {};
      await this.$nextTick();
      this.$refs.gisDataFieldForm.reset();
      this.$emit("add-utilisync-fields-dialog-added");
    },
  },
};
</script>
