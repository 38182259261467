<template>
  <v-dialog
    :value="showAddValueRangeDialog"
    max-width="500px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Add Value Range</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('add-value-range-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="py-5" style="background-color: #f1f2f1">
        <validation-observer ref="valueRangeForm">
          <form>
            <validation-provider
              v-slot="{ errors, valid }"
              name="Label"
              rules="required"
            >
              <v-text-field
                label="Label*"
                v-model="range.label"
                hide-details="auto"
                name="name"
                :error-messages="errors"
                :success="valid"
              >
              </v-text-field>
            </validation-provider>

            <div class="d-flex justify-space-between">
              <div style="width: 100%" class="mr-5">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Min Value"
                  rules="required"
                >
                  <v-text-field
                    label="Min Value*"
                    v-model.number="range.minValue"
                    hide-details="auto"
                    name="name"
                    :error-messages="errors"
                    :success="valid"
                  >
                  </v-text-field>
                </validation-provider>
              </div>

              <div style="width: 100%">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Max Value*"
                  rules="required"
                >
                  <v-text-field
                    label="Max Value*"
                    v-model.number="range.maxValue"
                    hide-details="auto"
                    name="name"
                    :error-messages="errors"
                    :success="valid"
                  >
                  </v-text-field>
                </validation-provider>
              </div>
            </div>
          </form>
        </validation-observer>
      </v-card-text>

      <v-card-actions class="d-flex justify-end">
        <v-btn @click="saveRange" color="primary" dark> Save Range </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AddValueRangeDialog",
  props: {
    showAddValueRangeDialog: Boolean,
  },
  data() {
    return {
      range: {},
    };
  },
  methods: {
    async saveRange() {
      const success = await this.$refs.valueRangeForm.validate();
      if (!success) {
        return;
      }
      this.$emit("range-set", this.range);
    },
  },
};
</script>
