<template>
  <div>
    <p class="caption mt-6 mb-3">Layers</p>

    <v-card class="py-1">
      <v-card-text>
        <p class="caption">Layers to show on map:</p>

        <v-list
          :style="{
            width: 'fit-content',
            'border-radius': '1%',
            border: '1px solid primary',
          }"
          class="py-0"
          dense
        >
          <v-list-item class="d-flex align-center">
            <v-checkbox
              :input-value="
                selectedSublayerIds.length === sublayerChoices.length
              "
              @change="onSelectAllChange"
              dense
              hide-details
              class="py-0 my-0"
            >
              <template #label>
                <b>Select All</b>
              </template>
            </v-checkbox>
          </v-list-item>
          <v-list-item
            class="d-flex align-center"
            v-for="s of sublayerChoices"
            :key="s.name"
          >
            <v-checkbox
              v-model="selectedSublayerIds"
              :value="s.name"
              dense
              hide-details
              class="py-0 my-0"
              name="selectedSublayerIds"
              @change="onLayerChanged"
              :label="s.title"
            ></v-checkbox>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { xml2js } from "xml-js";
import axios from "axios";
import { cloneDeep } from "lodash";
export default {
  name: "WmsSubLayersForm",
  props: {
    layer: Object,
  },
  data() {
    return {
      selectedLayer: {},
      sublayers: [],
      selectedSublayerIds: [],
    };
  },
  async beforeMount() {
    await this.getWmsLayerCapabilities();
    this.getSelectedSublayerIds();
  },
  computed: {
    sublayerChoices() {
      return this.sublayers.map((s) => {
        const {
          Name: { _text: name },
          Title: { _text: title, _cdata: cData },
        } = s;
        return { name, title: title ?? cData };
      });
    },
  },
  methods: {
    onSelectAllChange(checked) {
      if (checked) {
        this.selectedSublayerIds = this.sublayerChoices.map((s) => s.name);
      } else {
        this.selectedSublayerIds = [];
      }
      this.onLayerChanged();
    },
    onLayerChanged() {
      this.selectedLayer.sub_layers = this.sublayerChoices.map((s) => {
        return { ...s, visible: this.selectedSublayerIds.includes(s.name) };
      });
      this.$emit("layer-changed", this.selectedLayer);
    },
    getWmsSubLayers(layer) {
      const hasSublayers = layer.some((l) => Array.isArray(l?.Layer));
      if (!hasSublayers) {
        return layer;
      }
      return [layer, ...this.getWmsSubLayers(layer.map((l) => l.Layer).flat())]
        .flat()
        .filter((l) => l.Name);
    },
    getSelectedSublayerIds() {
      const { sub_layers: subLayers } = this.selectedLayer;
      if (!Array.isArray(subLayers)) {
        return;
      }
      this.selectedSublayerIds = subLayers
        .filter((s) => s.visible)
        .map((s) => s.name);
    },
    async getWmsLayerCapabilities() {
      const { service_url: serviceUrl } = this.selectedLayer;
      if (!serviceUrl) {
        return;
      }

      try {
        const token = localStorage.getItem("esri_token");
        const { data } = await axios.get(serviceUrl, {
          params: {
            SERVICE: "WMS",
            REQUEST: "GetCapabilities",
          },
        });
        const wmsData = xml2js(data, { compact: true });
        if (
          Array.isArray(wmsData?.WMS_Capabilities?.Capability?.Layer?.Layer)
        ) {
          this.sublayers = this.getWmsSubLayers(
            wmsData?.WMS_Capabilities?.Capability?.Layer?.Layer
          );
          this.selectedSublayerIds = this.sublayers.map((s) => {
            const {
              Name: { _text: name },
            } = s;
            return name;
          });
        } else {
          const { data } = await axios.get(serviceUrl, {
            params: {
              SERVICE: "WMS",
              REQUEST: "GetCapabilities",
              token,
            },
          });
          const wmsData = xml2js(data, { compact: true });
          if (
            Array.isArray(wmsData?.WMS_Capabilities?.Capability?.Layer?.Layer)
          ) {
            this.sublayers = this.getWmsSubLayers(
              wmsData?.WMS_Capabilities?.Capability?.Layer?.Layer
            );
            this.selectedSublayerIds = this.sublayers.map((s) => {
              const {
                Name: { _text: name },
              } = s;
              return name;
            });
          }
        }
      } catch (error) {
        console.warn(error);
      }
    },
  },
  watch: {
    "layer.service_url": {
      async handler(serviceUrl) {
        if (!serviceUrl) {
          return;
        }
        this.selectedLayer = {
          ...this.selectedLayer,
          service_url: serviceUrl,
        };
        await this.getWmsLayerCapabilities();
        this.selectedSublayerIds = this.subLayers.map((s) => s.name);
      },
    },
    layer: {
      immediate: true,
      deep: true,
      handler(val) {
        this.selectedLayer = cloneDeep(val);
      },
    },
  },
};
</script>
