<template>
  <v-dialog
    :value="showLayerCannotBeDeletedDialog"
    max-width="500px"
    persistent
  >
    <v-card>
      <v-card-text class="px-5 py-3">
        This layer cannot be deleted because it is being referenced in other
        places in the app. We recommend you archive the layer instead.
      </v-card-text>

      <v-card-actions class="d-flex justify-end px-5 py-3">
        <v-btn text @click="$emit('cancel')" color="primary">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "LayerCannotBeDeletedDialog",
  props: {
    showLayerCannotBeDeletedDialog: Boolean,
  },
};
</script>
