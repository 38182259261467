<template>
  <div>
    <p class="caption mt-6 mb-3">Access</p>

    <v-list>
      <DepartmentsAccessForm
        :layer="layer"
        @access-updated="$emit('access-updated', $event)"
      />
    </v-list>
  </div>
</template>

<script>
import DepartmentsAccessForm from "@/components/layers/edit-layer-form/shared/DepartmentsAccessForm";

export default {
  name: "AccessForm",
  components: { DepartmentsAccessForm },
  props: {
    layer: Object,
  },
};
</script>
