var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "caption mt-6 mb-3" }, [_vm._v("Related Layers")]),
      _c(
        "v-card",
        { attrs: { id: "relatedLayers" } },
        [
          _c("v-card-text", [
            _vm.parentLayerTestError
              ? _c(
                  "p",
                  { staticClass: "caption", staticStyle: { color: "red" } },
                  [
                    _vm._v(
                      " Unable to verify if related layers exist. Parent layer connection failed. "
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              { staticStyle: { "overflow-x": "hidden" } },
              [
                _c("v-data-table", {
                  staticStyle: { "overflow-x": "hidden !important" },
                  attrs: {
                    headers: _vm.computedHeaders,
                    items: _vm.filteredChildLayers,
                    "hide-default-footer": "",
                    "disable-pagination": "",
                    "item-key": "map_service_id",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "top",
                        fn: function () {
                          return [
                            _c(
                              "v-row",
                              [
                                _c("v-col", { attrs: { cols: "12" } }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between align-center",
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-chip",
                                            {
                                              staticClass: "d-flex",
                                              on: {
                                                click: function ($event) {
                                                  _vm.showActiveLayers =
                                                    !_vm.showActiveLayers
                                                  _vm.selectedLayer = {}
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "chip overflow-hidden text-truncate my-0 py-0 cursor-pointer",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.showActiveLayers
                                                          ? "Active"
                                                          : "Archived"
                                                      ) +
                                                      " "
                                                  ),
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.mdiSyncCircle
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              attrs: { "offset-y": "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                text: "",
                                                                disabled:
                                                                  _vm.disableAddButton ||
                                                                  _vm
                                                                    .relatedTables
                                                                    ?.length ===
                                                                    0 ||
                                                                  !_vm.canManageLayers,
                                                              },
                                                            },
                                                            "v-btn",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            " + Add Layer "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                            },
                                            [
                                              _c(
                                                "v-list",
                                                { staticClass: "pa-0 ma-0" },
                                                _vm._l(
                                                  _vm.relatedTables,
                                                  function (rt) {
                                                    return _c(
                                                      "v-list-item",
                                                      {
                                                        key: rt.relatedTableId,
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.addRelatedLayer(
                                                              rt
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              rt.name
                                                                ? rt.name
                                                                : "Unnamed"
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: `item.service_name`,
                        fn: function ({ item }) {
                          return [
                            _vm.selectedLayer.map_service_id ===
                            item.map_service_id
                              ? _c(
                                  "div",
                                  [
                                    _c("v-text-field", {
                                      staticClass: "pb-2",
                                      attrs: {
                                        label: "Name",
                                        "hide-details": "auto",
                                        name: "name",
                                        color: "primary",
                                      },
                                      model: {
                                        value: _vm.selectedLayer.service_name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.selectedLayer,
                                            "service_name",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "selectedLayer.service_name",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _c("div", [
                                  _c("div", [
                                    _vm._v(_vm._s(item.service_name)),
                                  ]),
                                  item.successfulTest === true
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "pl-2",
                                          staticStyle: { color: "#286054" },
                                        },
                                        [_vm._v(" Related table exists. ")]
                                      )
                                    : item.successfulTest === false
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "pl-2",
                                          staticStyle: { color: "red" },
                                        },
                                        [
                                          _vm._v(
                                            " Related table does not exist. "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                          ]
                        },
                      },
                      {
                        key: `item.ref_field`,
                        fn: function ({ item }) {
                          return [
                            _vm.selectedLayer.map_service_id ===
                            item.map_service_id
                              ? _c(
                                  "div",
                                  [
                                    _c("v-select", {
                                      staticClass: "pb-2",
                                      attrs: {
                                        autocomplete: "off",
                                        label: "Reference Field",
                                        "hide-details": "auto",
                                        color: "primary",
                                        name: "refField",
                                        "item-text": "label",
                                        "item-value": "value",
                                        items: _vm.gisDataFieldChoices,
                                      },
                                      model: {
                                        value: _vm.selectedLayer.ref_field,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.selectedLayer,
                                            "ref_field",
                                            $$v
                                          )
                                        },
                                        expression: "selectedLayer.ref_field",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _c("div", [
                                  _vm._v(" " + _vm._s(item.ref_field) + " "),
                                ]),
                          ]
                        },
                      },
                      {
                        key: `item.actions`,
                        fn: function ({ item }) {
                          return [
                            _vm.selectedLayer.map_service_id ===
                            item.map_service_id
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          color: "primary",
                                          text: "",
                                          disabled: !_vm.canManageLayers,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.saveChildLayer()
                                          },
                                        },
                                      },
                                      [_vm._v(" Save ")]
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  [
                                    _c(
                                      "v-menu",
                                      {
                                        attrs: { "offset-y": "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            disabled:
                                                              !_vm.canManageLayers,
                                                          },
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.mdiDotsVertical
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        item.is_active
                                          ? _c(
                                              "v-list",
                                              { staticClass: "py-0" },
                                              [
                                                _c(
                                                  "v-list-item",
                                                  {
                                                    staticClass: "py-1",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.editRelatedLayer(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.mdiPencil
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "overflowText",
                                                      },
                                                      [_vm._v("Edit")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.updateActiveSetting(
                                                          item,
                                                          false
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.mdiArchiveArrowDown
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "overflowText",
                                                      },
                                                      [_vm._v("Archive")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.showConfirmDeleteLayerDialog = true
                                                        _vm.layerForDeletion =
                                                          item
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.mdiDelete
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "overflowText",
                                                      },
                                                      [_vm._v("Delete")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "v-list",
                                              [
                                                _c(
                                                  "v-list-item",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.updateActiveSetting(
                                                          item,
                                                          true
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.mdiArchiveArrowUp
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "overflowText",
                                                      },
                                                      [_vm._v("Make Active")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.showConfirmDeleteLayerDialog = true
                                                        _vm.layerForDeletion =
                                                          item
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.mdiDelete
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "overflowText",
                                                      },
                                                      [_vm._v("Delete")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "600px",
            fullscreen: _vm.$vuetify.breakpoint.xsOnly,
          },
          model: {
            value: _vm.showConfirmDeleteLayerDialog,
            callback: function ($$v) {
              _vm.showConfirmDeleteLayerDialog = $$v
            },
            expression: "showConfirmDeleteLayerDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-list-item",
                [
                  _c("v-list-item-content", { staticClass: "pl-3" }, [
                    _c("div", { staticClass: "text-h5 py-3" }, [
                      _vm._v("Delete Related Layer?"),
                    ]),
                    _vm._v(
                      " Are you sure you want to delete this related layer? This action cannot be undone. "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c("v-list-item-content", [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-end" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { text: "", color: "primary" },
                            on: {
                              click: function ($event) {
                                _vm.showConfirmDeleteLayerDialog = false
                                _vm.layerForDeletion = undefined
                              },
                            },
                          },
                          [_vm._v(" Cancel ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteLayer()
                              },
                            },
                          },
                          [_vm._v(" Delete Layer ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("LayerCannotBeDeletedDialog", {
        attrs: {
          showLayerCannotBeDeletedDialog: _vm.showLayerCannotBeDeletedDialog,
        },
        on: {
          cancel: function ($event) {
            _vm.showLayerCannotBeDeletedDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }