var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "caption mt-6 mb-3" }, [_vm._v("Related Layers")]),
      _c(
        "v-card",
        { attrs: { id: "relatedLayers" } },
        [
          _c(
            "v-card-text",
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.filteredChildLayers,
                  "hide-default-footer": "",
                  "disable-pagination": "",
                  "item-key": "map_service_id",
                },
                on: { "click:row": _vm.editRelatedLayer },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "top",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-space-between align-center",
                            },
                            [
                              _c(
                                "v-chip",
                                {
                                  staticClass: "d-flex",
                                  on: {
                                    click: function ($event) {
                                      _vm.showActiveLayers =
                                        !_vm.showActiveLayers
                                      _vm.layerForEditing = {}
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "chip overflow-hidden text-truncate my-0 py-0 cursor-pointer",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.showActiveLayers
                                              ? "Active"
                                              : "Archived"
                                          ) +
                                          " "
                                      ),
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v(
                                          " " + _vm._s(_vm.mdiSyncCircle) + " "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    text: "",
                                    disabled: !_vm.canManageLayers,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.showAddLayerDialog = true
                                    },
                                  },
                                },
                                [_vm._v(" + Add Layer ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: `item.ref_field`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(" " + _vm._s(_vm.getRefFieldName(item)) + " "),
                        ]
                      },
                    },
                    {
                      key: `item.actions`,
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-menu",
                            {
                              attrs: { "offset-y": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  icon: "",
                                                  disabled:
                                                    !_vm.canManageLayers,
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.mdiDotsVertical) +
                                                  " "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              item.is_active
                                ? _c(
                                    "v-list",
                                    { staticClass: "py-0" },
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          staticClass: "py-1",
                                          on: {
                                            click: function ($event) {
                                              return _vm.editRelatedLayer(item)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-2",
                                              attrs: { color: "primary" },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.mdiPencil) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "overflowText" },
                                            [_vm._v("Edit")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.updateActiveSetting(
                                                item,
                                                false
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-2",
                                              attrs: { color: "primary" },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.mdiArchiveArrowDown
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "overflowText" },
                                            [_vm._v("Archive")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              _vm.showConfirmDeleteLayerDialog = true
                                              _vm.layerForDeletion = item
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-2",
                                              attrs: { color: "primary" },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.mdiDelete) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "overflowText" },
                                            [_vm._v("Delete")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.updateActiveSetting(
                                                item,
                                                true
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-2",
                                              attrs: { color: "primary" },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.mdiArchiveArrowUp
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "overflowText" },
                                            [_vm._v("Make Active")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              _vm.showConfirmDeleteLayerDialog = true
                                              _vm.layerForDeletion = item
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-2",
                                              attrs: { color: "primary" },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.mdiDelete) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "overflowText" },
                                            [_vm._v("Delete")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _vm.showAddLayerDialog
                ? _c("AddLayerDialog", {
                    attrs: {
                      showAddLayerDialog: _vm.showAddLayerDialog,
                      layer: _vm.layer,
                    },
                    on: {
                      close: function ($event) {
                        _vm.showAddLayerDialog = false
                      },
                      "layer-added": _vm.onLayerAdded,
                    },
                  })
                : _vm._e(),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "600px", persistent: "" },
                  model: {
                    value: _vm.showEditLayerDialog,
                    callback: function ($$v) {
                      _vm.showEditLayerDialog = $$v
                    },
                    expression: "showEditLayerDialog",
                  },
                },
                [
                  _vm.showEditLayerDialog
                    ? _c(_vm.EditLayerForm, {
                        tag: "component",
                        attrs: { selectedLayer: _vm.layerForEditing },
                        on: {
                          "edit-layer-form-close": function ($event) {
                            _vm.showEditLayerDialog = false
                          },
                          "edit-layer-form-submitted":
                            _vm.onEditLayerFormSubmitted,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.showConfirmDeleteLayerDialog
                ? _c("DeleteLayerDialog", {
                    attrs: {
                      showConfirmDeleteLayerDialog:
                        _vm.showConfirmDeleteLayerDialog,
                      layerForDeletion: _vm.layerForDeletion,
                    },
                    on: {
                      close: function ($event) {
                        _vm.showConfirmDeleteLayerDialog = false
                      },
                      "layer-deleted": _vm.onLayerDeleted,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }