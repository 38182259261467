<template>
  <v-dialog
    :value="showAdvancedInformationCreateSettingsDialog"
    max-width="600px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-toolbar dark color="primary">
      <v-toolbar-title>
        <div><b>Create</b></div>
        <div class="caption">Advanced Settings</div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="$emit('advanced-settings-dialog-close')">
        <v-icon>
          {{ mdiClose }}
        </v-icon>
      </v-btn>
    </v-toolbar>
    <validation-observer slim ref="advancedSettingsForm" v-slot="{ invalid }">
      <v-card class="rounded-0">
        <v-card-text class="py-3">
          <div class="d-flex gap">
            <validation-provider
              v-slot="{ errors, valid }"
              name="Condition"
              rules="required"
              class="select"
            >
              <v-select
                autocomplete="off"
                v-model="selectedItemOptions.information.create.applyCreate"
                :items="CREATE_CONDITION_CHOICES"
                name="condition"
                label="Condition"
                :error-messages="errors"
                :success="valid"
                item-text="label"
                item-value="value"
                @change="onConditionChange"
              >
              </v-select>
            </validation-provider>
          </div>

          <section
            v-if="
              CONDITION_CHOICES.CONDITIONAL ===
              selectedItemOptions.information.create.applyCreate
            "
          >
            <p class="caption py-0 my-0">Conditional Settings</p>

            <div
              class="d-flex align-end gap py-0 my-0"
              v-if="
                selectedItemOptions.information.create.applyCreateConditions
                  .conditions.length > 1
              "
            >
              <div>
                <b> Apply if </b>
              </div>

              <validation-provider
                v-slot="{ errors, valid }"
                rules="required"
                style="width: 70px"
              >
                <v-select
                  autocomplete="off"
                  height="20px"
                  v-model="
                    selectedItemOptions.information.create.applyCreateConditions
                      .applyIf
                  "
                  :items="APPLY_IF_CHOICES"
                  name="applyIf"
                  :error-messages="errors"
                  :success="valid"
                  item-text="label"
                  item-value="value"
                  hide-details
                >
                </v-select>
              </validation-provider>

              <div>
                <b> of the conditions are matched </b>
              </div>
            </div>

            <template v-if="showConditions">
              <div
                class="d-flex align-center gap"
                v-for="(condition, index) of selectedItemOptions.information
                  .create.applyCreateConditions.conditions"
                :key="condition.id"
              >
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Dependant"
                  rules="required"
                >
                  <v-select
                    autocomplete="off"
                    v-model="condition.type"
                    :items="dependantOptions"
                    label="Dependant"
                    :error-messages="errors"
                    :success="valid"
                    item-text="label"
                    item-value="value"
                    @change="onDependantChange()"
                    hide-details
                  ></v-select>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Field"
                  rules="required"
                  :key="condition.type"
                  v-if="condition.type"
                  style="width: 120px"
                >
                  <v-select
                    autocomplete="off"
                    :key="condition.type"
                    v-model="condition.field"
                    :items="getVisibilityValueOptions(condition)"
                    label="Field"
                    :error-messages="errors"
                    :success="valid"
                    item-text="label"
                    item-value="value"
                    hide-details
                  ></v-select>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Condition"
                  rules="required"
                  class="operator-dropdown"
                  v-if="condition.type"
                >
                  <v-select
                    autocomplete="off"
                    v-model="condition.condition"
                    :items="comparisonTypes"
                    name="Condition"
                    :error-messages="errors"
                    :success="valid"
                    item-text="label"
                    item-value="value"
                    @change="onSubmit()"
                    hide-details
                  >
                    <template #item="{ item: { label, value } }">
                      <div>
                        <MatchIcon :value="value" />
                        {{ label }}
                      </div>
                    </template>

                    <template #selection="{ item: { value } }">
                      <div>
                        <MatchIcon :value="value" />
                      </div>
                    </template>
                  </v-select>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Value"
                  rules="required"
                  v-if="condition.type"
                  style="min-width: 170px"
                >
                  <v-select
                    autocomplete="off"
                    v-if="getGisDataFieldOptionChoices(condition).length > 0"
                    v-model="condition.value"
                    :items="getGisDataFieldOptionChoices(condition)"
                    label="Value"
                    :error-messages="errors"
                    :success="valid"
                    item-text="label"
                    item-value="value"
                    hide-details
                  ></v-select>
                  <v-select
                    autocomplete="off"
                    v-else-if="getFormFieldOptionChoices(condition).length > 0"
                    v-model="condition.value"
                    :items="getFormFieldOptionChoices(condition)"
                    label="Value"
                    :error-messages="errors"
                    :success="valid"
                    item-text="label"
                    item-value="value"
                    hide-details
                  ></v-select>
                  <v-text-field
                    v-else
                    v-model="condition.value"
                    label="Value"
                    hide-details
                    :error-messages="errors"
                    :success="valid"
                    color="primary"
                    name="value"
                  />
                </validation-provider>

                <v-btn icon color="primary" @click="removeCondition(index)">
                  <v-icon>
                    {{ mdiDelete }}
                  </v-icon>
                </v-btn>
              </div>
            </template>

            <div class="mx-0 px-0">
              <v-btn
                text
                color="primary"
                :disabled="invalid"
                class="px-0 mx-0"
                @click="addCondition"
              >
                <v-icon x-small>
                  {{ mdiPlus }}
                </v-icon>
                Add Condition
              </v-btn>
            </div>
          </section>
        </v-card-text>

        <v-card-actions class="d-flex justify-end">
          <v-btn color="primary" :disabled="invalid" @click="onSubmit">
            Save Settings
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiDelete, mdiPlus } from "@mdi/js";
import axios from "axios";
import { cloneDeep } from "lodash";
import {
  comparisonTypes,
  defaultValueTypes,
  requiredOptions,
  visibilityOptions,
  orgDataOptions,
  locateRequestDataValues,
  displayInfoOnlyOptions,
  createOptions,
} from "@/constants/choices";
import { v4 as uuidv4 } from "uuid";
import MatchIcon from "@/components/shared/MatchIcon";
import {
  CONDITION_CHOICES,
  CREATE_CONDITION_CHOICES,
  APPLY_IF,
  APPLY_IF_CHOICES,
  DEPENDANT_OPTIONS,
} from "@/constants/advancedSettings";
import MATCH_CHOICE_VALUES from "@/constants/matchChoiceValues";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "AdvancedCreateSettingsDialog",
  props: {
    showAdvancedInformationCreateSettingsDialog: Boolean,
    formDefinition: Object,
    value: Object,
    layer: Object,
    selectedItem: Object,
  },
  components: { MatchIcon },
  computed: {
    fieldChoices() {
      const fieldChoices =
        this.fields?.map(({ name, alias }) => ({
          value: name,
          label: alias || name,
        })) ?? [];
      return fieldChoices;
    },
    userDataChoices() {
      const apiChoices = this.userDataFields.map(
        ({ user_data_field_id: value, name: label }) => ({
          value,
          label,
        })
      );
      return [
        { value: "f_name", label: "First Name" },
        { value: "l_name", label: "Last Name" },
        { value: "full_name", label: "Full Name" },
        { value: "email", label: "Email" },
        ...apiChoices,
      ];
    },
    gisDataChoices() {
      return this.gisDataFields.map(
        ({ gis_data_field_id: value, name, alias }) => ({
          value,
          label: alias || name,
        })
      );
    },
    dependantOptions() {
      if (this.layer?.service_type === "L") {
        return [
          {
            value: DEPENDANT_OPTIONS.LOCATE_DATA,
            label: "811 Ticket",
          },
          {
            value: DEPENDANT_OPTIONS.GIS,
            label: "GIS Field",
          },
          {
            value: DEPENDANT_OPTIONS.GIS_DATA,
            label: "UtiliSync Field",
          },
          {
            value: DEPENDANT_OPTIONS.FORM_ITEM,
            label: "Form Field",
          },
        ];
      }
      return [
        {
          value: DEPENDANT_OPTIONS.GIS,
          label: "GIS Field",
        },
        {
          value: DEPENDANT_OPTIONS.GIS_DATA,
          label: "UtiliSync Field",
        },
        {
          value: DEPENDANT_OPTIONS.FORM_ITEM,
          label: "Form Field",
        },
      ];
    },
    visibilityValueOptions() {
      if (this.selectedItemOptions.information.type === "SIGNATURE") {
        return [
          {
            value: null,
            label: "None",
          },
          {
            value: DEPENDANT_OPTIONS.USER,
            label: "User Data",
          },
          {
            value: DEPENDANT_OPTIONS.GIS_DATA,
            label: "UtiliSync Field",
          },
        ];
      }

      if (
        this.selectedItemOptions.information.isLong ||
        this.selectedItemOptions.information.type === "EMAIL"
      ) {
        const defaultValueTypes = this.defaultValueTypes.filter(
          ({ label }) => label !== "Organization Data"
        );
        if (this.layer?.service_type === "L") {
          defaultValueTypes.push({
            value: DEPENDANT_OPTIONS.LOCATE_DATA,
            label: "811 Ticket",
          });
        }
        return defaultValueTypes;
      }

      const defaultValueTypes = [...this.defaultValueTypes];

      if (this.layer?.service_type === "L") {
        defaultValueTypes.push({
          value: DEPENDANT_OPTIONS.LOCATE_DATA,
          label: "811 Ticket",
        });
      }
      return defaultValueTypes;
    },
    formFieldOptions() {
      const formDefinition = { ...this.formDefinition };
      const sectionItems = formDefinition.form.sections.map((s) => s.items);
      const flattenedSectionItems = sectionItems.flat();
      if (this.itemType === "section") {
        return flattenedSectionItems
          .filter(({ type: itemType, question: { type } = {} }) => {
            return (
              itemType !== "INFORMATION" &&
              !["MULTI_SELECT", "SIGNATURE", "PHOTO", "FILE"].includes(type)
            );
          })
          .map(({ id, number, question: { label } = {} }) => ({
            value: id,
            label: `${number} ${label}`,
          }));
      }
      if (this.isRepeatingGroupItem) {
        return flattenedSectionItems
          .filter(({ type: itemType, question: { type, isLong } = {} }) => {
            if (type === "GROUP") {
              return true;
            }
            return (
              itemType !== "INFORMATION" &&
              !["MULTI_SELECT", "SIGNATURE", "PHOTO", "FILE"].includes(type) &&
              !isLong
            );
          })
          .map(({ id, number, question: { label } = {} }) => ({
            value: id,
            label: `${number} ${label}`,
          }));
      }
      return flattenedSectionItems
        .filter(({ id, type: itemType, question: { type, isLong } = {} }) => {
          if (type === "GROUP") {
            return true;
          }
          return (
            id !== this.selectedItem?.id &&
            itemType !== "INFORMATION" &&
            !["MULTI_SELECT", "SIGNATURE", "PHOTO", "FILE"].includes(type) &&
            !isLong
          );
        })
        .map(({ id, number, question: { label } = {} }) => ({
          value: id,
          label: `${number} ${label}`,
        }));
    },
  },
  data() {
    return {
      mdiClose,
      mdiDelete,
      mdiPlus,
      fields: [],
      gisDataFields: [],
      userDataFields: [],
      selectedItemOptions: {},
      comparisonTypes,
      defaultValueTypes,
      requiredOptions,
      visibilityOptions,
      orgDataOptions,
      locateRequestDataValues,
      displayInfoOnlyOptions,
      createOptions,
      CONDITION_CHOICES,
      CREATE_CONDITION_CHOICES,
      showConditions: false,
      APPLY_IF_CHOICES,
    };
  },
  beforeMount() {
    this.getUserDataFields();
    this.getSelectedItemOptions();
    this.getGisDataFields();
    this.populateSettingsValue();
    this.showConditions = true;
  },
  methods: {
    populateSettingsValue() {
      if (!this.selectedItemOptions?.information?.create?.applyCreate) {
        this.selectedItemOptions.information.create.applyCreate =
          CONDITION_CHOICES.ALWAYS;
      }
    },
    onConditionChange() {
      if (!this.selectedItemOptions.information.create.applyCreate) {
        this.selectedItemOptions.information.create.applyCreate =
          CONDITION_CHOICES.ALWAYS;
      }

      if (
        this.selectedItemOptions.information.create.applyCreate ===
        CONDITION_CHOICES.ALWAYS
      ) {
        this.selectedItemOptions.information.create.applyCreateConditions.conditions =
          [];
      } else if (
        this.selectedItemOptions.information.create.applyCreate ===
        CONDITION_CHOICES.CONDITIONAL
      ) {
        if (
          this.selectedItemOptions.information.create.applyCreateConditions
            .conditions.length === 0
        ) {
          const condition = {
            id: uuidv4(),
            type: null,
            field: null,
            value: null,
            condition: MATCH_CHOICE_VALUES.EQUAL,
          };
          this.selectedItemOptions.information.create.applyCreateConditions.conditions.push(
            condition
          );
        } else {
          if (
            Array.isArray(
              this.selectedItemOptions?.information?.create
                ?.applyCreateConditions?.conditions
            )
          ) {
            for (const c of this.selectedItemOptions.information.create
              .applyCreateConditions.conditions) {
              c.condition = MATCH_CHOICE_VALUES.EQUAL;
            }
          }
        }
      }
    },
    getFormFieldOptionChoices(condition) {
      const formDefinition = { ...this.formDefinition };
      const sectionItems = formDefinition.form.sections.map((s) => s.items);
      const flattenedSectionItems = sectionItems.flat();
      const dependantItem = flattenedSectionItems.find((it) => {
        return +it.id === +condition.field;
      });
      if (condition.type === DEPENDANT_OPTIONS.FORM_ITEM) {
        return (
          dependantItem?.information?.options?.map(({ value, label }) => ({
            value,
            label,
          })) ?? []
        );
      }
      return [];
    },
    getGisDataFieldOptionChoices(condition) {
      if (condition.type === "GIS_DATA") {
        return (
          this.gisDataFields
            ?.find((g) => g.gis_data_field_id === this.createOptions.field)
            ?.gis_data_field_options?.map(({ label, value }) => {
              return {
                label,
                value,
              };
            }) ?? []
        );
      }
      return [];
    },
    getVisibilityValueOptions(condition) {
      if ([DEPENDANT_OPTIONS.LOCATE_DATA].includes(condition.type)) {
        return this.locateRequestDataValues;
      } else if ([DEPENDANT_OPTIONS.USER].includes(condition.type)) {
        return this.userDataChoices;
      } else if ([DEPENDANT_OPTIONS.GIS_DATA].includes(condition.type)) {
        return this.gisDataChoices;
      } else if ([DEPENDANT_OPTIONS.ORGANIZATION].includes(condition.type)) {
        return this.orgDataOptions;
      } else if ([DEPENDANT_OPTIONS.FORM_ITEM].includes(condition.type)) {
        return this.formFieldOptions;
      }
      return [];
    },
    async getGisDataFields() {
      if (this.formDefinition?.map_service_id) {
        const {
          data: { results },
        } = await axios.get(`${APIURL}/gis_data_fields`, {
          params: { map_service_id: this.formDefinition.map_service_id },
        });
        this.gisDataFields = results;
      }
    },
    async getUserDataFields() {
      const { user_group_id: userGroupId } = JSON.parse(
        localStorage.getItem("auth")
      );

      const {
        data: { results },
      } = await axios.get(`${APIURL}/user_data_fields`, {
        params: {
          user_group_id: userGroupId,
        },
      });
      this.userDataFields = results;
    },
    async getFeatureService() {
      try {
        const { map_service_id: mapServiceId } = this.formDefinition;
        const {
          data: {
            results: { service_url: featureServiceUrl, token_type: tokenType },
          },
        } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);

        const token =
          tokenType === "AGOL" ? localStorage.getItem("esri_token") : undefined;
        const params = {
          f: "pjson",
          token,
        };
        const {
          data: { fields },
        } = await axios.get(featureServiceUrl, { params });
        this.fields = fields;
      } catch (error) {
        this.fields = [];
      }
    },
    getSelectedItemOptions() {
      this.selectedItemOptions = cloneDeep(this.value);

      if (!this.selectedItemOptions?.information?.create) {
        this.selectedItemOptions.information.create = {
          applyCreate: null,
          applyCreateConditions: { conditions: [], applyIf: APPLY_IF.ANY },
        };
      }
      const { condition, dependantId, value } =
        this.selectedItemOptions?.information?.create ?? {};

      if (condition) {
        let applyCreate;
        if (
          [CONDITION_CHOICES.ALWAYS, CONDITION_CHOICES.NEVER].includes(
            condition
          )
        ) {
          applyCreate = condition;
        } else {
          applyCreate = CONDITION_CHOICES.NEVER;
        }
        this.selectedItemOptions.information.create = {
          applyCreate,
          applyCreateConditions: {
            applyIf: APPLY_IF.ANY,
            conditions: [
              {
                type: DEPENDANT_OPTIONS.FORM_ITEM,
                field: dependantId,
                value,
                condition,
              },
            ],
          },
        };
      }

      if (
        !this.selectedItemOptions?.information?.create?.applyCreateConditions
      ) {
        this.selectedItemOptions.information.create.applyCreateConditions = {
          applyIf: APPLY_IF.ANY,
          conditions: [],
        };
      }

      if (
        !this.selectedItemOptions.information?.create?.applyCreateConditions
          ?.applyIf
      ) {
        this.selectedItemOptions.information.create.applyCreateConditions.applyIf =
          APPLY_IF.ANY;
      }

      this.selectedItemOptions.information.create.applyCreateConditions.conditions =
        this.selectedItemOptions.information.create.applyCreateConditions.conditions.map(
          (c) => {
            const { type, field, value, condition } = c;
            return {
              id: uuidv4(),
              type,
              field,
              value,
              condition,
            };
          }
        );

      if (!this.selectedItemOptions.information.forInformationOnly) {
        this.selectedItemOptions.information.forInformationOnly = false;
      }
    },
    async onSubmit() {
      const success = await this.$refs.advancedSettingsForm.validate();
      if (!success) {
        return;
      }
      const selectedItemOptions = cloneDeep(this.selectedItemOptions);
      selectedItemOptions.information.create.applyCreateConditions.conditions =
        selectedItemOptions.information.create.applyCreateConditions.conditions.map(
          (c) => {
            const { type, field, value, condition } = c;
            return { type, field, value, condition };
          }
        );
      this.$emit("input", selectedItemOptions);
      this.$emit("advanced-settings-dialog-close");
    },
    async addCondition() {
      this.showConditions = false;
      const newCondition = {
        id: uuidv4(),
        type: null,
        value: null,
        condition: MATCH_CHOICE_VALUES.EQUAL,
      };
      this.selectedItemOptions.information.create.applyCreateConditions.conditions.push(
        newCondition
      );
      await this.$nextTick();
      this.showConditions = true;
    },
    async onDependantChange() {
      this.showConditions = false;
      await this.$nextTick();
      this.showConditions = true;
    },
    async removeCondition(index) {
      this.showConditions = false;
      this.selectedItemOptions.information.create.applyCreateConditions.conditions.splice(
        index,
        1
      );
      await this.$nextTick();
      this.showConditions = true;
    },
  },
};
</script>

<style scoped>
.select {
  width: 33%;
}

.operator-dropdown {
  max-width: 50px;
}
</style>
