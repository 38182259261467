var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list-item",
    [
      _c(
        "v-list-item-content",
        [
          _c(
            "section",
            { staticClass: "d-flex align-center justify-space-between" },
            [
              _c("div", [_vm._v("Departments")]),
              _c(
                "div",
                { staticClass: "d-flex gap" },
                [
                  _c(
                    "v-chip",
                    {
                      attrs: {
                        color: _vm.layerIsInAllDepartments
                          ? "primary"
                          : undefined,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addLayerToAllDepartments()
                        },
                      },
                    },
                    [_vm._v(" All ")]
                  ),
                  _c(
                    "v-chip",
                    {
                      attrs: {
                        color: _vm.layerIsInSomeDepartments
                          ? "primary"
                          : undefined,
                      },
                      on: {
                        click: function ($event) {
                          _vm.showLayerDepartmentAccessDialog = true
                        },
                      },
                    },
                    [_vm._v(" Selected ")]
                  ),
                  _c(
                    "v-chip",
                    {
                      attrs: {
                        color: _vm.layerIsInNoDepartments
                          ? "primary"
                          : undefined,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.removeLayerFromAllDepartments()
                        },
                      },
                    },
                    [_vm._v(" None ")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm.showLayerDepartmentAccessDialog
            ? _c("LayerDepartmentAccessDialog", {
                attrs: {
                  showLayerDepartmentAccessDialog:
                    _vm.showLayerDepartmentAccessDialog,
                  layer: _vm.layer,
                  departmentsThatHaveAccessToLayer:
                    _vm.newDepartmentsThatHaveAccessToLayer,
                },
                on: {
                  "department-dialog-close": function ($event) {
                    _vm.showLayerDepartmentAccessDialog = false
                  },
                  "access-updated": _vm.onAccessUpdated,
                },
              })
            : _vm._e(),
          _c("LayerIsInTheFollowingMapDialog", {
            attrs: {
              showLayerIsInTheFollowingMapDialog:
                _vm.showLayerIsInTheFollowingMapDialog,
              showLayerIsInTheFollowingMapDialogData:
                _vm.showLayerIsInTheFollowingMapDialogData,
            },
            on: {
              close: function ($event) {
                _vm.showLayerIsInTheFollowingMapDialog = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }