<template>
  <div>
    <LayerInfoForm
      :layer="layer"
      @layer-changed="$emit('layer-changed', $event)"
    />
    <LayerDetails
      :layer="layer"
      @symbology-saved="$emit('symbology-saved')"
      @open-edit-form-dialog="$emit('open-edit-form-dialog', $event)"
      @create-new-form="$emit('create-new-form')"
    />
  </div>
</template>

<script>
import LayerInfoForm from "@/components/layers/edit-layer-form/shared/LayerInfoForm";
import LayerDetails from "@/components/layers/edit-layer-form/shared/LayerDetails";

export default {
  name: "LayerForm811",
  components: {
    LayerInfoForm,
    LayerDetails,
  },
  props: {
    layer: Object,
  },
};
</script>
