<template>
  <validation-observer ref="calculationForm">
    <v-list
      dense
      style="max-height: calc(100vh - 56px)"
      class="overflow-y-auto"
    >
      <v-list-item class="ma-0 pa-0">
        <v-list-item-content>
          <validation-provider
            v-slot="{ errors, valid }"
            name="Label"
            rules="required"
          >
            <v-text-field
              v-model="selectedRepeatingGroupItemOptions.question.label"
              label="Label"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="name"
              @change="onSubmit()"
              ref="labelInput"
              :disabled="!canManageForms"
            />
          </validation-provider>
        </v-list-item-content>
      </v-list-item>

      <v-list-item class="ma-0 pa-0">
        <v-list-item-content>
          <v-card class="elevation-0 d-flex justify-space-between">
            <p class="caption">Calculation</p>
            <v-icon
              color="primary"
              class="mt-n2"
              @click="showCalculationDialog = true"
              :disabled="!canManageForms"
            >
              {{ mdiPencil }}
            </v-icon>
          </v-card>
          <v-card class="elevation-0">
            {{
              selectedRepeatingGroupItemOptions.question.calculation
                | groupReadableQuestion(formDefinition, groupItems)
            }}
          </v-card>

          <v-dialog
            v-model="showCalculationDialog"
            max-width="600px"
            :fullscreen="$vuetify.breakpoint.xsOnly"
          >
            <GroupFormulaForm
              v-if="showCalculationDialog"
              :groupItems="groupItems"
              :columnNumber="columnNumber"
              :formDefinition="formDefinition"
              :selectedRepeatingGroupItemOptions="
                selectedRepeatingGroupItemOptions
              "
              @formula-form-submitted="onFormulaFormSubmitted"
              @formula-form-close="showCalculationDialog = false"
            />
          </v-dialog>
        </v-list-item-content>
      </v-list-item>

      <v-list-item class="pa-0 ma-0">
        <v-list-item-content>
          <validation-provider
            v-slot="{ errors, valid }"
            name="Required"
            rules="required"
          >
            <v-select
              autocomplete="off"
              v-model="
                selectedRepeatingGroupItemOptions.question.required.condition
              "
              :items="groupItemRequiredOptions"
              label="Required"
              :error-messages="errors"
              :success="valid"
              item-text="label"
              item-value="value"
              @change="onSubmit()"
              :disabled="!canManageForms"
              hide-details="auto"
            ></v-select>
          </validation-provider>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </validation-observer>
</template>

<script>
import {
  comparisonTypes,
  defaultValueTypes,
  requiredOptions,
  visibilityOptions,
  createOptions,
} from "@/constants/choices";
import { mdiPencil } from "@mdi/js";
import { cloneDeep } from "lodash";
import GroupFormulaForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/calculation-form/GroupFormulaForm.vue";
import permissionsMixin from "@/mixins/permissionsMixin";
import { groupItemRequiredOptions } from "@/constants/choices";

export default {
  name: "TableFieldCalculationForm",
  components: {
    GroupFormulaForm,
  },
  props: {
    value: Object,
    groupItems: Array,
    selectedControlType: String,
    columnNumber: Number,
    formDefinition: Object,
  },
  data() {
    return {
      selectedRepeatingGroupItemOptions: {
        question: {},
      },
      defaultValueTypes,
      requiredOptions,
      visibilityOptions,
      comparisonTypes,
      mdiPencil,
      showCalculationDialog: false,
      requiredCondition: "ALWAYS",
      createCondition: "ALWAYS",
      createOptions,
      layer: {},
      showAdvancedVisibilitySettingsDialog: false,
      groupItemRequiredOptions,
    };
  },
  mixins: [permissionsMixin],
  beforeMount() {
    this.selectedRepeatingGroupItemOptions = cloneDeep(this.value);
    if (!this.selectedRepeatingGroupItemOptions?.question?.required) {
      this.selectedRepeatingGroupItemOptions.question.required = {
        condition: "NEVER",
      };
    }
  },
  methods: {
    async onSubmit() {
      const success = await this.$refs.calculationForm.validate();
      if (!success) {
        return;
      }
      this.$emit("input", this.selectedRepeatingGroupItemOptions);
    },
    onFormulaFormSubmitted(ev) {
      this.selectedRepeatingGroupItemOptions.question.calculation = ev;
      this.showCalculationDialog = false;
      const params = [...ev.matchAll(/{{(.*?)}}/g)];
      this.selectedRepeatingGroupItemOptions.question.watchIds = params.map(
        (p) => {
          const [, param] = p.toString().split(",");
          const [, columnNumber] = param.split(".");
          return +columnNumber;
        }
      );
      this.onSubmit();
    },
  },
};
</script>
