var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "validation-observer",
    { ref: "calculationForm" },
    [
      _c(
        "v-list",
        {
          staticClass: "overflow-y-auto",
          staticStyle: { "max-height": "calc(100vh - 56px)" },
          attrs: { dense: "" },
        },
        [
          _c(
            "v-list-item",
            { staticClass: "ma-0 pa-0" },
            [
              _c(
                "v-list-item-content",
                [
                  _c("validation-provider", {
                    attrs: { name: "Label", rules: "required" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors, valid }) {
                          return [
                            _c("v-text-field", {
                              ref: "labelInput",
                              attrs: {
                                label: "Label",
                                "hide-details": "auto",
                                "error-messages": errors,
                                success: valid,
                                color: "primary",
                                name: "name",
                                disabled: !_vm.canManageForms,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.onSubmit()
                                },
                              },
                              model: {
                                value:
                                  _vm.selectedRepeatingGroupItemOptions.question
                                    .label,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedRepeatingGroupItemOptions
                                      .question,
                                    "label",
                                    $$v
                                  )
                                },
                                expression:
                                  "selectedRepeatingGroupItemOptions.question.label",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item",
            { staticClass: "ma-0 pa-0" },
            [
              _c(
                "v-list-item-content",
                [
                  _c(
                    "v-card",
                    { staticClass: "elevation-0 d-flex justify-space-between" },
                    [
                      _c("p", { staticClass: "caption" }, [
                        _vm._v("Calculation"),
                      ]),
                      _c(
                        "v-icon",
                        {
                          staticClass: "mt-n2",
                          attrs: {
                            color: "primary",
                            disabled: !_vm.canManageForms,
                          },
                          on: {
                            click: function ($event) {
                              _vm.showCalculationDialog = true
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.mdiPencil) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c("v-card", { staticClass: "elevation-0" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("groupReadableQuestion")(
                            _vm.selectedRepeatingGroupItemOptions.question
                              .calculation,
                            _vm.formDefinition,
                            _vm.groupItems
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c(
                    "v-dialog",
                    {
                      attrs: {
                        "max-width": "600px",
                        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
                      },
                      model: {
                        value: _vm.showCalculationDialog,
                        callback: function ($$v) {
                          _vm.showCalculationDialog = $$v
                        },
                        expression: "showCalculationDialog",
                      },
                    },
                    [
                      _vm.showCalculationDialog
                        ? _c("GroupFormulaForm", {
                            attrs: {
                              groupItems: _vm.groupItems,
                              columnNumber: _vm.columnNumber,
                              formDefinition: _vm.formDefinition,
                              selectedRepeatingGroupItemOptions:
                                _vm.selectedRepeatingGroupItemOptions,
                            },
                            on: {
                              "formula-form-submitted":
                                _vm.onFormulaFormSubmitted,
                              "formula-form-close": function ($event) {
                                _vm.showCalculationDialog = false
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item",
            { staticClass: "pa-0 ma-0" },
            [
              _c(
                "v-list-item-content",
                [
                  _c("validation-provider", {
                    attrs: { name: "Required", rules: "required" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors, valid }) {
                          return [
                            _c("v-select", {
                              attrs: {
                                autocomplete: "off",
                                items: _vm.groupItemRequiredOptions,
                                label: "Required",
                                "error-messages": errors,
                                success: valid,
                                "item-text": "label",
                                "item-value": "value",
                                disabled: !_vm.canManageForms,
                                "hide-details": "auto",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.onSubmit()
                                },
                              },
                              model: {
                                value:
                                  _vm.selectedRepeatingGroupItemOptions.question
                                    .required.condition,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedRepeatingGroupItemOptions
                                      .question.required,
                                    "condition",
                                    $$v
                                  )
                                },
                                expression:
                                  "\n              selectedRepeatingGroupItemOptions.question.required.condition\n            ",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }