import TableRow from "@tiptap/extension-table-row";

const CustomTableRow = TableRow.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      pageBreakInside: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.pageBreakInside) {
            return {};
          }

          return {
            style: `page-break-inside: ${attributes.pageBreakInside}`,
          };
        },
        parseHTML: (element) => {
          return element.style.pageBreakInside.replace(/['"]+/g, "");
        },
      },
    };
  },
});

export default CustomTableRow;
