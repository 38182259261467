<template>
  <section>
    <div class="d-flex align-center justify-space-between">
      <div>
        {{ label }}
      </div>
      <div>
        <v-btn
          text
          color="primary"
          @click.stop="showRepeatingGroupDialog = true"
          :disabled="readOnly"
        >
          + Add
        </v-btn>
      </div>
    </div>

    <div>
      <v-responsive class="pa-0 ma-0">
        <table
          class="d-block overflow-x-auto"
          :style="{ 'max-width': selected ? '460px' : '485px' }"
        >
          <thead>
            <tr>
              <td v-for="h of tableHeaders" :key="h.id" class="px-3">
                <template v-if="h.information">
                  <div
                    v-if="isHtml(h.information.label)"
                    class="raw-html"
                    v-html="h.information.label"
                  ></div>
                  <b v-else>{{ h.information.label }}</b>
                </template>
                <template v-else-if="h.label">
                  <div
                    v-if="isHtml(h.label)"
                    class="raw-html"
                    v-html="h.label"
                  ></div>
                  <b v-else>{{ h.label }}</b>
                </template>
              </td>
              <td></td>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(v, index) of tableValues" :key="index">
              <td v-for="h of tableHeaders" :key="h.id" class="px-3">
                <template v-if="h.id.includes('QUESTION')">
                  <div
                    v-html="v[h.id]"
                    v-if="h.type === 'TEXT' && h.isLong"
                  ></div>
                  <div v-else>
                    {{ v[h.id] }}
                  </div>
                </template>
                <RepeatingGroupInfo
                  :item="h"
                  v-else-if="h.id.includes('INFORMATION')"
                />
              </td>
              <td class="d-flex justify-end">
                <v-btn
                  icon
                  @click.stop="
                    selectedValueIndex = index;
                    showEditRepeatingGroupDialog = true;
                    selectedValueItems = groupValues[index];
                  "
                >
                  <v-icon>
                    {{ mdiPencil }}
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </v-responsive>
    </div>

    <RepeatingGroupDialog
      v-if="showRepeatingGroupDialog"
      :showRepeatingGroupDialog="showRepeatingGroupDialog"
      :item="item"
      :readOnly="readOnly"
      :previewMode="previewMode"
      :formDefinition="formDefinition"
      @close-form="showRepeatingGroupDialog = false"
      @form-saved="onFormSaved"
    />

    <EditRepeatingGroupDialog
      v-if="showEditRepeatingGroupDialog"
      :showEditRepeatingGroupDialog="showEditRepeatingGroupDialog"
      :item="item"
      :selectedValueItems="selectedValueItems"
      :selectedValueIndex="selectedValueIndex"
      :readOnly="readOnly"
      :previewMode="previewMode"
      :formDefinition="formDefinition"
      @close-form="showEditRepeatingGroupDialog = false"
      @form-saved="onEditFormSaved"
      @delete-item="deleteItem"
    />
  </section>
</template>

<script>
import { mdiPencil } from "@mdi/js";
import RepeatingGroupDialog from "@/components/forms/form-builder-edit-form/item-preview/repeating-group/RepeatingGroupDialog";
import EditRepeatingGroupDialog from "@/components/forms/form-builder-edit-form/item-preview/repeating-group/EditRepeatingGroupDialog";
import RepeatingGroupInfo from "@/components/forms/form-builder-edit-form/item-preview/repeating-group/RepeatingGroupInfo";
import { cloneDeep } from "lodash";

export default {
  name: "RepeatingGroup",
  props: {
    item: Object,
    formDefinition: Object,
    value: Array,
    readOnly: Boolean,
    previewMode: Boolean,
    selected: Boolean,
  },
  components: {
    RepeatingGroupDialog,
    EditRepeatingGroupDialog,
    RepeatingGroupInfo,
  },
  beforeMount() {
    if (Array.isArray(this.value)) {
      this.groupValues = cloneDeep(this.value);
    }
  },
  methods: {
    isHtml(value) {
      return Boolean(value?.match?.(/<[^>]*>/g));
    },
    onFormSaved(savedValues) {
      this.groupValues.push(savedValues);
      this.showRepeatingGroupDialog = false;
      this.$emit("input", this.groupValues);
    },
    onEditFormSaved(savedValues) {
      this.$set(this.groupValues, this.selectedValueIndex, [...savedValues]);
      this.selectedValueIndex = undefined;
      this.showEditRepeatingGroupDialog = false;
      this.$emit("input", this.groupValues);
    },
    deleteItem(index) {
      this.groupValues.splice(index, 1);
      this.selectedValueIndex = undefined;
      this.showEditRepeatingGroupDialog = false;
      this.$emit("input", this.groupValues);
    },
  },
  data() {
    return {
      mdiPencil,
      showRepeatingGroupDialog: false,
      showEditRepeatingGroupDialog: false,
      selectedValueIndex: undefined,
      selectedValueItems: [],
      groupValues: [],
    };
  },
  computed: {
    label() {
      return this.item?.question?.label;
    },
    tableHeaders() {
      const headers = this.item.question.groupedItems
        .filter((g) => {
          const typeIncluded = !["FILE", "PHOTO", "SIGNATURE"].includes(
            g?.question?.type
          );
          const isDynamic = g?.information?.dynamic;
          return typeIncluded || isDynamic;
        })
        .map((g) => {
          const { question, information, id } = g;
          if (question) {
            const { label, type, isLong } = question;
            return { id: `QUESTION-${id}`, label, type, isLong };
          } else if (information) {
            const { label, type, isLong } = information;
            return { id: `INFORMATION-${id}`, label, type, isLong };
          }
        });
      return headers;
    },
    tableValues() {
      if (!Array.isArray(this.item?.value)) {
        return [];
      }
      const values = this.item.value.map((v) => {
        const entries = v.map((it) => {
          const { id, value } = it;
          if (it?.question) {
            if (it?.question?.type === "MULTI_SELECT") {
              const formattedValue = value?.map((v) => v.val).join(", ");
              return [`QUESTION-${id}`, formattedValue];
            }
            return [`QUESTION-${id}`, value];
          } else {
            return [`INFORMATION-${id}`, value];
          }
        });
        return Object.fromEntries(entries);
      });
      return values;
    },
  },
};
</script>

<style scoped>
.raw-html >>> p {
  margin-bottom: 0;
}
</style>

<style>
.v-data-table {
  overflow-x: auto !important;
}
</style>
