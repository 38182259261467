var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("LayerInfoForm", {
        attrs: { layer: _vm.layer },
        on: {
          "layer-changed": function ($event) {
            return _vm.$emit("layer-changed", $event)
          },
        },
      }),
      !_vm.layer.parent_map_service_id
        ? _c("UtiliSyncRelatedLayers", {
            attrs: { layer: _vm.layer },
            on: {
              "layer-changed": function ($event) {
                return _vm.$emit("layer-changed", $event)
              },
            },
          })
        : _vm._e(),
      _c("LayerDetails", {
        attrs: { layer: _vm.layer },
        on: {
          "symbology-saved": function ($event) {
            return _vm.$emit("symbology-saved")
          },
          "open-edit-form-dialog": function ($event) {
            return _vm.$emit("open-edit-form-dialog", $event)
          },
          "create-new-form": function ($event) {
            return _vm.$emit("create-new-form")
          },
          "utilisync-fields-saved": _vm.onUtilisyncFieldsSaved,
          "gis-fields-saved": _vm.onGisFieldsSaved,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }