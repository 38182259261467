var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "div",
        { staticClass: "d-flex align-center justify-space-between" },
        [
          _c("div", [_vm._v("Advanced Settings")]),
          _c(
            "v-btn",
            {
              attrs: {
                text: "",
                color: "primary",
                disabled: !_vm.canManageForms,
              },
              on: { click: _vm.onAddClick },
            },
            [_vm._v(" + Add ")]
          ),
        ],
        1
      ),
      _vm.showAddAdvancedInformationSettingsDialog
        ? _c("AddAdvancedInformationSettingsDialog", {
            attrs: {
              showAddAdvancedInformationSettingsDialog:
                _vm.showAddAdvancedInformationSettingsDialog,
              selectedItemOptions: _vm.selectedItemOptions,
              oldSelectedItemOptions: _vm.oldSelectedItemOptions,
              showVisibilityChoice: _vm.showVisibilityChoice,
              showDefaultChoice: _vm.showDefaultChoice,
              showRequireChoice: _vm.showRequireChoice,
              showCreateChoice: _vm.showCreateChoice,
            },
            on: {
              "advanced-settings-selected": _vm.onAdvancedSettingSelected,
              "add-advanced-settings-dialog-close": function ($event) {
                _vm.showAddAdvancedInformationSettingsDialog = false
              },
            },
          })
        : _vm._e(),
      _vm.showAdvancedInformationVisibilitySettingsDialog
        ? _c("AdvancedInformationVisibilitySettingsDialog", {
            attrs: {
              showAdvancedInformationVisibilitySettingsDialog:
                _vm.showAdvancedInformationVisibilitySettingsDialog,
              formDefinition: _vm.formDefinition,
              layer: _vm.layer,
              selectedItem: _vm.selectedItem,
            },
            on: {
              "advanced-settings-dialog-close": function ($event) {
                _vm.showAdvancedInformationVisibilitySettingsDialog = false
              },
              input: _vm.onInput,
            },
            model: {
              value: _vm.selectedItemOptions,
              callback: function ($$v) {
                _vm.selectedItemOptions = $$v
              },
              expression: "selectedItemOptions",
            },
          })
        : _vm._e(),
      _vm.showAdvancedInformationCreateSettingsDialog
        ? _c("AdvancedInformationCreateSettingsDialog", {
            attrs: {
              showAdvancedInformationCreateSettingsDialog:
                _vm.showAdvancedInformationCreateSettingsDialog,
              formDefinition: _vm.formDefinition,
              layer: _vm.layer,
              selectedItem: _vm.selectedItem,
            },
            on: {
              "advanced-settings-dialog-close": function ($event) {
                _vm.showAdvancedInformationCreateSettingsDialog = false
              },
              input: function ($event) {
                return _vm.onSubmit()
              },
            },
            model: {
              value: _vm.selectedItemOptions,
              callback: function ($$v) {
                _vm.selectedItemOptions = $$v
              },
              expression: "selectedItemOptions",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }