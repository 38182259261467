<template>
  <div>
    <p class="caption mt-6 mb-3">Related Layers</p>

    <v-card id="relatedLayers">
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="filteredChildLayers"
          hide-default-footer
          disable-pagination
          item-key="map_service_id"
          @click:row="editRelatedLayer"
        >
          <template #top>
            <div class="d-flex justify-space-between align-center">
              <v-chip
                class="d-flex"
                @click="
                  showActiveLayers = !showActiveLayers;
                  layerForEditing = {};
                "
              >
                <div
                  class="chip overflow-hidden text-truncate my-0 py-0 cursor-pointer"
                >
                  {{ showActiveLayers ? "Active" : "Archived" }}
                  <v-icon small>
                    {{ mdiSyncCircle }}
                  </v-icon>
                </div>
              </v-chip>

              <v-btn
                color="primary"
                text
                :disabled="!canManageLayers"
                @click="showAddLayerDialog = true"
              >
                + Add Layer
              </v-btn>
            </div>
          </template>

          <template v-slot:[`item.ref_field`]="{ item }">
            {{ getRefFieldName(item) }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  :disabled="!canManageLayers"
                >
                  <v-icon>
                    {{ mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>
              <v-list class="py-0" v-if="item.is_active">
                <v-list-item class="py-1" @click="editRelatedLayer(item)">
                  <v-icon color="primary" class="mr-2">
                    {{ mdiPencil }}
                  </v-icon>
                  <div class="overflowText">Edit</div>
                </v-list-item>
                <v-list-item @click="updateActiveSetting(item, false)">
                  <v-icon color="primary" class="mr-2">
                    {{ mdiArchiveArrowDown }}
                  </v-icon>
                  <div class="overflowText">Archive</div>
                </v-list-item>
                <v-list-item
                  @click="
                    showConfirmDeleteLayerDialog = true;
                    layerForDeletion = item;
                  "
                >
                  <v-icon color="primary" class="mr-2">
                    {{ mdiDelete }}
                  </v-icon>
                  <div class="overflowText">Delete</div>
                </v-list-item>
              </v-list>
              <v-list v-else>
                <v-list-item @click="updateActiveSetting(item, true)">
                  <v-icon color="primary" class="mr-2">
                    {{ mdiArchiveArrowUp }}
                  </v-icon>
                  <div class="overflowText">Make Active</div>
                </v-list-item>
                <v-list-item
                  @click="
                    showConfirmDeleteLayerDialog = true;
                    layerForDeletion = item;
                  "
                >
                  <v-icon color="primary" class="mr-2">
                    {{ mdiDelete }}
                  </v-icon>
                  <div class="overflowText">Delete</div>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>

        <AddLayerDialog
          v-if="showAddLayerDialog"
          :showAddLayerDialog="showAddLayerDialog"
          :layer="layer"
          @close="showAddLayerDialog = false"
          @layer-added="onLayerAdded"
        />

        <v-dialog v-model="showEditLayerDialog" max-width="600px" persistent>
          <component
            :is="EditLayerForm"
            v-if="showEditLayerDialog"
            @edit-layer-form-close="showEditLayerDialog = false"
            @edit-layer-form-submitted="onEditLayerFormSubmitted"
            :selectedLayer="layerForEditing"
          />
        </v-dialog>

        <DeleteLayerDialog
          v-if="showConfirmDeleteLayerDialog"
          :showConfirmDeleteLayerDialog="showConfirmDeleteLayerDialog"
          :layerForDeletion="layerForDeletion"
          @close="showConfirmDeleteLayerDialog = false"
          @layer-deleted="onLayerDeleted"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import permissionsMixin from "@/mixins/permissionsMixin";
import AddLayerDialog from "@/components/layers/edit-layer-form/shared/utilisync-related-layers/AddLayerDialog.vue";
import EditLayerForm from "@/components/layers/EditLayerForm.vue";
import DeleteLayerDialog from "@/components/layers/edit-layer-form/shared/utilisync-related-layers/DeleteLayerDialog.vue";
import {
  mdiDotsVertical,
  mdiPencil,
  mdiSyncCircle,
  mdiArchiveArrowDown,
  mdiArchiveArrowUp,
  mdiDelete,
} from "@mdi/js";
import axios from "axios";
import { cloneDeep } from "lodash";

const APIURL = process.env.VUE_APP_API_URL;

const headers = [
  {
    text: "Layer Name",
    align: "start",
    sortable: true,
    value: "service_name",
  },
  {
    text: "Reference Field",
    align: "start",
    sortable: false,
    value: "ref_field",
  },
  {
    text: "Actions",
    align: "end",
    sortable: false,
    value: "actions",
  },
];

export default {
  name: "UtiliSyncRelatedLayers",
  mixins: [permissionsMixin],
  components: { AddLayerDialog, DeleteLayerDialog },
  props: {
    layer: Object,
  },
  data() {
    return {
      headers,
      relatedLayers: [],
      mdiDotsVertical,
      mdiPencil,
      mdiSyncCircle,
      mdiArchiveArrowDown,
      mdiArchiveArrowUp,
      mdiDelete,
      showActiveLayers: true,
      showAddLayerDialog: false,
      layerForEditing: {},
      showConfirmDeleteLayerDialog: false,
      layerForDeletion: {},
      showEditLayerDialog: false,
      EditLayerForm,
      gisDataFields: [],
    };
  },
  computed: {
    filteredChildLayers() {
      if (this.showActiveLayers) {
        return this.relatedLayers.filter((l) => l.is_active);
      } else {
        return this.relatedLayers.filter((l) => !l.is_active);
      }
    },
  },
  methods: {
    async onEditLayerFormSubmitted() {
      this.showEditLayerDialog = false;
      await this.getGisDataFields();
      await this.getRelatedLayers();
    },
    async getGisDataFields() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/gis_data_fields_by_user_group`);
      this.gisDataFields = results;
    },
    getRefFieldName(item) {
      const gisDataField = this.gisDataFields.find(
        (f) => f.gis_data_field_id === item.ref_field
      );
      return gisDataField?.name;
    },
    async onLayerAdded() {
      this.showAddLayerDialog = false;
      await this.getRelatedLayers();
    },
    async onLayerDeleted() {
      this.showConfirmDeleteLayerDialog = false;
      await this.getRelatedLayers();
    },
    async getRelatedLayers() {
      const {
        data: { results },
      } = await axios.get(
        `${APIURL}/map_services/${this.layer.map_service_id}/children`
      );
      this.relatedLayers = cloneDeep(results);
    },
    async updateActiveSetting(layer, isActive = true) {
      await axios.put(`${APIURL}/map_services/${layer.map_service_id}`, {
        ...layer,
        is_active: isActive,
      });
      await this.getRelatedLayers();
    },
    editRelatedLayer(layer) {
      this.layerForEditing = layer;
      this.showEditLayerDialog = true;
    },
  },
  async beforeMount() {
    await this.getRelatedLayers();
    await this.getGisDataFields();
  },
};
</script>
