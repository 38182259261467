<template>
  <v-list-item>
    <v-list-item-content>
      <section class="d-flex align-center justify-space-between">
        <div>Departments</div>

        <div class="d-flex gap">
          <v-chip
            :color="layerIsInAllDepartments ? 'primary' : undefined"
            @click="addLayerToAllDepartments()"
          >
            All
          </v-chip>
          <v-chip
            :color="layerIsInSomeDepartments ? 'primary' : undefined"
            @click="showLayerDepartmentAccessDialog = true"
          >
            Selected
          </v-chip>
          <v-chip
            :color="layerIsInNoDepartments ? 'primary' : undefined"
            @click="removeLayerFromAllDepartments()"
          >
            None
          </v-chip>
        </div>
      </section>

      <LayerDepartmentAccessDialog
        v-if="showLayerDepartmentAccessDialog"
        :showLayerDepartmentAccessDialog="showLayerDepartmentAccessDialog"
        :layer="layer"
        :departmentsThatHaveAccessToLayer="newDepartmentsThatHaveAccessToLayer"
        @department-dialog-close="showLayerDepartmentAccessDialog = false"
        @access-updated="onAccessUpdated"
      />

      <LayerIsInTheFollowingMapDialog
        :showLayerIsInTheFollowingMapDialog="showLayerIsInTheFollowingMapDialog"
        :showLayerIsInTheFollowingMapDialogData="
          showLayerIsInTheFollowingMapDialogData
        "
        @close="showLayerIsInTheFollowingMapDialog = false"
      />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import LayerDepartmentAccessDialog from "@/components/layers/edit-layer-form/shared/department-access-form/LayerDepartmentAccessDialog.vue";
import axios from "axios";
import { cloneDeep } from "lodash";
import LayerIsInTheFollowingMapDialog from "@/components/layers/edit-layer-form/shared/department-access-form/layer-department-access-dialog/LayerIsInTheFollowingMapDialog.vue";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "DepartmentsAccessForm",
  props: {
    layer: Object,
  },
  components: { LayerDepartmentAccessDialog, LayerIsInTheFollowingMapDialog },
  data() {
    return {
      departments: [],
      departmentMapServices: [],
      departmentsLayerIsIn: [],
      showLayerDepartmentAccessDialog: false,
      newDepartmentsThatHaveAccessToLayer: [],
      showLayerIsInTheFollowingMapDialog: false,
      showLayerIsInTheFollowingMapDialogData: {
        departmentMapsWithMapService: [],
        mapService: {},
      },
    };
  },
  computed: {
    layerIsInAllDepartments() {
      return (
        this.departments.length === 0 ||
        this.newDepartmentsThatHaveAccessToLayer.length ===
          this.departments.length
      );
    },
    layerIsInSomeDepartments() {
      return (
        this.newDepartmentsThatHaveAccessToLayer.length > 0 &&
        this.newDepartmentsThatHaveAccessToLayer.length <
          this.departments.length
      );
    },
    layerIsInNoDepartments() {
      return !this.layerIsInAllDepartments && !this.layerIsInSomeDepartments;
    },
  },
  methods: {
    async getLayerWithMapsThatDepartmentsHaveAccessTo(
      mapServiceIdWithDepartmentAccess,
      departmentIdsWithAccess
    ) {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/department_maps`);
      const flattenedMapServices = results.map((r) => r.map_services).flat();
      const departmentMapsWithMapService = results.filter((r) => {
        const { map_services: mapServices, department_id: departmentId } = r;
        return mapServices.find((ms) => {
          const { map_service_id: mapServiceId } = ms;
          return (
            mapServiceIdWithDepartmentAccess === mapServiceId &&
            departmentIdsWithAccess.includes(departmentId)
          );
        });
      });
      const mapService = flattenedMapServices.find(
        (ms) => ms.map_service_id === mapServiceIdWithDepartmentAccess
      );

      return {
        departmentMapsWithMapService,
        mapService,
      };
    },
    async getDepartmentsThatHaveAccessToLayer(mapServiceId) {
      const {
        data: { results },
      } = await axios.get(
        `${APIURL}/department_map_services/map_service/${mapServiceId}`
      );
      this.departmentsLayerIsIn = results;
      this.newDepartmentsThatHaveAccessToLayer = cloneDeep(
        this.departmentsLayerIsIn
      );
    },
    async getDepartments() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/departments`);
      this.departments = results;
    },
    async addLayerToAllDepartments() {
      this.newDepartmentsThatHaveAccessToLayer = this.departments.map((d) => {
        const { department_id: departmentId } = d;
        return {
          map_service_id: this.layer.map_service_id,
          department_id: departmentId,
        };
      });
      this.$emit("access-updated", this.newDepartmentsThatHaveAccessToLayer);
    },
    async removeLayerFromAllDepartments() {
      const departmentIds = this.newDepartmentsThatHaveAccessToLayer.map(
        (d) => d.department_id
      );
      const mapServicesToDeleteWithDepartmentMapAccess =
        await this.getLayerWithMapsThatDepartmentsHaveAccessTo(
          this.layer.map_service_id,
          departmentIds
        );
      const { departmentMapsWithMapService } =
        mapServicesToDeleteWithDepartmentMapAccess;
      if (departmentMapsWithMapService.length > 0) {
        this.showLayerIsInTheFollowingMapDialogData =
          mapServicesToDeleteWithDepartmentMapAccess;
        this.showLayerIsInTheFollowingMapDialog = true;
      } else {
        this.newDepartmentsThatHaveAccessToLayer = [];
        this.$emit("access-updated", this.newDepartmentsThatHaveAccessToLayer);
      }
    },
    async onAccessUpdated(newDepartmentsThatHaveAccessToLayer) {
      this.showLayerDepartmentAccessDialog = false;
      this.newDepartmentsThatHaveAccessToLayer =
        newDepartmentsThatHaveAccessToLayer;
      this.$emit("access-updated", newDepartmentsThatHaveAccessToLayer);
    },
  },
  beforeMount() {
    this.getDepartments();
  },
  watch: {
    "layer.map_service_id": {
      immediate: true,
      handler(mapServiceId) {
        this.getDepartmentsThatHaveAccessToLayer(mapServiceId);
      },
    },
  },
};
</script>
