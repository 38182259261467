import { render, staticRenderFns } from "./AddLayerDialog.vue?vue&type=template&id=16a5a5c7&"
import script from "./AddLayerDialog.vue?vue&type=script&lang=js&"
export * from "./AddLayerDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('16a5a5c7')) {
      api.createRecord('16a5a5c7', component.options)
    } else {
      api.reload('16a5a5c7', component.options)
    }
    module.hot.accept("./AddLayerDialog.vue?vue&type=template&id=16a5a5c7&", function () {
      api.rerender('16a5a5c7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/layers/edit-layer-form/shared/utilisync-related-layers/AddLayerDialog.vue"
export default component.exports