import { v4 as uuidv4 } from "uuid";

const generateSingleSymbol = () => {
  return {
    fill_color: "#008adb",
    label: undefined,
    max_value: 0,
    min_value: 0,
    outline_color: "rgb(255, 255, 255)",
    picture_size: 32,
    renderer_symbol_id: uuidv4(),
    style: "Solid",
    symbol_type: "shape",
    url: "/img/mapSymbols/BlueCircleLargeB.png",
    width: 3,
    gis_data_field_option_id: null,
  };
};

export default generateSingleSymbol;
