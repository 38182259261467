var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isVisible
    ? _c(
        "section",
        { staticClass: "d-flex align-center px-6 py-4" },
        [
          _vm.canManageForms
            ? _c("v-icon", { staticClass: "mr-2 cursor-pointer handle" }, [
                _vm._v(
                  " " +
                    _vm._s(!_vm.previewMode ? _vm.mdiDragVertical : "") +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.item && _vm.item.question
            ? [
                ["TEXT", "EMAIL", "NUMBER"].includes(_vm.item.question.type)
                  ? _c("TextInput", {
                      attrs: {
                        item: _vm.item,
                        color: "primary",
                        formDefinition: _vm.formDefinition,
                        readOnly: _vm.readOnly,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$emit(
                            "input",
                            _vm.item.value,
                            _vm.sectionId,
                            _vm.item.id
                          )
                        },
                      },
                      model: {
                        value: _vm.item.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "value", $$v)
                        },
                        expression: "item.value",
                      },
                    })
                  : _vm._e(),
                ["SIGNATURE"].includes(_vm.item.question.type)
                  ? _c("validation-provider", {
                      attrs: {
                        slim: "",
                        name: _vm.item.question.label,
                        rules: {
                          required: _vm.isRequired,
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("SignaturePad", {
                                  attrs: {
                                    label: `${_vm.item.number} ${_vm.item.question.label}`,
                                    selected: _vm.selected,
                                    formDefinition: _vm.formDefinition,
                                    errors: errors,
                                    valid: valid,
                                    readOnly: _vm.readOnly,
                                  },
                                  model: {
                                    value: _vm.item.value,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.item, "value", $$v)
                                    },
                                    expression: "item.value",
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4176958680
                      ),
                    })
                  : _vm._e(),
                ["FILE"].includes(_vm.item.question.type)
                  ? _c("validation-provider", {
                      staticClass: "list-item",
                      attrs: {
                        name: _vm.item.question.label,
                        rules: {
                          required: _vm.isRequired,
                        },
                        slim: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                [
                                  _vm.item.question.isImage
                                    ? _c("PhotoInput", {
                                        attrs: {
                                          label: `${_vm.item.number} ${_vm.item.question.label}`,
                                          allowMultiple: _vm.item.allowMultiple,
                                          formResultId:
                                            _vm.formDefinition.form_result_id,
                                          maxWidthHeight:
                                            _vm.item.question.maxWidthHeight,
                                          showDescription:
                                            _vm.item.question.showDescription,
                                          readOnly: _vm.readOnly,
                                        },
                                        model: {
                                          value: _vm.item.value,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.item, "value", $$v)
                                          },
                                          expression: "item.value",
                                        },
                                      })
                                    : _c("FileInput", {
                                        attrs: {
                                          label: `${_vm.item.number} ${_vm.item.question.label}`,
                                          allowMultiple: _vm.item.allowMultiple,
                                          formResultId:
                                            _vm.formDefinition.form_result_id,
                                          showDescription:
                                            _vm.item.question.showDescription,
                                          readOnly: _vm.readOnly,
                                        },
                                        model: {
                                          value: _vm.item.value,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.item, "value", $$v)
                                          },
                                          expression: "item.value",
                                        },
                                      }),
                                ],
                                !valid
                                  ? _c("p", { staticClass: "caption" }, [
                                      _vm._v(_vm._s(errors[0])),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4132261685
                      ),
                    })
                  : _vm._e(),
                ["CALCULATION"].includes(_vm.item.question.type)
                  ? _c("CalculationInput", {
                      attrs: {
                        item: _vm.item,
                        formDefinition: _vm.formDefinition,
                        selected: _vm.selected,
                      },
                      model: {
                        value: _vm.item.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "value", $$v)
                        },
                        expression: "item.value",
                      },
                    })
                  : _vm._e(),
                ["POSITIVE_RESPONSE"].includes(_vm.item.question.type)
                  ? _c("PositiveResponseInput", {
                      attrs: {
                        item: _vm.item,
                        formDefinition: _vm.formDefinition,
                        selected: _vm.selected,
                        readOnly: _vm.readOnly,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$emit(
                            "input",
                            _vm.item.value,
                            _vm.sectionId,
                            _vm.item.id
                          )
                        },
                      },
                      model: {
                        value: _vm.item.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "value", $$v)
                        },
                        expression: "item.value",
                      },
                    })
                  : _vm._e(),
                ["SINGLE_SELECT"].includes(_vm.item.question.type)
                  ? _c("SingleSelectInput", {
                      attrs: {
                        item: _vm.item,
                        formDefinition: _vm.formDefinition,
                        selected: _vm.selected,
                        readOnly: _vm.readOnly,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$emit(
                            "input",
                            _vm.item.value,
                            _vm.sectionId,
                            _vm.item.id
                          )
                        },
                      },
                      model: {
                        value: _vm.item.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "value", $$v)
                        },
                        expression: "item.value",
                      },
                    })
                  : _vm._e(),
                ["MULTI_SELECT"].includes(_vm.item.question.type)
                  ? _c("MultiSelectInput", {
                      attrs: {
                        item: _vm.item,
                        formDefinition: _vm.formDefinition,
                        selected: _vm.selected,
                        readOnly: _vm.readOnly,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$emit(
                            "input",
                            _vm.item.value,
                            _vm.sectionId,
                            _vm.item.id
                          )
                        },
                      },
                      model: {
                        value: _vm.item.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "value", $$v)
                        },
                        expression: "item.value",
                      },
                    })
                  : _vm._e(),
                ["DATE"].includes(_vm.item.question.type)
                  ? _c("validation-provider", {
                      attrs: {
                        slim: "",
                        name: _vm.item.question.label,
                        rules: {
                          required: _vm.isRequired,
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _vm.readOnly
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "mb-n2",
                                        staticStyle: { width: "100%" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              color: "rgba(0, 0, 0, 0.38)",
                                              "font-size": "16px",
                                              "border-bottom":
                                                "1px rgba(0, 0, 0, 0.38) dashed",
                                              "padding-bottom": "4px",
                                              width: "100%",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.item.number) +
                                                " " +
                                                _vm._s(
                                                  _vm.item.question.label
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              color: "rgba(0, 0, 0, 0.38)",
                                              "font-size": "12px",
                                              "padding-top": "4px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.item.question.placeholder
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "v-menu",
                                      {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          label: `${_vm.item.number} ${_vm.item.question.label}`,
                                                          color: "primary",
                                                          hint: _vm.item
                                                            .question
                                                            .placeholder,
                                                          "persistent-hint": "",
                                                          "error-messages":
                                                            errors,
                                                          success: valid,
                                                          disabled:
                                                            _vm.readOnly,
                                                        },
                                                        model: {
                                                          value: _vm.item.value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.item,
                                                              "value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.value",
                                                        },
                                                      },
                                                      on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3114922332
                                        ),
                                      },
                                      [
                                        _c("v-date-picker", {
                                          model: {
                                            value: _vm.item.value,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.item, "value", $$v)
                                            },
                                            expression: "item.value",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1548802322
                      ),
                    })
                  : _vm._e(),
                ["TIME"].includes(_vm.item.question.type)
                  ? _c("validation-provider", {
                      attrs: {
                        slim: "",
                        name: _vm.item.question.label,
                        rules: {
                          required: _vm.isRequired,
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _vm.readOnly
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "mb-n2",
                                        staticStyle: { width: "100%" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              color: "rgba(0, 0, 0, 0.38)",
                                              "font-size": "16px",
                                              "border-bottom":
                                                "1px rgba(0, 0, 0, 0.38) dashed",
                                              "padding-bottom": "4px",
                                              width: "100%",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.item.number) +
                                                " " +
                                                _vm._s(
                                                  _vm.item.question.label
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              color: "rgba(0, 0, 0, 0.38)",
                                              "font-size": "12px",
                                              "padding-top": "4px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.item.question.placeholder
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "v-menu",
                                      {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "time-field",
                                                        attrs: {
                                                          label: `${_vm.item.number} ${_vm.item.question.label}`,
                                                          hint: _vm.item
                                                            .question
                                                            .placeholder,
                                                          "persistent-hint": "",
                                                          color: "primary",
                                                          "error-messages":
                                                            errors,
                                                          success: valid,
                                                        },
                                                        model: {
                                                          value: _vm.item.value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.item,
                                                              "value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.value",
                                                        },
                                                      },
                                                      on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1791272998
                                        ),
                                      },
                                      [
                                        _c("v-time-picker", {
                                          model: {
                                            value: _vm.item.value,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.item, "value", $$v)
                                            },
                                            expression: "item.value",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2752569732
                      ),
                    })
                  : _vm._e(),
                ["GROUP"].includes(_vm.item.question.type)
                  ? _c("RepeatingGroup", {
                      attrs: {
                        item: _vm.item,
                        formDefinition: _vm.formDefinition,
                        selected: _vm.selected,
                        readOnly: _vm.readOnly,
                        previewMode: _vm.previewMode,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$emit(
                            "input",
                            _vm.item.value,
                            _vm.sectionId,
                            _vm.item.id
                          )
                        },
                      },
                      model: {
                        value: _vm.item.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "value", $$v)
                        },
                        expression: "item.value",
                      },
                    })
                  : _vm._e(),
                ["ACTION_ITEM"].includes(_vm.item.question.type)
                  ? _c("ActionItem", {
                      attrs: {
                        item: _vm.item,
                        formDefinition: _vm.formDefinition,
                        selected: _vm.selected,
                        readOnly: _vm.readOnly,
                      },
                      model: {
                        value: _vm.item.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "value", $$v)
                        },
                        expression: "item.value",
                      },
                    })
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm.item.information
            ? [
                ["URL"].includes(_vm.item.information.type)
                  ? _c(
                      "v-card",
                      {
                        staticClass: "elevation-0",
                        style: _vm.selected ? "background-color: #fff8e1" : "",
                      },
                      [
                        _vm.readOnly
                          ? _c("p", { staticClass: "mb-n1" }, [
                              _vm._v(_vm._s(_vm.item.information.label)),
                            ])
                          : _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.item.information.url,
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.item.information.label) + " "
                                ),
                              ]
                            ),
                      ]
                    )
                  : _vm._e(),
                ["HEADER"].includes(_vm.item.information.type)
                  ? _c(
                      "v-card",
                      {
                        staticClass: "elevation-0",
                        style: _vm.selected ? "background-color: #fff8e1" : "",
                      },
                      [
                        _c("h2", [
                          _vm._v(
                            " " + _vm._s(_vm.item.information.label) + " "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                ["TEXT"].includes(_vm.item.information.type)
                  ? _c(
                      "v-card",
                      {
                        staticClass: "elevation-0 pt-4",
                        style: _vm.selected ? "background-color: #fff8e1" : "",
                      },
                      [
                        _c("v-card-text", { staticClass: "pa-0 ma-0" }, [
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(_vm.item.information.label),
                            },
                          }),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                ["IMAGE"].includes(_vm.item.information.type)
                  ? _c(
                      "v-card",
                      {
                        staticClass: "elevation-0",
                        style: _vm.selected ? "background-color: #fff8e1" : "",
                      },
                      [
                        _vm.readOnly
                          ? _c("div", [
                              _c("p", [
                                _vm._v(_vm._s(_vm.item.information.label)),
                              ]),
                              _c("img", {
                                style: { height: "auto", "max-width": "500px" },
                                attrs: { src: _vm.item.information.url },
                              }),
                            ])
                          : _c("div", [
                              _c("p", [
                                _vm._v(_vm._s(_vm.item.information.label)),
                              ]),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.item.information.openUrl,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c("img", {
                                    style: {
                                      height: "auto",
                                      "max-width": "500px",
                                    },
                                    attrs: { src: _vm.item.information.url },
                                  }),
                                ]
                              ),
                            ]),
                      ]
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }