<template>
  <div>
    <LayerInfoForm
      :layer="layer"
      @layer-changed="$emit('layer-changed', $event)"
    />
    <ServiceInfoForm
      :layer="layer"
      @layer-changed="$emit('layer-changed', $event)"
    />
  </div>
</template>

<script>
import LayerInfoForm from "@/components/layers/edit-layer-form/shared/LayerInfoForm";
import ServiceInfoForm from "@/components/layers/edit-layer-form/shared/ServiceInfoForm";

export default {
  name: "MapTileServiceForm",
  components: {
    LayerInfoForm,
    ServiceInfoForm,
  },
  props: {
    layer: Object,
  },
};
</script>
