<template>
  <v-dialog :value="showLayerIsInTheFollowingMapDialog" max-width="600px">
    <v-card class="m-2">
      <v-toolbar dark color="primary">
        <v-toolbar-title>
          <div>Map Access</div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="py-5">
        <p>
          This layer is on the following maps that have access to the layer
          department(s) that you are attempting to remove access to.
        </p>
        <p>
          {{ mapNames.join(", ") }}
        </p>
        <p>
          You must first remove the layer from the map(s) before you can remove
          access to the department.
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "LayerIsInTheFollowingMapDialog",
  props: {
    showLayerIsInTheFollowingMapDialog: Boolean,
    showLayerIsInTheFollowingMapDialogData: Object,
  },
  computed: {
    mapNames() {
      const mapNames =
        this.showLayerIsInTheFollowingMapDialogData?.departmentMapsWithMapService?.map(
          (m) => {
            const { name } = m;
            return name;
          }
        );
      if (Array.isArray(mapNames)) {
        return [...new Set(mapNames)];
      }
      return [];
    },
  },
};
</script>
