<template>
  <div>
    <v-card class="elevation-2">
      <v-card-text class="pa-4">
        <v-row>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors, valid }"
              name="Activity Type"
            >
              <v-select
                autocomplete="off"
                label="Activity Type"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="activityType"
                :items="activityTypes"
                item-text="label"
                item-value="value"
                v-model="
                  copiedFormDefinition.form.city_works_webhook_config
                    .create_task.type
                "
                @change="$emit('input', copiedFormDefinition)"
                :disabled="!canManageForms"
              >
              </v-select>
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <validation-provider v-slot="{ errors, valid }" name="Description">
              <v-text-field
                label="Description"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="description"
                v-model="
                  copiedFormDefinition.form.city_works_webhook_config
                    .create_task.description
                "
                @change="$emit('input', copiedFormDefinition)"
                :disabled="!canManageForms"
              >
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors, valid }"
              name="Cityworks Unique GIS Field"
            >
              <v-select
                autocomplete="off"
                label="Cityworks Unique GIS Field"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="gisField"
                :items="fieldChoices"
                item-text="label"
                item-value="value"
                v-model="
                  copiedFormDefinition.form.city_works_webhook_config
                    .create_task.unique_gis_field
                "
                @change="$emit('input', copiedFormDefinition)"
                :disabled="!canManageForms"
              >
              </v-select>
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <validation-provider v-slot="{ errors, valid }" name="Created By">
              <v-select
                autocomplete="off"
                label="Created By"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="createdBy"
                :items="userChoices"
                item-text="label"
                item-value="value"
                v-model="
                  copiedFormDefinition.form.city_works_webhook_config
                    .create_task.created_by
                "
                @change="$emit('input', copiedFormDefinition)"
                :disabled="!canManageForms"
              >
              </v-select>
            </validation-provider>
          </v-col>

          <v-col cols="12">
            <validation-provider v-slot="{ errors, valid }" name="Assign To">
              <v-select
                autocomplete="off"
                label="Assign To"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="assignTo"
                :items="userChoices"
                item-text="label"
                item-value="value"
                v-model="
                  copiedFormDefinition.form.city_works_webhook_config
                    .create_task.assigned_to
                "
                @change="$emit('input', copiedFormDefinition)"
                :disabled="!canManageForms"
              >
              </v-select>
            </validation-provider>
          </v-col>

          <v-col cols="12">
            <v-switch
              v-model="
                copiedFormDefinition.form.city_works_webhook_config.create_task
                  .assignee_closable
              "
              label="Assignee Closable"
              @change="$emit('input', copiedFormDefinition)"
              :disabled="!canManageForms"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mt-4">
      <v-card-text>
        <div>
          Copy this into the <strong>Template</strong> field of Action Templates
          webhook within Cityworks Admin.
        </div>
        <div class="pt-2 d-flex justify-space-between align-center">
          <v-card color="#dddddd">
            <v-card-text class="pa-1">
              <v-card flat>
                <v-card-text class="pa-2">{{ templatePayload }}</v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
          <v-btn icon class="ml-2" @click="copyPayload">
            <v-icon color="blue">mdi-content-copy</v-icon>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="showCopiedPayloadSnack" :timeout="5000">
      Copied webhook Template payload!
    </v-snackbar>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import axios from "axios";
import { mdiContentCopy } from "@mdi/js";
import permissionsMixin from "@/mixins/permissionsMixin";

const APIURL = process.env.VUE_APP_API_URL;

const activityTypes = [
  { value: "Inspection", label: "Inspection" },
  // { value: "workorder", label: "Work Order" },
];

export default {
  name: "FromCityworksConfigurationForm",
  props: {
    value: Object,
  },
  mixins: [permissionsMixin],
  data() {
    return {
      copiedFormDefinition: {},
      users: [],
      mdiContentCopy,
      activityTypes,
      showCopiedPayloadSnack: false,
      fields: [],
    };
  },
  computed: {
    userChoices() {
      return this.users
        .map((u) => {
          const { f_name: fName, l_name: lName, user_id: value } = u;
          return {
            label: `${fName} ${lName}`,
            value,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
    },
    templatePayload() {
      // I think using EntityUid will work for both inspections and work orders
      return `data={"activity_id": "{{${
        this.copiedFormDefinition.form.city_works_webhook_config.create_task
          .type === "Inspection"
          ? "InspectionId"
          : "WorkOrderId"
      }}}", "activity_type": "${
        this.copiedFormDefinition.form.city_works_webhook_config.create_task
          .type
      }", "due_date": "{{${
        this.copiedFormDefinition.form.city_works_webhook_config.create_task
          .type === "Inspection"
          ? "PrjFinishDate"
          : "ProjFinishDate"
      }}}",  "entity_uid": "{{EntityUid}}", "form_definition_id": "${
        this.copiedFormDefinition.form_definition_id
      }"}`;
    },
    fieldChoices() {
      const fieldChoices = this.fields.map(({ name, alias }) => ({
        value: name,
        label: alias || name,
      }));
      return fieldChoices;
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        const formDefinition = cloneDeep(val);
        this.copiedFormDefinition = { ...formDefinition };
        // To be removed when we want to deal with Workorders
        this.copiedFormDefinition.form.city_works_webhook_config.create_task.type =
          "Inspection";
      },
    },
  },
  methods: {
    async getUsers() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/users`);
      this.users = results.filter((user) => user.is_active === true);
    },
    copyPayload() {
      navigator.clipboard.writeText(this.templatePayload);
      this.showCopiedPayloadSnack = true;
    },
    async getGisFields() {
      try {
        const { map_service_id: mapServiceId } = this.value;
        const {
          data: {
            results: { service_url: featureServiceUrl, token_type: tokenType },
          },
        } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
        const token =
          tokenType === "AGOL" ? localStorage.getItem("esri_token") : undefined;
        const params = {
          f: "pjson",
          token,
        };
        const {
          data: { fields },
        } = await axios.get(featureServiceUrl, { params });
        this.fields = fields;
      } catch (error) {
        this.fields = [];
      }
    },
  },
  async beforeMount() {
    await this.getUsers();
    await this.getGisFields();
  },
};
</script>
