<template>
  <div>
    <LayerInfoForm
      :layer="layer"
      @layer-changed="$emit('layer-changed', $event)"
    />
    <ServiceInfoForm
      :layer="layer"
      @layer-changed="$emit('layer-changed', $event)"
    />
    <RelatedLayers
      :layer="layer"
      @layer-changed="$emit('layer-changed', $event)"
    />
    <LayerDetails
      :layer="layer"
      @symbology-saved="$emit('symbology-saved')"
      @open-edit-form-dialog="$emit('open-edit-form-dialog', $event)"
      @create-new-form="$emit('create-new-form')"
      @utilisync-fields-saved="onUtilisyncFieldsSaved"
      @gis-fields-saved="onGisFieldsSaved"
    />
  </div>
</template>

<script>
import LayerInfoForm from "@/components/layers/edit-layer-form/shared/LayerInfoForm";
import ServiceInfoForm from "@/components/layers/edit-layer-form/shared/ServiceInfoForm";
import LayerDetails from "@/components/layers/edit-layer-form/shared/LayerDetails";
import RelatedLayers from "@/components/layers/edit-layer-form/shared/RelatedLayers";

export default {
  name: "FeatureServiceForm",
  components: {
    LayerInfoForm,
    ServiceInfoForm,
    LayerDetails,
    RelatedLayers,
  },
  props: {
    layer: Object,
  },
  data() {
    return {
      featureServiceInfo: {},
    };
  },
  methods: {
    onUtilisyncFieldsSaved(layer) {
      this.$emit("utilisync-fields-saved", layer);
    },
    onGisFieldsSaved(layer) {
      this.$emit("gis-fields-saved", layer);
    },
  },
};
</script>
