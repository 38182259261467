<template>
  <section>
    <div class="d-flex align-center justify-space-between">
      <div>Advanced Settings</div>

      <v-btn
        text
        color="primary"
        @click="onAddClick"
        :disabled="!canManageForms"
      >
        + Add
      </v-btn>
    </div>

    <AddAdvancedInformationSettingsDialog
      v-if="showAddAdvancedInformationSettingsDialog"
      :showAddAdvancedInformationSettingsDialog="
        showAddAdvancedInformationSettingsDialog
      "
      :selectedItemOptions="selectedItemOptions"
      :oldSelectedItemOptions="oldSelectedItemOptions"
      :showVisibilityChoice="showVisibilityChoice"
      :showDefaultChoice="showDefaultChoice"
      :showRequireChoice="showRequireChoice"
      :showCreateChoice="showCreateChoice"
      @advanced-settings-selected="onAdvancedSettingSelected"
      @add-advanced-settings-dialog-close="
        showAddAdvancedInformationSettingsDialog = false
      "
    />

    <AdvancedInformationVisibilitySettingsDialog
      v-if="showAdvancedInformationVisibilitySettingsDialog"
      :showAdvancedInformationVisibilitySettingsDialog="
        showAdvancedInformationVisibilitySettingsDialog
      "
      :formDefinition="formDefinition"
      :layer="layer"
      :selectedItem="selectedItem"
      @advanced-settings-dialog-close="
        showAdvancedInformationVisibilitySettingsDialog = false
      "
      v-model="selectedItemOptions"
      @input="onInput"
    />

    <AdvancedInformationCreateSettingsDialog
      v-if="showAdvancedInformationCreateSettingsDialog"
      :showAdvancedInformationCreateSettingsDialog="
        showAdvancedInformationCreateSettingsDialog
      "
      :formDefinition="formDefinition"
      :layer="layer"
      :selectedItem="selectedItem"
      @advanced-settings-dialog-close="
        showAdvancedInformationCreateSettingsDialog = false
      "
      v-model="selectedItemOptions"
      @input="onSubmit()"
    />
  </section>
</template>

<script>
import { cloneDeep } from "lodash";
import AdvancedInformationVisibilitySettingsDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/AdvancedInformationVisibilitySettingsDialog.vue";
import AddAdvancedInformationSettingsDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/AddAdvancedInformationSettingsDialog.vue";
import AdvancedInformationCreateSettingsDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/AdvancedInformationCreateSettingsDialog.vue";
import { ADVANCED_SETTINGS_CHOICES } from "@/constants/advancedSettings";
import permissionsMixin from "@/mixins/permissionsMixin";

export default {
  name: "AdvancedInformationSettingsForm",
  props: {
    value: Object,
    formDefinition: Object,
    layer: Object,
    selectedItem: Object,
    oldSelectedItemOptions: Object,
    showVisibilityChoice: { type: Boolean, default: true },
    showDefaultChoice: { type: Boolean, default: true },
    showRequireChoice: { type: Boolean, default: true },
    showCreateChoice: { type: Boolean, default: true },
  },
  components: {
    AdvancedInformationVisibilitySettingsDialog,
    AddAdvancedInformationSettingsDialog,
    AdvancedInformationCreateSettingsDialog,
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.selectedItemOptions = cloneDeep(val);
      },
    },
  },
  mixins: [permissionsMixin],
  data() {
    return {
      selectedItemOptions: {},
      showAdvancedDefaultSettingsDialog: false,
      showAdvancedInformationVisibilitySettingsDialog: false,
      showAddAdvancedInformationSettingsDialog: false,
      showAdvancedRequiredSettingsDialog: false,
      showAdvancedInformationCreateSettingsDialog: false,
      advancedSettingType: ADVANCED_SETTINGS_CHOICES.DEFAULT,
    };
  },
  beforeMount() {
    this.selectedItemOptions = cloneDeep(this.value);
  },
  methods: {
    onAdvancedSettingSelected(advancedSettingType) {
      this.showAddAdvancedInformationSettingsDialog = false;
      if (advancedSettingType === ADVANCED_SETTINGS_CHOICES.VISIBILITY) {
        this.showAdvancedInformationVisibilitySettingsDialog = true;
      } else if (advancedSettingType === ADVANCED_SETTINGS_CHOICES.CREATE) {
        this.showAdvancedInformationCreateSettingsDialog = true;
      }
    },
    onInput(selectedItemOptions) {
      this.$emit("input", selectedItemOptions);
    },
    onAddClick() {
      this.showAddAdvancedInformationSettingsDialog = true;
    },
  },
};
</script>
