var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("validation-observer", { ref: "form" }, [
    _c(
      "form",
      [
        _c(
          "v-list",
          {
            staticClass: "overflow-y-auto",
            staticStyle: { "max-height": "calc(100vh - 56px)" },
          },
          [
            _c(
              "v-list-item",
              { staticClass: "py-1" },
              [
                _c("validation-provider", {
                  attrs: { name: "Filter" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ errors, valid }) {
                        return [
                          _c("v-text-field", {
                            attrs: {
                              label: "Form Placeholder",
                              "hide-details": "auto",
                              "error-messages": errors,
                              success: valid,
                              color: "primary",
                              name: "filterText",
                            },
                            model: {
                              value: _vm.filterText,
                              callback: function ($$v) {
                                _vm.filterText = $$v
                              },
                              expression: "filterText",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._l(_vm.questionChoices, function (item) {
              return _c(
                "v-list-item",
                {
                  key: item.id,
                  staticClass: "question d-block",
                  on: {
                    click: function ($event) {
                      return _vm.copyToClipboard(item.id, `{{col.${item.id}}}`)
                    },
                  },
                },
                [
                  _c("transition", { attrs: { name: "slide-fade" } }, [
                    _vm.showCopyAnimation[item.id]
                      ? _c("p", { staticClass: "absolute" }, [
                          _c("span", {
                            staticClass: "param mr-1",
                            domProps: {
                              innerHTML: _vm._s(`{{col.${item.id}}}`),
                            },
                          }),
                          _vm._v(" " + _vm._s(item.question.label) + " "),
                        ])
                      : _vm._e(),
                  ]),
                  _c("p", [
                    _c("span", {
                      staticClass: "param mr-1",
                      domProps: { innerHTML: _vm._s(`{{col.${item.id}}}`) },
                    }),
                    _vm._v(" " + _vm._s(item.question.label) + " "),
                  ]),
                ],
                1
              )
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }