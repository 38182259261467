<template>
  <div>
    <p class="caption mt-6 mb-3">Service Info</p>

    <v-card class="py-5">
      <validation-observer ref="form" v-slot="{ invalid }">
        <form>
          <v-card-text>
            <validation-provider
              :bails="false"
              name="arcGisServiceUrl"
              v-slot="{ errors, valid }"
              :rules="{
                required: true,
                regex:
                  /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
              }"
            >
              <div class="d-flex flex-row">
                <v-textarea
                  v-model="selectedLayer.service_url"
                  :error-messages="errors"
                  :success="valid"
                  label="ArcGIS Service URL *"
                  color="primary"
                  class="ma-0 mr-4 pa-0"
                  @change="$emit('layer-changed', selectedLayer)"
                  auto-grow
                  rows="1"
                  hide-details
                >
                </v-textarea>
                <v-btn
                  text
                  @click="goToService(selectedLayer.service_url)"
                  width="30px"
                  min-width="30px"
                  height="30px"
                  min-height="30px"
                >
                  <v-icon color="#1976d2">{{ mdiOpenInNew }}</v-icon>
                </v-btn>
              </div>
            </validation-provider>

            <template v-if="submitted">
              <p class="caption my-0 py-0" style="color: red" v-if="error">
                {{ error }}
              </p>
              <p class="caption my-0 py-0" style="color: #093637" v-else>
                Success! Connected to ArcGIS Feature Service.
              </p>
            </template>

            <validation-provider>
              <v-switch
                class="py-0"
                v-model="securedService"
                label="Secured Service"
              >
              </v-switch>
            </validation-provider>
          </v-card-text>
        </form>
        <v-card-actions class="d-flex align-center justify-end py-0">
          <v-progress-circular
            v-if="testingConnection"
            indeterminate
            color="primary"
            size="20"
          ></v-progress-circular>
          <v-btn
            color="primary"
            @click="testUrl"
            :disabled="invalid || testingConnection"
          >
            Test Connection
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { mdiOpenInNew } from "@mdi/js";
import { EventBus } from "@/main.js";
import { xml2js } from "xml-js";
import axios from "axios";

export default {
  name: "WmsLayerServiceInfoForm",
  props: {
    layer: Object,
  },
  data() {
    return {
      selectedLayer: {},
      securedService: false,
      submitted: false,
      mdiOpenInNew,
      testingConnection: false,
    };
  },
  watch: {
    securedService(val) {
      if (val) {
        this.selectedLayer.token_type = "AGOL";
      } else {
        this.selectedLayer.token_type = "NONE";
      }
      this.$emit("layer-changed", this.selectedLayer);
    },
    layer: {
      immediate: true,
      deep: true,
      handler(val) {
        this.selectedLayer = cloneDeep(val);
        if (this.selectedLayer.token_type !== "NONE") {
          this.securedService = true;
        }
      },
    },
  },
  methods: {
    async testUrl() {
      const success = await this.$refs.form.validate();
      if (!success) {
        return;
      }
      this.testingConnection = true;
      this.submitted = false;
      this.error = "";
      const { selectedLayer } = this;
      try {
        const token =
          selectedLayer.token_type === "AGOL"
            ? localStorage.getItem("esri_token")
            : undefined;
        const { data } = await axios.get(selectedLayer.service_url, {
          params: {
            SERVICE: "WMS",
            REQUEST: "GetCapabilities",
            token,
          },
        });
        const wmsData = xml2js(data, { compact: true });

        if (!wmsData?.WMS_Capabilities) {
          this.error =
            "Unable to connect to layer. Your user does not have permission to access that layer";
          const { data } = await axios.get(selectedLayer.service_url, {
            params: {
              SERVICE: "WMS",
              REQUEST: "GetCapabilities",
              token,
            },
          });
          const wmsData = xml2js(data, { compact: true });
          if (!wmsData?.WMS_Capabilities) {
            this.error =
              "Unable to connect to layer. Your user does not have permission to access that layer";
          }
        }
      } catch (error) {
        this.error =
          "Unable to connect to layer. Your user does not have permission to access that layer";
      } finally {
        this.testingConnection = false;
        this.submitted = true;
      }
      EventBus.$emit("test-clicked", this.error);
    },
    goToService(serviceURL) {
      const token = localStorage.getItem("esri_token");
      if (this.securedService && token) {
        window.open(`${serviceURL}?token=${token}`, "blank");
      } else {
        window.open(serviceURL, "blank");
      }
    },
  },
};
</script>
