import { render, staticRenderFns } from "./EditGisFieldsDialog.vue?vue&type=template&id=50108fce&scoped=true&"
import script from "./EditGisFieldsDialog.vue?vue&type=script&lang=js&"
export * from "./EditGisFieldsDialog.vue?vue&type=script&lang=js&"
import style0 from "./EditGisFieldsDialog.vue?vue&type=style&index=0&id=50108fce&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50108fce",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('50108fce')) {
      api.createRecord('50108fce', component.options)
    } else {
      api.reload('50108fce', component.options)
    }
    module.hot.accept("./EditGisFieldsDialog.vue?vue&type=template&id=50108fce&scoped=true&", function () {
      api.rerender('50108fce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/layers/edit-layer-form/shared/layer-details/EditGisFieldsDialog.vue"
export default component.exports