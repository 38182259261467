import { render, staticRenderFns } from "./FormBuilderPreview.vue?vue&type=template&id=7334f698&scoped=true&"
import script from "./FormBuilderPreview.vue?vue&type=script&lang=js&"
export * from "./FormBuilderPreview.vue?vue&type=script&lang=js&"
import style0 from "./FormBuilderPreview.vue?vue&type=style&index=0&id=7334f698&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7334f698",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7334f698')) {
      api.createRecord('7334f698', component.options)
    } else {
      api.reload('7334f698', component.options)
    }
    module.hot.accept("./FormBuilderPreview.vue?vue&type=template&id=7334f698&scoped=true&", function () {
      api.rerender('7334f698', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/forms/form-builder-edit-form/FormBuilderPreview.vue"
export default component.exports