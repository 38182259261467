var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "caption mt-6 mb-3" }, [_vm._v("Layers")]),
      _c(
        "v-card",
        { staticClass: "py-1" },
        [
          _c(
            "v-card-text",
            [
              _c("p", { staticClass: "caption" }, [
                _vm._v("Layers to show on map:"),
              ]),
              _c(
                "v-list",
                {
                  staticClass: "py-0",
                  style: {
                    width: "fit-content",
                    "border-radius": "1%",
                    border: "1px solid primary",
                  },
                  attrs: { dense: "" },
                },
                [
                  _c(
                    "v-list-item",
                    { staticClass: "d-flex align-center" },
                    [
                      _c("v-checkbox", {
                        staticClass: "py-0 my-0",
                        attrs: {
                          "input-value":
                            _vm.selectedSublayerIds.length ===
                            _vm.sublayerChoices.length,
                          dense: "",
                          "hide-details": "",
                        },
                        on: { change: _vm.onSelectAllChange },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function () {
                              return [_c("b", [_vm._v("Select All")])]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._l(_vm.sublayerChoices, function (s) {
                    return _c(
                      "v-list-item",
                      { key: s.name, staticClass: "d-flex align-center" },
                      [
                        _c("v-checkbox", {
                          staticClass: "py-0 my-0",
                          attrs: {
                            value: s.name,
                            dense: "",
                            "hide-details": "",
                            name: "selectedSublayerIds",
                            label: s.title,
                          },
                          on: { change: _vm.onLayerChanged },
                          model: {
                            value: _vm.selectedSublayerIds,
                            callback: function ($$v) {
                              _vm.selectedSublayerIds = $$v
                            },
                            expression: "selectedSublayerIds",
                          },
                        }),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }