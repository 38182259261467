var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showAdvancedCreateSettingsDialog,
        "max-width": "600px",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
    },
    [
      _c(
        "v-toolbar",
        { attrs: { dark: "", color: "primary" } },
        [
          _c("v-toolbar-title", [
            _c("div", [_c("b", [_vm._v("Create")])]),
            _c("div", { staticClass: "caption" }, [
              _vm._v("Advanced Settings"),
            ]),
          ]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { icon: "", dark: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("advanced-settings-dialog-close")
                },
              },
            },
            [_c("v-icon", [_vm._v(" " + _vm._s(_vm.mdiClose) + " ")])],
            1
          ),
        ],
        1
      ),
      _c("validation-observer", {
        ref: "advancedSettingsForm",
        attrs: { slim: "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ invalid }) {
              return [
                _c(
                  "v-card",
                  { staticClass: "rounded-0" },
                  [
                    _c("v-card-text", { staticClass: "py-3" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex gap" },
                        [
                          _c("validation-provider", {
                            staticClass: "select",
                            attrs: { name: "Condition", rules: "required" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors, valid }) {
                                    return [
                                      _c("v-select", {
                                        attrs: {
                                          autocomplete: "off",
                                          items: _vm.CREATE_CONDITION_CHOICES,
                                          name: "condition",
                                          label: "Condition",
                                          "error-messages": errors,
                                          success: valid,
                                          "item-text": "label",
                                          "item-value": "value",
                                        },
                                        on: { change: _vm.onConditionChange },
                                        model: {
                                          value:
                                            _vm.selectedItemOptions.question
                                              .create.applyCreate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.selectedItemOptions.question
                                                .create,
                                              "applyCreate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectedItemOptions.question.create.applyCreate",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm.CONDITION_CHOICES.CONDITIONAL ===
                      _vm.selectedItemOptions.question.create.applyCreate
                        ? _c(
                            "section",
                            [
                              _c("p", { staticClass: "caption py-0 my-0" }, [
                                _vm._v("Conditional Settings"),
                              ]),
                              _vm.selectedItemOptions.question.create
                                .applyCreateConditions.conditions.length > 1
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-end gap py-0 my-0",
                                    },
                                    [
                                      _c("div", [
                                        _c("b", [_vm._v(" Apply if ")]),
                                      ]),
                                      _c("validation-provider", {
                                        staticStyle: { width: "70px" },
                                        attrs: { rules: "required" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors, valid }) {
                                                return [
                                                  _c("v-select", {
                                                    attrs: {
                                                      autocomplete: "off",
                                                      height: "20px",
                                                      items:
                                                        _vm.APPLY_IF_CHOICES,
                                                      name: "applyIf",
                                                      "error-messages": errors,
                                                      success: valid,
                                                      "item-text": "label",
                                                      "item-value": "value",
                                                      "hide-details": "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedItemOptions
                                                          .question.create
                                                          .applyCreateConditions
                                                          .applyIf,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm
                                                            .selectedItemOptions
                                                            .question.create
                                                            .applyCreateConditions,
                                                          "applyIf",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "\n                  selectedItemOptions.question.create.applyCreateConditions\n                    .applyIf\n                ",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _c("div", [
                                        _c("b", [
                                          _vm._v(
                                            " of the conditions are matched "
                                          ),
                                        ]),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.showConditions
                                ? _vm._l(
                                    _vm.selectedItemOptions.question.create
                                      .applyCreateConditions.conditions,
                                    function (condition, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: condition.id,
                                          staticClass:
                                            "d-flex align-center gap",
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "Dependant",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({
                                                    errors,
                                                    valid,
                                                  }) {
                                                    return [
                                                      _c("v-select", {
                                                        attrs: {
                                                          autocomplete: "off",
                                                          items:
                                                            _vm.dependantOptions,
                                                          label: "Dependant",
                                                          "error-messages":
                                                            errors,
                                                          success: valid,
                                                          "item-text": "label",
                                                          "item-value": "value",
                                                          "hide-details": "",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.onDependantChange()
                                                          },
                                                        },
                                                        model: {
                                                          value: condition.type,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              condition,
                                                              "type",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "condition.type",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                          condition.type
                                            ? _c("validation-provider", {
                                                key: condition.type,
                                                staticStyle: { width: "120px" },
                                                attrs: {
                                                  name: "Field",
                                                  rules: "required",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        errors,
                                                        valid,
                                                      }) {
                                                        return [
                                                          _c("v-select", {
                                                            key: condition.type,
                                                            attrs: {
                                                              autocomplete:
                                                                "off",
                                                              items:
                                                                _vm.getVisibilityValueOptions(
                                                                  condition
                                                                ),
                                                              label: "Field",
                                                              "error-messages":
                                                                errors,
                                                              success: valid,
                                                              "item-text":
                                                                "label",
                                                              "item-value":
                                                                "value",
                                                              "hide-details":
                                                                "",
                                                            },
                                                            model: {
                                                              value:
                                                                condition.field,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    condition,
                                                                    "field",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "condition.field",
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              })
                                            : _vm._e(),
                                          condition.type
                                            ? _c("validation-provider", {
                                                staticClass:
                                                  "operator-dropdown",
                                                attrs: {
                                                  name: "Condition",
                                                  rules: "required",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        errors,
                                                        valid,
                                                      }) {
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              autocomplete:
                                                                "off",
                                                              items:
                                                                _vm.comparisonTypes,
                                                              name: "Condition",
                                                              "error-messages":
                                                                errors,
                                                              success: valid,
                                                              "item-text":
                                                                "label",
                                                              "item-value":
                                                                "value",
                                                              "hide-details":
                                                                "",
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.onSubmit()
                                                              },
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "item",
                                                                  fn: function ({
                                                                    item: {
                                                                      label,
                                                                      value,
                                                                    },
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "MatchIcon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  value:
                                                                                    value,
                                                                                },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                label
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                                {
                                                                  key: "selection",
                                                                  fn: function ({
                                                                    item: {
                                                                      value,
                                                                    },
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "MatchIcon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  value:
                                                                                    value,
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                            model: {
                                                              value:
                                                                condition.condition,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    condition,
                                                                    "condition",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "condition.condition",
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              })
                                            : _vm._e(),
                                          condition.type
                                            ? _c("validation-provider", {
                                                staticStyle: {
                                                  "min-width": "170px",
                                                },
                                                attrs: {
                                                  name: "Value",
                                                  rules: "required",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        errors,
                                                        valid,
                                                      }) {
                                                        return [
                                                          _vm.getGisDataFieldOptionChoices(
                                                            condition
                                                          ).length > 0
                                                            ? _c("v-select", {
                                                                attrs: {
                                                                  autocomplete:
                                                                    "off",
                                                                  items:
                                                                    _vm.getGisDataFieldOptionChoices(
                                                                      condition
                                                                    ),
                                                                  label:
                                                                    "Value",
                                                                  "error-messages":
                                                                    errors,
                                                                  success:
                                                                    valid,
                                                                  "item-text":
                                                                    "label",
                                                                  "item-value":
                                                                    "value",
                                                                  "hide-details":
                                                                    "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    condition.value,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        condition,
                                                                        "value",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "condition.value",
                                                                },
                                                              })
                                                            : _vm.getFormFieldOptionChoices(
                                                                condition
                                                              ).length > 0
                                                            ? _c("v-select", {
                                                                attrs: {
                                                                  autocomplete:
                                                                    "off",
                                                                  items:
                                                                    _vm.getFormFieldOptionChoices(
                                                                      condition
                                                                    ),
                                                                  label:
                                                                    "Value",
                                                                  "error-messages":
                                                                    errors,
                                                                  success:
                                                                    valid,
                                                                  "item-text":
                                                                    "label",
                                                                  "item-value":
                                                                    "value",
                                                                  "hide-details":
                                                                    "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    condition.value,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        condition,
                                                                        "value",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "condition.value",
                                                                },
                                                              })
                                                            : _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "Value",
                                                                    "hide-details":
                                                                      "",
                                                                    "error-messages":
                                                                      errors,
                                                                    success:
                                                                      valid,
                                                                    color:
                                                                      "primary",
                                                                    name: "value",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      condition.value,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          condition,
                                                                          "value",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "condition.value",
                                                                  },
                                                                }
                                                              ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              })
                                            : _vm._e(),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                icon: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeCondition(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.mdiDelete) +
                                                    " "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "mx-0 px-0" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "px-0 mx-0",
                                      attrs: {
                                        text: "",
                                        color: "primary",
                                        disabled: invalid,
                                      },
                                      on: { click: _vm.addCondition },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { "x-small": "" } },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.mdiPlus) + " "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" Add Condition "),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]),
                    _c(
                      "v-card-actions",
                      { staticClass: "d-flex justify-end" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "primary",
                              disabled:
                                invalid ||
                                (_vm.selectedItemOptions.question.create
                                  ?.applyCreate ===
                                  _vm.CONDITION_CHOICES.CONDITIONAL &&
                                  _vm.selectedItemOptions.question.create
                                    ?.applyCreateConditions.conditions
                                    .length === 0),
                            },
                            on: { click: _vm.onSubmit },
                          },
                          [_vm._v(" Save Settings ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }