var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("div", { staticClass: "d-flex align-center justify-space-between" }, [
        _c("div", [_vm._v(" " + _vm._s(_vm.label) + " ")]),
        _c(
          "div",
          [
            _c(
              "v-btn",
              {
                attrs: { text: "", color: "primary", disabled: _vm.readOnly },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.showRepeatingGroupDialog = true
                  },
                },
              },
              [_vm._v(" + Add ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        [
          _c("v-responsive", { staticClass: "pa-0 ma-0" }, [
            _c(
              "table",
              {
                staticClass: "d-block overflow-x-auto",
                style: { "max-width": _vm.selected ? "460px" : "485px" },
              },
              [
                _c("thead", [
                  _c(
                    "tr",
                    [
                      _vm._l(_vm.tableHeaders, function (h) {
                        return _c(
                          "td",
                          { key: h.id, staticClass: "px-3" },
                          [
                            h.information
                              ? [
                                  _vm.isHtml(h.information.label)
                                    ? _c("div", {
                                        staticClass: "raw-html",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            h.information.label
                                          ),
                                        },
                                      })
                                    : _c("b", [
                                        _vm._v(_vm._s(h.information.label)),
                                      ]),
                                ]
                              : h.label
                              ? [
                                  _vm.isHtml(h.label)
                                    ? _c("div", {
                                        staticClass: "raw-html",
                                        domProps: {
                                          innerHTML: _vm._s(h.label),
                                        },
                                      })
                                    : _c("b", [_vm._v(_vm._s(h.label))]),
                                ]
                              : _vm._e(),
                          ],
                          2
                        )
                      }),
                      _c("td"),
                    ],
                    2
                  ),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.tableValues, function (v, index) {
                    return _c(
                      "tr",
                      { key: index },
                      [
                        _vm._l(_vm.tableHeaders, function (h) {
                          return _c(
                            "td",
                            { key: h.id, staticClass: "px-3" },
                            [
                              h.id.includes("QUESTION")
                                ? [
                                    h.type === "TEXT" && h.isLong
                                      ? _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(v[h.id]),
                                          },
                                        })
                                      : _c("div", [
                                          _vm._v(" " + _vm._s(v[h.id]) + " "),
                                        ]),
                                  ]
                                : h.id.includes("INFORMATION")
                                ? _c("RepeatingGroupInfo", {
                                    attrs: { item: h },
                                  })
                                : _vm._e(),
                            ],
                            2
                          )
                        }),
                        _c(
                          "td",
                          { staticClass: "d-flex justify-end" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    _vm.selectedValueIndex = index
                                    _vm.showEditRepeatingGroupDialog = true
                                    _vm.selectedValueItems =
                                      _vm.groupValues[index]
                                  },
                                },
                              },
                              [
                                _c("v-icon", [
                                  _vm._v(" " + _vm._s(_vm.mdiPencil) + " "),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm.showRepeatingGroupDialog
        ? _c("RepeatingGroupDialog", {
            attrs: {
              showRepeatingGroupDialog: _vm.showRepeatingGroupDialog,
              item: _vm.item,
              readOnly: _vm.readOnly,
              previewMode: _vm.previewMode,
              formDefinition: _vm.formDefinition,
            },
            on: {
              "close-form": function ($event) {
                _vm.showRepeatingGroupDialog = false
              },
              "form-saved": _vm.onFormSaved,
            },
          })
        : _vm._e(),
      _vm.showEditRepeatingGroupDialog
        ? _c("EditRepeatingGroupDialog", {
            attrs: {
              showEditRepeatingGroupDialog: _vm.showEditRepeatingGroupDialog,
              item: _vm.item,
              selectedValueItems: _vm.selectedValueItems,
              selectedValueIndex: _vm.selectedValueIndex,
              readOnly: _vm.readOnly,
              previewMode: _vm.previewMode,
              formDefinition: _vm.formDefinition,
            },
            on: {
              "close-form": function ($event) {
                _vm.showEditRepeatingGroupDialog = false
              },
              "form-saved": _vm.onEditFormSaved,
              "delete-item": _vm.deleteItem,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }