<template>
  <img
    class="ml-2 mx-0 px-0"
    v-if="rendererSymbol.url && imgFound"
    :src="
      rendererSymbol.url.startsWith('/img/')
        ? require(`@/assets${rendererSymbol.url}`)
        : rendererSymbol.url
    "
    :style="{
      width: `${rendererSymbol.picture_size}px`,
      height: `${rendererSymbol.picture_size}px`,
    }"
  />
</template>

<script>
export default {
  name: "EditSymbolsFormImage",
  props: {
    rendererSymbol: Object,
  },
  data() {
    return {
      imgFound: false,
    };
  },
  beforeMount() {
    const { rendererSymbol } = this;
    this.testImage(
      rendererSymbol.url?.startsWith("/img/")
        ? require(`@/assets${rendererSymbol.url}`)
        : rendererSymbol.url
    );
  },
  watch: {
    rendererSymbol: {
      deep: true,
      handler() {
        const { rendererSymbol } = this;
        this.testImage(
          rendererSymbol.url?.startsWith("/img/")
            ? require(`@/assets${rendererSymbol.url}`)
            : rendererSymbol.url
        );
      },
    },
  },
  methods: {
    testImage(imageUrl) {
      const img = new Image();
      img.onload = this.imageFound;
      img.onerror = this.imageNotFound;
      img.src = imageUrl;
    },
    imageFound() {
      this.imgFound = true;
    },
    imageNotFound() {
      this.imgFound = false;
    },
  },
};
</script>
