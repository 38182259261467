var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showLayerCannotBeDeletedDialog,
        "max-width": "500px",
        persistent: "",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-text", { staticClass: "px-5 py-3" }, [
            _vm._v(
              " This layer cannot be deleted because it is being referenced in other places in the app. We recommend you archive the layer instead. "
            ),
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end px-5 py-3" },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("cancel")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }