var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showEditValueRangeDialog,
        "max-width": "500px",
        persistent: "",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", color: "primary" } },
            [
              _c("v-toolbar-title", [_vm._v("Edit Value Range")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("edit-value-range-dialog-close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              staticClass: "py-5",
              staticStyle: { "background-color": "#f1f2f1" },
            },
            [
              _c("validation-observer", { ref: "valueRangeForm" }, [
                _c(
                  "form",
                  [
                    _c("validation-provider", {
                      attrs: { name: "Label", rules: "required" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ errors, valid }) {
                            return [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Label*",
                                  "hide-details": "auto",
                                  name: "name",
                                  "error-messages": errors,
                                  success: valid,
                                },
                                model: {
                                  value: _vm.rendererSymbol.label,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.rendererSymbol, "label", $$v)
                                  },
                                  expression: "rendererSymbol.label",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("div", { staticClass: "d-flex justify-space-between" }, [
                      _c(
                        "div",
                        { staticClass: "mr-5", staticStyle: { width: "100%" } },
                        [
                          _c("validation-provider", {
                            attrs: { name: "Min Value*", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ errors, valid }) {
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Min Value",
                                        "hide-details": "auto",
                                        name: "name",
                                        "error-messages": errors,
                                        success: valid,
                                      },
                                      model: {
                                        value: _vm.rendererSymbol.min_value,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.rendererSymbol,
                                            "min_value",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "rendererSymbol.min_value",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "100%" } },
                        [
                          _c("validation-provider", {
                            attrs: { name: "Max Value*", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ errors, valid }) {
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Max Value",
                                        "hide-details": "auto",
                                        name: "name",
                                        "error-messages": errors,
                                        success: valid,
                                      },
                                      model: {
                                        value: _vm.rendererSymbol.max_value,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.rendererSymbol,
                                            "max_value",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "rendererSymbol.max_value",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", dark: "" },
                  on: { click: _vm.saveRange },
                },
                [_vm._v(" Save Range ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }