<template>
  <v-dialog
    :value="showDuplicateFieldNameDialog"
    max-width="500px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-card-text class="pt-3">
        A UtiliSync Field with that name already exists. Please enter a unique
        field name.
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          text
          color="primary"
          @click="$emit('duplicate-field-mame-dialog-close')"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DuplicateFieldNameDialog",
  props: {
    showDuplicateFieldNameDialog: Boolean,
  },
};
</script>
