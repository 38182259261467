var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.rendererSymbol.url && _vm.imgFound
    ? _c("img", {
        staticClass: "ml-2 mx-0 px-0",
        style: {
          width: `${_vm.rendererSymbol.picture_size}px`,
          height: `${_vm.rendererSymbol.picture_size}px`,
        },
        attrs: {
          src: _vm.rendererSymbol.url.startsWith("/img/")
            ? require(`@/assets${_vm.rendererSymbol.url}`)
            : _vm.rendererSymbol.url,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }