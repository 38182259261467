<template>
  <div>
    <LayerInfoForm
      :layer="layer"
      @layer-changed="$emit('layer-changed', $event)"
    />
    <WmsLayerServiceInfoForm
      :layer="layer"
      @layer-changed="$emit('layer-changed', $event)"
    />
    <WmsSubLayersForm
      :layer="layer"
      @layer-changed="$emit('layer-changed', $event)"
    />
  </div>
</template>

<script>
import LayerInfoForm from "@/components/layers/edit-layer-form/shared/LayerInfoForm";
import WmsLayerServiceInfoForm from "@/components/layers/edit-layer-form/shared/WmsLayerServiceInfoForm";
import WmsSubLayersForm from "@/components/layers/edit-layer-form/shared/WmsSubLayersForm";

export default {
  name: "WmsServiceForm",
  components: {
    LayerInfoForm,
    WmsLayerServiceInfoForm,
    WmsSubLayersForm,
  },
  props: {
    layer: Object,
  },
};
</script>
