var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-toolbar",
        { attrs: { dark: "", color: "primary" } },
        [
          _c("v-toolbar-title", [_vm._v("Edit Formula")]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { dark: "", text: "" },
              on: {
                click: function ($event) {
                  _vm.showPlaceholderDrawer = true
                },
              },
            },
            [_vm._v(" Form Placeholders ")]
          ),
          _c(
            "v-btn",
            { attrs: { icon: "", dark: "" }, on: { click: _vm.closeForm } },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _c(
            "validation-observer",
            { ref: "formulaForm" },
            [
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSubmit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("validation-provider", {
                            attrs: { name: "Calculation", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ errors, valid }) {
                                  return [
                                    _c("v-textarea", {
                                      attrs: {
                                        outlined: "",
                                        label: "Calculation",
                                        "hide-details": "auto",
                                        "error-messages": errors,
                                        success: valid,
                                        color: "primary",
                                        name: "value",
                                      },
                                      model: {
                                        value: _vm.formula,
                                        callback: function ($$v) {
                                          _vm.formula = $$v
                                        },
                                        expression: "formula",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("p", { staticClass: "caption" }, [
                          _vm._v("Readable Formula"),
                        ]),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm._f("readableQuestion")(
                                _vm.formula,
                                _vm.formDefinition
                              )
                            )
                          ),
                        ]),
                      ]),
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex justify-end",
                          attrs: { cols: "12" },
                        },
                        [
                          _c(
                            "v-btn",
                            { attrs: { color: "primary", type: "submit" } },
                            [_vm._v("Save Calculation")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-navigation-drawer",
                {
                  staticStyle: { "z-index": "2" },
                  attrs: {
                    fixed: "",
                    app: "",
                    right: "",
                    temporary: "",
                    width: "300px",
                  },
                  model: {
                    value: _vm.showPlaceholderDrawer,
                    callback: function ($$v) {
                      _vm.showPlaceholderDrawer = $$v
                    },
                    expression: "showPlaceholderDrawer",
                  },
                },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "primary" } },
                    [
                      _c(
                        "v-list-item",
                        { attrs: { "two-line": "" } },
                        [
                          _c("v-list-item-content", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-space-between mr-n6",
                              },
                              [
                                _c(
                                  "v-list-item-title",
                                  { staticClass: "white--text text-h6" },
                                  [_vm._v(" Form Placeholders ")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { text: "", color: "white" },
                                    on: {
                                      click: function ($event) {
                                        _vm.showPlaceholderDrawer = false
                                      },
                                    },
                                  },
                                  [_c("v-icon", [_vm._v("mdi-close")])],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("FormPlaceholdersForm", {
                    attrs: {
                      formDefinition: _vm.formDefinition,
                      type: "formula",
                    },
                    on: {
                      "placeholder-copied": function ($event) {
                        _vm.showCopiedSnackbar = true
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-snackbar",
            {
              staticStyle: { "z-index": "9999" },
              model: {
                value: _vm.showCopiedSnackbar,
                callback: function ($$v) {
                  _vm.showCopiedSnackbar = $$v
                },
                expression: "showCopiedSnackbar",
              },
            },
            [_vm._v(" Form Placeholder Copied ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }