<template>
  <div>
    <p class="caption">Layer Info</p>

    <v-card id="layerInfo">
      <v-card-text>
        <p class="caption mb-0 pb-0">Layer Type</p>
        <p>{{ selectedLayer.service_type | layerType }}</p>

        <validation-observer ref="layerForm">
          <form>
            <validation-provider
              v-slot="{ errors, valid }"
              name="Layer Name"
              rules="required"
            >
              <v-text-field
                v-model="selectedLayer.service_name"
                label="Layer Name *"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="serviceName"
                @change="$emit('layer-changed', selectedLayer)"
                :disabled="!canManageLayers"
              />
            </validation-provider>

            <div style="width: 40%">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Reference Field"
                v-if="['U', 'F'].includes(selectedLayer.service_type)"
              >
                <v-select
                  autocomplete="off"
                  clearable
                  v-model="selectedLayer.ref_field"
                  label="Reference Field"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="refField"
                  item-text="label"
                  item-value="value"
                  :items="gisDataFieldChoices"
                  @change="$emit('layer-changed', selectedLayer)"
                  :disabled="!canManageLayers"
                >
                </v-select>
              </validation-provider>
            </div>

            <div class="d-flex flex-wrap gap">
              <section>
                <v-switch
                  v-if="['U', 'F'].includes(selectedLayer.service_type)"
                  v-model="selectedLayer.stormwater_enabled"
                  label="Stormwater Enabled"
                  @change="$emit('layer-changed', selectedLayer)"
                  :disabled="!canManageLayers"
                ></v-switch>
              </section>

              <section>
                <v-switch
                  v-if="['U', 'F'].includes(selectedLayer.service_type)"
                  v-model="selectedLayer.site_enabled"
                  label="Site Enabled"
                  @change="$emit('layer-changed', selectedLayer)"
                  :disabled="!canManageLayers"
                ></v-switch>
              </section>
            </div>

            <validation-provider
              v-slot="{ errors, valid }"
              name="811 Code"
              v-if="['L'].includes(selectedLayer.service_type)"
            >
              <v-select
                autocomplete="off"
                label="811 Code"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="account811"
                :items="account811Choices"
                item-text="label"
                item-value="value"
                v-model="selectedLayer.locate_request_provider_account_id"
                @change="$emit('layer-changed', selectedLayer)"
                :disabled="!canManageLayers"
              >
              </v-select>
            </validation-provider>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import axios from "axios";
import permissionsMixin from "@/mixins/permissionsMixin";
import { mapGetters, mapMutations } from "vuex";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "LayerInfoForm",
  props: {
    layer: Object,
  },
  mixins: [permissionsMixin],
  data() {
    return {
      selectedLayer: {},
      gisDataFields: [],
      accounts811: [],
      featureServiceGisFields: [],
    };
  },
  async beforeMount() {
    await this.getGisDataFields();
    await this.getFeatureServiceFields();
    await this.get811Accounts();
  },
  computed: {
    ...mapGetters(["utiliSyncFieldsUpdated"]),
    gisDataFieldChoices() {
      if (this.layer.service_type === "F") {
        return this.featureServiceGisFields.map((f) => {
          const { name, alias } = f;
          return {
            label: alias || name,
            value: name,
          };
        });
      }
      return this.gisDataFields.map((f) => {
        const { gis_data_field_id: gisDataFieldId, name } = f;
        return {
          value: gisDataFieldId,
          label: name,
        };
      });
    },
    account811Choices() {
      const accounts811Options = this.accounts811.map((a) => {
        const {
          locate_request_provider_account_id: locateRequestProviderAccountId,
          label,
        } = a;
        return {
          label,
          value: locateRequestProviderAccountId,
        };
      });

      const showAll = {
        label: "Show All",
        value: null,
      };
      return [...accounts811Options, showAll];
    },
  },
  methods: {
    async getFeatureServiceFields() {
      const { map_service_id: mapServiceId } = this.layer;
      const {
        data: {
          results: { service_url: featureServiceUrl, token_type: tokenType },
        },
      } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
      const token =
        tokenType === "AGOL" ? localStorage.getItem("esri_token") : undefined;
      if (!featureServiceUrl) {
        return;
      }
      try {
        const params = {
          f: "pjson",
          token,
        };
        const {
          data: { fields = [] },
        } = await axios.get(featureServiceUrl, { params });
        this.featureServiceGisFields = fields;
      } catch (error) {
        console.warn(error);
      }
    },
    async getGisDataFields() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/gis_data_fields`, {
        params: {
          map_service_id: this.layer.map_service_id,
        },
      });
      this.gisDataFields = results;
    },
    async get811Accounts() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/locate_request_provider_accounts/all`);
      this.accounts811 = results;
    },
    ...mapMutations(["setUtiliSyncFieldsUpdated"]),
  },
  watch: {
    layer: {
      immediate: true,
      deep: true,
      handler(val) {
        this.selectedLayer = cloneDeep(val);
      },
    },
    utiliSyncFieldsUpdated: {
      immediate: true,
      async handler(val) {
        if (val) {
          await this.getGisDataFields();
          this.setUtiliSyncFieldsUpdated(false);
        }
      },
    },
  },
};
</script>
