var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showLayerIsInTheFollowingMapDialog,
        "max-width": "600px",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "m-2" },
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", color: "primary" } },
            [
              _c("v-toolbar-title", [_c("div", [_vm._v("Map Access")])]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c("v-card-text", { staticClass: "py-5" }, [
            _c("p", [
              _vm._v(
                " This layer is on the following maps that have access to the layer department(s) that you are attempting to remove access to. "
              ),
            ]),
            _c("p", [_vm._v(" " + _vm._s(_vm.mapNames.join(", ")) + " ")]),
            _c("p", [
              _vm._v(
                " You must first remove the layer from the map(s) before you can remove access to the department. "
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }