var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showAddLayerDialog,
        "max-width": "600",
        close: "",
        persistent: "",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("validation-observer", {
            ref: "addLayerForm",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ valid: isFormValid }) {
                  return [
                    _c(
                      "form",
                      {
                        attrs: { id: "portal-url-form" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.createLayer.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "v-toolbar",
                          {
                            staticClass: "elevation-0",
                            attrs: { dark: "", color: "primary" },
                          },
                          [
                            _c("v-toolbar-title", [_vm._v("Add Layer")]),
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "", dark: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("close")
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-card-text",
                          { staticClass: "pa-5" },
                          [
                            _c("p", [_vm._v("Name the related layer.")]),
                            _c("validation-provider", {
                              attrs: {
                                name: "Layer Name",
                                rules: {
                                  required: true,
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Layer Name",
                                            "hide-details": "auto",
                                            "error-messages": errors,
                                            success: valid,
                                            color: "primary",
                                            name: "portalUrl",
                                            "persistent-hint": "",
                                          },
                                          model: {
                                            value: _vm.layerName,
                                            callback: function ($$v) {
                                              _vm.layerName = $$v
                                            },
                                            expression: "layerName",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          { staticClass: "pa-5 d-flex justify-end" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "primary",
                                  disabled: !isFormValid,
                                },
                                on: { click: _vm.createLayer },
                              },
                              [_vm._v(" Create Layer ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }