<template>
  <section :class="{ selected }" class="py-5">
    <div>
      <span class="caption">
        {{ item.question.number }} {{ item.question.label }}
      </span>
    </div>

    <div class="d-flex align-center">
      <div>
        {{ result }}
      </div>
      <v-btn text @click.stop="showCalculationDialog = true">
        <v-icon>{{ mdiInformationOutline }}</v-icon>
      </v-btn>
    </div>

    <v-dialog
      v-model="showCalculationDialog"
      max-width="600px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <GroupCalculationList
        :groupedItems="groupedItems"
        :item="item"
        @calculation-list-close="showCalculationDialog = false"
      />
    </v-dialog>
  </section>
</template>

<script>
import { mdiInformationOutline } from "@mdi/js";
import { evaluate } from "mathjs";
import GroupCalculationList from "@/components/forms/form-builder-edit-form/item-preview/calculation-input/GroupCalculationList.vue";
import { isObject } from "lodash";

export default {
  name: "GroupCalculationInput",
  components: {
    GroupCalculationList,
  },
  props: {
    item: Object,
    formDefinition: Object,
    selected: Boolean,
    groupedItems: Array,
  },
  data() {
    return {
      mdiInformationOutline,
      showCalculationDialog: false,
      result: 0,
    };
  },
  methods: {
    calculateResult() {
      if (
        this.item.question.calculation &&
        Array.isArray(this.groupedItems) &&
        isObject(this.formDefinition)
      ) {
        let expression = this.item.question.calculation;
        const params = [
          ...this.item.question.calculation.matchAll(/{{(.*?)}}/g),
        ];
        const externalParams = [
          ...this.item.question.calculation.matchAll(
            /{{(params.\d+.col.\d+)}}/g
          ),
        ];
        const { sections } = { ...this.formDefinition.form };
        const nestedItems = sections.map(({ items }) => items);
        const formFieldItems = nestedItems.flat();
        for (const p of externalParams) {
          const [fullParam, param] = p;
          const [formFieldParam] = param.match(/params.\d+/);
          const [groupColumnParam] = param.match(/col.\d+/);
          const [, questionId] = formFieldParam.split(".");
          const [, columnNumber] = groupColumnParam.split(".");
          const formFieldItem = formFieldItems.find(
            (i) => +i.id === +questionId
          );
          const values = formFieldItem.value.map((v) => {
            return v[columnNumber - 1]?.value;
          });
          expression = expression.replace(fullParam, values.join(", "));
        }
        for (const p of params) {
          const [fullParam, param] = p.toString().split(",");
          const [, columnNumber] = param.split(".");
          const item = this.groupedItems[columnNumber - 1];
          expression = expression.replace(fullParam, item?.value ?? 0);
        }
        try {
          const result = evaluate(expression);
          const [, decimal] = result.toString().split(".");
          const decimalLength = decimal?.length ?? 0;
          return +result.toFixed(Math.min(2, decimalLength));
        } catch (error) {
          return 0;
        }
      }
      return 0;
    },
  },
  watch: {
    formDefinition: {
      deep: true,
      immediate: true,
      handler() {
        this.result = this.calculateResult();
      },
    },
    groupedItems: {
      deep: true,
      immediate: true,
      handler() {
        this.result = this.calculateResult();
      },
    },
    item: {
      deep: true,
      immediate: true,
      handler() {
        this.result = this.calculateResult();
      },
    },
    result(val) {
      this.$emit("input", val);
    },
  },
};
</script>
